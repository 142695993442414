import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../../core/http/api.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { User } from 'src/app/shared/models/User';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  selectedUser: User;
  resetPasswordEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.selectedUser = this.data;
   }

  resetPassword() {
    this.apiService.resetPassword(this.selectedUser.id).subscribe(response => {
      this.resetPasswordEvent.emit(200);
      this.toasterService.success(this.selectedUser.name +
       this.messages.success.SUCCESS_UPDATE_PASSWORD[this.messages.locale],
       this.messages.success.SUCCESS_TITLE[this.messages.locale], {positionClass: 'toast-top-center'});
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(error.message,
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    });
  }

}
