<!-- <div *ngIf="showDeleteCaseWindow" class="deleteCaseWindow">
	<h5 class="mb-3">Biztosan törli a dokumentumot?</h5>
	<button class="searchButton mr-5" (click)="deleteCase()">Igen</button>
	<button class="searchButton"  mat-dialog-close >Mégsem</button>
</div> -->

<i class="fa fa-times close" mat-dialog-close aria-hidden="true"></i>
<h3 mat-dialog-title i18n>Biztosan törli a dokumentumot?</h3>
<mat-dialog-content class="mat-typography">
    <div class="auth-form-container" style="clear: both;">
        <div class="d-md-flex align-items-center">
            <div class="option-wrapper">
                <button class="deleteCaseButton" (click)="deleteCase()" i18n>Igen</button>
            </div>
            <div class="option-wrapper">
                <button class="deleteCaseButton"  mat-dialog-close i18n>Mégsem</button>
            </div>
        </div>
    </div>
</mat-dialog-content>
