<i class="fa fa-times close" (click)="closeModal()"mat-dialog-close aria-hidden="true"></i>
<h3 mat-dialog-title i18n>Az aktuális dokumentumhoz több eset is tartozik.<br>Melyiket választja?</h3>
<mat-dialog-content class="mat-typography">
    <table>
        <thead>
            <tr class="table100-head">
                <th class="column1 l" i18n>létrehozás dátuma</th>
                <th class="column2" i18n>leletező orvos</th>
                <th class="column3 " i18n>típus</th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let case of cases" (click)="onSelectedCase(case.id)">
                    <td class="column1 l">{{case.creationTime | i18nDateFormat: messages.locale}}</td>
                    <td class="column2">{{case.doctorName}}</td>
                    <td class="column3 ">{{case.isValidated ? (messages.locale === 'en' ? 'Validated' : 'Validált') : (messages.locale === 'en' ? 'Waiting for validation' : 'Validálásra vár')}}</td>
                </tr>
        </tbody>
    </table>
</mat-dialog-content>
