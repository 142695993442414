<div class="wrapper" [formGroup]="cytologyApplicationForm" id="#cytologyForm">
    <div class="form-inline" >
        <label class="cytologyLabel">utolsó menstruáció</label>
        <input [formControl]="utolsoMenstruacio" class="cytologyInput form-control" type="text"/>
        <label style ="margin: 20px;"class="cytologyLabel">kenetvétel ideje</label>
        <input [formControl]="kenetvetelIdeje" class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel">kolposzkópia</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="kolposzkopiaNormal" type="checkbox" class="form-check-input" id="kolposzkopiaNormal">
            <label class="form-check-label" for="kolposzkopiaNormal">normál</label>
        </div>
        <div class="form-group form-check">
            <input [formControl]="kolposzkopiaKoros" type="checkbox" class="form-check-input" id="kolposzkopiaKoros">
            <label class="form-check-label" for="kolposzkopiaKoros">kóros</label>
        </div>
        <label class="cytologyLabel">éspedig</label>
        <input [formControl]="kolposzkopiaMegjegyzes" style="width: 300px"class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel">jelenleg terhes</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="jelenlegTerhesNem" type="checkbox" class="form-check-input" id="jelenlegTerhesNem">
            <label style="margin-right: 19px;" class="form-check-label" for="jelenlegTerhesNem">nem</label>
        </div>
        <div class="form-group form-check mr-5">
            <input [formControl]="jelenlegTerhesIgen" type="checkbox" class="form-check-input" id="jelenlegTerhes">
            <label class="form-check-label" for="jelenlegTerhes">igen</label>
        </div>
        <div class="form-group form-check mr-5">
            <input [formControl]="jelenlegTerhesLaktal" type="checkbox" class="form-check-input" id="laktal">
            <label style="margin-right: 28px;"class="form-check-label" for="laktal">laktál</label>
        </div>
        <input [formControl]="jelenlegTerhesMegjegyzes" style="width: 300px"class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel">fogamzásgátlás</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="fogamzasgatlasNem" type="checkbox" class="form-check-input" id="fogamzasgatlasNem">
            <label style="margin-right: 19px;"class="form-check-label" for="fogamzasgatlasNem">nem</label>
        </div>
        <div class="form-group form-check">
            <input [formControl]="fogamzasgatlasIgen" type="checkbox" class="form-check-input" id="fogamzasgatlas">
            <label class="form-check-label" for="fogamzasgatlas">igen</label>
        </div>
        <label sytle="width: 40px;" class="mr-3 ml-3">éspedig</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="fogamzasgatlasIUD" type="checkbox" class="form-check-input" id="IUD">
            <label class="form-check-label" for="IUD">IUD</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="fogamzasgatlasHormon" type="checkbox" class="form-check-input" id="hormon">
            <label class="form-check-label" for="hormon">hormon</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="fogamzasgatlasEgyeb" type="checkbox" class="form-check-input" id="egyeb">
            <label class="form-check-label" for="egyeb">egyéb</label>
        </div>
        <input [formControl]="fogamzasgatlasMegjegyzes" style="width: 166px"class="cytologyInput form-control" type="text"/>
    </div>
    <label class="ml-3 mt-3 mb-3">előzmény</label>
    <div class="form-inline">
        <label style="width: 84px"class="cytologyLabel">citológia</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isCitologiaNem" type="checkbox" class="form-check-input" id="citologiaNem">
            <label style="margin-right: 10px;" class="form-check-label" for="citologiaNem">nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isCitologiaIgen" type="checkbox" class="form-check-input" id="citologiaIgen">
            <label class="form-check-label" for="citologiaIgen">igen</label>
        </div>
        <label style="margin-right: 2px">hol</label>
        <input [formControl]="citologiaHely" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px">mikor</label>
        <input [formControl]="citologiaIdo" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px">dg</label>
        <input [formControl]="citologiaDg" class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel">szövettan</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isSzovettanNem" type="checkbox" class="form-check-input" id="szovettanNem">
            <label style="margin-right: 10px;" class="form-check-label" for="szovettanNem">nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isSzovettanIgen" type="checkbox" class="form-check-input" id="szovettanIgen">
            <label class="form-check-label" for="szovettanIgen">igen</label>
        </div>
        <label style="margin-right: 2px">hol</label>
        <input [formControl]="szovettanHely" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px">mikor</label>
        <input [formControl]="szovettanIdo" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px">dg</label>
        <input [formControl]="szovettanDg" class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel">műtét</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isMutetNem" type="checkbox" class="form-check-input" id="mutetnem">
            <label style="margin-right: 10px;" class="form-check-label" for="mutetnem">nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isMutetIgen" type="checkbox" class="form-check-input" id="mutetigen">
            <label class="form-check-label" for="mutetigen">igen</label>
        </div>
        <label class="cytologyLabel">éspedig</label>
        <input [formControl]="mutetMegjegyzes" style="width: 400px" class="cytologyInput form-control mr-3" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel">kezelés</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isKezelesNem" type="checkbox" class="form-check-input" id="kezelesNem">
            <label style="margin-right: 10px;" class="form-check-label" for="kezelesNem">nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isKezelesIgen" type="checkbox" class="form-check-input" id="kezelesIgen">
            <label class="form-check-label" for="kezelesIgen">igen</label>
        </div>
        <label class="cytologyLabel">éspedig</label>
        <input [formControl]="kezelesMegjegyzes" style="width: 400px" class="cytologyInput form-control mr-3" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel">egyéb</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="otherNem" type="checkbox" class="form-check-input" id="egyebNem">
            <label style="margin-right: 10px;" class="form-check-label" for="egyebNem">nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="otherIgen" type="checkbox" class="form-check-input" id="egyebIgen">
            <label class="form-check-label" for="egyebIgen">igen</label>
        </div>
        <label class="cytologyLabel">éspedig</label>
        <input [formControl]="otherMegjegyzes" style="width: 400px" class="cytologyInput form-control mr-3" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel">eszközös vétel</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="eszkozosVetelECTF" type="checkbox" class="form-check-input" id="ectfzonabol">
            <label style="margin-right: 10px;" class="form-check-label" for="ectfzonabol">EC/TF zónából</label>
        </div>
        <div class="form-group form-check mr-5">
            <input [formControl]="eszkozosVetelPortio" type="checkbox" class="form-check-input" id="portiofelszinerol">
            <label class="form-check-label" for="portiofelszinerol">portio felszínéről</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="eszkozosVetelMindketto" type="checkbox" class="form-check-input" id="mindkettorol">
            <label style="margin-right: 17px;"class="form-check-label" for="mindkettorol">mindkettőről</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="eszkozosVetelHuvely" type="checkbox" class="form-check-input" id="huvelyboltozatrol">
            <label class="form-check-label" for="huvelyboltozatrol">hüvelyboltozatról</label>
        </div>
    </div>
</div>
