import { SaveCytologyResV3Request } from './../../../shared/models/request/saveCytologyResV3Request';
import { Ebeu } from './../../../shared/models/ebeu';
import { ResetCaseComponent } from './../../components/reset-case/reset-case.component';
import { SelectEbeuComponent } from './../../components/select-ebeu/select-ebeu.component';
import { SetLogNumberRequest } from './../../../shared/models/request/setLogNumberRequest';
import { SelectCasesComponent } from './../../components/select-cases/select-cases.component';
import { HttpErrorResponse } from '@angular/common/http';
// tslint:disable: deprecation
// tslint:disable: max-line-length
import { FormService } from './../../../core/services/form.service';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { ApiService } from 'src/app/core/http/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from './../../../core/services/general.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Bno } from './../../../shared/models/bno';
import { ValidatorUser } from './../../../shared/models/validatorUser';
import { User } from './../../../shared/models/User';
import { GetUserByUserNameResponse } from './../../../shared/models/response/getUserByUsernameResponse';
import { PdfHeader } from './../../../shared/models/pdfHeader';
import { GetCaseByIdResponse } from './../../../shared/models/response/getCaseById';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/locale/messages';
import { SzakmaKod } from 'src/app/shared/models/szakmakod';
import * as _ from 'lodash';

@Component({
  selector: 'app-cytologicalreportv3',
  templateUrl: './cytologicalreportv3.component.html',
  styleUrls: ['./cytologicalreportv3.component.css']
})
export class Cytologicalreportv3Component implements OnInit {

  dateType = this.messages.locale ===  'en' ? 'en-US' : 'hu-HU';

  papanicolaou = new FormControl(null);
  diagnozisNegativ = new FormControl(false);
  diagnozisEgyeb = new FormControl(false);
  diagnozisKoros = new FormControl(false);
  dodorleinFlora = new FormControl(false);
  dodorleinFloraNem = new FormControl(false);
  korokozokTrichomonas = new FormControl(false);
  korokozokGomba = new FormControl(false);
  korokozokBakterialis = new FormControl(false);
  korokozokVegyes = new FormControl(false);
  korokozokActynomices = new FormControl(false);
  korokozokHerpes = new FormControl(false);
  korokozokCitomegalogVirus = new FormControl(false);
  korokozokEgyeb = new FormControl('');
  egyebEndometriumSejtek = new FormControl(false);
  kenetBeerkezesIdoPontja = new FormControl('');
  kenettipusPap = new FormControl(false);
  kenettipusLB = new FormControl(false);
  ertekelhetoKenetFeldolgozott = new FormControl(false);
  ertekelhetoKenetNemFeldolgozott = new FormControl(false);
  ertekelhetoKenetNemFeldolgozottMert = new FormControl('');
  ertekelhetoKenetErtekelhetoDe = new FormControl(false);
  ertekelhetoKenetNemErtekelheto = new FormControl(false);
  ertekelhetoKenetCellularitas = new FormControl(null);
  ertekelhetoKenetEndorCervicalis = new FormControl(false);
  ertekelhetoKenetZavaroTenyezok = new FormControl(false);
  ertekelhetoKenetLaphamsejtek = new FormControl(false);
  ertekelhetoKenetKiszaradas = new FormControl(false);
  ertekelhetoKenetNyakos = new FormControl(false);
  ertekelhetoKenetSulyosAcutGyulladas = new FormControl(false);
  ertekelhetoKenetGatloAnyagok = new FormControl(false);
  ertekelhetoKenetHuvelyKup = new FormControl(false);
  ertekelhetoKenetTulzottanVeresKenet = new FormControl(false);
  ertekelhetoKenetNemAzonosithato = new FormControl(false);
  ertekelhetoKenetSejtszegeny = new FormControl(false);
  ertekelhetoKenetEgyeb = new FormControl(false);
  ertekelhetoKenetEgyebMegjegyzes = new FormControl('');
  nemNeoplasztikusNemNeopl = new FormControl(false);
  nemNeoplasztikusLapham = new FormControl(false);
  nemNeoplasztikusEretlen = new FormControl(false);
  nemNeoplasztikusElszarusodas = new FormControl(false);
  nemNeoplasztikusTubalis = new FormControl(false);
  nemNeoplasztikusEgyeb = new FormControl(false);
  nemNeoplasztikusEgyebDrop = new FormControl(null);
  nemNeoplasztikusEgyebDrop2 = new FormControl(null);
  nemNeoplasztikusAtrophia = new FormControl(false);
  nemNeoplasztikusAtrophiasColpitis = new FormControl(false);
  nemNeoplasztikusGraviditashozTarsulo = new FormControl(false);
  nemNeoplasztikusGraviditas = new FormControl(null);
  nemNeoplasztikusPostPartum = new FormControl(false);
  nemNeoplasztikusReaktivSejtelvaltozasokkalJaro = new FormControl(false);
  nemNeoplasztikusReaktivGyulladasMerteke = new FormControl(null);
  nemNeoplasztikusLymphocytas = new FormControl(false);
  nemNeoplasztikusSugarhatas = new FormControl(false);
  nemNeoplasztikusKesoi = new FormControl(false);
  nemNeoplasztikusKemoterapiasHatas = new FormControl(false);
  nemNeoplasztikusKemoKesoi = new FormControl(false);
  nemNeoplasztikusIUDMechanikusHatas = new FormControl(false);
  nemNeoplasztikusMirigysejtekHysterect = new FormControl(false);
  nemNeoplasztikusRepair = new FormControl(false);
  nemNeoplasztikusRepairMegjegyzes = new FormControl('');
  laphamsejtekAtipusos = new FormControl(false);
  laphamsejtekNemMeghatarozhato = new FormControl(false);
  laphamsejtekNemZarhatoki = new FormControl(false);
  laphamsejtekEnyhefoku = new FormControl(false);
  laphamsejtekHPV = new FormControl(false);
  laphamsejtekCIN1 = new FormControl(false);
  laphamsejtekKifejezettFoku = new FormControl(false);
  laphamsejtekCIN2 = new FormControl(false);
  laphamsejtekCIN3 = new FormControl(false);
  laphamsejtekCIS = new FormControl(false);
  laphamsejtekMirigybeterjesedes = new FormControl(false);
  laphamsejtekInvasioGyanuja = new FormControl(false);
  laphamsejtekLaphamsejtekCarcinoma = new FormControl(false);
  mirigyhamsejtekAtypusos = new FormControl(false);
  mirigyhamsejtekEndoCervicalisNOS = new FormControl(false);
  mirigyhamsejtekEndometrialisNOS = new FormControl(false);
  mirigyhamsejtekNOS = new FormControl(false);
  mirigyhamsejtekInkabbNeoplasticusAGC = new FormControl(false);
  mirigyhamsejtekEndocervicalisInkabbNeoplast  = new FormControl(false);
  mirigyhamsejtekMirigyhamsejtekInkabbNeoplast = new FormControl(false);
  mirigyhamsejtekEndocervicalisAIS = new FormControl(false);
  mirigyhamsejtekAdenocarcinoma = new FormControl(false);
  mirigyhamsejtekEndocervicalis = new FormControl(false);
  mirigyhamsejtekEndoMetrialis = new FormControl(false);
  mirigyhamsejtekExtrauterin = new FormControl(false);
  mirigyhamsejtekAdenoNOS = new FormControl(false);
  javaslatKiegeszitoTesztek = new FormControl(false);
  javaslatHPVDNS = new FormControl(false);
  javaslatP16 = new FormControl(false);
  javaslatKi67 = new FormControl(false);
  javaslatCinTecPlusz = new FormControl(false);
  javaslatIsmetlesKlinikaiMegitelesSzerint = new FormControl(false);
  javaslatIsmetlesKezelesUtan = new FormControl(false);
  javaslatIsmetles36Honap = new FormControl(false);
  javaslatSzovettaniVizsgalat = new FormControl(false);
  javaslatKezeles = new FormControl(false);
  javaslatEgyeb = new FormControl(false);
  javaslatEgyebMegjegyzes = new FormControl('');
  gratisControl = new FormControl('');
  egyebMalingnusElvaltozas = new FormControl(false);

  currentYear = new Date().toISOString().substr(0, 4);

  cytologyResFormGroup = new FormGroup({
    gratis: this.gratisControl,
    papanicolaou: this.papanicolaou,
    diagnozis: new FormGroup({
      egyeb: this.diagnozisEgyeb,
      koros: this.diagnozisKoros,
      negativ: this.diagnozisNegativ
    }),
    doderleinFlora: this.dodorleinFlora,
    doderleinFloraNem: this.dodorleinFloraNem,
    egyeb: new FormGroup({
      endometriumSejtek: this.egyebEndometriumSejtek,
    }),
    ertekelhetoseg: new FormGroup({
      ertekelheto: new FormGroup({
        celluralitas: this.ertekelhetoKenetCellularitas,
        endocervialis: this.ertekelhetoKenetEndorCervicalis,
        ertekelhetoDe: this.ertekelhetoKenetErtekelhetoDe,
        gatloAnyagok: new FormGroup({
          gatloAnyagok: this.ertekelhetoKenetGatloAnyagok,
          huvelykup: this.ertekelhetoKenetHuvelyKup,
          tulzottanVeres: this.ertekelhetoKenetTulzottanVeresKenet
        }),
        zavaroTenyezok: new FormGroup({
          kiszaradas: this.ertekelhetoKenetKiszaradas,
          laphamsejtekKarosodasa: this.ertekelhetoKenetLaphamsejtek,
          nyakos: this.ertekelhetoKenetNyakos,
          sulyosAcutGyulladas: this.ertekelhetoKenetSulyosAcutGyulladas,
          zavaroTenyezok: this.ertekelhetoKenetZavaroTenyezok
        })
      }),
      feldolgozott: this.ertekelhetoKenetFeldolgozott,
      nemErtekleheto: new FormGroup({
        egyeb: this.ertekelhetoKenetEgyeb,
        egyebMegjegyzes: this.ertekelhetoKenetEgyebMegjegyzes,
        nemAzonosithato: this.ertekelhetoKenetNemAzonosithato,
        nemErtekelhetoMert: this.ertekelhetoKenetNemErtekelheto,
        sejtszegeny: this.ertekelhetoKenetSejtszegeny
      }),
      nemFeldolgozott: this.ertekelhetoKenetNemFeldolgozott,
      nemFeldolgozottMert: this.ertekelhetoKenetNemFeldolgozottMert
    }),
    javaslat: new FormGroup({
      cinTecPlusz: this.javaslatCinTecPlusz,
      egyeb: this.javaslatEgyeb,
      egyebMegj: this.javaslatEgyebMegjegyzes,
      hpvDns: this.javaslatHPVDNS,
      ismetlesHaromHatHonapMulva: this.javaslatIsmetles36Honap,
      ismetlesKezelesUtan: this.javaslatIsmetlesKezelesUtan,
      ismetlesKlinikaiMegitelesSzerint: this.javaslatIsmetlesKlinikaiMegitelesSzerint,
      ki67: this.javaslatKi67,
      kiegeszitoTesztek: this.javaslatKiegeszitoTesztek,
      p16: this.javaslatP16,
      szovettaniVizsgalat: this.javaslatSzovettaniVizsgalat,
      kezeles: this.javaslatKezeles
    }),
    kenetBeerkezesenekIdopontja: this.kenetBeerkezesIdoPontja,
    kenettipus: new FormGroup({
      lb: this.kenettipusLB,
      pap: this.kenettipusPap
    }),
    korokozok: new FormGroup({
      actinomyces: this.korokozokActynomices,
      bakterialisVaginosis: this.korokozokBakterialis,
      citomegalovirus: this.korokozokCitomegalogVirus,
      gomba: this.korokozokGomba,
      herpesSimlexVirus: this.korokozokHerpes,
      megjegyzes: this.korokozokEgyeb,
      trichomonas: this.korokozokTrichomonas,
      vegyesBakterium: this.korokozokVegyes
    }),
    nemNeoplasztikus: new FormGroup({
      atrophia: this.nemNeoplasztikusAtrophia,
      atrophiasColpitis: this.nemNeoplasztikusAtrophiasColpitis,
      egyeb: this.nemNeoplasztikusEgyeb,
      egyebMegj: this.nemNeoplasztikusEgyebDrop,
      egyebMegj2: this.nemNeoplasztikusEgyebDrop2,
      eretlen: this.nemNeoplasztikusEretlen,
      graviditashozTarsulo: this.nemNeoplasztikusGraviditashozTarsulo,
      graviditas: this.nemNeoplasztikusGraviditas,
      hpv: this.nemNeoplasztikusElszarusodas,
      laphamEtaplasia: this.nemNeoplasztikusLapham,
      mirigysejtekHysterectUtan: this.nemNeoplasztikusMirigysejtekHysterect,
      postPatrum: this.nemNeoplasztikusPostPartum,
      reaktiv: new FormGroup({
        gyulladasMerteke: this.nemNeoplasztikusReaktivGyulladasMerteke,
        iud: this.nemNeoplasztikusIUDMechanikusHatas,
        kesoi: this.nemNeoplasztikusKesoi,
        kemoKesoi: this.nemNeoplasztikusKemoKesoi,
        lymphocitas: this.nemNeoplasztikusLymphocytas,
        reaktivSejtelvaltozas: this.nemNeoplasztikusReaktivSejtelvaltozasokkalJaro,
        sugarhatas: this.nemNeoplasztikusSugarhatas,
        kemoHatas: this.nemNeoplasztikusKemoterapiasHatas
      }),
      repair: this.nemNeoplasztikusRepair,
      repairMegj: this.nemNeoplasztikusRepairMegjegyzes,
      tubalisMetaplasia: this.nemNeoplasztikusTubalis,
      nemNeoplSejtelv: this.nemNeoplasztikusNemNeopl
    }),
    neoplasztikus: new FormGroup({
      laphamsejtek: new FormGroup({
        asc: new FormGroup({
          asc: this.laphamsejtekAtipusos,
          nemMeghatarozhatoOkbol: this.laphamsejtekNemMeghatarozhato,
          nemZarhatoKiHsil: this.laphamsejtekNemZarhatoki
        }),
        hsil: new FormGroup({
          cin2: this.laphamsejtekCIN2,
          cin3: this.laphamsejtekCIN3,
          cis: this.laphamsejtekCIS,
          hsil: this.laphamsejtekKifejezettFoku,
          invasioGyanuja: this.laphamsejtekInvasioGyanuja,
          laphamsejtesCarcinoma: this.laphamsejtekLaphamsejtekCarcinoma,
          mirigybeterjedes: this.laphamsejtekMirigybeterjesedes,
        }),
        lsil: new FormGroup({
          lsil: this.laphamsejtekEnyhefoku,
          cin1: this.laphamsejtekCIN1,
          hpv: this.laphamsejtekHPV
        })
      }),
      mirigyhamsejtek: new FormGroup({
        adenocarcinoma: new FormGroup({
          adenocarcinoma: this.mirigyhamsejtekAdenocarcinoma,
          endocervicalis: this.mirigyhamsejtekEndocervicalis,
          endometrialis: this.mirigyhamsejtekEndoMetrialis,
          nos: this.mirigyhamsejtekAdenoNOS,
          extrauterin: this.mirigyhamsejtekExtrauterin
        }),
        agc: new FormGroup({
          agc: this.mirigyhamsejtekInkabbNeoplasticusAGC,
          endocervicalisSejtek: this.mirigyhamsejtekEndocervicalisInkabbNeoplast,
          mirigyhamsejtek: this.mirigyhamsejtekMirigyhamsejtekInkabbNeoplast
        }),
        ais: this.mirigyhamsejtekEndocervicalisAIS,
        nos: new FormGroup({
          agcNos: this.mirigyhamsejtekAtypusos,
          endocervialisSejtek: this.mirigyhamsejtekEndoCervicalisNOS,
          endometrialisSejtek: this.mirigyhamsejtekEndometrialisNOS,
          mirigyhamsejtek: this.mirigyhamsejtekNOS
        }),
        egyebMalingnusElvaltozas: this.egyebMalingnusElvaltozas
      })
    })
  });

  logPrefix: number;
  gratis: boolean;
  logNumber = new FormControl('');
  bno = new FormControl('');
  eesztBeutaloAzon = new FormControl('');
  beutaloAzon = new FormControl('');
  arrivalTime = new FormControl('');
  responseCreationDate = new FormControl('');
  doctorName = new FormControl('');
  preScreeningSpecialistControl = new FormControl('');
  validatorUserControl = new FormControl('');
  sendEmailNotificationControl = new FormControl(true);
  szakmakodControl = new FormControl(null);
  // pdfHeader = new FormControl('');

  cytologyResDataFormGroup = new FormGroup({
    logNumber: this.logNumber,
    bno: this.bno,
    eesztBeutaloAzon: this.eesztBeutaloAzon,
    beutaloAzon: this.beutaloAzon,
    arrivalTime: this.arrivalTime,
    responseCreationDate: this.responseCreationDate,
    doctorName: this.doctorName,
    // pdfheaders: this.pdfHeader,
    preScreeningSpecialist: this.preScreeningSpecialistControl
  });

  cases: Array<GetCaseByIdResponse>;

  printablePdf: string;
  // pdfHeaders: Array<PdfHeader>;

  user: GetUserByUserNameResponse;
  preScreeningSpecialists = new Array<User>();
  validatorUsers: Array<ValidatorUser>;
  bnoList: Array<Bno>;
  szakmakodok: Array<SzakmaKod>;

  constructor(
    private dialog: MatDialog,
    private toasterService: ToastrService,
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    private formService: FormService,
    private messages: Messages
    ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getCasesByCaseId(+this.route.snapshot.paramMap.get('caseId'));
    this.findAllValidatorUser(this.user.servicePointId);
    this.findAllBno();
    this.findAllSzakmakod();

    // this.getPdfHeadersBySpId(this.user.servicePointId);
    this.getUsersOfInstitute(this.user.servicePointId);
    this.formService.xOrCheckboxes(this.ertekelhetoKenetFeldolgozott, this.ertekelhetoKenetNemFeldolgozott);
    this.formService.xOrCheckboxes(this.dodorleinFlora, this.dodorleinFloraNem);
    this.formService.xOrCheckboxes(this.kenettipusLB, this.kenettipusPap);
    this.formService.xOrCheckboxes(this.mirigyhamsejtekEndocervicalisInkabbNeoplast, this.mirigyhamsejtekMirigyhamsejtekInkabbNeoplast);
    this.formService.xOrCheckboxes(this.laphamsejtekNemMeghatarozhato, this.laphamsejtekNemZarhatoki);
    this.formService.checkBoxWithText(this.nemNeoplasztikusGraviditashozTarsulo, this.nemNeoplasztikusGraviditas);
    this.formService.checkBoxWithText(this.javaslatEgyeb, this.javaslatEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.ertekelhetoKenetEgyeb, this.ertekelhetoKenetEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.nemNeoplasztikusRepair, this.nemNeoplasztikusRepairMegjegyzes);
    this.formService.checkBoxWithText(this.ertekelhetoKenetNemFeldolgozott, this.ertekelhetoKenetNemFeldolgozottMert);
    this.formService.xOr3Checkboxes(this.diagnozisKoros, this.diagnozisNegativ, this.diagnozisEgyeb);

    this.formService.checkBoxWithText(this.ertekelhetoKenetFeldolgozott, this.korokozokEgyeb);

    this.formService.xORCheckboxMultiple(this.ertekelhetoKenetNemErtekelheto, [
      this.diagnozisKoros,
      this.diagnozisNegativ,
      this.diagnozisEgyeb,

      this.dodorleinFlora,
      this.dodorleinFloraNem,

      this.korokozokTrichomonas,
      this.korokozokVegyes,
      this.korokozokHerpes,
      this.korokozokGomba,
      this.korokozokCitomegalogVirus,
      this.korokozokBakterialis,
      this.korokozokActynomices,

      this.egyebEndometriumSejtek,

      this.nemNeoplasztikusNemNeopl,
      this.nemNeoplasztikusReaktivSejtelvaltozasokkalJaro,
      this.nemNeoplasztikusMirigysejtekHysterect,
      this.nemNeoplasztikusRepair,

      this.laphamsejtekAtipusos,
      this.laphamsejtekEnyhefoku,
      this.laphamsejtekKifejezettFoku,
      this.laphamsejtekLaphamsejtekCarcinoma,

      this.mirigyhamsejtekAtypusos,
      this.mirigyhamsejtekInkabbNeoplasticusAGC,
      this.mirigyhamsejtekEndocervicalisAIS,
      this.mirigyhamsejtekAdenocarcinoma,
      this.egyebMalingnusElvaltozas,

      this.javaslatKiegeszitoTesztek,
      this.javaslatIsmetlesKlinikaiMegitelesSzerint,
      this.javaslatIsmetlesKezelesUtan,
      this.javaslatIsmetles36Honap,
      this.javaslatEgyeb,
      this.javaslatSzovettaniVizsgalat,
      this.javaslatKezeles,
    ]);
    this.formService.xORCheckboxMultiple(this.ertekelhetoKenetNemFeldolgozott, [
      this.diagnozisKoros,
      this.diagnozisNegativ,
      this.diagnozisEgyeb,

      this.dodorleinFlora,
      this.dodorleinFloraNem,

      this.korokozokTrichomonas,
      this.korokozokVegyes,
      this.korokozokHerpes,
      this.korokozokGomba,
      this.korokozokCitomegalogVirus,
      this.korokozokBakterialis,
      this.korokozokActynomices,

      this.egyebEndometriumSejtek,

      this.nemNeoplasztikusNemNeopl,
      this.nemNeoplasztikusReaktivSejtelvaltozasokkalJaro,
      this.nemNeoplasztikusMirigysejtekHysterect,
      this.nemNeoplasztikusRepair,

      this.laphamsejtekAtipusos,
      this.laphamsejtekEnyhefoku,
      this.laphamsejtekKifejezettFoku,
      this.laphamsejtekLaphamsejtekCarcinoma,

      this.mirigyhamsejtekAtypusos,
      this.mirigyhamsejtekInkabbNeoplasticusAGC,
      this.mirigyhamsejtekEndocervicalisAIS,
      this.mirigyhamsejtekAdenocarcinoma,
      this.egyebMalingnusElvaltozas,

      this.javaslatKiegeszitoTesztek,
      this.javaslatIsmetlesKlinikaiMegitelesSzerint,
      this.javaslatIsmetlesKezelesUtan,
      this.javaslatIsmetles36Honap,
      this.javaslatEgyeb,
      this.javaslatSzovettaniVizsgalat,
      this.javaslatKezeles,
    ]);

    this.formService.xOrCheckboxes(this.ertekelhetoKenetErtekelhetoDe, this.ertekelhetoKenetNemErtekelheto);

    this.formService.xOrCheckboxArray([
      this.mirigyhamsejtekExtrauterin,
      this.mirigyhamsejtekAdenoNOS,
      this.mirigyhamsejtekEndoMetrialis,
      this.mirigyhamsejtekEndocervicalis
    ]);
    this.formService.xOrCheckboxArray([
      this.mirigyhamsejtekNOS,
      this.mirigyhamsejtekEndoCervicalisNOS,
      this.mirigyhamsejtekEndometrialisNOS,
    ]);

    this.formService.xOrCheckboxes(this.laphamsejtekAtipusos, this.laphamsejtekEnyhefoku);
    this.formService.xOrCheckboxes(this.laphamsejtekAtipusos, this.laphamsejtekKifejezettFoku);
    this.formService.xOrCheckboxArray([
      this.laphamsejtekAtipusos,
      this.laphamsejtekLaphamsejtekCarcinoma
    ]);

    this.formService.checkBase(this.laphamsejtekAtipusos, [
      this.laphamsejtekNemMeghatarozhato,
      this.laphamsejtekNemZarhatoki,
    ]);
    this.formService.checkBase(this.laphamsejtekEnyhefoku, [
      this.laphamsejtekHPV,
      this.laphamsejtekCIN1,
    ]);
    this.formService.checkBase(this.laphamsejtekKifejezettFoku, [
      this.laphamsejtekCIN2,
      this.laphamsejtekCIN3,
      this.laphamsejtekCIS,
      this.laphamsejtekMirigybeterjesedes,
      this.laphamsejtekInvasioGyanuja,
    ]);
    this.formService.checkBase(this.ertekelhetoKenetFeldolgozott, [
      this.ertekelhetoKenetErtekelhetoDe,
      this.ertekelhetoKenetNemErtekelheto
    ]);

    this.formService.checkBase(this.nemNeoplasztikusNemNeopl, [
      this.nemNeoplasztikusLapham,
      this.nemNeoplasztikusAtrophia,
      this.nemNeoplasztikusAtrophiasColpitis,
      this.nemNeoplasztikusElszarusodas,
      this.nemNeoplasztikusPostPartum,
      this.nemNeoplasztikusTubalis,
      this.nemNeoplasztikusEgyeb,
      this.nemNeoplasztikusGraviditashozTarsulo
    ]);

    this.formService.checkBoxWithText(this.ertekelhetoKenetErtekelhetoDe, this.ertekelhetoKenetCellularitas);
    this.formService.checkBase(this.nemNeoplasztikusLapham, [this.nemNeoplasztikusEretlen]);
    this.formService.checkBase(this.ertekelhetoKenetErtekelhetoDe, [
      this.ertekelhetoKenetEndorCervicalis,
      this.ertekelhetoKenetZavaroTenyezok,
      this.ertekelhetoKenetGatloAnyagok
    ]);

    this.formService.checkBase(this.mirigyhamsejtekAtypusos, [
      this.mirigyhamsejtekEndoCervicalisNOS,
      this.mirigyhamsejtekEndometrialisNOS,
      this.mirigyhamsejtekNOS
    ]);
    this.formService.checkBase(this.mirigyhamsejtekAdenocarcinoma, [
      this.mirigyhamsejtekEndocervicalis,
      this.mirigyhamsejtekEndoMetrialis,
      this.mirigyhamsejtekExtrauterin,
      this.mirigyhamsejtekAdenoNOS
    ]);

    this.formService.checkBase(this.mirigyhamsejtekInkabbNeoplasticusAGC, [
      this.mirigyhamsejtekEndocervicalisInkabbNeoplast,
      this.mirigyhamsejtekMirigyhamsejtekInkabbNeoplast
    ]);

    this.formService.checkBase(this.ertekelhetoKenetZavaroTenyezok, [
      this.ertekelhetoKenetLaphamsejtek,
      this.ertekelhetoKenetKiszaradas,
      this.ertekelhetoKenetNyakos,
      this.ertekelhetoKenetSulyosAcutGyulladas
    ]);
    this.formService.checkBase(this.ertekelhetoKenetGatloAnyagok, [
      this.ertekelhetoKenetHuvelyKup,
      this.ertekelhetoKenetTulzottanVeresKenet
    ]);
    this.formService.checkBase(this.ertekelhetoKenetNemErtekelheto, [
      this.ertekelhetoKenetNemAzonosithato,
      this.ertekelhetoKenetSejtszegeny,
      this.ertekelhetoKenetEgyeb
    ]);
    this.formService.checkBoxWithText(this.nemNeoplasztikusReaktivSejtelvaltozasokkalJaro, this.nemNeoplasztikusReaktivGyulladasMerteke);
    this.formService.checkBoxWithText(this.nemNeoplasztikusEgyeb, this.nemNeoplasztikusEgyebDrop);
    this.formService.checkBoxWithText(this.nemNeoplasztikusEgyeb, this.nemNeoplasztikusEgyebDrop2);
    this.formService.checkBase(this.nemNeoplasztikusReaktivSejtelvaltozasokkalJaro, [
      this.nemNeoplasztikusLymphocytas,
      this.nemNeoplasztikusSugarhatas,
      this.nemNeoplasztikusKesoi,
      this.nemNeoplasztikusIUDMechanikusHatas,
      this.nemNeoplasztikusKemoterapiasHatas,
      this.nemNeoplasztikusKemoKesoi
    ]);

    this.formService.checkBase(this.javaslatKiegeszitoTesztek, [
      this.javaslatHPVDNS,
      this.javaslatP16,
      this.javaslatKi67,
      this.javaslatCinTecPlusz
    ]);

    this.laphamsejtekAtipusos.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.laphamsejtekEnyhefoku.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.laphamsejtekKifejezettFoku.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.laphamsejtekLaphamsejtekCarcinoma.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.mirigyhamsejtekAtypusos.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.mirigyhamsejtekInkabbNeoplasticusAGC.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.mirigyhamsejtekEndocervicalisAIS.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.mirigyhamsejtekAdenocarcinoma.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });
    this.egyebMalingnusElvaltozas.valueChanges.subscribe(value => {
      if (value) {
        this.diagnozisKoros.setValue(true);
      }
    });

    this.diagnozisNegativ.valueChanges.subscribe(value => {
      if (value) {
        this.resetKorosCheckBoxes();
      }
    });

    this.diagnozisKoros.valueChanges.subscribe(value => {
      if (!value) {
        this.resetKorosCheckBoxes();
      }
    });
  }

  findAllSzakmakod() {
    this.apiService.getAllSzakmaKod().subscribe(response => {
      this.szakmakodok = response;
      const szakmakod = response.find(x => x.code === '5402');
      this.szakmakodControl.setValue(szakmakod.id);
    });
  }

  resetKorosCheckBoxes() {
    this.laphamsejtekAtipusos.setValue(false);
    this.laphamsejtekEnyhefoku.setValue(false);
    this.laphamsejtekKifejezettFoku.setValue(false);
    this.laphamsejtekLaphamsejtekCarcinoma.setValue(false);
    this.mirigyhamsejtekAtypusos.setValue(false);
    this.mirigyhamsejtekInkabbNeoplasticusAGC.setValue(false);
    this.mirigyhamsejtekEndocervicalisAIS.setValue(false);
    this.mirigyhamsejtekAdenocarcinoma.setValue(false);
    this.egyebMalingnusElvaltozas.setValue(false);
  }



  findAllValidatorUser(servicePointId: number) {
    this.apiService.getAllValidatorUsersBySp(servicePointId).subscribe(response => {
      this.validatorUsers = response;
      if (response.length === 1) {
        this.validatorUserControl.setValue(response[0].id);
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

  findAllBno() {
    this.apiService.findAllBno().subscribe(response => {
      this.bnoList = response.filter(x =>
        x.code.toLowerCase() === 'n8700' ||
        x.code.toLowerCase() === 'n8710' ||
        x.code.toLowerCase() === 'n8720' ||
        x.code.toLowerCase() === 'c5390' ||
        x.code.toLowerCase() === 'c5490' ||
        x.code.toLowerCase() === 'u9990' ||
        x.code.toLowerCase() === 'n72h0' ||
        x.code.toLowerCase() === 'n8880' ||
        x.code.toLowerCase() === 'n8930' ||
        x.code.toLowerCase() === 'n9030'
        );
    }, (error: Response) => {
      console.error(error);
    });
  }

  getUsersOfInstitute(servicePointId: number) {
    const preScreeningSpecialists = new Array<User>();
    this.apiService.getUsersByRefId(servicePointId).subscribe(response => {
      response.forEach(user => {
        if (user.role.code !== 'ADMIN' && user.role.code !== 'REFERRING_ASSISTANT' && user.role.code !== 'REFERRING_DOCTOR') {
          preScreeningSpecialists.push(user);
        }
      });
      this.preScreeningSpecialists = preScreeningSpecialists;
    }, (error: Response) => {
      console.error(error);
    });
  }

  saveCytologyRes(isValidated: boolean) {
    let technicalUserId: number;

    this.apiService.getServicePointById(this.user.servicePointId).subscribe(response => {
      technicalUserId = response.technicalUserId;
    }, error => {
      console.error(error);
    });



    if (this.bno.value && this.logNumber.value && this.preScreeningSpecialistControl.value && this.validatorUserControl.value && this.szakmakodControl.value) {
      if (this.logNumber.value.length <= 8) {
        const request: SaveCytologyResV3Request = {
          szakmakodId: this.szakmakodControl.value,
          sendEmailNotification: this.sendEmailNotificationControl.value,
          validatorUserId: this.validatorUserControl.value,
          caseId: +this.route.snapshot.paramMap.get('caseId'),
          creatorUsername: this.user.username,
          preScreeningSpecialistId: this.preScreeningSpecialistControl.value,
          isValidated,
          patientId: +this.route.snapshot.paramMap.get('patientId'),
          servicePointId: this.user.servicePointId,
          beutaloId: this.eesztBeutaloAzon.value,
          customBeutaloId: this.beutaloAzon.value,
          diagnose: this.bno.value,
          logNumber: this.logNumber.value,
          pdfHeaderId: null,
          citologyResXmlV3: this.cytologyResFormGroup.value
        };

        request.citologyResXmlV3.gratis = this.gratis;

        if (this.isRequestValid(request)) {
          this.apiService.saveCytologyResV3Request(request).subscribe(response => {
            if (this.logNumber.enabled) {
              this.setLogNumber(request.caseId, this.logNumber.value);
            }
            this.toasterService.success(
              this.messages.success.SUCCESS_SAVE_OK[this.messages.locale],
              this.messages.success.SUCCESS_TITLE[this.messages.locale], { positionClass: 'toast-top-center' });
            // this.generalService.goToPage('reports');
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate([currentUrl]);
            });
          }, (error: HttpErrorResponse) => {
            // if (error.status === 500) {
              this.toasterService.error(error.error.message, error.status.toString(),
              { positionClass: 'toast-top-center' });
            // }
          });
        } else {
          this.toasterService.error(
            this.messages.error.ERROR_FILL_FORM_VALID[this.messages.locale],
            this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
              { positionClass: 'toast-top-center' });
        }
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_LENGTH[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    } else {
      if (!this.szakmakodControl.value) {
        this.toasterService.error('Kérem adja meg a szakmakódot!', 'Hiba!', {positionClass: 'toast-top-center'});
      }
      if (!this.validatorUserControl.value) {
        this.toasterService.error(
          this.messages.error.ERROR_VALIDATOR_DOCTOR_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
      if (!this.preScreeningSpecialistControl.value) {
        this.toasterService.error(
          this.messages.error.ERROR_PRE_SCREENING_SPECIALIST_MISSING[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
      if (!this.logNumber.value) {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
      if (!this.bno.value) {
        this.toasterService.error(
          this.messages.error.ERROR_BNO_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    }
  }

  getCasesByCaseId(caseId: number) {
    this.cases = [];
    // this.getDescription(caseId);
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(element => {
        if (element.type.includes('RES')) {
           if (((this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') && element.isValidated) ||
            (this.user.roleCode !== 'REFERRING_DOCTOR' && this.user.roleCode !== 'REFERRING_ASSISTANT')) {
            this.cases.push(element);
          }
        } else if (element.type.includes('REQ')) {
          this.logNumber.setValue(element.values['kenetszam']);
          this.gratis = !element.values['gratis'] ? false : true;
        }
      });

      if (this.cases.length > 1) {
        const cases = this.cases;
        const dialogRef = this.dialog.open(SelectCasesComponent, {
          data: {cases}
        });
        const sub = dialogRef.componentInstance.selectedCase.subscribe((data: number) => {
          this.getCaseById(data);
          dialogRef.close();
        });
      } else {
        if (this.cases !== []) {
          this.getCaseById(this.cases[0]?.id);
          this.getDescription(response[0]?.id, true);
        }
      }
    });
  }

  getCaseById(id: number) {
    if (this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') {
      this.cytologyResFormGroup.disable();
      this.cytologyResDataFormGroup.disable();
    }
    this.cases.forEach(element => {
      if (element.id === id) {
        this.printablePdf = element.pdf;

        this.bno.setValue(element.diagnose);
        if (element.values['kenetBeerkezesenekIdopontja']) {
          element.values['kenetBeerkezesenekIdopontja'] = element.values['kenetBeerkezesenekIdopontja'].substr(0, element.values['kenetBeerkezesenekIdopontja'].indexOf('T'));
        }
        this.cytologyResFormGroup.setValue(element.values);
        this.getDescription(element.id);
      }
    });
  }

  getDescription(id: number, isNewDocument?: boolean) {
    const currentDate = this.generalService.getCurrentDate();
    this.apiService.getDescription(id).subscribe(response => {
      this.logPrefix = response.logPrefix;
      if (response.logNumber) {
        // this.logNumber.disable();
        this.logNumber.setValue(response.logNumber);
      }
      if (response.preScreeningSpecialistId) {
        this.preScreeningSpecialistControl.setValue(response.preScreeningSpecialistId);
      } else {
        this.preScreeningSpecialistControl.setValue(this.user.id);
      }

      if (response.customBeutaloId) {
        this.beutaloAzon.disable();
        this.beutaloAzon.setValue(response.customBeutaloId);
      }
      if (response.arrivalTime) {
        response.arrivalTime = this.sharedDataService.
        dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(response.arrivalTime)));
        this.arrivalTime.disable();
        this.arrivalTime.setValue(response.arrivalTime.replace('T', ' '));
      }
      this.responseCreationDate.setValue(currentDate.substr(0, currentDate.indexOf('.')).replace('T', ' '));
      this.responseCreationDate.disable();

      if (isNewDocument) {
        if (this.user.roleCode === 'REFERRING_ASSISTANT' || this.user.roleCode === 'REFERRING_DOCTR') {
          this.doctorName.setValue('');
          this.doctorName.disable();
        } else {
          this.doctorName.setValue(this.user.name);
          this.doctorName.disable();
        }

      } else {
        this.doctorName.setValue(response.doctorName);
        this.doctorName.disable();
      }

    }, (error: Response) => {
      console.error(error);
    });
  }

  setLogNumber(caseId: number, logNumber: string) {
    const request: SetLogNumberRequest = {
      caseId,
      logNumber
    };

    this.apiService.setLogNumber(request).subscribe(response => {
      // this.generalService.goToPage('reports');
    }, (error: Response) => {
      console.error(error);
    });
  }


  checkEszigTokenValid() {
    if (JSON.parse(localStorage.getItem('eszigTokenValid')))  {
      const dialogRef = this.dialog.open(SelectEbeuComponent);
      dialogRef.componentInstance.selectedEbeu.subscribe(ebeu => {
        this.setEbeuAzon(ebeu);
        dialogRef.close();
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_EESZT_AUTH_EXPIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'}
        );
    }
  }

  setEbeuAzon(ebeu: Ebeu) {
    this.eesztBeutaloAzon.setValue(ebeu.businessContent.beutaloAzonosito);
    this.eesztBeutaloAzon.disable();
  }

  deleteCase() {
    const dialogRef = this.dialog.open(ResetCaseComponent);
    dialogRef.componentInstance.option.subscribe(response => {
      this.cytologyResFormGroup.reset();
      this.diagnozisNegativ.setValue(false);
      dialogRef.close();
    });
  }

  // getPdfHeadersBySpId(spId: number) {
  //   this.apiService.getPdfHeadersBySpId(spId).subscribe(response => {
  //     this.pdfHeaders = response;
  //     if (response.length === 1) {
  //       this.pdfHeader.setValue(response[0].id);
  //     }
  //   }, (error: Response) => {
  //     console.error(error);
  //   });
  // }

  isRequestValid(request: SaveCytologyResV3Request): boolean {
    if (request.citologyResXmlV3) {
      if (new Date() < new Date(request.citologyResXmlV3.kenetBeerkezesenekIdopontja)) {
        this.toasterService.error(this.messages.error.ERROR_DATE_CANNOT_BE_FUTURE[this.messages.locale]);
        return false;
      }
      if (request.citologyResXmlV3.ertekelhetoseg?.nemFeldolgozott) {
        if (!request.citologyResXmlV3.ertekelhetoseg?.nemFeldolgozottMert) {
          this.toasterService.error(this.messages.error.ERROR_NOT_PROCESSED_COMMENT[this.messages.locale]);
          return false;
        } else {
          return true;
        }
      }

      if (request.citologyResXmlV3.diagnozis?.koros && !request.citologyResXmlV3.neoplasztikus?.laphamsejtek?.asc?.asc && !request.citologyResXmlV3.neoplasztikus?.laphamsejtek?.lsil?.lsil &&
        !request.citologyResXmlV3.neoplasztikus?.laphamsejtek?.hsil?.hsil && !request.citologyResXmlV3.neoplasztikus?.laphamsejtek?.hsil?.laphamsejtesCarcinoma &&
        !request.citologyResXmlV3.neoplasztikus?.mirigyhamsejtek?.nos?.agcNos && !request.citologyResXmlV3?.neoplasztikus?.mirigyhamsejtek?.agc?.agc &&
        !request.citologyResXmlV3.neoplasztikus?.mirigyhamsejtek?.ais && !request.citologyResXmlV3.neoplasztikus?.mirigyhamsejtek?.adenocarcinoma?.adenocarcinoma &&
        !request.citologyResXmlV3.neoplasztikus?.mirigyhamsejtek?.egyebMalingnusElvaltozas) {
          this.toasterService.error(this.messages.error.ERROR_KOROS_DIAG_OKA[this.messages.locale]);
          return false;
      }

      if (request.citologyResXmlV3.ertekelhetoseg?.feldolgozott) {
        if (request.citologyResXmlV3.ertekelhetoseg?.nemErtekleheto) {
          if (request.citologyResXmlV3.ertekelhetoseg?.nemErtekleheto?.nemAzonosithato ||
              request.citologyResXmlV3.ertekelhetoseg?.nemErtekleheto?.sejtszegeny ||
              request.citologyResXmlV3.ertekelhetoseg?.nemErtekleheto?.egyeb) {
              return true;
            }
        }
      }
      if ((!request.citologyResXmlV3?.kenettipus?.lb && !request.citologyResXmlV3?.kenettipus?.pap) ||
      (!request.citologyResXmlV3?.diagnozis?.egyeb && !request.citologyResXmlV3?.diagnozis.koros && !request.citologyResXmlV3?.diagnozis?.negativ) ||
      (!request.citologyResXmlV3?.ertekelhetoseg?.feldolgozott && !request.citologyResXmlV3?.ertekelhetoseg?.nemFeldolgozott))  {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkValidDate(dateString: string) {
    const today = new Date();
    const dateInput = new Date(dateString);

    if (today < dateInput) {
      this.toasterService.error(this.messages.error.ERROR_DATE_CANNOT_BE_FUTURE[this.messages.locale]);
    }
  }

}
