import { CookieService } from 'ngx-cookie-service';
import { GetPatientResponse } from 'src/app/shared/models/response/getPatientResponse';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GetServicePointByIdResponse } from 'src/app/shared/models/response/getServicePointByIdResponse';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';

@Injectable({
  providedIn: 'root'
})
export class ShareddataService {

  messageSource = new ReplaySubject<any>(1);
  isCyto = new ReplaySubject<boolean>(1);
  isReport = new ReplaySubject<boolean>(1);
  currentPatient = new ReplaySubject<GetPatientResponse>(1);
  currentUser = new ReplaySubject<GetUserByUserNameResponse>(1);

  eszigTokenExpiration = null;
  eszigTokenExpirationShared = new ReplaySubject<any>(1);
  eszigTokenisValid = new ReplaySubject<boolean>(1);

  constructor(
    private cookieService: CookieService,
  ) { }

  formGroupChange(currentMessage) {
    this.messageSource.next(currentMessage);
  }

  isCytoChange(isCyto: boolean) {
    this.isCyto.next(isCyto);
  }

  setCurrentPatient(patient: GetPatientResponse) {
    this.currentPatient.next(patient);
  }

  isReportChange(isReport: boolean) {
    this.isReport.next(isReport);
  }

  dateFromISO8601(isostr) {
    const parts = isostr.match(/\d+/g);
    parts[1] = parts[1] - 1;
    return new Date(parts[0], parts[1], parts[2], parts[3], parts[4], parts[5]);
  }

  dateToDateString(date: Date) {
    const dateString = date.getFullYear() + '-' +
      (('0' + (date.getMonth() + 1)).slice(-2)) + '-' +
      (('0' + date.getDate()).slice(-2));
    return dateString;
  }

  DateStringConvert(date: string) {
    // const dateFromDate = this.dateFromISO8601(date.substring(0, date.indexOf('.')));
    const dateFromDate = this.dateFromISO8601(date);
    const dateReturn = dateFromDate.getTime() - (dateFromDate.getTimezoneOffset() * 60000);
    return dateReturn;
  }

  dateToDateTimeStringHuman(date: Date) {
    const dateTimeString = date.getFullYear() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)) + '-' + (('0' + date.getDate()).slice(-2)) +
      ' ' + (('0' + date.getHours()).slice(-2)) + ':' + (('0' + date.getMinutes()).slice(-2)) + ':' + (('0' + date.getSeconds()).slice(-2));

    return dateTimeString;
  }

  setEszigTokenExpDate(expirationDate: string) {
    this.cookieService.set('etexpDate', expirationDate);
    if (this.DateStringConvert(expirationDate) > new Date().getTime()) {
      localStorage.setItem('eszigTokenExpiration', this.DateStringConvert(expirationDate).toString());
      this.eszigTokenExpiration = this.DateStringConvert(expirationDate);
      this.eszigTokenExpirationShared.next(this.eszigTokenExpiration);
    }
  }

  setEszigTokenValid(isValid: boolean) {
    localStorage.setItem('eszigTokenValid', isValid.toString());
    this.eszigTokenisValid.next(isValid);
  }

}
