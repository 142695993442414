import { LoginComponent } from './../../pages/login/login.component';
import { GetUserByUserNameResponse } from './../../../shared/models/response/getUserByUsernameResponse';
// tslint:disable: deprecation
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/http/api.service';
import { FormService } from 'src/app/core/services/form.service';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-cytologyapplicationv2',
  templateUrl: './cytologyapplicationv2.component.html',
  styleUrls: ['./cytologyapplicationv2.component.css']
})
export class Cytologyapplicationv2Component implements OnInit, OnDestroy {

  dateType = this.messages.locale === 'en' ? 'en-US' : 'hu-HU';

  @Input() cytologyVersion: string;

  logNumber  = new FormControl('');

  utolsoMenstruacio = new FormControl('');
  kenetvetelIdeje = new FormControl('');
  kenetSzam = new FormControl('');

  fogamzasgatlasNem = new FormControl(false);
  fogamzasgatlasIgen = new FormControl(false);
  fogamzasgatlasIUD = new FormControl(false);
  fogamzasgatlasHormon = new FormControl(false);
  fogamzasgatlasEgyeb = new FormControl(false);
  fogamzasgatlasMegjegyzes = new FormControl('');

  isCitologiaNem = new FormControl(false);
  isCitologiaIgen = new FormControl(false);
  citologiaDg = new FormControl('');
  citologiaHely = new FormControl('');
  citologiaIdo = new FormControl('');

  isKezelesNem = new FormControl(false);
  isKezelesIgen = new FormControl(false);
  kezelesMegjegyzes = new FormControl('');

  isMutetNem = new FormControl(false);
  isMutetIgen = new FormControl(false);
  mutetMegjegyzes = new FormControl('');

  isSzovettanNem = new FormControl(false);
  isSzovettanIgen = new FormControl(false);
  szovettanDg = new FormControl('');
  szovettanHely = new FormControl('');
  szovettanIdo = new FormControl('');

  jelenlegTerhesNem = new FormControl(false);
  jelenlegTerhesIgen = new FormControl(false);
  jelenlegTerhesLaktal = new FormControl(false);
  jelenlegTerhesMegjegyzes = new FormControl('');

  kolposzkopiaNormal = new FormControl(false);
  kolposzkopiaKoros = new FormControl(false);
  kolposzkopiaMegjegyzes = new FormControl('');

  otherIgen = new FormControl(false);
  otherNem = new FormControl(false);
  otherMegjegyzes = new FormControl('');

  eszkozosVetelECTF = new FormControl(false);
  eszkozosVetelPortio = new FormControl(false);
  eszkozosVetelMindketto = new FormControl(false);
  eszkozosVetelHuvely = new FormControl(false);
  eszkozosVetelEgyeb = new FormControl(false);
  eszkozosVetelEgyebMegjegyzes = new FormControl('');

  konvCytologia = new FormControl(false);
  konvCytologiaCintec = new FormControl(false);
  konvCytologiaCintecAltalany = new FormControl(false);
  konvCytologiaHrHpvTip =  new FormControl(false);
  konvCytologiaHrHpvTipPluszCyt = new FormControl(false);
  konvCytologiaHrHpvTipPluszLbc = new FormControl(false);
  konvCytologiaLbc = new FormControl(false);
  konvCytologiaSurgos = new FormControl(false);

  hpvTipizalasEredmenye = new FormControl('');
  hpvTipizalasIgen = new FormControl(false);
  hpvTipizalasNem = new FormControl(false);

  cytologyApplicationFormV2 = new FormGroup( {
    utolsoMenstruacio: this.utolsoMenstruacio,
    kenetvetelIdeje: this.kenetvetelIdeje,
    fogamzasgatlasNem: this.fogamzasgatlasNem,
    fogamzasgatlasIgen: this.fogamzasgatlasIgen,
    fogamzasgatlasIUD: this.fogamzasgatlasIUD,
    fogamzasgatlasHormon: this.fogamzasgatlasHormon,
    fogamzasgatlasEgyeb: this.fogamzasgatlasEgyeb,
    fogamzasgatlasMegjegyzes: this.fogamzasgatlasMegjegyzes,
    isCitologiaNem: this.isCitologiaNem,
    isCitologiaIgen: this.isCitologiaIgen,
    citologiaDg: this.citologiaDg,
    citologiaHely: this.citologiaHely,
    citologiaIdo: this.citologiaIdo,
    isKezelesNem: this.isKezelesNem,
    isKezelesIgen: this.isKezelesIgen,
    kezelesMegjegyzes: this.kezelesMegjegyzes,
    isMutetNem: this.isMutetNem,
    isMutetIgen: this.isMutetIgen,
    mutetMegjegyzes: this.mutetMegjegyzes,
    isSzovettanNem: this.isSzovettanNem,
    isSzovettanIgen: this.isSzovettanIgen,
    szovettanDg: this.szovettanDg,
    szovettanHely: this.szovettanHely,
    szovettanIdo: this.szovettanIdo,
    jelenlegTerhesNem: this.jelenlegTerhesNem,
    jelenlegTerhesIgen: this.jelenlegTerhesIgen,
    jelenlegTerhesLaktal: this.jelenlegTerhesLaktal,
    jelenlegTerhesMegjegyzes: this.jelenlegTerhesMegjegyzes,
    kolposzkopiaNormal: this.kolposzkopiaNormal,
    kolposzkopiaKoros: this.kolposzkopiaKoros,
    kolposzkopiaMegjegyzes: this.kolposzkopiaMegjegyzes,
    otherIgen: this.otherIgen,
    otherNem: this.otherNem,
    otherMegjegyzes: this.otherMegjegyzes,
    eszkozosVetelECTF: this.eszkozosVetelECTF,
    eszkozosVetelPortio: this.eszkozosVetelPortio,
    eszkozosVetelMindketto: this.eszkozosVetelMindketto,
    eszkozosVetelHuvely: this.eszkozosVetelHuvely,
    eszkozosVetelEgyeb: this.eszkozosVetelEgyeb,
    eszkozosVetelEgyebMegjegyzes: this.eszkozosVetelEgyebMegjegyzes,
    hpvTipizalasEredmenye: this.hpvTipizalasEredmenye,
    hpvTipizalasIgen: this.hpvTipizalasIgen,
    hpvTipizalasNem: this.hpvTipizalasNem,
    konvCytologia: this.konvCytologia,
    konvCytologiaCintec: this.konvCytologiaCintec,
    konvCytologiaCintecAltalany: this.konvCytologiaCintecAltalany,
    konvCytologiaHrHpvTip: this.konvCytologiaHrHpvTip,
    konvCytologiaHrHpvTipPluszCyt: this.konvCytologiaHrHpvTipPluszCyt,
    konvCytologiaHrHpvTipPluszLbc: this.konvCytologiaHrHpvTipPluszLbc,
    konvCytologiaLbc: this.konvCytologiaLbc,
    konvCytologiaSurgos: this.konvCytologiaSurgos,
    kenetSzam: this.kenetSzam,
    logNumber: this.logNumber
});

  user: GetUserByUserNameResponse;

  public sub = new Subscription();

  statusOfCase: string;
  constructor(
    private sharedDataService: ShareddataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private formService: FormService,
    private messages: Messages
  ) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.sub = this.cytologyApplicationFormV2.valueChanges.subscribe(data => {
        this.sharedDataService.formGroupChange(data);
    });
    if (+this.route.snapshot.paramMap.get('caseId')) {
      this.checkCaseStatus(+this.route.snapshot.paramMap.get('caseId'));
    } else {
      this.setKenetSzam(JSON.parse(localStorage.getItem('servicePoint')));
    }

    this.formService.checkBoxWithText(this.kolposzkopiaKoros, this.kolposzkopiaMegjegyzes);
    // this.formService.checkBoxesWithText([this.jelenlegTerhesLaktal,this.jelenlegTerhesIgen] , this.jelenlegTerhesMegjegyzes);
    this.formService.checkBoxWithText(this.fogamzasgatlasEgyeb, this.fogamzasgatlasMegjegyzes);
    this.formService.checkBoxWithText(this.isCitologiaIgen, this.citologiaDg);
    this.formService.checkBoxWithText(this.isCitologiaIgen, this.citologiaHely);
    this.formService.checkBoxWithText(this.isCitologiaIgen, this.citologiaIdo);
    this.formService.checkBoxWithText(this.isSzovettanIgen, this.szovettanDg);
    this.formService.checkBoxWithText(this.isSzovettanIgen, this.szovettanHely);
    this.formService.checkBoxWithText(this.isSzovettanIgen, this.szovettanIdo);
    this.formService.checkBoxWithText(this.isMutetIgen, this.mutetMegjegyzes);
    this.formService.checkBoxWithText(this.isKezelesIgen, this.kezelesMegjegyzes);
    this.formService.checkBoxWithText(this.otherIgen, this.otherMegjegyzes);
    this.formService.checkBoxWithText(this.hpvTipizalasIgen, this.hpvTipizalasEredmenye);
    this.formService.checkBoxWithText(this.eszkozosVetelEgyeb, this.eszkozosVetelEgyebMegjegyzes);

    this.formService.xOrCheckboxes(this.kolposzkopiaNormal, this.kolposzkopiaKoros);
    this.formService.xOrCheckboxes(this.jelenlegTerhesIgen, this.jelenlegTerhesNem);
    this.formService.xOrCheckboxes(this.fogamzasgatlasIgen, this.fogamzasgatlasNem);
    this.formService.xOrCheckboxes(this.isCitologiaIgen, this.isCitologiaNem);
    this.formService.xOrCheckboxes(this.isSzovettanIgen, this.isSzovettanNem);
    this.formService.xOrCheckboxes(this.fogamzasgatlasIgen, this.fogamzasgatlasNem);
    this.formService.xOrCheckboxes(this.isCitologiaIgen, this.isCitologiaNem);
    this.formService.xOrCheckboxes(this.isSzovettanIgen, this.isSzovettanNem);
    this.formService.xOrCheckboxes(this.isMutetNem, this.isMutetIgen);
    this.formService.xOrCheckboxes(this.isKezelesIgen, this.isKezelesNem);
    this.formService.xOrCheckboxes(this.otherIgen, this.otherNem);
    this.formService.xOrCheckboxes(this.hpvTipizalasIgen, this.hpvTipizalasNem);


    this.formService.checkBase(this.fogamzasgatlasIgen, [this.fogamzasgatlasIUD, this.fogamzasgatlasHormon, this.fogamzasgatlasEgyeb]);

    this.formService.xOrCheckboxArray([
    this.konvCytologia, this.konvCytologiaLbc,
    this.konvCytologiaHrHpvTip, this.konvCytologiaHrHpvTipPluszCyt,
    this.konvCytologiaHrHpvTipPluszLbc, this.konvCytologiaLbc,
    this.konvCytologiaCintecAltalany, this.konvCytologiaSurgos,
    this.konvCytologiaCintec]);
  }

  ngOnDestroy() {
    this.sharedDataService.formGroupChange(this.cytologyApplicationFormV2.reset());
    this.sub.unsubscribe();
  }

  setKenetSzam(servicePoint: any) {
    if (!this.logNumber.value) {
      this.apiService.getNextLogNumber(servicePoint['refServicePointId']).subscribe(response => {
        this.logNumber.setValue(response);
      });
    }
  }

  checkCaseStatus(caseId: number) {
    this.apiService.findCaseByCaseId(caseId).subscribe(response => {
      this.statusOfCase = response.status;
      this.getCaseById(+this.route.snapshot.paramMap.get('caseId'));
    });
  }

  getCaseById(caseId: number) {
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(document => {
        if (document.type.includes('REQ')) {
          this.cytologyApplicationFormV2.setValue(document.values);
          if (this.statusOfCase !== 'új') {
            this.cytologyApplicationFormV2.disable();
          } else {
            if (this.user.roleCode !== 'REFERRING_ASSISTANT' && this.user.roleCode !== 'REFERRING_DOCTOR') {
              this.cytologyApplicationFormV2.disable();
            }
          }
        }
      });
    }, (error: Response) => {
      console.error(error);
    });
  }

}
