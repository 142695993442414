<i class="fa fa-times close" mat-dialog-close aria-hidden="true"></i>
<h3 mat-dialog-title i18n>Az aktuális dokumentumhoz több eset is tartozik.<br>Melyiket választja?</h3>
<mat-dialog-content class="mat-typography">
        <label i18n>beutaló időszak kezdete</label>
        <input [formControl]="beutaloIdoszakKezdet" class="form-control w-50" type="date" max="2099-12-31" min="2000-01-01"   [lang]="dateType"/>
        <label class="mt-2" i18n>beutaló időszak vége</label>
        <input [formControl]="beutaloIdoszakVege" class="form-control w-50" type="date" max="2099-12-31" min="2000-01-01" [lang]="dateType"/>
        <label  class="mt-2" i18n>ellátás időszak kezdete</label>
        <input [formControl]="ellatasIdoszakKezdete" class="form-control w-50" type="date" max="2099-12-31" min="2000-01-01" [lang]="dateType"/>
        <label  class="mt-2" i18n>ellátás időszak vége</label>
        <input [formControl]="ellatasIdoszakVege" class="form-control w-50" type="date" max="2099-12-31" min="2000-01-01" [lang]="dateType"/>
    <div class="form-inline mt-3">
        <div class="form-group form-check">
            <input [formControl]="breakGlass" type="checkbox" class="form-check-input" id="breakglass">
            <label class="form-check-label mr-2" for="breakglass" i18n>sürgősségi adatlekérés</label>
        </div>
        <input [formControl]="breakOkGlass" class="form-control" type="text"/>
    </div>
    <p *ngIf="breakGlass.value == true" class="mt-3" style="font-size: x-small" i18n>
        A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br>
    1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br>
    2.) A páciens 24 órás egyedi engedélye alapján (minta az e-egeszegugy.gov.hu oldalon letölthető).
    </p>
    <button class="cytoReportButton mt-5 mb-2 mr-3" (click)="searchEbeutalo()" i18n>Keresés</button>
    <button class="cytoReportButton mt-5 mb-2" mat-dialog-close i18n>Mégsem</button>
    <div class="table-wrapper">
        <table class="mb-5">
            <thead>
                <tr class="table100-head">
                    <th class="column1 l" i18n>beteg neve</th>
                    <th class="column2" i18n>beteg tajszáma</th>
                    <th class="column3 " i18n>beutaló intézmény neve</th>
                    <th class="column4 " i18n>beutaló orvos</th>
                    <th class="column5 " i18n>beutaló pecsétszáma</th>
                    <th class="column6 " i18n>kiállítás dátuma</th>
                    <th class="column7 " i18n>érvényesség ideje</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let ebeu of ebeuResponse" (click)="onSelectedEbeuAzon(ebeu)">
                        <td class="column1 l">{{ebeu.businessContent.beteg.nev.lastName + ebeu.businessContent.beteg.nev.firstName}}</td>
                        <td class="column2">{{ebeu.businessContent.beteg.taj}}</td>
                        <td class="column3 ">{{ebeu.businessContent.beutaloIntezmeny.intezmenyMegnevezes}}</td>
                        <td class="column4 ">{{ebeu.businessContent.beutaloOrvos.lastName + ebeu.businessContent.beutaloOrvos.firstName}}</td>
                        <td class="column5 ">{{ebeu.businessContent.beutaloOrvos.stampNo}}</td>
                        <td class="column6 ">{{ebeu.businessContent.kiallitasDatuma.substr(0,ebeu.businessContent.kiallitasDatuma.indexOf('T'))}}</td>
                        <td class="column7 ">{{ebeu.businessContent.ervenytessegDatuma.substr(0,ebeu.businessContent.ervenytessegDatuma.indexOf('T'))}}</td>
                    </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
