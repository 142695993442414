<i class="fa fa-times close" mat-dialog-close aria-hidden="true"></i>
<h3 mat-dialog-title i18n>Milyen finanszírozási státuszt kíván beállítani az esethez?</h3>
<mat-dialog-content class="mat-typography">
    <table id="financeTable">
        <thead>
            <tr class="table100-head">
                <th class="finColumn1" i18n>típus</th>
                <th class="finColumn2" i18n>naplószám</th>
                <th class="finColumn3" i18n>beteg neve</th>
                <th class="finColumn4" i18n>beküldő klinika</th>
                <th class="finColumn5" i18n>beküldő orvos</th>
                <th class="finColumn6" i18n>29450</th>
                <th class="finColumn7" i18n>29400</th>
                <th class="finColumn8" i18n>29070</th>
                <th class="finColumn9" i18n>25040</th>
                <th class="finColumn10" i18n>29050</th>
                <th class="finColumn11" i18n>29000</th>
                <th class="finColumn11" i18n>összeg</th>
            </tr>
        </thead>
        <tbody>
                <tr>
                    <td class="finColumn1">{{accountedCase.type | translateType: messages.locale}}</td>
                    <td class="finColumn2">{{accountedCase.logNumber}}</td>
                    <td class="finColumn3">{{accountedCase.patient.name}}</td>
                    <td class="finColumn4">{{accountedCase.servicePoint.name}}</td>
                    <td class="finColumn5">{{accountedCase.doctorName}}</td>
                    <td class="finColumn6">{{accountedCase.who ? accountedCase.who.cytologiaiVizsgalatImmuncyto : ''}}</td>
                    <td class="finColumn7">{{accountedCase.who ? accountedCase.who.diagnosztikusAspiratiosExfol : ''}}</td>
                    <td class="finColumn8">{{accountedCase.who ? accountedCase.who.kiegeszitoPontszamVolumiozus : ''}}</td>
                    <td class="finColumn9">{{accountedCase.who ? accountedCase.who.mikroszkoposVizsgalatZiehl : ''}}</td>
                    <td class="finColumn10">{{accountedCase.who ? accountedCase.who.szovettaniVizsgalatImmunhisz : ''}}</td>
                    <td class="finColumn11">{{accountedCase.who ? accountedCase.who.szovettaniVizsgalatASzokaso : ''}}</td>
                    <td class="finColumn11">{{accountedCase.price ? accountedCase.price : ''}}</td>
                </tr>
        </tbody>
    </table>
    <div class="modifyWindowForm form-inline mt-3">
        <button (click)="accountCase(true)" i18n>Könyvelt</button>
        <button (click)="accountCase(false)" i18n>Nem könyvelt</button>
    </div>
</mat-dialog-content>
