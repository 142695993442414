<app-dashboard></app-dashboard>
<div class="wrapper">
    <label class="histologyHeaderLabel mt-3" i18n>makroszkópos leírás</label>
    <div *ngFor="let item of [].constructor(histologyReqLocalizacio.length); let i=index" class="form-inline">
        <label class="histologyLabel">{{i+1 | arabicToRoman }}</label>
        <textarea [formControl]="makroszkop[i]" class="histologytextArea form-control mt-3"  name="makroszkop" rows="8"></textarea>
    </div>

    <label class="histologyHeaderLabel mt-3" i18n>mikroszkópos leírás</label>
    <div *ngFor="let item of [].constructor(histologyReqLocalizacio.length);  let i=index" class="form-inline">
        <label class="histologyLabel">{{i+1 | arabicToRoman }}</label>
        <textarea [formControl]="mikroszkop[i]" class="histologytextArea form-control mt-3"  name="mikroszkop" rows="8"></textarea>
    </div>

    <label class="histologyHeaderLabel mt-3" i18n>kórszövettani diagnózis</label>
    <div *ngFor="let item of [].constructor(histologyReqLocalizacio.length);  let i=index" class="form-inline">
        <label class="histologyLabel">{{i+1 | arabicToRoman }}</label>
        <textarea [formControl]="korszovdiag[i]" class="histologytextArea form-control mt-3" name="korszovdiag" rows="8"></textarea>
    </div>

    <label class="histologyHeaderLabel mt-3" i18n>who</label>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>25040 mikroszkópos vizsgálat ziehl-nielsen festéssel</span>
        <input [formControl]="who25040" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>29000 szövettani vizsgálat a szokásos feldolgozási módszerrel
        </span>
        <input [formControl]="who29000" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>29050 szövettani vizsgálat immunhisztokémiai reakció alkalmazása esetén</span>
        <input [formControl]="who29050" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>29070 kiegészítő pontszám voluminózus anyag esetén
        </span>
        <input [formControl]="who29070" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>29400 diagnosztikus aspiratios/exfoliatív citológiai vizsgálat a szokásos feldolgozási módszerrel</span>
        <input [formControl]="who29400" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>29450 cytologiai vizsgálat immuncitokémiai rekcióval
        </span>
        <input [formControl]="who29450" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>25030 Mikroszkópos vizsgálat, tenyésztetlen anyagból
        </span>
        <input [formControl]="who25030" class="whoInput form-control" type="number"/>
    </div>
    <div class="form-inline mt-3">
        <span class="whoLabel" i18n>27000 Gomba vizsgálata direkt kenetben
        </span>
        <input [formControl]="who27000" class="whoInput form-control" type="number"/>
    </div>
    <label class="histologyHeaderLabel mt-3" i18n="@@bno">bno</label>
    <div class="form-inline">
        <div *ngFor="let item of [].constructor(histologyReqLocalizacio.length);  let i=index" class="form-inline">
            <label class="bnoLabel">{{i+1 | arabicToRoman }}</label>
            <ng-select [formControl]="bnoRes[i]" class="histologyInput ng_select" [items]="bnoStringList" [virtualScroll]="true"></ng-select>
        </div>
    </div>
    <label class="histologyHeaderLabel mt-3" i18n>snomed</label>
    <div class="form-inline mt-3">
        <div *ngFor="let item of [].constructor(histologyReqLocalizacio.length);  let i=index" class="form-inline">
            <label class="snomedLabel">{{i+1 | arabicToRoman }}</label>
            <ng-select [formControl]="snomed[i]" class="histologyInput ng_select" [items]="snomedList" [virtualScroll]="true"></ng-select>
        </div>
    </div>
    <div style="float:right; margin-bottom: 50px" class="form-inline mt-3">
        <div  *ngIf="!user?.roleCode.includes('REFERRING')" class="form-group form-check mr-3">
            <input [formControl]="sendEmailNotificationControl" type="checkbox" class="form-check-input" id="sendEmailNotification">
            <label class="form-check-label" for="sendEmailNotification"  title="e-mail értesítés küldése a beküldő orvos által megadott címre" i18n>email értesítés küldése</label>
        </div>
        <button (click)="generalService.printPDf(printablePdf)" class="histoReportButton mr-3" i18n>nyomtatás</button>
        <button [disabled]="user?.roleCode.includes('REFERRING')" [ngClass]="user?.roleCode.includes('REFERRING') ? 'disabledhistReportButton mr-3' : 'histoReportButton mr-3'" (click)="deleteCase()" class="histoReportButton mr-3" i18n>törlés</button>
        <button [disabled]="user?.roleCode != 'HISTOPATHOLOGIST' && user?.roleCode != 'CYTOPATHOLOGISTS'" [ngClass]="user?.roleCode == 'HISTOPATHOLOGIST'  || user?.roleCode == 'CYTOPATHOLOGISTS' ? 'histoReportButton mr-3' :'disabledhistReportButton mr-3'" (click)="saveHistologyRes(true)" class="histoReportButton mr-3" type="submit" i18n>validálás</button>
        <button [disabled]="user?.roleCode.includes('REFERRING')" [ngClass]="user?.roleCode.includes('REFERRING') ? 'disabledhistReportButton mr-3' : 'histoReportButton mr-3'"  (click)="saveHistologyRes(false)" class="histoReportButton" type="submit" i18n>mentés</button>
    </div>
</div>
<form class="histoData" [formGroup]="histologyResDataFormGroup">
    <label class="histoDataLabel mt-5" i18n>naplószám</label>
    <div class="form-inline mt-1">
        <label class="mr-1">{{logPrefix ? logPrefix + '/' : ''}}</label>
        <input [formControl]="logNumber" class="histoDataInput form-control required" type="text" name="lognumber" style="width: 158px;"/>
    </div>
    <label class="histoDataLabel mt-3" i18n="@@bno">bno</label>
    <ng-select [formControl]="bno" class="histoDataInput ng_select_required bnoSelector" [items]="bnoList" bindValue="code" [virtualScroll]="true" [searchFn]="generalService.bnoSearchFn">
        <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item"  title="item">
            {{item.code}} - {{item.name}}
        </ng-template>
    </ng-select>
    <label class="histoDataLabel mt-3" i18n>szakmakód</label>
    <ng-select [formControl]="szakmakodControl" class="histoDataInput ng_select_required bnoSelector" [items]="szakmakodok" bindValue="id" [virtualScroll]="true">
      <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item" title="item.name">
        <span title="{{item.name}}">{{item.name}}</span>
      </ng-template>
    </ng-select>
    <label class="histoDataLabel mt-3" i18n>beutaló azonosító</label>
    <input [formControl]="beutaloAzon" class="histoDataInput form-control" name="beutaloazon" type="text">
    <label class="cytoDataLabel mt-3" i18n>fejléc</label>
    <ng-select class='histoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="pdfHeaders" [searchable]="true" bindLabel="instituteName" bindValue="id" [formControl]="pdfHeader" placeholder="Válasszon..."></ng-select>
    <button (click)="checkEszigTokenValid()" class="histoReportButton mt-5" i18n>e-beutalók lekérdezése</button><br>
    <label class="histoDataLabel mt-3" i18n>EESZT beutaló azonosító</label>
    <input [formControl]="eesztBeutaloAzon" class="mt-2 histoDataInput form-control" name="eesztbeutaloazon">
    <label class="histoDataLabel mt-3" i18n>kérés online feladásának ideje</label>
    <input [formControl]="arrivalTime" class="histoDataInput form-control"/>
    <label class="histoDataLabel mt-3" i18n>leletezés megkezdésének ideje</label>
    <input [formControl]="responseCreationDate" class="histoDataInput form-control"/>
    <label class="histoDataLabel mt-3" i18n>leletezést végezte</label>
    <input [formControl]="doctorName" class="histoDataInput form-control" type="text"/>
</form>

