import { ServicePoint } from './../../../../shared/models/ServicePoint';
import { ApiService } from './../../../../core/http/api.service';
import { Component, OnInit } from '@angular/core';
import { ThemeService, Label } from 'ng2-charts';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  user: GetUserByUserNameResponse;
  refServicePoints: Array<ServicePoint>;
  refServicePointsControl = new FormControl('');

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 1
        }
      }]
    }
  };
  barChartLabels = new Array<Label>();
  barChartType: ChartType;
  barChartLegend = true;
  barChartPlugins = [];

  barChartTypeControl = new FormControl('bar');
  barChartTypeOptions = [{
    name: 'oszlop',
    value: 'bar'
  }, {
    name: 'kör',
    value: 'pie'
  }, {
    name: 'vonal',
    value: 'line'
  }, {
    name: 'terület',
    value: 'polarArea'
  }, {
    name: 'radar',
    value: 'radar'
  }];

  barChartData: ChartDataSets[] = [
    { data: [], label: 'Összes dokumentum' },
    { data: [], label: 'Hibás dokumentum' }
  ];

  constructor(
    private themeService: ThemeService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getServicePointsByRef();
  }

  getWrongDocumentsForSp(servicePointId: number) {

    const wrongDocuments = new Array<number>();
    const totalDocuments = new Array<number>();
    const wrongDocumentLabels =  new Array<Label>();

    this.apiService.getWrongDocumentsForSp(servicePointId).subscribe(response => {

      response.forEach(wrongdocument => {

        wrongDocumentLabels.push(wrongdocument.name);
        wrongDocuments.push(wrongdocument.wrongDocumentAmount);
        totalDocuments.push(wrongdocument.totalDocumentAmount);
      });

      this.barChartLabels = wrongDocumentLabels;
      this.barChartData[0].data = totalDocuments;
      this.barChartData[1].data = wrongDocuments;
    }, (error: Response) => {
      console.error(error);
    });
  }

  getServicePointsByRef() {
    this.apiService.getAllServicePointsByRef(this.user.servicePointId).subscribe(response => {
      this.refServicePoints = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  changeCharType() {
    if (this.barChartType === 'bar') {
      this.barChartType = 'pie';
    } else {
      this.barChartType = 'bar';
    }
  }
}
