<div class="container" *ngIf="!isLoggedIn">
    <img class="logo img-fluid" src="../../../../assets/images/nautopsy_logo_04-02-05.png">
    <form class="form-group">
        <div class="row title">
            <h2 i18n>A dokumentum megnyitásához<br>adja meg jelszavát!</h2>
        </div>
        <div class="row mt-5">
            <input [formControl]="password" class="loginInput form-control" type="password" placeholder="Jelszó"/>
        </div>
        <div class="row mt-3">
            <button (click)="openDocument()" class="loginButton btn btn-primary" type="submit" i18n>Megnyitás</button>
        </div>
    </form>

</div>
<div *ngIf="isLoggedIn">

</div>
