import { ToastrService } from 'ngx-toastr';
// tslint:disable: deprecation
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/http/api.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormService } from './../../../core/services/form.service';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { GetCaseByIdResponse } from './../../../shared/models/response/getCaseById';
import { GetPatientResponse } from './../../../shared/models/response/getPatientResponse';
import { GetUserByUserNameResponse } from './../../../shared/models/response/getUserByUsernameResponse';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-cytologyapplicationv3',
  templateUrl: './cytologyapplicationv3.component.html',
  styleUrls: ['./cytologyapplicationv3.component.css']
})
export class Cytologyapplicationv3Component implements OnInit, OnChanges {

  dateType = this.messages.locale === 'en' ? 'en-US' : 'hu-HU';
  @Input() patientData;

  betegKora = new FormControl('');
  graviditas = new FormControl(false);
  graviditasNumber = new FormControl(null);
  partius = new FormControl(false);
  partiusNumber = new FormControl(null);
  laktal = new FormControl(false);
  laktalIgen = new FormControl(false);
  laktalNem = new FormControl(false);
  utolsoMenstruacioIgen = new FormControl(false);
  utolsoMenstruacioNem = new FormControl(false);
  utolsoMenstruacioIdeje = new FormControl('');
  jelenlegTerhesIgen = new FormControl(false);
  jelenlegTerhesHet = new FormControl('');
  szuletesiIdo = new FormControl('');
  fogamzasgatlasNem  = new FormControl(false);
  fogamzasgatlasIgen = new FormControl(false);
  fogamzasgatlasMegjegyzes  = new FormControl('');
  hormonkezelesNem = new FormControl(false);
  hormonkezelesIgen = new FormControl(false);
  hormonkezelesMegjegyzes = new FormControl('');
  iudNem = new FormControl(false);
  iudIgen = new FormControl(false);
  iudMegjegyzes  = new FormControl('');
  menopausaNem = new FormControl(false);
  menopausaIgen = new FormControl(false);
  elozoCitologiaVizsgNem  = new FormControl(false);
  elozoCitologiaVizsgIgen = new FormControl(false);
  elozoCitologiaVizsgPappanicolau = new FormControl('');
  elozoCitologiaVizsgLSil = new FormControl(false);
  elozoCitologiaVizsgHSil = new FormControl(false);
  hpvPozitiv = new FormControl(false);
  hpvTipus  = new FormControl('');
  hpvNegativ = new FormControl(false);
  hpvHSil  = new FormControl(false);
  nogyogyaszatiVizsgEredNegativ = new FormControl(false);
  nogyogyaszatiVizsgEredNeopl = new FormControl(false);
  nogyogyaszatiVizsgEredColpos = new FormControl('');
  nogyogyaszatiVizsgEredUH = new FormControl('');
  nogyogyaszatiVizsgEredElozmeny  = new FormControl('');
  korabbiNogyogyMutetIdeje = new FormControl('');
  korabbiNogyogyMutetDg = new FormControl('');
  korabbiKemoIdeje = new FormControl('');
  korabbiKemoDg = new FormControl('');
  korabbiRadioTerapiaIdeje = new FormControl('');
  korabbiRadioTerapiaDg = new FormControl('');
  egyeb = new FormControl('');
  kenetszam = new FormControl('');
  paciensEmail = new FormControl('');
  lb = new FormControl(false);
  pap = new FormControl(false);
  gratis = new FormControl(false);

  cytologyApplicationFormV3 = new FormGroup({
    paciensEmail: this.paciensEmail,
    kenetszam: this.kenetszam,
    gratis: this.gratis,
    betegKora: this.betegKora,
    egyeb: this.egyeb,
    elozmeny: this.nogyogyaszatiVizsgEredElozmeny,
    elozoCytologiaiVizsg: new FormGroup({
      hsil: this.elozoCitologiaVizsgHSil,
      igen: this.elozoCitologiaVizsgIgen,
      lsil: this.elozoCitologiaVizsgLSil,
      pappanicalau: this.elozoCitologiaVizsgPappanicolau,
      nem: this.elozoCitologiaVizsgNem
    }),
    fogamzasgatlas: new FormGroup({
      igen: this.fogamzasgatlasIgen,
      megjegyzes: this.fogamzasgatlasMegjegyzes,
      nem: this.fogamzasgatlasNem
    }),
    graviditas: this.graviditas,
    graviditasNumber: this.graviditasNumber,
    hormonkezeles: new FormGroup({
      igen: this.hormonkezelesIgen,
      megjegyzes: this.hormonkezelesMegjegyzes,
      nem: this.hormonkezelesNem
    }),
    hpv: new FormGroup({
      negativ: this.hpvNegativ,
      pozitiv: this.hpvPozitiv,
      tipus: this.hpvTipus
    }),
    iud: new FormGroup({
      igen: this.iudIgen,
      megjegyzes: this.iudMegjegyzes,
      nem: this.iudNem
    }),
    jelenlegTerhesHet: this.jelenlegTerhesHet,
    jelenlegTerhesIgen: this.jelenlegTerhesIgen,
    korabbiKemoterapiaIdeje: new FormGroup({
      dg: this.korabbiKemoDg,
      ev: this.korabbiKemoIdeje
    }),
    korabbiNogyogyaszatiMutetIdeje: new FormGroup({
      dg: this.korabbiNogyogyMutetDg,
      ev: this.korabbiNogyogyMutetIdeje
    }),
    korabbiRadioterapiaIdeje: new FormGroup({
      dg: this.korabbiRadioTerapiaDg,
      ev: this.korabbiRadioTerapiaIdeje
    }),
    laktal: this.laktal,
    laktalIgen: this.laktalIgen,
    laktalNem: this.laktalNem,
    menopausaIgen: this.menopausaIgen,
    menopausaNem: this.menopausaNem,
    nogyogyaszatiVizsgEredmenye: new FormGroup({
      colposcoposDg: this.nogyogyaszatiVizsgEredColpos,
      uhDg: this.nogyogyaszatiVizsgEredUH,
      negativ: this.nogyogyaszatiVizsgEredNegativ,
      neopol: this.nogyogyaszatiVizsgEredNeopl
    }),
    partius: this.partius,
    partiusNumber: this.partiusNumber,
    szuletesiIdo: this.szuletesiIdo,
    utolsoMenstruacioIdeje: this.utolsoMenstruacioIdeje,
    utolsoMenstruacioIgen: this.utolsoMenstruacioIgen,
    utolsoMenstruacioNem: this.utolsoMenstruacioNem
  });
  user: GetUserByUserNameResponse;
  public sub = new Subscription();

  statusOfCase: string;
  constructor(
    private sharedDataService: ShareddataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private formService: FormService,
    private toaster: ToastrService,
    private messages: Messages
  ) {
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.sub = this.cytologyApplicationFormV3.valueChanges.subscribe(data => {
      this.sharedDataService.formGroupChange(data);
    });
    if (+this.route.snapshot.paramMap.get('caseId')) {
      this.checkCaseStatus(+this.route.snapshot.paramMap.get('caseId'));
    } else {
      this.setKenetSzam(JSON.parse(localStorage.getItem('servicePoint')));
    }

    this.formService.checkBoxWithText(this.jelenlegTerhesIgen, this.jelenlegTerhesHet);
    this.formService.checkBoxWithText(this.fogamzasgatlasIgen, this.fogamzasgatlasMegjegyzes);
    this.formService.checkBoxWithText(this.hormonkezelesIgen, this.hormonkezelesMegjegyzes);
    this.formService.checkBoxWithText(this.iudIgen, this.iudMegjegyzes);
    this.formService.checkBoxWithText(this.hpvPozitiv, this.hpvTipus);
    this.formService.checkBoxWithText(this.elozoCitologiaVizsgIgen, this.elozoCitologiaVizsgPappanicolau);
    this.formService.checkBoxWithText(this.jelenlegTerhesIgen, this.szuletesiIdo);

    this.formService.xOrCheckboxes(this.nogyogyaszatiVizsgEredNegativ, this.nogyogyaszatiVizsgEredNeopl);
    this.formService.xOrCheckboxes(this.laktalIgen, this.laktalNem);
    this.formService.xOrCheckboxes(this.hpvPozitiv, this.hpvNegativ);
    this.formService.xOrCheckboxes(this.utolsoMenstruacioIgen, this.utolsoMenstruacioNem);
    this.formService.xOrCheckboxes(this.fogamzasgatlasIgen, this.fogamzasgatlasNem);
    this.formService.xOrCheckboxes(this.hormonkezelesIgen, this.hormonkezelesNem);
    this.formService.xOrCheckboxes(this.iudIgen, this.iudNem);
    this.formService.xOrCheckboxes(this.elozoCitologiaVizsgIgen, this.elozoCitologiaVizsgNem);
    this.formService.xOrCheckboxes(this.elozoCitologiaVizsgLSil, this.elozoCitologiaVizsgHSil);
    this.formService.xOrCheckboxes(this.menopausaIgen, this.menopausaNem);

    this.formService.checkBase(this.elozoCitologiaVizsgIgen, [
      this.elozoCitologiaVizsgLSil, this.elozoCitologiaVizsgHSil,
    ]);
    this.sharedDataService.currentPatient.subscribe(patient => {
      this.calculateAge(patient);
    });

    // this.korabbiKemoIdeje.valueChanges.subscribe(value => {
    //   if (value) {
    //     if (value.toString().length > 2) {
    //       const stringValue = value.toString().substring(0, 2);
    //       this.korabbiKemoIdeje.setValue(+stringValue);
    //     }
    //   }
    // });
    // this.korabbiNogyogyMutetIdeje.valueChanges.subscribe(value => {
    //   if (value) {
    //     if (value.toString().length > 2) {
    //       const stringValue = value.toString().substring(0, 2);
    //       this.korabbiNogyogyMutetIdeje.setValue(+stringValue);
    //     }
    //   }
    // });
    // this.korabbiRadioTerapiaIdeje.valueChanges.subscribe(value => {
    //   if (value) {
    //     if (value.toString().length > 2) {
    //       const stringValue = value.toString().substring(0, 2);
    //       this.korabbiRadioTerapiaIdeje.setValue(+stringValue);
    //     }
    //   }
    // });
  }

  ngOnChanges(): void {
    this.calculateAge(this.patientData);
  }

  setKenetSzam(servicePoint: any) {
    if (!this.kenetszam.value) {
      this.apiService.getNextLogNumber(servicePoint['refServicePointId']).subscribe(response => {
        this.kenetszam.setValue(response);
      });
    }
  }

  calculateAge(patient: GetPatientResponse) {
    const timeDiff = Math.abs(Date.now() - new Date(patient?.birthDate).getTime());
    this.betegKora.setValue(Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25));
  }

  checkCaseStatus(caseId: number) {
    this.apiService.findCaseByCaseId(caseId).subscribe(response => {
      this.statusOfCase = response.status;
      this.getCaseById(+this.route.snapshot.paramMap.get('caseId'));
    });
  }

  getCaseById(caseId: number) {
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(document => {
        if (document.type.includes('REQ')) {
          this.cytologyApplicationFormV3.setValue(document.values);
          if (this.statusOfCase !== 'új') {
            this.cytologyApplicationFormV3.disable();
          } else {
            if (this.user.roleCode !== 'REFERRING_ASSISTANT' && this.user.roleCode !== 'REFERRING_DOCTOR') {
              this.cytologyApplicationFormV3.disable();
            }
          }
        }
      });
    }, (error: Response) => {
      console.error(error);
    });
  }

  checkValidDate(dateString: string) {
    const today = new Date();
    const dateInput = new Date(dateString);

    if (today < dateInput) {
      this.toaster.error(this.messages.error.ERROR_DATE_CANNOT_BE_FUTURE[this.messages.locale]);
    }
  }

  setNumberToPositive(formControl: FormControl) {
    if (formControl.value < 0) {
      formControl.setValue(0);
    }
  }

}
