import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DeleteCaseComponent } from './../../pathologyreports/delete-case/delete-case.component';
import { ShareddataService } from './../../../../core/services/shareddata.service';
import { ApiService } from './../../../../core/http/api.service';
import { GetUserByUserNameResponse } from './../../../../shared/models/response/getUserByUsernameResponse';
import { MatTableDataSource } from '@angular/material/table';
import { GetAllCasesResponse } from './../../../../shared/models/response/getAllCasesResponse';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  displayedColumns: string[] = [
    'type',
    'birthName',
    'ssn',
    'birthDate',
    'doctorName',
    'logNumber',
    'status',
    'creationDate',
    'icons'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ssn = new FormControl('');
  logNumber = new FormControl('');
  birthName = new FormControl('');

  searchForm = new FormGroup({
    ssn: this.ssn,
    logNumber: this.logNumber,
    birthName: this.birthName
  });

  currentUser: GetUserByUserNameResponse;

  typeOfCase: string;
  statusOfCase: string;
  allCases = new Array<GetAllCasesResponse>();
  filteredCases = new Array<GetAllCasesResponse>();
  casesForPaginator: MatTableDataSource<GetAllCasesResponse>;

  constructor(
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    private dialog: MatDialog,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getAllCasesByRefId();

    this.searchForm.valueChanges.subscribe(value => {
      this.findReport(value, this.typeOfCase, this.statusOfCase);
    });
  }

  getAllCases(refServicePointId: number) {
    const nonDeletedCases = new Array<GetAllCasesResponse>();
    this.apiService.getAllCases(refServicePointId).subscribe(data => {
      data.forEach(responseData => {
        if (!responseData.isEESZTDeleted) {
          nonDeletedCases.push(responseData);
        }
      });

      nonDeletedCases.forEach(element => {
        element.patientBirthDate = this.sharedDataService.
        dateToDateString(new Date(this.sharedDataService.DateStringConvert(element.patientBirthDate)));

        element.creationDate = this.sharedDataService.
        dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(element.creationDate)));
      });

      this.allCases = nonDeletedCases;
      this.filteredCases = nonDeletedCases;
      this.casesForPaginator = new MatTableDataSource(nonDeletedCases);

      this.casesForPaginator.paginator = this.paginator;
      this.setPaginatorTexts(this.paginator);
      this.findReport(this.searchForm.value, this.typeOfCase, this.statusOfCase);
    }, (error: Response) => {
      console.error(error);
    });
  }


  setPaginatorTexts(paginator: MatPaginator) {
    paginator._intl.nextPageLabel = this.messages.locale === 'hu' ? 'Következő' : 'Next';
    paginator._intl.previousPageLabel = this.messages.locale === 'hu' ? 'Előző' : 'Previous';
    paginator._intl.itemsPerPageLabel = this.messages.locale === 'hu' ? 'Oldalanként' : 'Size';
    paginator._intl.firstPageLabel = this.messages.locale === 'hu' ? 'Első' : 'First';
    paginator._intl.lastPageLabel = this.messages.locale === 'hu' ? 'Utolsó' : 'Last';
  }


  getAllCasesByRefId() {
    this.apiService.getServicePointById(this.currentUser.servicePointId).subscribe(response => {
      if (response.refServicePointId) {
       this.getAllCases(response.refServicePointId);
      } else {
        this.getAllCases(this.currentUser.servicePointId);
      }
    });

  }

  filterByTypeOfCase($event) {
    this.typeOfCase = $event;
    this.findReport(this.searchForm.value, $event, this.statusOfCase);
  }

  filterByStatusOfCase($event) {
    this.statusOfCase = $event;
    this.findReport(this.searchForm.value, this.typeOfCase, $event);
  }

  findReport(formgroupValue, type, status) {

    let filteredCases = this.allCases;

    if (formgroupValue.ssn) {
      filteredCases = filteredCases.filter(value =>
        value.patientSsn.replace(/-/g,'').toLowerCase().includes(formgroupValue.ssn.replace(/-/g,'').toLowerCase()));
    }

    if (formgroupValue.logNumber) {
      filteredCases = filteredCases.filter(value =>
        value.logNumber?.toLowerCase().includes(formgroupValue.logNumber.toLowerCase()));
    }

    if (formgroupValue.birthName) {
      filteredCases = filteredCases.filter(value =>
        value.patientBirthName.toLowerCase().includes(formgroupValue.birthName.toLowerCase()));
    }

    if (type && type !== 'all') {
       filteredCases = filteredCases.filter(value => value.type === type);
    } else {
      filteredCases = filteredCases;
    }

    if (status && status !== 'all') {
      filteredCases = filteredCases.filter(value => value.status === status);
    } else {
      filteredCases = filteredCases;
    }

    this.filteredCases = filteredCases;

    this.casesForPaginator.data = this.filteredCases;
    this.casesForPaginator.paginator = this.paginator;
  }

  showDeleteCase($event) {
    const dialogRef = this.dialog.open(DeleteCaseComponent, {
      data: {$event}
    });
    const sub = dialogRef.componentInstance.caseDeleted.subscribe(data => {
      if (data) {
        this.toasterService.success(
          this.messages.success.SUCCESS_DELETE_CASE_OK[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          {positionClass: 'toast-top-center'});
      }
      dialogRef.close();
      this.getAllCasesByRefId();
    });
  }

}
