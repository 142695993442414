<app-navbar></app-navbar>
<ul class="nav nav-tabs nav-justified" role="tablist">

    <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#bcp" i18n>EESZT kommunikációs napló</a>
    </li>

    <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#ehr" i18n>egészségügyi dokumentumok</a>
    </li>
</ul>
<div class="tab-content">
    <div id="bcp" class="tab-pane active"><br>
        <div class="wrapper">
            <button class="searchPatientButton mr-5 mb-5" style="width: 150px;" (click)="getAllBcpMessages()" i18n>Lekérdezés</button>
            <button class="searchPatientButton mr-5 mb-5" style="width: 200px;" (click)="resendBcpMessages()" i18n>Kijelöltek újraküldése</button>
            <button class="searchPatientButton mr-5 mb-5" style="width: 200px;" (click)="resendAllBcpMessages()" i18n>Összes beküldése</button>
        </div>
        <table>
            <thead>
                <tr class="table100-head">
                    <th class="column1" i18n>küldés azonosítója</th>
                    <th class="column2" i18n>küldés ideje</th>
                    <th class="column3" i18n>üzenet típusa</th>
                    <th class="column4" i18n>modul</th>
                    <th class="column5" i18n>üzenet</th>
                    <th class="column6" i18n></th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let bcpMessage of bcpMessages">
                        <td class="column1">{{bcpMessage.id}}</td>
                        <td class="column2">{{bcpMessage.messageTime.substr(0,bcpMessage.messageTime.indexOf('T'))}}</td>
                        <td class="column3">{{bcpMessage.messageType}}</td>
                        <td class="column4">{{bcpMessage.modul}}</td>
                        <td class="column5">{{bcpMessage.response}}</td>
                        <td class="column6">
                            <input [formControl]="checkBcpMessage" type="checkbox" class="form-check-input" id="bcpMessageCheckbox" (change)="addToBcpList(bcpMessage.id)">
                        </td>
                    </tr>
            </tbody>
        </table>
    </div>
    <div id="ehr" class="tab-pane"><br>
        <div class="wrapper">
            <div class="form-inline">
                <label class="histologyLabel mr-2" i18n>taj szám</label>
                <input [formControl]="ehrtaj" class="ebeuInput form-control mr-5" type="text"/>
                <label class="histologyLabel mr-2" i18n>születési dátum</label>
                <input [formControl]="ehrbirthDate" class="ebeuInput form-control mr-5" type="date" [lang]="dateType" max="2099-12-31" min="2000-01-01"/>
            </div>
            <div class="form-inline mt-3">
                <label class="histologyLabel mr-2" i18n>tól</label>
                <input [formControl]="fromDate" class="ebeuInput form-control mr-5" type="date" [lang]="dateType" max="2099-12-31" min="2000-01-01"/>
                <label class="histologyLabel mr-2" i18n>ig</label>
                <input [formControl]="toDate" class="ebeuInput form-control mr-5" type="date" [lang]="dateType" max="2099-12-31" min="2000-01-01"/>
                </div>
            <div class="form-inline mt-3">
                <label class="histologyLabel mr-2" i18n>who</label>
                <input [formControl]="who" class="ebeuInput form-control mr-5" type="text"/>
            </div>
            <div class="form-inline mt-3">
                <div class="form-group form-check">
                    <input [formControl]="ehrBreakGlass" type="checkbox" class="form-check-input" id="breakglass2">
                    <label class="form-check-label" style="margin-right:10px" for="breakglass2" i18n>sürgősségi adatlekérés</label>
                </div>
                <input [formControl]="ehrBreakOkGlass" class="ebeuInput form-control" type="text"/>
            </div>
            <p *ngIf="ehrBreakGlass.value" class="mt-3" style="font-size: x-small" i18n>
                A "sürgősségi adatlekérés" csak a következő esetekben használható jogszerűen:<br>
  1.) Sürgős esetben, ha a páciens nem tud, nem képes eseti rendelkezést tenni. (Az Eüak. 10. § (4) bekezdése szerint sürgős szükség esetén a kezelést végző orvos által ismert, a gyógykezeléssel összefüggésbe hozható minden egészségügyi és személyazonosító adat továbbítható.) <br>
  2.) A páciens 24 órás egyedi engedélye alapján (minta az e-egeszegugy.gov.hu oldalon letölthető).
            </p>
            <button class="searchPatientButton mt-5" style="width: 150px;" (click)="getEhrList()" i18n>Keresés</button>
        </div>
        <table>
            <thead>
                <tr class="table100-head">
                    <th i18n>létrehozó orvos</th>
                    <th i18n>pecsétszám</th>
                    <th i18n>küldés ideje</th>
                    <th i18n>beküldő intézmény</th>
                    <th i18n="@@bno">bno</th>
                    <th i18n>dokumentum azonosítója</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="ehrList">
                    <tr *ngFor="let ehrData of ehrList.data.documentList">
                        <td>{{ehrData.doctor.firstName}} {{ehrData.doctor.lastName}}</td>
                        <td>{{ehrData.doctor.stampNo}}</td>
                        <td>{{ehrData.timeStamp.substr(0,ehrData.timeStamp.lastIndexOf(':')).replace('T', ' ')}}</td>
                        <td>{{ehrData.hospitalDescr}}</td>
                        <td>{{ehrData.diagnose}}</td>
                        <td>{{ehrData.documentID}}</td>
                        <td><i class="fa fa-download downloadButton" aria-hidden="true" (click)="downloadEhr(ehrData)"></i></td>
                    </tr>
            </tbody>
        </table>
    </div>
 </div>
