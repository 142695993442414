<i class="fa fa-times close" mat-dialog-close aria-hidden="true"></i>
<h3 mat-dialog-title i18n>Válasszon pácienst</h3>
<div class="form-inline mb-2">
    <label class="submitReportLabel mr-3 ml-3" i18n>név</label>
    <input [formControl]="nameControl" class="submitReportInput form-control" type="text"/>
    <button class="searchPatientButton ml-auto" (click)="onSelectedPatient()" i18n>Új páciens felvétele</button>
</div>
<mat-dialog-content class="mat-typography">
    <table>
        <thead>
            <tr class="table100-head">
                <th class="column1" i18n>taj</th>
                <th class="column2" i18n>név</th>
                <th class="column3" i18n>születési név</th>
                <th class="column4" i18n>születési dátum</th>
                <th class="column5" i18n>anyja neve</th>
                <th class="column6" i18n>cím</th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let patient of filteredPatients" (click)="onSelectedPatient(patient)">
                    <td class="column1">{{patient.ssn}}</td>
                    <td class="column2">{{patient.name}}</td>
                    <td class="column3">{{patient.birthName}}</td>
                    <td class="column4 ">{{patient.birthDate}}</td>
                    <td class="column5">{{patient.mothersName}}</td>
                    <td class="column6 ">{{patient.address}}</td>
                </tr>
        </tbody>
    </table>
</mat-dialog-content>
