import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'i18nDateFormat'
})
export class I18nDateFormatPipe implements PipeTransform {

  transform(value: string, locale: string): string {
    const enDate = moment(value).format('MM.DD.YYYY HH:mm:ss');
    const huDate = moment(value).format('YYYY.MM.DD HH:mm:ss');
    return locale === 'en' ? enDate : huDate;
  }

}
