<div class="container">
    <img class="logo img-fluid" src="../../../../assets/images/nautopsy_logo_04-02-05.png">
    <form [formGroup]="loginFormGroup" class="form-group">
        <div class="row">
            <label i18n>Felhasználó</label>
        </div>
        <div class="row mb-3">
            <input [formControl]="userName" class="loginInput form-control" type="text" name="username" id="username" autocomplete="username">
        </div>
        <div class="row">
            <label i18n>Jelszó</label>
        </div>
        <div class="row">
            <input [formControl]="password" class="loginInput form-control" type="password"/>
        </div>
        <div class="row mt-3">
            <button (click)="doLogin()" class="loginButton btn btn-primary" type="submit" i18n>Bejelentkezés</button>
        </div>
        <div class="row mt-3">
            <a (click)="showNews()" i18n>Hírek</a>
        </div>
    </form>
    <div class="rssContainer" *ngIf="showRss">
        <p class="rssTitle">{{rssData?.channel.title}}</p>
        <p id="encodedContent" class="rssEncodedContent">{{rssData?.channel.item[0].title}}<br>
        {{rssData?.channel.item[0].description}}</p>
    </div>
</div>
