// tslint:disable: deprecation
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ValidatorUser } from './../../../../shared/models/validatorUser';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/http/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ServicePoint } from 'src/app/shared/models/ServicePoint';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { SaveSpRequest } from 'src/app/shared/models/request/saveSpRequest';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteServicepointComponent } from 'src/app/modules/components/delete-servicepoint/delete-servicepoint.component';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-servicepoints',
  templateUrl: './servicepoints.component.html',
  styleUrls: ['./servicepoints.component.css']
})
export class ServicepointsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  isModify = false;
  servicePoints: Array<ServicePoint>;
  servicePointsForPaginator: MatTableDataSource<ServicePoint>;
  selectedServicePoint: ServicePoint;

  user: GetUserByUserNameResponse;

  spName = new FormControl('', [Validators.required]);
  spAddress = new FormControl('', [Validators.required]);
  spPhoneNumber = new FormControl('');
  spEmail = new FormControl('');
  ekatEventTypeControl = new FormControl(null);
  hasRefServicePoint = new FormControl(false);
  technicalUserId = new FormControl('');

  registerServicePointFormGroup = new FormGroup({
    name: this.spName,
    adress: this.spAddress,
    phoneNumber: this.spPhoneNumber,
    email: this.spEmail,
    hasRefServicePoint: this.hasRefServicePoint,
    technicalUserId: this.technicalUserId,
  });

  displayedColumns: string[] = [
    'name',
    'address',
    'phoneNumber',
    'email',
    'icons'
  ];


  constructor(
    private apiService: ApiService,
    private toasterService: ToastrService,
    private dialog: MatDialog,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.findAllServicePointsByRef();
  }

  findAllServicePointsByRef() {
    this.apiService.getAllServicePointsByRef(this.user.servicePointId).subscribe(data => {
      this.servicePoints = data;
      this.servicePointsForPaginator = new MatTableDataSource(this.servicePoints);
      this.servicePointsForPaginator.paginator = this.paginator;
      this.setPaginatorTexts(this.paginator);
    }, (error: Response) => {
      console.error(error);
    });
  }

  setPaginatorTexts(paginator: MatPaginator) {
    paginator._intl.nextPageLabel = this.messages.locale === 'hu' ? 'Következő' : 'Next';
    paginator._intl.previousPageLabel = this.messages.locale === 'hu' ? 'Előző' : 'Previous';
    paginator._intl.itemsPerPageLabel = this.messages.locale === 'hu' ? 'Oldalanként' : 'Size';
    paginator._intl.firstPageLabel = this.messages.locale === 'hu' ? 'Első' : 'First';
    paginator._intl.lastPageLabel = this.messages.locale === 'hu' ? 'Utolsó' : 'Last';
  }


  saveSp() {
    if (this.spName.value && this.spAddress.value) {
      const request: SaveSpRequest = {
        active: null,
        address: this.spAddress.value,
        ecmUrl: null,
        eesztOrg: null,
        eesztOrgUnit: null,
        email: this.spEmail.value,
        name: this.spName.value,
        phoneNumber: this.spPhoneNumber.value,
        refServicePointId: this.user.servicePointId,
        technicalUserId: +this.technicalUserId.value ? + +this.technicalUserId.value : null,
        eesztForced: false,
        ekatEventType: this.ekatEventTypeControl.value
      };

      this.apiService.saveServicePoint(request).subscribe(data => {
        this.toasterService.success(
          this.messages.success.SUCCESS_INSITUTE_SAVE_OK[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          {positionClass: 'toast-top-center'});
        this.findAllServicePointsByRef();
      }, (error: HttpErrorResponse) => {
        this.toasterService.error(error.error.message, error.status.toString(), {positionClass: 'toast-top-center'});
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_NAME_ADDRESS_REQUIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    }
  }

  modifyServicePoint(sp: ServicePoint) {
    this.isModify = true;
    this.spName.setValue(sp.name);
    this.spAddress.setValue(sp.address);
    this.spPhoneNumber.setValue(sp.phoneNumber);
    this.spEmail.setValue(sp.email);
    this.hasRefServicePoint.setValue(sp.refServicePointId ? true : false);
    this.technicalUserId.setValue(sp.technicalUserId);
    this.selectedServicePoint = sp;
    this.ekatEventTypeControl.setValue(sp.ekatEventType);
  }

  updateServicePoint() {

    const request: ServicePoint = {
      eesztForced: false,
      active: this.selectedServicePoint.active,
      address: this.spAddress.value,
      ecmUrl: this.selectedServicePoint.ecmUrl,
      eesztOrg: this.selectedServicePoint.eesztOrg,
      eesztOrgUnit: this.selectedServicePoint.eesztOrgUnit,
      email: this.spEmail.value,
      id: this.selectedServicePoint.id,
      name: this.spName.value,
      phoneNumber: this.spPhoneNumber.value,
      refServicePointId: this.user.servicePointId,
      technicalUserId: this.technicalUserId.value ? +this.technicalUserId.value : null,
      validatorUserId: null,
      citologyVersion: null,
      ekatEventType: this.ekatEventTypeControl.value
    };

    this.apiService.updateServicePoint(request).subscribe(response => {
      this.toasterService.success(
        this.messages.success.SUCCESS_UPDATE_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale],
        {positionClass: 'toast-top-center'});
      this.isModify = false;
      this.registerServicePointFormGroup.reset();
      this.findAllServicePointsByRef();
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(error.error.message, error.status.toString(), {positionClass: 'toast-top-center'});
    });
  }


  deleteServicePoint(sp: ServicePoint) {
    const dialogRef = this.dialog.open(DeleteServicepointComponent, {
      data: sp
    });
    dialogRef.componentInstance.onModalClosed.subscribe((response: Response) => {
      this.findAllServicePointsByRef();
      this.isModify = false;
      this.registerServicePointFormGroup.reset();
      dialogRef.close();
    });
  }

  clearForm(formGroup: FormGroup) {
    formGroup.reset();
    this.isModify = false;
    this.selectedServicePoint = null;
  }


}
