import { AreYouSureComponent } from './../../../components/are-you-sure/are-you-sure.component';
import { HttpErrorResponse } from '@angular/common/http';
// tslint:disable: deprecation
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/http/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { FormGroup, FormControl } from '@angular/forms';
import { AccountedCase } from 'src/app/shared/models/accountedCase';
import { SelectFinancetypeComponent } from 'src/app/modules/components/select-financetype/select-financetype.component';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { Messages } from 'src/app/shared/locale/messages';
import * as moment from 'moment';

export interface AccountedCaseTableData {
  check: FormControl;
  accountedCase: AccountedCase;
}

@Component({
  selector: 'app-financing',
  templateUrl: './financing.component.html',
  styleUrls: ['./financing.component.css']
})
export class FinancingComponent implements OnInit {


  dateType = this.messages.locale === 'en' ? 'en-US' : 'hu-HU';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: GetUserByUserNameResponse;
  allAccountedCase: Array<AccountedCase>;
  filteredAccountedCases: Array<AccountedCaseTableData>;
  allAccountedCaseForPaginator: MatTableDataSource<AccountedCaseTableData>;


  selectAllControl = new FormControl(false);

  patientName = new FormControl('');
  clinic = new FormControl('');
  isAccounted = new FormControl(false);
  type = new FormControl('');
  dateFrom = new FormControl('');
  dateTo = new FormControl('');

  types = ['szöv', 'cyt', 'acyt'];

  searchFinanceFormGroup = new FormGroup({
    clinic: this.clinic,
    patientName: this.patientName,
    isAccounted: this.isAccounted,
    type: this.type,
    dateFrom: this.dateFrom,
    dateTo: this.dateTo
  });

  displayedColumns: string[] = [
    'number',
    'creationDate',
    'type',
    'logNumber',
    'patientName',
    'servicePoint',
    'doctorName',
    'pap',
    'lbc',
    'gratis',
    'who29450',
    'who29400',
    'who29070',
    'who25040',
    'who29050',
    'who29000',
    'who25030',
    'who27000',
    'price',
    'accounted',
    'action'
  ];

  accountedSelectionList = new Array<number>();

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private messages: Messages,
    private toasterService: ToastrService
  ) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getAllAccountedCase();

    this.searchFinanceFormGroup.valueChanges.subscribe(value => {
      this.filterFinance(value);
    });
  }

  getAllAccountedCase() {
    this.apiService.getAllAccountedCase(this.user.servicePointId).subscribe(response => {
      this.allAccountedCase = response;
      this.filteredAccountedCases = this.createAccountedCasesTableDataSource(response.filter(x => !x.accounted));
      this.allAccountedCaseForPaginator = new MatTableDataSource(this.filteredAccountedCases);
      this.allAccountedCaseForPaginator.paginator = this.paginator;
      this.setPaginatorTexts(this.paginator);
      this.filterFinance(this.searchFinanceFormGroup.value);
    }, (error: Response) => {
      console.error(error);
    });
  }

  createAccountedCasesTableDataSource(accountedCases: Array<AccountedCase>): Array<AccountedCaseTableData> {
    const dataSource = new Array<AccountedCaseTableData>();

    accountedCases.forEach(accountedCase => {
      const accountedCaseTableData: AccountedCaseTableData = {
        check: new FormControl(false),
        accountedCase
      };

      dataSource.push(accountedCaseTableData);
    });

    return dataSource;
  }

  setPaginatorTexts(paginator: MatPaginator) {
    paginator._intl.nextPageLabel = this.messages.locale === 'hu' ? 'Következő' : 'Next';
    paginator._intl.previousPageLabel = this.messages.locale === 'hu' ? 'Előző' : 'Previous';
    paginator._intl.itemsPerPageLabel = this.messages.locale === 'hu' ? 'Oldalanként' : 'Size';
    paginator._intl.firstPageLabel = this.messages.locale === 'hu' ? 'Első' : 'First';
    paginator._intl.lastPageLabel = this.messages.locale === 'hu' ? 'Utolsó' : 'Last';
  }

  setAccountedStatus($event) {
    const dialogRef = this.dialog.open(SelectFinancetypeComponent, {
      data: $event
    });
    dialogRef.componentInstance.onModalClosed.subscribe((response: Response) => {
      this.getAllAccountedCase();
      dialogRef.close();
    });
  }

  setAccountedList(isAccounted: boolean) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: this.messages.locale  === 'en' ?
      'Are you sure to update the accountancy of the selected records?' :
      'Biztosan módosítja a kiválasztott rekordok könyvelését?'
    });

    dialogRef.componentInstance.emitAnswer.subscribe((answer: boolean) => {
      if (answer) {
        this.apiService.setAccountedList(this.accountedSelectionList, isAccounted).subscribe({
          next: (response) => {
            this.resetAccountedSelectionList();
            this.toasterService.success(this.messages.success.SUCCESS_ACCOUNTED_LIST[this.messages.locale],
              this.messages.success.SUCCESS_TITLE[this.messages.locale],
              {positionClass: 'toast-top-center'});
            this.getAllAccountedCase();
          },
          error: (error: HttpErrorResponse) => {
            this.toasterService.error(error.error.message, this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
              {positionClass: 'toast-top-center'});
          }
        });
      }
      dialogRef.close();
    });
  }

  resetAccountedSelectionList() {
    this.accountedSelectionList = new Array<number>();
    for (const accountedCase of this.allAccountedCaseForPaginator.data) {

      if (accountedCase.check.value) {
        accountedCase.check.setValue(false);
      }
    }
  }

  filterFinance(value) {
    let filteredCases: Array<AccountedCase>;
    filteredCases = this.allAccountedCase;

    if (value.clinic) {
      filteredCases = filteredCases.filter(x =>
        x.servicePoint.name.toLowerCase().includes(value.clinic.toLowerCase()));
    }
    if (value.patientName) {
      filteredCases = filteredCases.filter(x =>
        x.patient.name.toLowerCase().includes(value.patientName.toLowerCase()));
    }
    if (value.isAccounted) {
      filteredCases = filteredCases.filter(x => x.accounted);
    } else {
      filteredCases = filteredCases.filter(x => !x.accounted);
    }
    if (value.type) {
      filteredCases = filteredCases.filter(x => x.type === value.type);
    }
    if (value.dateFrom) {
      filteredCases = filteredCases.filter(x => new Date(x.resultDate) >= new Date(value.dateFrom));
    }
    if (value.dateTo) {
      filteredCases = filteredCases.filter(x => new Date(x.resultDate) <= new Date(value.dateTo));
    }

    this.allAccountedCaseForPaginator.data = this.createAccountedCasesTableDataSource(filteredCases);
    this.allAccountedCaseForPaginator.paginator = this.paginator;
  }

  downloadExcel() {
    const accountedCases = this.allAccountedCaseForPaginator.data.map(x => x.accountedCase);
    const table = document.getElementById('financeTable');
    const worksheet = XLSX.utils.aoa_to_sheet([]);
    const ws = XLSX.utils.sheet_add_aoa(worksheet,[
      ['Leletezés dátuma',
      'Típus',
      'Naplószám',
      'Beteg neve',
      'Beküldő klinika',
      'Beküldő orvos',
      'PAP',
      'LBC',
      'GRATIS',
      '29450',
      '29400',
      '29070',
      '25040',
      '29050',
      '29000',
      '25030',
      '27000',
      'Összeg',
      'Könyvelt']
    ], {origin: 'A1'});
    accountedCases.forEach((x, index) => {
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          moment(x.resultDate).format('YYYY-MM-DD'),
          x.type,
          x.logNumber,
          x.patient.name,
          x.servicePoint.name,
          x.doctorName,
          x.pap ?  '✔' : '✖',
          x.lbc ?  '✔' : '✖',
          x.gratis ?  '✔' : '✖',
          x.who?.cytologiaiVizsgalatImmuncyto,
          x.who?.diagnosztikusAspiratiosExfol,
          x.who?.kiegeszitoPontszamVolumiozus,
          x.who?.mikroszkoposVizsgalatZiehl,
          x.who?.szovettaniVizsgalatImmunhisz,
          x.who?.szovettaniVizsgalatASzokaso,
          x.who?.mikroszkiposVizsgalatTenyesztetlenAnyagbol,
          x.who?.gombaVizsgalatDirektKenetben,
          x.price,
          x.accounted ? '✔' : '✖'
        ]
      ], {origin: 'A' + (index + 2)});
    });

    const workbook = { Sheets: {data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.allAccountedCaseForPaginator.data.map(x => x.accountedCase));
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(workbook, 'finanszirozas' + new Date().toISOString().replace('-', '_').replace('T', '_') + '.xlsx');
  }

  selectAll(allSelected: boolean) {
    let selectionList = new Array<number>();
    for (const accountedCase of this.allAccountedCaseForPaginator.data) {
      if (allSelected) {
        accountedCase.check.setValue(true);
        selectionList.push(accountedCase.accountedCase.id);
      } else {
        accountedCase.check.setValue(false);
        selectionList = new Array<number>();
      }
    }

    this.accountedSelectionList = selectionList;
  }

  selectCase(caseId: number, $event) {
    $event.stopPropagation();
    if (this.accountedSelectionList.find(x => x === caseId)) {
      this.accountedSelectionList.splice(this.accountedSelectionList.indexOf(caseId), 1);
    } else {
      this.accountedSelectionList.push(caseId);
    }
  }



}
