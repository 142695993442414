import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from './../../../core/services/general.service';
import { GetPdfForRefferingRequest } from './../../../shared/models/request/getPdfForRefferingRequest';
import { GetPdfForRefferingResponse } from './../../../shared/models/response/getPdfForRefferingResponse';
import { ApiService } from 'src/app/core/http/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-printpdf',
  templateUrl: './printpdf.component.html',
  styleUrls: ['./printpdf.component.css']
})
export class PrintpdfComponent implements OnInit {

  password = new FormControl('');
  isLoggedIn = false;

  hashedDocumentId: string;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private generalService: GeneralService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.hashedDocumentId = this.route.snapshot.paramMap.get('docId');
  }

  openDocument() {
    const request: GetPdfForRefferingRequest = {
      generatedCode: this.hashedDocumentId,
      password: this.password.value
    };

    this.apiService.getPdfForReffering(request).subscribe(response => {
     this.generalService.printPDf(response.pdf);
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(
        this.messages.error.ERROR_PDF_PASSWORD_WRONG[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
    });
  }
}
