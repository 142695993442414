import { AuthGuard } from './core/guard/auth.guard';
import { PrintpdfComponent } from './modules/pages/printpdf/printpdf.component';
import { EesztComponent } from './modules/pages/eeszt/eeszt.component';
import { AdminComponent } from './modules/pages/admin/admin.component';
import { HistologicalreportComponent } from './modules/pages/histologicalreport/histologicalreport.component';
import { PathologyreportsComponent } from './modules/pages/pathologyreports/pathologyreports.component';
import { DashboardComponent } from './modules/pages/dashboard/dashboard.component';
import { RegistrationComponent } from './modules/pages/registration/registration.component';
import { LoginComponent } from './modules/pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResponsereportComponent } from './modules/pages/responsereport/responsereport.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'registration', component: RegistrationComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'reports', component: PathologyreportsComponent, canActivate: [AuthGuard]},
  // {path: 'report/cyt/:patientId/:caseId', component: CytologicalreportComponent},
  // {path: 'report/cyt/:patientId/:caseId', component: Cytologicalreportv2Component},
  {path: 'report/cyt/:patientId/:caseId', component: ResponsereportComponent, canActivate: [AuthGuard]},
  {path: 'report/szov/:patientId/:caseId', component: HistologicalreportComponent, canActivate: [AuthGuard]},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
  {path: 'eeszt', component: EesztComponent, canActivate: [AuthGuard]},
  {path: 'print/:docId', component: PrintpdfComponent},
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
