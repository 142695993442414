import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ChartsModule } from 'ng2-charts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/pages/login/login.component';
import { RegistrationComponent } from './modules/pages/registration/registration.component';
import { DashboardComponent } from './modules/pages/dashboard/dashboard.component';
import { NavbarComponent } from './modules/components/navbar/navbar.component';
import { SearchComponent } from './modules/components/search/search.component';
import { SubmitreportComponent } from './modules/components/submitreport/submitreport.component';
import { CytologyapplicationComponent } from './modules/components/cytologyapplication/cytologyapplication.component';
import { HistologyapplicationComponent } from './modules/components/histologyapplication/histologyapplication.component';
import { PathologyreportsComponent } from './modules/pages/pathologyreports/pathologyreports.component';
import { CytologicalreportComponent } from './modules/pages/cytologicalreport/cytologicalreport.component';
import { HistologicalreportComponent } from './modules/pages/histologicalreport/histologicalreport.component';
import { httpInterceptProviders } from './core/interceptors';
import { AdminComponent } from './modules/pages/admin/admin.component';
import { EesztComponent } from './modules/pages/eeszt/eeszt.component';
import { CounterPipe } from './core/pipes/counter.pipe';
import { EesztAuthenticateComponent } from './modules/components/navbar/eeszt-authenticate/eeszt-authenticate.component';
import { DeleteCaseComponent } from './modules/pages/pathologyreports/delete-case/delete-case.component';
import { SelectCasesComponent } from './modules/components/select-cases/select-cases.component';
import { SelectEbeuComponent } from './modules/components/select-ebeu/select-ebeu.component';
import { SelectFinancetypeComponent } from './modules/components/select-financetype/select-financetype.component';
import { ResetPasswordComponent } from './modules/components/reset-password/reset-password.component';
import { DeleteServicepointComponent } from './modules/components/delete-servicepoint/delete-servicepoint.component';
import { ResetCaseComponent } from './modules/components/reset-case/reset-case.component';
import { ResponsereportComponent } from './modules/pages/responsereport/responsereport.component';
import { Cytologyapplicationv2Component } from './modules/components/cytologyapplicationv2/cytologyapplicationv2.component';
import { Cytologicalreportv2Component } from './modules/pages/cytologicalreportv2/cytologicalreportv2.component';
import { StatisticsComponent } from './modules/pages/admin/statistics/statistics.component';
import { UsersComponent } from './modules/pages/admin/users/users.component';
import { ServicepointsComponent } from './modules/pages/admin/servicepoints/servicepoints.component';
import { FinancingComponent } from './modules/pages/admin/financing/financing.component';
import { PricesComponent } from './modules/pages/admin/prices/prices.component';
import { SelectPatientComponent } from './modules/components/select-patient/select-patient.component';
import { ValidatorUsersComponent } from './modules/pages/admin/validator-users/validator-users.component';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { DocumentsComponent } from './modules/pages/admin/documents/documents.component';
import { PrintpdfComponent } from './modules/pages/printpdf/printpdf.component';
import { Cytologyapplicationv3Component } from './modules/components/cytologyapplicationv3/cytologyapplicationv3.component';
import { Cytologicalreportv3Component } from './modules/pages/cytologicalreportv3/cytologicalreportv3.component';
import { AreYouSureComponent } from './modules/components/are-you-sure/are-you-sure.component';
import { ArabicToRomanPipe } from './shared/pipes/arabic-to-roman.pipe';
import { TranslateStatusPipe } from './shared/pipes/translate-status.pipe';
import { TranslateTypePipe } from './shared/pipes/translate-type.pipe';
import { I18nDateFormatPipe } from './shared/pipes/i18n-date-format.pipe';
import { DatePickerLangPipe } from './shared/pipes/date-picker-lang.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardComponent,
    NavbarComponent,
    SearchComponent,
    SubmitreportComponent,
    CytologyapplicationComponent,
    HistologyapplicationComponent,
    PathologyreportsComponent,
    CytologicalreportComponent,
    HistologicalreportComponent,
    AdminComponent,
    EesztComponent,
    CounterPipe,
    EesztAuthenticateComponent,
    DeleteCaseComponent,
    SelectCasesComponent,
    SelectEbeuComponent,
    SelectFinancetypeComponent,
    ResetPasswordComponent,
    DeleteServicepointComponent,
    ResetCaseComponent,
    ResponsereportComponent,
    Cytologyapplicationv2Component,
    Cytologicalreportv2Component,
    StatisticsComponent,
    UsersComponent,
    ServicepointsComponent,
    FinancingComponent,
    PricesComponent,
    SelectPatientComponent,
    ValidatorUsersComponent,
    DocumentsComponent,
    PrintpdfComponent,
    Cytologyapplicationv3Component,
    Cytologicalreportv3Component,
    AreYouSureComponent,
    ArabicToRomanPipe,
    TranslateStatusPipe,
    TranslateTypePipe,
    I18nDateFormatPipe,
    DatePickerLangPipe
  ],
  imports: [
    ChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    NgxUiLoaderModule,
    // NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({
      exclude: ['/reports'],
      showForeground: true,
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center'
    }),
    PdfViewerModule,
    ScrollingModule
  ],
  providers: [
    CookieService,
    ToastrService,
    httpInterceptProviders,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
      hasBackdrop: true,
      disableClose: true}},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
