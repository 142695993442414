<app-navbar></app-navbar>
<div class="wrapper">
  <div class="row mb-5">
    <div class="col">
      <div class="form-inline">
        <label class="pathologyRecordsLabel" i18n>taj szám</label>
        <input [formControl]="ssn" class="pathologyRecordsInput form-control" type="text"/>
      </div>
    </div>
    <div class="col">
      <div class="form-inline">
        <label class="pathologyRecordsLabel" i18n>naplószám</label>
			  <input [formControl]="logNumber" class="pathologyRecordsInput form-control" type="text"/>
      </div>
    </div>
    <div class="col">
      <div class="form-inline">
        <label class="pathologyRecordsLabel" i18n>név</label>
        <input [formControl]="birthName" class="pathologyRecordsInput form-control" type="text"/>
      </div>
    </div>
    <div class="col">
      <div class="form-inline">
        <label class="pathologyRecordsLabel" i18n>beküldő orvos</label>
			  <input [formControl]="referringDoctor" class="pathologyRecordsInput form-control" type="text"/>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="cytologyVersion">
    <div class="col">
      <div class="d-flex align-items-center">
        <div class="form-inline mr-2">
          <label class="pathologyRecordsLabel" i18n>beküldés ideje - tól</label>
          <input [formControl]="creationDateFrom" class="dateInput form-control" type="date" [lang]="dateType" min="1900-01-01" max="2100-12-31">
        </div>
        <div class="form-inline">
          <label class="pathologyRecordsLabel" i18n>beküldés ideje - ig</label>
          <input [formControl]="creationDateTo" class="dateInput form-control" type="date"  [lang]="dateType" min="1900-01-01" max="2100-12-31"/>
        </div>
      </div>
    </div>
  </div>
	<div class="w-100">
		<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        <table mat-table [dataSource]="casesForPaginator" class="w-100">
            <ng-container matColumnDef="id" style="display: none;">
                <td mat-cell *matCellDef="let element">{{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="number">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  <select (change)="filterByTypeOfCase($event.target.value)" class="select" name="típus" id="type">
                    <option value="" disabled selected i18n>típus</option>
                    <option value="szöv" i18n>szöv</option>
                    <option value="cyt" i18n>cyt</option>
                    <option value="acyt" i18n>acyt</option>
                    <option value="" i18n>összes</option>
                  </select>
				        </th>
                <td mat-cell *matCellDef="let element">{{element.type | translateType: messages.locale}}</td>
            </ng-container>
            <ng-container matColumnDef="birthName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>név</th>
                <td mat-cell *matCellDef="let element">{{element.patientBirthName}}</td>
            </ng-container>
            <ng-container matColumnDef="ssn">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>taj</th>
                <td mat-cell *matCellDef="let element">{{element.patientSsn}}</td>
            </ng-container>
            <ng-container matColumnDef="birthDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>születési dátum</th>
                <td mat-cell *matCellDef="let element">{{element.patientBirthDate}}</td>
            </ng-container>
            <ng-container matColumnDef="doctorName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beküldő orvos</th>
                <td mat-cell *matCellDef="let element">{{element.creatorUserName}}</td>
            </ng-container>
            <ng-container matColumnDef="logNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>naplószám</th>
                <td mat-cell *matCellDef="let element">{{element.logPrefix? element.logPrefix + '/' : '' }}{{element.logNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  <select (change)="filterByStatusOfCase($event.target.value)" class="select" name="státusz" id="statusz">
                    <option value="" disabled selected i18n>státusz</option>
                    <option value="új" i18n>új</option>
                    <option value="véglegesítésre vár" i18n>véglegesítésre vár</option>
                    <option value="kész" i18n>kész</option>
                    <option value="all" i18n>összes</option>
                  </select>
				        </th>
                <td mat-cell *matCellDef="let element">{{element.status | translateStatus: messages.locale}}</td>
            </ng-container>
            <ng-container matColumnDef="eesztDone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>EESZT</th>
                <td mat-cell *matCellDef="let element">{{element.isEesztSent ? '✔' : '✖' }}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beküldés ideje</th>
                <td mat-cell *matCellDef="let element">{{element.creationDate | i18nDateFormat: messages.locale}}</td>
            </ng-container>
            <ng-container matColumnDef="icons">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" style="text-align: left; padding: 5px;">
					<i *ngIf="(currentUser.roleCode !== 'REFERRING_DOCTOR' && currentUser.roleCode !== 'REFERRING_ASSISTANT' && element.status == 'véglegesítésre vár') || element.status == 'új' || element.status == 'kész'" class="fa fa-edit mr-2 modifyIcon" (click)="goToReport(element)"
          [title]="currentUser.roleCode === 'REFERRING_DOCTOR' || currentUser.roleCode === 'REFERRING_ASSISTANT' ? 'megtekintés' : 'megtekintés/módosítás'"></i>
					<i *ngIf="element.status == 'kész' && currentUser.roleCode !== 'REFERRING_DOCTOR' && currentUser.roleCode !== 'REFERRING_ASSISTANT'" class="fa fa-trash deleteIcon" aria-hidden="true" (click)="showDeleteCase(element)"  title="törlés"></i>
				</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
        </table>
    </div>
</div>
