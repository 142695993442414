<div class="wrapper mt-5">
    <div class="form-inline">
        <label class="submitReportLabel mr-3 ml-3" i18n>páciens neve</label>
        <input [formControl]="patientName" class="submitReportInput form-control" type="text"/>
        <label class="submitReportLabel mr-3 ml-5" i18n>klinika</label>
        <input [formControl]="clinic" class="submitReportInput form-control" type="text"/>
        <label class="submitReportLabel mr-3 ml-5" i18n>típus</label>
        <ng-select [formControl]="type" [items]="types" bindLabel="type" style="min-width: 100px"></ng-select>
        <label class="submitReportLabel mr-3 ml-5" i18n>könyvelve</label>
        <input [formControl]="isAccounted"  type="checkbox"/>
    </div>
    <div class="form-inline mt-1">
        <label class="submitReportLabel mr-3 ml-3" i18n>leletezés dátuma - tól</label>
        <input [formControl]="dateFrom" class="submitReportInput form-control" type="date" min="1900-01-01" max="2100-12-31" [lang]="dateType"/>
        <label class="submitReportLabel mr-3 ml-5" i18n>leletezés dátuma - ig</label>
        <input [formControl]="dateTo" class="submitReportInput form-control" type="date" min="1900-01-01" max="2100-12-31" [lang]="dateType"/>
    </div>
    <button (click)="downloadExcel()" class="mb-2 mr-3 downloadExcelButton" style="float: right;" i18n>Táblázat letöltése <i class="fa fa-download" aria-hidden="true"></i></button>
    <button class="mb-2 mr-3 downloadExcelButton" style="float: right;" i18n="@@unAccountSelected"  *ngIf="isAccounted.value" (click)="setAccountedList(false)">Kijelöltek könyvelésének visszavonása</button>
    <button class="mb-2 mr-3 downloadExcelButton" style="float: right;" i18n="@@accountSelected" *ngIf="!isAccounted.value" (click)="setAccountedList(true)">Kijelöltek könyvelése</button>
    <div class="w-100 mt-2">
        <mat-paginator [pageSizeOptions]="[10, 25, 100, 500, 1000]" style="float:left"></mat-paginator>
        <table mat-table [dataSource]="allAccountedCaseForPaginator" class="w-100" id="financeTable">
            <ng-container matColumnDef="id" style="display: none;">
                <td mat-cell *matCellDef="let element">{{element.accountedCase.id}} </td>
            </ng-container>
            <ng-container matColumnDef="number">
              <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>leletezés dátuma</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.resultDate | date: 'yyyy-MM-dd'}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>típus</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.type}}</td>
            </ng-container>
            <ng-container matColumnDef="logNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>naplószám</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.logNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="patientName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beteg neve</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.patient.name}}</td>
            </ng-container>
            <ng-container matColumnDef="servicePoint">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beküldő klinika</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.servicePoint.name}}</td>
            </ng-container>
            <ng-container matColumnDef="doctorName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beküldő orvos</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.doctorName}}</td>
            </ng-container>
            <ng-container matColumnDef="pap">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>pap</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.pap ?  '✔' : '✖' }}</td>
            </ng-container>
            <ng-container matColumnDef="lbc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>lbc</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.lbc ?  '✔' : '✖'}}</td>
            </ng-container>
            <ng-container matColumnDef="gratis">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>gratis</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.gratis ?  '✔' : '✖'}}</td>
            </ng-container>
            <ng-container matColumnDef="who29450">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29450</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.cytologiaiVizsgalatImmuncyto : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who29400">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29400</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.diagnosztikusAspiratiosExfol : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who29070">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29070</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.kiegeszitoPontszamVolumiozus : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who25040">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>25040</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.mikroszkoposVizsgalatZiehl : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who29050">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29050</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.szovettaniVizsgalatImmunhisz : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who29000">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29000</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.szovettaniVizsgalatASzokaso : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who25030">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>25030</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.mikroszkiposVizsgalatTenyesztetlenAnyagbol : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="who27000">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>27000</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.who ? element.accountedCase.who.gombaVizsgalatDirektKenetben : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>összeg</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.price? element.accountedCase.price : ''}}</td>
            </ng-container>
            <ng-container matColumnDef="accounted">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>könyvelt</th>
                <td mat-cell *matCellDef="let element">{{element.accountedCase.accounted ? '✔' : '✖'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  <input type="checkbox" title="Összes kijelölése" (change)="selectAll(selectAllControl.value)" [formControl]="selectAllControl">
                </th>
                <td mat-cell *matCellDef="let element">
                  <input type="checkbox" [formControl]="element.check" (click)="selectCase(element.accountedCase.id, $event)">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; " (click)="setAccountedStatus(row.accountedCase)"></tr>
        </table>
    </div>
</div>
