import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { GetFinanceOfServicePointResponse } from './../../../../shared/models/response/getFinanceOfServicePointResponse';
import { ApiService } from 'src/app/core/http/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Finance } from 'src/app/shared/models/finance';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { FormGroup, FormControl } from '@angular/forms';
import { ServicePoint } from 'src/app/shared/models/ServicePoint';
import { MatSortable } from '@angular/material/sort';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: GetUserByUserNameResponse;
  servicePoints: Array<ServicePoint>;

  allFinance = new MatTableDataSource<GetFinanceOfServicePointResponse>();
  modifiableFinanceId: number;
  isModifyFinance = false;

  financeServicePoint = new FormControl('');

  citology = new FormControl('');
  pap = new FormControl('');
  lbc = new FormControl('');
  cytologiaiVizsgalatImmuncyto = new FormControl('');
  diagnosztikusAspiratiosExfol = new FormControl('');
  kiegeszitoPontszamVolumiozus = new FormControl('');
  mikroszkoposVizsgalatZiehl = new FormControl('');
  szovettaniVizsgalatImmunhisz = new FormControl('');
  szovettaniVizsgalatASzokaso = new FormControl('');
  gombaVizsgalatDirektKenetben = new FormControl('');
  mikroszkiposVizsgalatTenyesztetlenAnyagbol = new FormControl('');

  financeFormGroup = new FormGroup({
    financeServicePoint: this.financeServicePoint,
    citology: this.citology,
    pap: this.pap,
    lbc: this.lbc,
    cytologiaiVizsgalatImmuncyto: this.cytologiaiVizsgalatImmuncyto,
    diagnosztikusAspiratiosExfol: this.diagnosztikusAspiratiosExfol,
    kiegeszitoPontszamVolumiozus: this.kiegeszitoPontszamVolumiozus,
    mikroszkoposVizsgalatZiehl: this.mikroszkoposVizsgalatZiehl,
    szovettaniVizsgalatImmunhisz: this.szovettaniVizsgalatImmunhisz,
    szovettaniVizsgalatASzokaso: this.szovettaniVizsgalatASzokaso,
    gombaVizsgalatDirektKenetben: this.gombaVizsgalatDirektKenetben,
    mikroszkiposVizsgalatTenyesztetlenAnyagbol: this.mikroszkiposVizsgalatTenyesztetlenAnyagbol,
  });

  displayedColumns: string[] = [
    'servicePoint',
    'citology',
    'pap',
    'lbc',
    '29450',
    '29400',
    '29070',
    '25040',
    '29050',
    '29000',
    '25030',
    '27000',
    'icons'
  ];


  constructor(
    private apiService: ApiService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getAllFinanceBySp();
    this.findAllServicePointsByRef();
  }

  getAllFinanceBySp() {
    this.apiService.getAllFinanceByRefId(this.user.servicePointId).subscribe(response => {
      this.allFinance.data = response;
      this.allFinance.paginator = this.paginator;
      this.setPaginatorTexts(this.paginator);
    }, (error: Response) => {
      console.error(error);
    });
  }

  setPaginatorTexts(paginator: MatPaginator) {
    paginator._intl.nextPageLabel = this.messages.locale === 'hu' ? 'Következő' : 'Next';
    paginator._intl.previousPageLabel = this.messages.locale === 'hu' ? 'Előző' : 'Previous';
    paginator._intl.itemsPerPageLabel = this.messages.locale === 'hu' ? 'Oldalanként' : 'Size';
    paginator._intl.firstPageLabel = this.messages.locale === 'hu' ? 'Első' : 'First';
    paginator._intl.lastPageLabel = this.messages.locale === 'hu' ? 'Utolsó' : 'Last';
  }

  findAllServicePointsByRef() {
    this.apiService.getAllServicePointsByRef(this.user.servicePointId).subscribe(data => {
      this.servicePoints = data;
    }, (error: Response) => {
      console.error(error);
    });
  }

  saveFinance() {
    if (this.financeServicePoint.value) {
      const request: Finance = {
        citologia: +this.citology.value,
        citologiaPap: +this.pap.value,
        citologiaLbc: +this.lbc.value,
        servicePointId: this.financeServicePoint.value,
        cytologiaiVizsgalatImmuncyto: +this.cytologiaiVizsgalatImmuncyto.value,
        diagnosztikusAspiratiosExfol: +this.diagnosztikusAspiratiosExfol.value,
        kiegeszitoPontszamVolumiozus: +this.kiegeszitoPontszamVolumiozus.value,
        mikroszkoposVizsgalatZiehl: +this.mikroszkoposVizsgalatZiehl.value,
        szovettaniVizsgalatASzokaso: +this.szovettaniVizsgalatASzokaso.value,
        szovettaniVizsgalatImmunhisz: +this.szovettaniVizsgalatImmunhisz.value,
        mikroszkiposVizsgalatTenyesztetlenAnyagbol: +this.mikroszkiposVizsgalatTenyesztetlenAnyagbol.value,
        gombaVizsgalatDirektKenetben: +this.gombaVizsgalatDirektKenetben.value
      };
      this.apiService.saveFinance(request).subscribe(response => {
        this.financeFormGroup.reset();
        this.getAllFinanceBySp();
      }, (error: Response) => {
        this.toasterService.error(
          this.messages.error.ERROR_INSITUTE_DATA_EXISTS[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_INSTITUTE_REQUIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    }
  }

  updateFinance() {
    const modifiableFinanceId = this.modifiableFinanceId;
    const request: Finance = {
      citologia: +this.citology.value,
      citologiaPap: +this.pap.value,
      citologiaLbc: +this.lbc.value,
      servicePointId: this.financeServicePoint.value,
      cytologiaiVizsgalatImmuncyto: +this.cytologiaiVizsgalatImmuncyto.value,
      diagnosztikusAspiratiosExfol: +this.diagnosztikusAspiratiosExfol.value,
      kiegeszitoPontszamVolumiozus: +this.kiegeszitoPontszamVolumiozus.value,
      mikroszkoposVizsgalatZiehl: +this.mikroszkoposVizsgalatZiehl.value,
      szovettaniVizsgalatASzokaso: +this.szovettaniVizsgalatASzokaso.value,
      szovettaniVizsgalatImmunhisz: +this.szovettaniVizsgalatImmunhisz.value,
      mikroszkiposVizsgalatTenyesztetlenAnyagbol: +this.mikroszkiposVizsgalatTenyesztetlenAnyagbol.value,
      gombaVizsgalatDirektKenetben: +this.gombaVizsgalatDirektKenetben.value,
    };

    this.apiService.updateFinance(modifiableFinanceId, request).subscribe(response => {
      this.financeFormGroup.reset();
      this.isModifyFinance = false;
      this.getAllFinanceBySp();
      this.toasterService.success(
        this.messages.success.SUCCESS_UPDATE_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale],
        {positionClass: 'toast-top-center'});
    }, (error: Response) => {
      this.toasterService.error(
        this.messages.error.ERROR_WRONG_VALUE[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    });
  }

  modifyFinance($event: GetFinanceOfServicePointResponse) {
    this.isModifyFinance = true;
    this.modifiableFinanceId = $event.id;
    this.financeServicePoint.setValue($event.servicePoint.id);
    this.citology.setValue($event.citologia);
    this.pap.setValue($event.citologiaPap);
    this.lbc.setValue($event.citologiaLbc);
    this.cytologiaiVizsgalatImmuncyto.setValue($event.cytologiaiVizsgalatImmuncyto);
    this.diagnosztikusAspiratiosExfol.setValue($event.diagnosztikusAspiratiosExfol);
    this.kiegeszitoPontszamVolumiozus.setValue($event.kiegeszitoPontszamVolumiozus);
    this.mikroszkoposVizsgalatZiehl.setValue($event.mikroszkoposVizsgalatZiehl);
    this.szovettaniVizsgalatImmunhisz.setValue($event.szovettaniVizsgalatImmunhisz);
    this.szovettaniVizsgalatASzokaso.setValue($event.szovettaniVizsgalatASzokaso);
    this.mikroszkiposVizsgalatTenyesztetlenAnyagbol.setValue($event.mikroszkiposVizsgalatTenyesztetlenAnyagbol);
    this.gombaVizsgalatDirektKenetben.setValue($event.gombaVizsgalatDirektKenetben);
  }

  clearForm(formGroup: FormGroup) {
    formGroup.reset();
    this.isModifyFinance = false;
  }


}
