<div class="wrapper">
        <label class="submitReportLabel mt-3" i18n>beküldő orvos</label>
        <input *ngIf="user.roleCode !=='REFERRING_ASSISTANT' || this.router.url.includes('report/')" [formControl]="doctorName" class="submitReportInput form-control" type="text"/>
        <ng-select  (change)="setSubmitReportdata($event)" *ngIf="user.roleCode ==='REFERRING_ASSISTANT' && !this.router.url.includes('report/')" [clearable]="true" [closeOnSelect]="true" [items]="usersOfServicePoint" [searchable]="true" bindLabel="name" bindValue="id" [formControl]="doctorName" placeholder="Válasszon..."></ng-select>
        <label class="submitReportLabel mt-3" i18n>beküldő orvos címe</label>
        <input [formControl]="addressOfDoctor" class="submitReportInput form-control" type="text"/>
        <label class="submitReportLabel mt-3"i18n >beküldő orvos telefonszáma</label>
        <input [formControl]="phoneOfDoctor" class="submitReportInput form-control" type="text"/>
        <label class="submitReportLabel mt-5" i18n>klinika</label>
        <input [formControl]="servicePoint" class="submitReportInput form-control" type="text"/>
        <label class="submitReportLabel mt-3" i18n>klinika címe</label>
        <input [formControl]="addressOfServicePoint" class="submitReportInput form-control" type="text"/>
        <label class="submitReportLabel mt-3" i18n>klinika telefonszáma</label>
        <input [formControl]="phoneOfServicePoint" class="submitReportInput form-control" type="text"/>
        <button *ngIf="isCyto && router.url.includes('/dashboard')" (click)="checkCitologySave()" class="submitReportButton mt-3" i18n>küldés</button>
        <button *ngIf="router.url.includes('cyt') && statusOfCase === 'új' && (user.roleCode ==='REFERRING_ASSISTANT' || user.roleCode === 'REFERRING_DOCTOR')" (click)="checkCitologySave()" class="submitReportButton mt-3" i18n>módosítás</button>
        <button *ngIf="!isCyto &&  router.url.includes('/dashboard')" (click)="checkHistologySave()" class="submitReportButton mt-3" i18n>küldés</button>
        <button *ngIf="router.url.includes('szov') && statusOfCase === 'új' && (user.roleCode ==='REFERRING_ASSISTANT' || user.roleCode === 'REFERRING_DOCTOR')" (click)="saveHistologyReq()" class="submitReportButton mt-3" i18n>módosítás</button>
</div>

