import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Inject, Injectable} from "@angular/core";
import {AuthenticationService} from 'src/app/core/authentication/authentication.service';
import { DOCUMENT } from '@angular/common';

@Injectable({providedIn: 'root'})
export class Messages {
    constructor(
      @Inject(DOCUMENT) private document: Document
    ) {}
    locale = this.document.location.hostname.includes('com') ? 'en' : 'hu';
    error = {
        ERROR_TITLE_DEFAULT: {
            hu: 'Hiba!',
            en: 'Error!'
        },
        ERROR_TITLE_WRONG_PARAMETER: {
            hu: 'Hibás paraméterezés!',
            en: 'Invalid parameters!'
        },
        ERROR_INVALID_CREDENTIALS: {
            hu: 'Hibás felhasználónév vagy jelszó!',
            en: 'Invalid username or password!'
        },
        ERROR_SERVER_COMMUNICATION: {
            hu: 'Hiba történt a kommunkáció során!',
            en: 'Server communication error!'
        },
        ERROR_PRINTABLE_CASE_DOESNT_EXIST: {
            hu: 'Még nem létezik nyomtatható eset!',
            en: 'There is no printable document!'
        },
        ERROR_EESZT_AUTH_EXPIRED: {
            hu: 'EESZT munkamenete lejárt, kérem autentikálja magát újra',
            en: 'EESZT session expired, please authenticate again'
        },
        ERROR_ESZEMELYI_CLIENT_COMMUNCATION: {
            hu: 'Hiba az eszemélyi klienssel való kommunikáció során, kérjük ellenőrizze, hogy fut-e az eszemélyi kliens.',
            en: 'Server communication error while authentication via eSzemelyi Client, check if the client is running!'
        },
        ERROR_MISSING_SAVE_PATIENT_DATA: {
            hu: 'Adjon meg minden szükséges adatot a mentéshez!',
            en: 'Please provide every REQUIRED data for the save!'
        },
        ERROR_WRONG_SSN: {
            hu: 'A taj szám nem megfelelő!',
            en: 'Invalid social security number'
        },

        ERROR_CHOOSE_DOCTOR: {
            hu: 'A kérés beküldése előtt válasszon beküldő orvost!',
            en: 'Before sending, please choose a referring doctor'
        },
        ERROR_SAVE_PATIENT_DATA_BEFORE_SEND: {
            hu: 'A kérés beküldése előtt kérjük mentse a páciens adatait!',
            en: 'Before sending, please save the patient'
        },
        ERROR_FILL_FORM: {
            hu: 'Kérjük töltse ki az űrlapot!',
            en: 'Please fill the form!'
        },
        ERROR_FILL_FORM_VALID: {
            hu: 'Kérjük tötlse ki az űrlapot megfelelően',
            en: 'Please fill the form correctly!'
        },
        ERROR_DATE_CANNOT_BE_FUTURE: {
            hu: 'Dátum nem lehet jövőbeli!',
            en: 'Date cannot be future!'
        },
        ERROR_FILL_FORM_ASTERISK: {
            hu: 'A "*"-al jelölt mezők kitöltése kötelező!',
            en: 'Fields marked with "*" are required'
        },
        ERROR_INSITUTE_DATA_EXISTS: {
            hu: 'A megadott intézményhez már léteznek adatok! Módosítsa azokat!',
            en: 'The choosen institue already has data, please update them!'
        },
        ERROR_INSTITUTE_REQUIRED: {
            hu: 'Intézmény megadása kötelező!',
            en: 'Institute is required!'
        },
        ERROR_WRONG_VALUE: {
            hu: 'Hibás érték!',
            en: 'Invalid value!'
        },
        ERROR_NAME_ADDRESS_REQUIRED: {
            hu: 'Név és cím megadása kötelező!',
            en: 'Name and address is required!'
        },
        ERROR_NAME_REQUIRED: {
            hu: 'Név megadása kötelező!',
            en: 'Name is required!'
        },
        ERROR_USERNAME_REQUIRED: {
            hu: 'Felhasználónév megadása kötelező!',
            en: 'Username is required!'
        },
        ERROR_ROLE_REQUIRED: {
            hu: 'Szerepkör megadása kötelező!',
            en: 'Role is required!'
        },
        ERROR_EMAIL_WRONG: {
            hu: 'Email formátum nem megfelelő!',
            en: 'Invalid email!'
        },
        ERROR_LOGNUMBER_LENGTH: {
            hu: 'Naplószám maxmimum 8 jegyű lehet!',
            en: 'Lognumber must be 8 characters!'
        },
        ERROR_LOGNUMBER_REQUIRED: {
            hu: 'Naplószám megadása kötelező!',
            en: 'Lognumber is required'
        },
        ERROR_BNO_REQUIRED: {
            hu: 'BNO megadása kötelező!',
            en: 'ICD is required'
        },
        ERROR_HEADER_REQUIRED: {
            hu: 'Fejléc megadása kötelező!',
            en: 'Header is required'
        },
        ERROR_PRE_SCREENING_SPECIALIST_MISSING: {
            hu: 'Előszűrő szakasszisztens megadás kötelező!',
            en: 'Pre-screening specialist is required'
        },
        ERROR_NOT_PROCESSED_COMMENT: {
            hu: 'Adja meg a nem feldolgozott indoklását!',
            en: 'Please provide the not processed reason'
        },
        ERROR_KOROS_DIAG_OKA: {
            hu: 'Jelölje be a kóros diagnózis okait!',
            en: 'Mark the reason of abnormality!'
        },
        ERROR_NO_PATIENT: {
            hu: 'Nincs ilyen páciens!',
            en: 'Patient not found!'
        },
        ERROR_USER_ALREADY_LOGGED_IN: {
            hu: 'Jelenleg egy másik felhasználó van bejelentkezve!',
            en: 'Another user is logged in!'
        },
        ERROR_PDF_PASSWORD_WRONG: {
            hu: 'Hibás jelszót adott meg, vagy nincsen joga a dokumanetum megtekintéséhez!',
            en: 'Invalid password, or no right to open the document!'
        },
        ERROR_PASSWORDS_DONT_MATCH: {
            hu: 'A két jelszó nem egyezik meg!',
            en: 'The passwrods dont match!'
        },
        ERROR_VALIDATOR_DOCTOR_REQUIRED: {
          hu: 'Validáló orvos megadása kötelező!',
          en: 'Validator doctor is required!'
        }
    };
    success = {
        SUCCESS_TITLE: {
            hu: 'Ok!',
            en: 'Ok!'
        },
        SUCCESS_DELETE_INSTITUTE: {
            hu: 'A kiválasztott intézmény törlésre került!',
            en: 'Selected institute has been succesfully deleted!'
        },
        SUCCESS_EESZT_AUTH_OK: {
            hu: 'Sikeres csatlakozás!',
            en: 'Connection successful!'
        },
        SUCCESS_PASSWORD_RESET: {
            hu: ' felhasználó jelszava sikeresn visszaállítva az alapértelmezettre!',
            en: ' user\'s password has been successfully reset!'
        },
        SUCCESS_SAVE_PATIENT: {
            hu: 'A páciens mentése sikeres!',
            en: 'Patient saved successfully!'
        },
        SUCCESS_UPDATE_PATIENT: {
            hu: 'Páciens adatok módosítása sikeres!',
            en: 'Patient data has been updated successfully!'
        },
        SUCCESS_SEND_OK: {
            hu: 'Sikeres küldés!',
            en: 'Sending successfully!'
        },
        SUCCESS_DELETE_CASE_OK: {
            hu: 'Az eset törlése sikeres!',
            en: 'The case has been deleted successfully'
        },
        SUCCESS_SAVE_OK: {
            hu: 'Sikeres mentés!',
            en: 'Saved successfully!'
        },
        SUCCESS_UPDATE_OK: {
            hu: 'Sikeres módosítás!',
            en: 'Updated successfully!'
        },
        SUCCESS_INSITUTE_SAVE_OK: {
            hu: 'A klinika létrehozása sikeres!',
            en: 'Institute has been saved successfully!'
        },
        SUCCESS_SAVE_USER: {
            hu: 'A felhasználó létrehozása sikeres!',
            en: 'User has been saved successfully'
        },
        SUCCESS_VALIDATOR_USER_DELETED: {
            hu: 'Validáló orvos törölve',
            en: 'Validator user has been successfully deleted!'
        },
        SUCCESS_UPDATE_PASSWORD: {
            hu: 'Sikeres jelszóváltoztatás!',
            en: 'Password has been updated successfully!'
        },
        SUCCESS_ACCOUNTED_LIST: {
            hu: 'Esetek sikeresen könyvelve!',
            en: 'Cases has been successfully accounted!'
        }
    };
    info = {
        INFO_NO_PATIENT_FOUND: {
            hu: 'Nem található páciens. Kérem adja meg a szükséges adatokat!',
            en: 'Patient not found. Please provide the required data!'
        },
        INFO_EBEU_NOT_FOUND: {
            hu: 'Nem található a pácienshez e-beutaló',
            en: 'No EBEU found!'
        },
        INFO_BCP_LIST_EMPTY: {
            hu: 'EESZT kommunikációs napló üres!',
            en: 'EESZT communcation log is empty!'
        },
        INFO_EHR_NOT_FOUND: {
            hu: 'Nem található egészségügyi dokumentum a pácienshez!',
            en: 'No document found for this patient!'
        }
    };
}
