import { GetUserByServicePointIdAndSealNumberResponse } from './../../shared/models/response/GetUserByServicePointIdAndSealNumber';
import { EhrDocument } from './../../shared/models/response/ehrDocument';
import { GetEhrDocumentRequest } from './../../shared/models/request/getEhrDocumentRequest';
import { SaveHistologyReqV3Request } from './../../shared/models/request/saveHistologyReqV3Request';
import { Case } from './../../shared/models/case';
import { SaveCytologyResV3Request } from './../../shared/models/request/saveCytologyResV3Request';
import { SaveCytologyReqV3Request } from './../../shared/models/request/saveCytologyReqV3Request';
import { GetPdfForRefferingResponse } from './../../shared/models/response/getPdfForRefferingResponse';
import { GetPdfForRefferingRequest } from './../../shared/models/request/getPdfForRefferingRequest';
import { Snomed } from './../../shared/models/snomed';
import { Bno } from './../../shared/models/bno';
import { SaveValidatorUserRequest } from './../../shared/models/request/saveValidatorUser';
import { ValidatorUser } from './../../shared/models/validatorUser';
import { WrongDocuments } from './../../shared/models/wrongDocuments';
import { SaveCytologyResV2Request } from './../../shared/models/request/saveCytologyResV2Request';
import { ChangePwRequest } from './../../shared/models/request/changePwRequest';
import { EesztSamlOtpResponse } from './../../shared/models/response/eesztSamlOtpResponse';
import { EesztSamlOtpRequest } from './../../shared/models/request/eesztSamlOtpRequest';
import { PdfHeader } from './../../shared/models/pdfHeader';
import { GetFinanceOfServicePointResponse } from './../../shared/models/response/getFinanceOfServicePointResponse';
import { ModifyUserRequest } from './../../shared/models/request/modifyUserRequest';
import { BcpMessage } from './../../shared/models/bcpMessage';
import { SearchEbeuResponse } from './../../shared/models/response/searchEbeuResponse';
import { EesztSamlEszigRequest } from './../../shared/models/request/eesztSamlEszigRequest';
import { EesztSamlEidResponse } from './../../shared/models/response/eesztSamlEidResponse';
import { EesztSamlEidRequest } from './../../shared/models/request/eesztSamlEidRequest';
import { Role } from './../../shared/models/Role';
import { EbeuSearchRequest } from './../../shared/models/request/ebeuSearchRequest';
import { User } from 'src/app/shared/models/User';
import { SaveSpRequest } from './../../shared/models/request/saveSpRequest';
import { ServicePoint } from './../../shared/models/ServicePoint';
import { SetLogNumberRequest } from './../../shared/models/request/setLogNumberRequest';
import { GetCaseDescriptionByIdResponse } from './../../shared/models/response/getCaseDescriptionByIdResponse';
import { GetServicePointByIdResponse } from './../../shared/models/response/getServicePointByIdResponse';
import { GetAllCasesResponse } from './../../shared/models/response/getAllCasesResponse';
import { SaveCytologyReqRequest } from './../../shared/models/request/saveCytologyReqRequest';
import { SaveCytologyResRequest } from './../../shared/models/request/savecytologyResRequest';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { GetPatientRequest } from 'src/app/shared/models/request/getPatientRequest';
import { GetPatientResponse } from 'src/app/shared/models/response/getPatientResponse';
import { SavePatientRequest } from 'src/app/shared/models/request/savePatientRequest';
import { SaveHistologyReqRequest } from 'src/app/shared/models/request/saveHistologyReqRequest';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { SaveHistologyResRequest } from 'src/app/shared/models/request/saveHistologyResRequest';
import { EszigAuthResponse } from 'src/app/shared/models/response/eszigAuthResponse';
import { GetEhrListRequest } from 'src/app/shared/models/request/getEhrListRequest';
import { GetEhrListResponse, } from 'src/app/shared/models/response/getEhrListResponse';
import { GetUserByIdResponse } from 'src/app/shared/models/response/getUserByIdResponse';
import { AccountedCase } from 'src/app/shared/models/accountedCase';
import { EesztSamlEszigResponse } from 'src/app/shared/models/response/eesztSamlEszigResponse';
import { DeleteCaseRequest } from 'src/app/shared/models/request/deleteCaseRequest';
import { Finance } from 'src/app/shared/models/finance';
import { EesztSamlMobilResponse } from 'src/app/shared/models/response/eesztSamlMobilResponse';
import { EesztSamlMobilRequest } from 'src/app/shared/models/request/eesztSamlMobilRequest';
import { RssDataResponse } from 'src/app/shared/models/response/rssDataResponse';
import { SaveCytologyReqV2Request } from 'src/app/shared/models/request/saveCytologyReqV2Request';
import { UpdatePatientRequest } from 'src/app/shared/models/request/updatePatientRequest';
import { SzakmaKod } from 'src/app/shared/models/szakmakod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  environment = environment;

  constructor(
    private http: HttpClient
  ) { }

  getPatientBy2T(request: GetPatientRequest) {
    return this.http.get<Array<GetPatientResponse>>
    (this.environment.pathologyURL + 'rest/patient/' + request.ssn + '/' + request.birthDate);
  }

  getPatientById(patientId: number) {
    return this.http.get<GetPatientResponse>(this.environment.pathologyURL + 'rest/patient/find/' + patientId);
  }

  savePatient(request: SavePatientRequest) {
    return this.http.post<HttpHeaders>(this.environment.pathologyURL + 'rest/patient/save', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      ),
      observe: 'response'
    });
  }

  updatePatient(id: number, request: UpdatePatientRequest) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/patient/update/' + id, request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  getCaseById(caseId: number) {
    return this.http.get<Array<GetCaseByIdResponse>>(this.environment.pathologyURL + 'rest/document/get/case/' + caseId);
  }

  saveCytologyReqRequest(request: SaveCytologyReqRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/citologia/req', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }
  saveCytologyReqV2Request(request: SaveCytologyReqV2Request) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/citology/req/v2', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }
  saveCytologyReqV3Request(request: SaveCytologyReqV3Request) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/citology/req/v3', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  saveCytologyResRequest(request: SaveCytologyResRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/citologia/res', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }
  saveCytologyResV2Request(request: SaveCytologyResV2Request) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/citology/res/v2', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }
  saveCytologyResV3Request(request: SaveCytologyResV3Request) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/citology/res/v3', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  saveHistologyReqRequest(request: SaveHistologyReqRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/szovettan/req', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }
  saveHistologyReqV3Request(request: SaveHistologyReqV3Request) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/szovettan/req/v3', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  saveHistologyResRequest(request: SaveHistologyResRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/document/save/szovettan/res', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  getPdfOfDocument(documentId: number) {
    return this.http.get<Response>(this.environment.pathologyURL + 'rest/document/get/pdf/'  + documentId);
  }

  getAllCases(servicePointId: number) {
    return this.http.get<Array<GetAllCasesResponse>>(this.environment.pathologyURL + 'rest/case/findall/' + servicePointId);
  }

  findCaseByCaseId(caseId: number) {
    return this.http.get<Case>(this.environment.pathologyURL + 'rest/case/findby/' + caseId);
  }

  getDescription(documentId: number) {
    return this.http.get<GetCaseDescriptionByIdResponse>(this.environment.pathologyURL + 'rest/document/description/' + documentId);
  }

  updateStatus(id: number, status: string) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/case/update/status/' + id + '/' + status, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  deleteCaseById(caseId: number) {
    return this.http.delete<Response>(this.environment.pathologyURL + 'rest/case/delete/' + caseId);
  }

  setLogNumber(request: SetLogNumberRequest) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/case/set/lognumber/' + request.caseId + '/' + request.logNumber, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  getAllAccountedCase(refServicePointId: number) {
    // return this.http.get<Array<AccountedCase>>(this.environment.pathologyURL + 'rest/case/findall/accounted/old/' + refServicePointId);
    return this.http.get<Array<AccountedCase>>(this.environment.pathologyURL + 'rest/case/findall/accounted/' + refServicePointId);
  }

  setAccounted(caseId: number, status: boolean) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/case/set/accounted/' + caseId , status, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  setAccountedList(caseIdList: Array<number>, accounted: boolean) {
    const request = {
      caseIdList,
      accounted
    };

    return this.http.put<Response>(this.environment.pathologyURL + 'rest/case/set/accounted-list', request);
  }

  deleteCase(deleteCaseRequest: DeleteCaseRequest) {
    return this.http.delete<Response>(this.environment.pathologyURL + 'rest/case/delete/' +
    deleteCaseRequest.id + '/' +
    deleteCaseRequest.technicalUserId + '/' +
    deleteCaseRequest.userId + '/' +
    deleteCaseRequest.servicePointId + '/' +
    deleteCaseRequest.validatorUserId);
  }

  getUserById(id: number) {
    return this.http.get<GetUserByIdResponse>(this.environment.pathologyURL + 'rest/user/findby/id/' + id);
  }

  getUserByUsername(username: string) {
    return this.http.get<GetUserByUserNameResponse>(this.environment.pathologyURL + 'rest/user/findby/username/' + username);
  }

  getUsersByServicePointId(servicePointId: number) {
    return this.http.get<Array<User>>(this.environment.pathologyURL + 'rest/user/findby/servicepoint/' + servicePointId);
  }

  getUsersByRefId(refId: number) {
    return this.http.get<Array<User>>(this.environment.pathologyURL + 'rest/user/find/allbyref/' + refId);
  }

  getUserByServicePointIdAndSealNumber(servicePointId: number, sealNumber: String) {
    return this.http.get<GetUserByServicePointIdAndSealNumberResponse>(this.environment.pathologyURL + 'rest/user/find/service-point/' + servicePointId + '/seal-number/' + sealNumber);
  }

  getAllValidatorUsersBySp(servicePointId: number) {
    return this.http.get<Array<ValidatorUser>>(this.environment.pathologyURL + 'rest/validatorusers/findby/' + servicePointId);
  }


  resetPassword(userId: number) {
    return this.http.get<Response>(this.environment.pathologyURL + 'rest/user/password/reset/' + userId);
  }

  changePassword(userId: number, request: ChangePwRequest) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/user/password/change/' + userId, request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  deleteServicePoint(servicePointId: number) {
    return this.http.delete<Response>(this.environment.pathologyURL + 'rest/servicepoints/delete/' + servicePointId);
  }

  getServicePointById(servicePointId: number) {
    return this.http.get<ServicePoint>(this.environment.pathologyURL + 'rest/servicepoints/find/' + servicePointId);
  }

  getAllServicePoints() {
    return this.http.get<Array<ServicePoint>>(this.environment.pathologyURL + 'rest/servicepoints/find/all');
  }

  getAllServicePointsByRef(refId: number) {
    return this.http.get<Array<ServicePoint>>(this.environment.pathologyURL + 'rest/servicepoints/find/allbyref/' + refId);
  }

  saveServicePoint(request: SaveSpRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/servicepoints/save', request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  updateServicePoint(request: ServicePoint) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/servicepoints/modify/' + request.id, request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  updateUser(userId: number, request: ModifyUserRequest) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/user/modify/' + userId , request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  getAllRoles() {
    return this.http.get<Array<Role>>(this.environment.pathologyURL + 'rest/role/find/all');
  }

  searchEbeutalo(request: EbeuSearchRequest) {
    return this.http.post<SearchEbeuResponse>(this.environment.pathologyURL + 'rest/eeszt/ebeu/search', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  eszigAuth(token: string) {
    return this.http.get<EszigAuthResponse>(this.environment.eszigReader + token);
  }


  eesztSamlEidReq(request: EesztSamlEidRequest) {
    return this.http.post<EesztSamlEidResponse>(this.environment.pathologyURL + 'rest/eeszt/saml/eid/req', request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  eesztSamlEszigReq(request: EesztSamlEszigRequest) {
    return this.http.post<EesztSamlEszigResponse>(this.environment.pathologyURL + 'rest/eeszt/saml/eszig/req', request , {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  eesztSamlMobilReq(request: EesztSamlMobilRequest) {
    return this.http.post<EesztSamlMobilResponse>(this.environment.pathologyURL + 'rest/eeszt/saml/mobil/req', request , {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  eesztSamlOtpReq(request: EesztSamlOtpRequest) {
    return this.http.post<EesztSamlOtpResponse>(this.environment.pathologyURL + 'rest/eeszt/saml/otp/req', request , {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  getAllBcpMessages(username: string) {
    return this.http.get<Array<BcpMessage>>(this.environment.pathologyURL + 'rest/bcp/find/all/' + username);
  }

  resendBcpMessages(idList: Array<number>) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/bcp/resend/list', idList, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  resendAllBcpMessages(username: string) {
    return this.http.get<Response>(this.environment.pathologyURL + 'rest/bcp/resend/all/' + username);
  }

  getBcpSize(username: string) {
    return this.http.get<number>(this.environment.pathologyURL + 'rest/bcp/get/size/' + username);
  }

  getEhrList(request: GetEhrListRequest) {
    return this.http.post<GetEhrListResponse>(this.environment.pathologyURL + 'rest/eeszt/ehr/getlist', request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  getEhrDocument(request: GetEhrDocumentRequest) {
    return this.http.post<EhrDocument>(this.environment.pathologyURL + 'rest/eeszt/ehr/getdocument', request);
  }

  getRssData() {
    return this.http.get<RssDataResponse>(this.environment.pathologyURL + 'rest/rss/get');
  }

  saveFinance(request: Finance) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/finance/save', request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  updateFinance(id: number, request: Finance) {
    return this.http.put<Response>(this.environment.pathologyURL + 'rest/finance/update/' + id, request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  getAllFinance() {
    return this.http.get<Array<GetFinanceOfServicePointResponse>>(this.environment.pathologyURL + 'rest/finance/findall');
  }

  getAllFinanceBySp(spId: number) {
    return this.http.get<GetFinanceOfServicePointResponse>(this.environment.pathologyURL + 'rest/finance/findby/sp/' + spId);
  }
  getAllFinanceByRefId(refId: number) {
    return this.http.get<Array<GetFinanceOfServicePointResponse>>(this.environment.pathologyURL + 'rest/finance/findallby/refid/' + refId);
  }

  getPdfHeadersBySpId(spId: number) {
    return this.http.get<Array<PdfHeader>>(this.environment.pathologyURL + 'rest/pdfheader/find/sp/' + spId);
  }

  getPdfForReffering(request: GetPdfForRefferingRequest) {
    return this.http.post<GetPdfForRefferingResponse>(this.environment.pathologyURL + 'rest/document/getpdfforreffering', request, {
      headers: new HttpHeaders(
        {'Content-Type' : 'application/json'}
      )
    });
  }

  getWrongDocumentsForSp(servicePointId: number) {
    return this.http.get<Array<WrongDocuments>>
    (this.environment.pathologyURL + 'rest/statistics/getwrongdocuments/forsp/' + servicePointId);
  }

  saveValidatorUser(request: SaveValidatorUserRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/validatorusers/save', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  deleteValidatorUserById(validatorUserId: number) {
    return this.http.delete<Response>(this.environment.pathologyURL + 'rest/validatorusers/' + validatorUserId);
  }

  deleteValidatorUserBySp(servicePointId: number, userId: number) {
    return this.http.delete<Response>(this.environment.pathologyURL + 'rest/validatorusers/' + servicePointId + '/' + userId);
  }

  findAllBno() {
    return this.http.get<Array<Bno>>(this.environment.pathologyURL + 'rest/bno/findall');
  }

  findAllSnomed() {
    return this.http.get<Array<Snomed>>(this.environment.pathologyURL + 'rest/snomed/findall');
  }

  getNextLogNumber(spId: number) {
    return this.http.get(this.environment.pathologyURL + 'rest/splognumber/getnextlognumber/' + spId, {
       responseType: 'text'
    });
  }

  getAllSzakmaKod() {
    return this.http.get<Array<SzakmaKod>>(this.environment.pathologyURL + 'rest/szakmakodok/all');
  }
}
