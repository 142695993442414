import { ServicePoint } from 'src/app/shared/models/ServicePoint';
import { GetCaseByIdResponse } from './../../../shared/models/response/getCaseById';
import { ApiService } from 'src/app/core/http/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsereport',
  templateUrl: './responsereport.component.html',
  styleUrls: ['./responsereport.component.css']
})
export class ResponsereportComponent implements OnInit {


  servicePoint: ServicePoint;

  constructor(
  ) { }

  ngOnInit(): void {
    this.checkReportType(JSON.parse(localStorage.getItem('servicePoint')));
  }

  checkReportType(servicePoint) {
    this.servicePoint = servicePoint;
  }
}
