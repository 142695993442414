
<i class="fa fa-times close" mat-dialog-close aria-hidden="true"></i>
<h3 mat-dialog-title>{{data}}</h3>
<mat-dialog-content class="mat-typography">
  <div class="row mb-3">
    <div class="col text-center">
      <button class="answerButton btn btn-primary mr-3" (click)="emitAnswer.emit(true)" tabindex="-1" i18n>
        Igen
      </button>
      <button class="answerButton btn btn-primary" (click)="emitAnswer.emit(false)"  tabindex="-1" i18n>
        Nem
      </button>
    </div>
  </div>
</mat-dialog-content>
