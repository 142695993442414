// tslint:disable: deprecation
import { Injectable } from '@angular/core';
import { CheckboxControlValueAccessor, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  xOrCheckboxes(checked: FormControl, unchecked: FormControl) {
    checked.valueChanges.subscribe(data => {
      if (data) {
        unchecked.setValue(false);
      }
    });
    unchecked.valueChanges.subscribe(data => {
      if (data) {
        checked.setValue(false);
      }
    });
  }

  xOr3Checkboxes(checked: FormControl, unchecked: FormControl, otherUnchecked: FormControl) {
    checked.valueChanges.subscribe(data => {
      if (data) {
        unchecked.setValue(false);
        otherUnchecked.setValue(false);
      }
    });
    unchecked.valueChanges.subscribe(data => {
      if (data) {
        checked.setValue(false);
        otherUnchecked.setValue(false);
      }
    });
    otherUnchecked.valueChanges.subscribe(data => {
      if (data) {
        checked.setValue(false);
        unchecked.setValue(false);
      }
    });
  }

  xOrCheckboxArray(checkboxArray: Array<FormControl>) {
    checkboxArray.forEach(checkbox => {
      checkbox.valueChanges.subscribe(data => {
        if (data) {
          checkboxArray.forEach(element => {
            if (element !== checkbox) {
              element.setValue(false);
            }
          });
        }
      });
    });
  }

  checkBase(baseCheck: FormControl, toBeDisabled: Array<FormControl>) {
    toBeDisabled.forEach(checkbox => {
      checkbox.disable();
    });
    baseCheck.valueChanges.subscribe(data => {
      if (!data) {
        toBeDisabled.forEach(checkBox => {
          checkBox.disable();
          checkBox.setValue(false);
        });
      } else {
        toBeDisabled.forEach(checkbox => {
          checkbox.enable();
        });
      }

      if (baseCheck.disabled) {
        toBeDisabled.forEach(checkbox => {
          checkbox.disable();
        });
      }
    });
  }

  xORCheckboxMultiple(baseCheck: FormControl, toBeDisabled: Array<FormControl>) {
    baseCheck.valueChanges.subscribe(data => {
      if (data) {
        toBeDisabled.forEach(checkbox => {
          checkbox.disable();
        });
      } else {
        toBeDisabled.forEach(checkbox => {
          checkbox.enable();
        });
      }
    });
  }

  xORCheckboxMultipleTrue(baseCheck: FormControl, toBeDisabled: Array<FormControl>) {
    baseCheck.valueChanges.subscribe(data => {
      if (!data) {
        toBeDisabled.forEach(checkbox => {
          checkbox.disable();
        });
      } else {
        toBeDisabled.forEach(checkbox => {
          checkbox.enable();
        });
      }
    });
  }

  checkBoxWithText(checkbox: FormControl, checkboxText: FormControl) {
    checkboxText.disable();
    checkbox.valueChanges.subscribe(data => {
      if (!data) {
        checkboxText.disable();
        checkboxText.setValue(undefined);
      } else {
        if (checkbox.disabled) {
          checkboxText.disable();
        } else {
          checkboxText.enable();
        }
      }
    });
  }

  checkBoxesWithText(checkboxes: Array<FormControl>, checkboxText: FormControl) {
    const trueCheckboxes = Array<FormControl>();
    const falseCheckboxes = Array<FormControl>();
    checkboxText.disable();
    checkboxes.forEach(checkbox => {
      checkbox.valueChanges.subscribe(data => {
        if (!data) {
          trueCheckboxes.pop();
          falseCheckboxes.push(checkbox);
          if (trueCheckboxes.length < 1) {
            checkboxText.disable();
            checkboxText.setValue('');
          }
        } else {
          falseCheckboxes.pop();
          trueCheckboxes.push(checkbox);
          if (trueCheckboxes.length > 0) {
            checkboxText.enable();
          }
        }
      });
    });
  }

  maximumCheckBoxes(checkboxes: Array<FormControl>, maximumAllowedCheckboxes: number) {
    let checked = 0;

    checkboxes.forEach(checkbox => {
      checkbox.valueChanges.subscribe(value => {
        if ( value ) {
          checked++;
          if (checked === maximumAllowedCheckboxes) {
            checkboxes.forEach(innerCheckbox => {
              if (!innerCheckbox.value) {
                innerCheckbox.disable({emitEvent: false});
              }
            });
          }
        } else {
          if (checked > 0) {
            checked--;
            checkboxes.forEach(innerCheckbox => {
              if (!innerCheckbox.value) {
                innerCheckbox.enable({emitEvent: false});
              }
            });
          }
        }
      });
    });
  }
}
