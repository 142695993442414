<div class="wrapper mt-5">
    <div class="form-inline">
        <label class="submitReportLabel mr-3 ml-3" i18n>validáló orvos</label>
        <ng-select [clearable]="true" [closeOnSelect]="true" [items]="users" [searchable]="true"  class="ng_select" bindLabel="name" bindValue="id" [formControl]="usersControl" placeholder="Válasszon..."></ng-select>
        <button class="addValidatorUserButton ml-2" (click)="addValidatorUser()" i18n>Hozzáadás</button>
    </div>
    <table class="my-3">
        <thead>
            <tr class="table100-head">
                <th i18n>név</th>
                <th i18n>cím</th>
                <th i18n>telefonszám</th>
                <th i18n>email</th>
                <th i18n>pecsétszám</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let validatorUser of validatorUsers">
                    <td>{{validatorUser.name}}</td>
                    <td>{{validatorUser.address}}</td>
                    <td>{{validatorUser.phoneNumber}}</td>
                    <td>{{validatorUser.email}}</td>
                    <td>{{validatorUser.sealNumber}}</td>
                    <td style="text-align: left; cursor: pointer;"><i aria-hidden="true" class="fa fa-trash"  title="törlés" (click)="deleteValidatorUser(validatorUser)"></i></td>
                </tr>
        </tbody>
    </table>
</div>
