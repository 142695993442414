import { EesztAuthenticateComponent } from './eeszt-authenticate/eeszt-authenticate.component';
import { ApiService } from './../../../core/http/api.service';
import { Subscription, timer } from 'rxjs';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { GeneralService } from './../../../core/services/general.service';
import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { EesztSamlEidRequest } from 'src/app/shared/models/request/eesztSamlEidRequest';
import { EesztSamlEszigRequest } from 'src/app/shared/models/request/eesztSamlEszigRequest';
import { Toast, ToastrService } from 'ngx-toastr';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { MatDialog } from '@angular/material/dialog';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  user: GetUserByUserNameResponse;
  currentServicePoint: string;

  eszigTokenExpiration = '';
  eszigTokenValid = false;
  eszigTokenExpirationSubscription: Subscription;
  countDown: Subscription;
  counter: number;

  bcpMessages: number;
  eszigResponseCode: number;
  eszigMessage: string;

  eszigTokenTime =  Number(localStorage.getItem('eszigTokenExpiration'));
  actualTime = new Date().getTime();

  constructor(
    public generalService: GeneralService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    public router: Router,
    private sharedDataService: ShareddataService,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public dialog: MatDialog,
    private messages: Messages
  ) { }

  ngOnInit(): void {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.getServicePointById();

      this.eszigTokenExpirationSubscription = this.sharedDataService.eszigTokenExpirationShared.subscribe(data => {
        this.eszigTokenExpiration = data;
        if (Number(this.eszigTokenExpiration) > new Date().getTime()) {
          this.sharedDataService.setEszigTokenValid(true);

        } else {
          this.sharedDataService.setEszigTokenValid(false);
        }
        this.eszigTokenValid = JSON.parse(localStorage.getItem('eszigTokenValid'));
        this.startTimer();
      });
      this.eszigTokenValid = JSON.parse(localStorage.getItem('eszigTokenValid'));
      this.startTimer();
      this.getBcpSize();
  }

  ngOnDestroy() {
    this.eszigTokenExpirationSubscription.unsubscribe();
  }

  getServicePointById() {
    this.apiService.getServicePointById(this.user.servicePointId).subscribe(response => {
      this.currentServicePoint = response.name;
    }, (error: Response) => {
      console.error(error);
    });
  }

  startTimer() {
    if (this.eszigTokenValid) {
      this.countDown = timer(0, 1000).subscribe(
        () => {
          this.counter =  Number(localStorage.getItem('eszigTokenExpiration')) - new Date().getTime();
          if (this.counter < 0) {
            this.eszigTokenValid = false;
            localStorage.removeItem('eszigTokenValid');
          }
      });
    }
  }

  logout() {
    this.authenticationService.deleteCookies();
    localStorage.removeItem('eszigTokenValid');
    localStorage.removeItem('eszigTokenExpiration');
    this.router.navigate(['login']);
  }

  goToPageWithCheckRoute() {
    if (this.router.url.includes('reports')) {
      // this.generalService.goToPage('dashboard');
      this.router.navigate(['dashboard']);
    } else if (this.router.url.includes('dashboard')) {
      this.generalService.goToPage('reports');
    } else {
      this.generalService.goToPage('reports');
    }
  }

  goToEeszt() {
    if (!this.eszigTokenValid) {
      this.toasterService.error(
        this.messages.error.ERROR_EESZT_AUTH_EXPIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    } else {
      this.generalService.goToPage('eeszt');
    }
  }


  getBcpSize() {
    this.apiService.getBcpSize(this.user.username).subscribe(response => {
      this.bcpMessages = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  openEesztAuthModal() {
    this.dialog.open(EesztAuthenticateComponent);
  }

}
