<form class="form-inline w-50 mb-2 mt-2">
    <div class="form-group">
        <label class="mr-3 ml-5" i18n>intézmény</label>
        <ng-select [clearable]="true"  (change)="getWrongDocumentsForSp(refServicePointsControl.value)" [closeOnSelect]="true" [items]="refServicePoints" [searchable]="true" bindLabel="name" bindValue="id" [formControl]="refServicePointsControl" placeholder="Válasszon..."></ng-select>
    </div>
</form>
<form class="form-inline w-50 mb-2 mt-2">
    <div class="form-group">
        <label class="mr-3 ml-5" i18n>nézetváltás</label>
        <ng-select [clearable]="true" [closeOnSelect]="true" [items]="barChartTypeOptions" [searchable]="true" bindLabel="name" bindValue="value" [formControl]="barChartTypeControl" placeholder="Válasszon..."></ng-select>
    </div>
</form>

<div style="display: block;" class="mt-2 mx-3 mb-3">
    <canvas baseChart
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartTypeControl.value">
    </canvas>
</div>
