<app-navbar></app-navbar>
<ul class="nav nav-tabs nav-justified" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" (click)="tabChange(1)" data-toggle="tab" href="#users" i18n>felhasználók</a>
      </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="tabChange(2)" href="#servicepoints" i18n>klinikák</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="tabChange(3)" href="#validatorUsers" i18n>validáló orvosok</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="tabChange(4)" href="#financing" i18n>finanszírozás</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="tabChange(5)" href="#prices" i18n>árak</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="tabChange(6)" href="#statistics" i18n>minőség-ellenőrzés</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" (click)="tabChange(7)" href="#documents" i18n>dokumentumok</a>
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div id="users" class="tab-pane active"><br>
        <app-users *ngIf="currentTab === 1"></app-users>
    </div>
    <div id="servicepoints" class="tab-pane fade"><br>
        <app-servicepoints *ngIf="currentTab === 2"></app-servicepoints>
    </div>
    <div id="validatorUsers" class="tab-pane fade"><br>
        <app-validator-users *ngIf="currentTab === 3"></app-validator-users>
    </div>
    <div id="financing" class="tab-pane"><br>
        <app-financing *ngIf="currentTab === 4"></app-financing>
    </div>
    <div id="prices" class="tab-pane"><br>
        <app-prices *ngIf="currentTab === 5"></app-prices>
    </div>
    <div id="statistics" class="tab-pane">
        <app-statistics *ngIf="currentTab === 6"></app-statistics>
    </div>
    <div id="documents" class="tab-pane">
        <app-documents *ngIf="currentTab === 7"></app-documents>
    </div>
  </div>

