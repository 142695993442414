import { ToastrService } from 'ngx-toastr';
import { ServicePoint } from './../../../shared/models/ServicePoint';
import { ApiService } from './../../../core/http/api.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-delete-servicepoint',
  templateUrl: './delete-servicepoint.component.html',
  styleUrls: ['./delete-servicepoint.component.css']
})
export class DeleteServicepointComponent implements OnInit {

  selectedServicePoint: ServicePoint;
  onModalClosed = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ServicePoint,
    private apiService: ApiService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.selectedServicePoint = this.data;
  }

  deleteServicePoint() {
    this.apiService.deleteServicePoint(this.selectedServicePoint.id).subscribe(response => {
      this.toasterService.success(
        this.messages.success.SUCCESS_DELETE_INSTITUTE[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale],
        {positionClass: 'toast-top-center'});
      this.onModalClosed.emit(response);
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(error.error.message, error.error.status, {positionClass: 'toast-top-center'});
      this.onModalClosed.emit(error);
    });
  }

}
