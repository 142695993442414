<i class="fa fa-times close" mat-dialog-close aria-hidden="true"></i>
<h1 mat-dialog-title i18n>Hogyan szeretné magát autentikálni?</h1>
<mat-dialog-content class="mat-typography">
    <div class="auth-form-container mt-2 mb-2" style="clear: both;">
        <div class="d-md-flex align-items-center mb-5">
            <div class="option-wrapper mr-3">
                <div class="wrapper">
                    <img class="img img-fluid" src="../../../../../assets/images/kartyaolaso.png"/>
                    <h3 class="mt-2" i18n>e-Személyi olvasóval történő azonosítás</h3>
                    <button (click)="eesztSamlEidReq()" class="authenticateButton" i18n>Autentikálás</button>
                </div>
            </div>
            <div class="option-wrapper mr-3">
                <div class="wrapper">
                    <img class="img img-fluid" src="../../../../../assets/images/mobiltoken.png"/>
                    <h3 class="mt-2" i18n>mobiltokenes azonosítás</h3>
                    <input [formControl]="motp" class="form-control mt-2" type="text" placeholder="token"/>
                    <button (click)="eesztSamlMobilReq()" class="authenticateButton" i18n>Autentikálás</button>
                </div>
            </div>
            <div class="option-wrapper mr-3">
                <form class="wrapper">
                    <img class="img img-fluid" src="../../../../../assets/images/otp.png"/>
                    <h3 class="mt-2" i18n>otp tokenes azonosítás</h3>
                    <input [formControl]="userName" class="form-control mt-2" type="text" name="otpTokenUserName" i18n-placeholder="@@username" placeholder="Felhasználónév"/>
                    <input [formControl]="password" class="form-control mt-2" type="password" i18n-placeholder="@@password" placeholder="jelszó"/>
                    <input [formControl]="otp" class="form-control mt-2" type="text" placeholder="token"/>
                    <button (click)="eesztSamlOtpReq()"class="authenticateButton" type="submit" i18n>Autentikálás</button>
                </form>
            </div>
        </div>
    </div>
</mat-dialog-content>
