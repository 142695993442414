    <div class="wrapper-left my-5">
		<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        <table mat-table [dataSource]="usersForPaginator" class="w-100">
            <ng-container matColumnDef="id" style="display: none;">
                <td mat-cell *matCellDef="let element">{{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>név</th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="address">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>cím</th>
                <td mat-cell *matCellDef="let element">{{element.address}}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>telefonszám</th>
                <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>email</th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="sealNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>pecsétszám</th>
                <td mat-cell *matCellDef="let element">{{element.sealNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="username">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>felhasználónév</th>
                <td mat-cell *matCellDef="let element">{{element.username}}</td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>szerepkör</th>
                <td mat-cell *matCellDef="let element">{{element.role.name}}</td>
            </ng-container>
            <ng-container matColumnDef="servicePoint">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>klinika</th>
                <td mat-cell *matCellDef="let element">{{element.servicePoint.name}}</td>
            </ng-container>
            <ng-container matColumnDef="icons">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" style="text-align: left; padding: 5px;">
                    <i aria-hidden="true" class="fa fa-wrench"  title="módosítás" (click)="modifyUser(element, registrationForm)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
        </table>
    </div>
<div class="wrapper-right mt-5" [formGroup]="registrationFormGroup" #registrationForm>

    <label class="submitReportLabel mt-3" i18n>név</label>
    <input [formControl]="name" class="submitReportInput form-control required" type="text"/>
    <label class="submitReportLabel mt-3" i18n>cím</label>
    <input [formControl]="address" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>telefonszám</label>
    <input [formControl]="phoneNumber" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>email</label>
    <input [formControl]="email" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>pecsétszám</label>
    <input [formControl]="sealNumber" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>klinika</label>
    <ng-select [clearable]="true" [closeOnSelect]="true" [items]="servicePoints" [searchable]="true"  class="ng_select_required" bindLabel="name" bindValue="id" formControlName="servicePointId" placeholder="Válasszon..."></ng-select>
    <label class="submitReportLabel mt-3" i18n>felhasználónév</label>
    <input [formControl]="username" class="submitReportInput form-control required" type="text"/>
    <label class="submitReportLabel mt-3" i18n>szerepkör</label>
    <ng-select [clearable]="true" [closeOnSelect]="true" [items]="roles" [searchable]="true" class="ng_select_required" bindLabel="name" bindValue="code" formControlName="role" placeholder="Válasszon..."></ng-select>
    <button [ngClass]="isModify ? 'disabledsubmitUserOrSpButton mr-3 mt-5' : 'submitUserOrSpButton mr-3 mt-5'" [disabled]="isModify" (click)="registerDoctor()" i18n>küldés</button>
    <button [ngClass]="!isModify ? 'disabledsubmitUserOrSpButton mr-3 mt-3' : 'submitUserOrSpButton mr-3 mt-3'" [disabled]="!isModify" (click)="updateUser()" i18n>módosítás</button>
    <button [ngClass]="!isModify ? 'disabledsubmitUserOrSpButton mr-3 mt-3' : 'submitUserOrSpButton mr-3 mt-3'" [disabled]="!isModify" (click)="clearForm(registrationFormGroup)" i18n>mégsem</button>
    <button [ngClass]="selectedUser ? 'submitUserOrSpButton my-3': 'disabledsubmitUserOrSpButton my-3'" style="width: 150px;" (click)="resetPassword()" i18n>Jelszó visszaállítása</button>
</div>
