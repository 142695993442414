import { ServicePoint } from './../../../shared/models/ServicePoint';
// tslint:disable: deprecation
import { DeleteCaseComponent } from './delete-case/delete-case.component';
import { MatDialog } from '@angular/material/dialog';
import { GetAllCasesResponse } from './../../../shared/models/response/getAllCasesResponse';
import { ToastrService } from 'ngx-toastr';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { ApiService } from './../../../core/http/api.service';
import { GeneralService } from './../../../core/services/general.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-pathologyreports',
  templateUrl: './pathologyreports.component.html',
  styleUrls: ['./pathologyreports.component.css']
})
export class PathologyreportsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentYear = new Date().toISOString().substr(0, 4);
  birthName = new FormControl('');
  ssn = new FormControl('');
  logNumber = new FormControl('');
  referringDoctor = new FormControl('');
  reportDoctor = new FormControl('');
  creationDateFrom = new FormControl('');
  creationDateTo = new FormControl('');
  isValidToken = false;

  searchForm = new FormGroup({
    birthName: this.birthName,
    ssn: this.ssn,
    logNumber: this.logNumber,
    referringDoctor: this.referringDoctor,
    dateFrom: this.creationDateFrom,
    dateTo: this.creationDateTo
  });

  allCases = new Array<GetAllCasesResponse>();
  filteredCases = new Array<GetAllCasesResponse>();
  casesForPaginator: MatTableDataSource<GetAllCasesResponse>;
  statusOfCase: string;
  typeOfCase: string;

  currentUser: GetUserByUserNameResponse;

  refServicePointId: number;

  displayedColumns: string[] = [
    'number',
    'type',
    'birthName',
    'ssn',
    'birthDate',
    'doctorName',
    'logNumber',
    'status',
    'eesztDone',
    'creationDate',
    'icons'
  ];

  cytologyVersion: string;

  dateType = this.messages.locale === 'en' ? 'en-US' : 'hu-HU';

  constructor(
    public generalService: GeneralService,
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    private toasterService: ToastrService,
    private dialog: MatDialog,
    public messages: Messages
  ) {}

  ngOnInit() {
    localStorage.removeItem('newReport');
    this.sharedDataService.eszigTokenisValid.subscribe(data => {
      this.isValidToken = data;
    });
    this.isValidToken = JSON.parse(localStorage.getItem('eszigTokenValid'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getAllCasesByRefId();
    this.searchForm.valueChanges.subscribe(value => {
      this.findReport(value, this.typeOfCase, this.statusOfCase);
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkReportType(JSON.parse(localStorage.getItem('servicePoint')));
    }, 100);
  }

  getAllCasesByRefId() {
    this.apiService.getServicePointById(this.currentUser.servicePointId).subscribe(response => {
      if (response.refServicePointId) {
       this.getAllCases(response.refServicePointId);
      } else {
        this.getAllCases(this.currentUser.servicePointId);
      }
    });

  }

  getAllCases(refServicePointId: number) {
    const nonDeletedCases = new Array<GetAllCasesResponse>();
    this.apiService.getAllCases(refServicePointId).subscribe(data => {
      data.forEach(responseData => {
        if (!responseData.isEESZTDeleted) {
          nonDeletedCases.push(responseData);
        }
      });
      if (this.currentUser.roleCode === 'REFERRING_DOCTOR') {
        nonDeletedCases.forEach(element => {
          element.patientBirthDate = this.sharedDataService.
          dateToDateString(new Date(this.sharedDataService.DateStringConvert(element.patientBirthDate)));

          element.creationDate = this.sharedDataService.
          dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(element.creationDate)));
          if (element.creatorUserName === this.currentUser.name &&
            element.servicePointId === this.currentUser.servicePointId
            //  && element.status !== 'véglegesítésre vár'
             ) {
              this.allCases.push(element);
              this.filteredCases.push(element);
          }
        });
        this.casesForPaginator = new MatTableDataSource(this.filteredCases);

        this.casesForPaginator.paginator = this.paginator;
        this.setPaginatorTexts(this.paginator);

      } else if (this.currentUser.roleCode === 'REFERRING_ASSISTANT') {
        nonDeletedCases.forEach(element => {

          element.patientBirthDate = this.sharedDataService.
          dateToDateString(new Date(this.sharedDataService.DateStringConvert(element.patientBirthDate)));

          element.creationDate = this.sharedDataService.
          dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(element.creationDate)));
          if (element.servicePointId === this.currentUser.servicePointId
            //  && element.status !== 'véglegesítésre vár'
              ) {
            this.allCases.push(element);
            this.filteredCases.push(element);
          }
        });
        this.casesForPaginator = new MatTableDataSource(this.filteredCases);

        this.casesForPaginator.paginator = this.paginator;
        this.setPaginatorTexts(this.paginator);

      } else {
        nonDeletedCases.forEach(element => {
          element.patientBirthDate = this.sharedDataService.
          dateToDateString(new Date(this.sharedDataService.DateStringConvert(element.patientBirthDate)));

          element.creationDate = this.sharedDataService.
          dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(element.creationDate)));
        });

        this.allCases = nonDeletedCases;
        this.filteredCases = nonDeletedCases;
        this.casesForPaginator = new MatTableDataSource(nonDeletedCases);

        this.casesForPaginator.paginator = this.paginator;
        this.setPaginatorTexts(this.paginator);
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

  setPaginatorTexts(paginator: MatPaginator) {
    paginator._intl.nextPageLabel = this.messages.locale === 'hu' ? 'Következő' : 'Next';
    paginator._intl.previousPageLabel = this.messages.locale === 'hu' ? 'Előző' : 'Previous';
    paginator._intl.itemsPerPageLabel = this.messages.locale === 'hu' ? 'Oldalanként' : 'Size';
    paginator._intl.firstPageLabel = this.messages.locale === 'hu' ? 'Első' : 'First';
    paginator._intl.lastPageLabel = this.messages.locale === 'hu' ? 'Utolsó' : 'Last';
  }

  goToReport($event) {
    // console.log($event);
    if ($event.type === 'acyt' || $event.type === 'szöv') {
      this.sharedDataService.isCytoChange(false);
      this.generalService.goToPage('report/szov/' + $event.patientId + '/' + $event.id);
    } else {
      this.sharedDataService.isCytoChange(true);
      this.apiService.getCaseById($event.id).subscribe(response => {
        response.forEach(element => {
          if (element.type.includes('NEW')) {
            localStorage.setItem('newReport', 'true');
          } else {
            localStorage.setItem('newReport', 'false');
          }
        });
        this.generalService.goToPage('report/' + $event.type + '/' + $event.patientId + '/' + $event.id);
      });
    }
  }


  findReport(formgroupValue, type, status) {

    let filteredCases = this.allCases;

    if (formgroupValue.ssn) {
      filteredCases = filteredCases.filter(value =>
        value.patientSsn.replace(/-/g, '').replace(/ /g, '').toLowerCase().includes(formgroupValue.ssn.replace(/-/g, '').replace(/ /g, '').toLowerCase()));
    }

    if (formgroupValue.logNumber) {
      filteredCases = filteredCases.filter(value =>
        value.logNumber?.toLowerCase().includes(formgroupValue.logNumber.toLowerCase()));
    }

    if (formgroupValue.birthName) {
      filteredCases = filteredCases.filter(value =>
        value.patientBirthName.toLowerCase().includes(formgroupValue.birthName.toLowerCase()));
    }

    if (formgroupValue.referringDoctor) {
      filteredCases = filteredCases.filter(value =>
        value.creatorUserName?.toLowerCase().includes(formgroupValue.referringDoctor.toLowerCase()));
    }

    if (type && type !== 'all') {
       filteredCases = filteredCases.filter(value => value.type === type);
    } else {
      filteredCases = filteredCases;
    }

    if (status && status !== 'all') {
      filteredCases = filteredCases.filter(value => value.status === status);
    } else {
      filteredCases = filteredCases;
    }


    if (formgroupValue.dateFrom) {
      filteredCases = filteredCases.filter(value =>
        new Date(value.creationDate) >= new Date(formgroupValue.dateFrom));
    }

    if (formgroupValue.dateTo) {
      filteredCases = filteredCases.filter(value =>
        new Date(value.creationDate) <= new Date(formgroupValue.dateTo));
    }


    this.filteredCases = filteredCases;

    this.casesForPaginator.data = this.filteredCases;
    this.casesForPaginator.paginator = this.paginator;
  }

  filterByTypeOfCase($event) {
    this.typeOfCase = $event;
    this.findReport(this.searchForm.value, $event, this.statusOfCase);
  }

  filterByStatusOfCase($event) {
    this.statusOfCase = $event;
    this.findReport(this.searchForm.value, this.typeOfCase, $event);
  }

  showDeleteCase($event) {
    if (this.isValidToken) {
      const dialogRef = this.dialog.open(DeleteCaseComponent, {
        data: {$event}
      });
      const sub = dialogRef.componentInstance.caseDeleted.subscribe(() => {
        dialogRef.close();
        this.getAllCasesByRefId();
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_EESZT_AUTH_EXPIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'}
      );
    }
  }

  checkReportType(servicePoint: ServicePoint) {
    this.cytologyVersion = servicePoint.citologyVersion;
  }

}
