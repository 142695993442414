import { EesztSamlOtpRequest } from './../../../../shared/models/request/eesztSamlOtpRequest';
import { EesztSamlMobilRequest } from './../../../../shared/models/request/eesztSamlMobilRequest';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { ApiService } from './../../../../core/http/api.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EesztSamlEszigRequest } from 'src/app/shared/models/request/eesztSamlEszigRequest';
import { EesztSamlEidRequest } from 'src/app/shared/models/request/eesztSamlEidRequest';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-eeszt-authenticate',
  templateUrl: './eeszt-authenticate.component.html',
  styleUrls: ['./eeszt-authenticate.component.css']
})
export class EesztAuthenticateComponent implements OnInit {

  motp = new FormControl('');
  otp = new FormControl('');
  userName = new FormControl('');
  password = new FormControl('');
  user: GetUserByUserNameResponse;

  constructor(
    private toasterService: ToastrService,
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  eesztSamlEidReq() {
    const request: EesztSamlEidRequest = {
      callerTransactionId: this.user.id.toString(),
      hisNonce: '',
      servicePointId: this.user.servicePointId,
      userId: this.user.id
    };
    this.apiService.eesztSamlEidReq(request).subscribe(response => {
    if (response.code === 'OK') {
      this.apiService.eszigAuth(response.data).subscribe(
        eidResponse => {
          this.toasterService.success(
            this.messages.success.SUCCESS_EESZT_AUTH_OK[this.messages.locale],
            this.messages.success.SUCCESS_TITLE[this.messages.locale],
            {positionClass: 'toast-top-center'});
          const eszigReq: EesztSamlEszigRequest = {
            eidToken: eidResponse.id_token,
            userId: this.user.id,
            servicePointId: this.user.servicePointId
          };
          this.apiService.eesztSamlEszigReq(eszigReq).subscribe(EszigResponse => {
            this.sharedDataService.setEszigTokenExpDate(EszigResponse.expDate);
          }, (error: Response) => {
            this.toasterService.error(
              this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
              this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
          });
        }, eszigError => {
          console.error(eszigError);
          this.toasterService.error(
            this.messages.error.ERROR_ESZEMELYI_CLIENT_COMMUNCATION[this.messages.locale],
            this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
            {positionClass: 'toast-top-center'});
        });
      } else {
        this.toasterService.error(response.message, response.code, {positionClass: 'toast-top-center'});
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

  eesztSamlMobilReq() {
    const request: EesztSamlMobilRequest = {
      motp: this.motp.value,
      userId: this.user.eesztUserId,
      servicePointId: this.user.servicePointId
    };

    this.apiService.eesztSamlMobilReq(request).subscribe(response => {
      this.toasterService.success(
        this.messages.success.SUCCESS_EESZT_AUTH_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale],
        {positionClass: 'toast-top-center'});
      this.sharedDataService.setEszigTokenExpDate(response.expDate);
      this.motp.reset();
    }, (error: Response) => {
      this.toasterService.error(
        this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    });
  }

  eesztSamlOtpReq() {
    const request: EesztSamlOtpRequest = {
      otp: this.otp.value,
      password: this.password.value,
      username: this. userName.value,
      servicePointId: this.user.servicePointId
    };

    this.apiService.eesztSamlOtpReq(request).subscribe(response => {
      this.toasterService.success(
        this.messages.success.SUCCESS_EESZT_AUTH_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale],
        {positionClass: 'toast-top-center'});
      this.otp.reset();
      this.password.reset();
      this.userName.reset();
      this.sharedDataService.setEszigTokenExpDate(response.expDate);
    }, (error: Response) => {
      this.toasterService.error(
        this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        {positionClass: 'toast-top-center'});
    });
  }
}
