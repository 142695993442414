import { tap } from 'rxjs/operators';
import { GetUserByServicePointIdAndSealNumberResponse } from './../../../shared/models/response/GetUserByServicePointIdAndSealNumber';
import { SaveHistologyReqV3Request } from './../../../shared/models/request/saveHistologyReqV3Request';
// tslint:disable: deprecation
// tslint:disable: max-line-length
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/User';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from './../../../core/http/api.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ServicePoint } from './../../../shared/models/ServicePoint';
import { GeneralService } from './../../../core/services/general.service';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { GetPatientResponse } from 'src/app/shared/models/response/getPatientResponse';
import { SaveCytologyReqRequest } from './../../../shared/models/request/saveCytologyReqRequest';
import { SaveCytologyReqV2Request } from 'src/app/shared/models/request/saveCytologyReqV2Request';
import { SaveCytologyReqV3Request } from 'src/app/shared/models/request/saveCytologyReqV3Request';
import { SaveHistologyReqRequest } from './../../../shared/models/request/saveHistologyReqRequest';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { Messages } from 'src/app/shared/locale/messages';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'app-submitreport',
  templateUrl: './submitreport.component.html',
  styleUrls: ['./submitreport.component.css'],
})
export class SubmitreportComponent implements OnInit, OnDestroy {

  @Input() isOldReport: boolean;
  @Input() set patientData(patient: GetPatientResponse) {
    this.currentPatient = patient;
  }

  // userRole: string;
  user: GetUserByUserNameResponse;
  usersOfServicePoint: Array<User>;
  selectedReferrerDoctor: User;

  doctorName = new FormControl('');
  addressOfDoctor = new FormControl('');
  phoneOfDoctor = new FormControl('');

  servicePoint = new FormControl('');
  addressOfServicePoint  = new FormControl('');
  phoneOfServicePoint  = new FormControl('');

  submitReportFormGroup = new FormGroup({
    doctorName: this.doctorName,
    addressOfDoctor: this.addressOfDoctor,
    phoneOfDoctor: this.phoneOfDoctor,
    servicePoint: this.servicePoint,
    addressOfServicePoint: this.addressOfServicePoint,
    phoneOfServicePoint: this.phoneOfServicePoint
  });

  isCyto: boolean;
  isCytoSub = new Subscription();

  formGroupValue: any;
  formGroupSub = new Subscription();

  currentPatient: GetPatientResponse;
  currentPatientSub = new Subscription();
  currentUserSub = new Subscription();
  currentServicePoint: ServicePoint;

  statusOfCase: string;

  historyId: string = null;
  sealNumber: string = '';
  creatorUserNameApi: string = '';
  servicePointId: number = null;

  constructor(
    public generalService: GeneralService,
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    public route: ActivatedRoute,
    public router: Router,
    private toasterService: ToastrService,
    private messages: Messages,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.currentServicePoint = JSON.parse(localStorage.getItem('servicePoint'));
    this.getUserByUserName(this.user);
    this.formGroupSub = this.sharedDataService.messageSource.subscribe(currentMessage => {
      this.formGroupValue = currentMessage;
    });

    this.isCytoSub = this.sharedDataService.isCyto.subscribe(data => {
      this.isCyto = data;
    });
    // this.currentPatientSub = this.sharedDataService.currentPatient.subscribe(data => {
    //   this.currentPatient = data;
    // });

    this.checkCaseStatus(+this.route.snapshot.paramMap.get('caseId'));
  }

  ngOnDestroy() {
    this.formGroupSub.unsubscribe();
    this.isCytoSub.unsubscribe();
    this.currentPatientSub.unsubscribe();
  }

  checkCaseStatus(caseId: number) {
    this.apiService.findCaseByCaseId(caseId).subscribe(response => {
      this.statusOfCase = response.status;
    });
  }

  saveCytologyReq(caseId: number) {

    let servicePointId: number;
    let creatorUsername: string;

    if (this.user.roleCode === 'REFERRING_ASSISTANT') {
      if (this.doctorName.value) {
        servicePointId = this.selectedReferrerDoctor.servicePoint.id;
        creatorUsername = this.selectedReferrerDoctor.username;
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_CHOOSE_DOCTOR[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
        );
      }
    } else {
      servicePointId = this.user.servicePointId;
      creatorUsername = this.user.username;
    }

    const request: SaveCytologyReqRequest = {
      citologyXml: this.formGroupValue,
      caseId: caseId === 0 ? null : caseId,
      creatorUsername,
      patientId: this.currentPatient?.id,
      servicePointId,
      isValidated: false
    };

    this.apiService.saveCytologyReqRequest(request).subscribe(response => {
      this.toasterService.success(
        this.messages.success.SUCCESS_SEND_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale],
        { positionClass: 'toast-top-center' }
      );
      this.generalService.goToPage('reports');
      this.sharedDataService.currentPatient.next(null);
    }, (error: Response) => {
      console.error(error);
      this.sharedDataService.currentPatient.next(null);
      if (error.status === 400) {
        if (this.currentPatient?.id === undefined) {
          this.toasterService.error(
            this.messages.error.ERROR_SAVE_PATIENT_DATA_BEFORE_SEND[this.messages.locale],
            this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
          { positionClass: 'toast-top-center' }
          );
        } else {
          this.toasterService.error(this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale], error.status.toString(),
          { positionClass: 'toast-top-center' });
        }
      } else if (error.status === 500) {
        this.toasterService.error(
          this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
          error.status.toString(),
          { positionClass: 'toast-top-center' }
        );
      }
    });
  }


  saveCytologyReqV2(caseId: number) {

    let servicePointId: number;
    let creatorUsername: string;

    if (this.user.roleCode === 'REFERRING_ASSISTANT') {
      if (this.doctorName.value) {
        servicePointId = this.selectedReferrerDoctor.servicePoint.id;
        creatorUsername = this.selectedReferrerDoctor.username;
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_CHOOSE_DOCTOR[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
        );
      }
    } else {
      servicePointId = this.user.servicePointId;
      creatorUsername = this.user.username;
    }

    const request: SaveCytologyReqV2Request = {
      citologyReqXml: this.formGroupValue,
      caseId: caseId === 0 ? null : caseId,
      creatorUsername,
      patientId: this.currentPatient?.id,
      servicePointId,
      isValidated: false,
    };

    request.citologyReqXml.logNumber = this.currentServicePoint.citologyVersion === 'v4' ? this.formGroupValue.logNumber : null;

    if (request.citologyReqXml) {
      this.apiService.saveCytologyReqV2Request(request).subscribe(response => {
        this.toasterService.success(
          this.messages.success.SUCCESS_SEND_OK[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          { positionClass: 'toast-top-center' }
        );
        this.generalService.goToPage('reports');
        this.sharedDataService.currentPatient.next(null);
      }, (error: Response) => {
        console.error(error);
        this.sharedDataService.currentPatient.next(null);
        if (error.status === 400) {
          if (this.currentPatient?.id === undefined) {
            this.toasterService.error(
              this.messages.error.ERROR_SAVE_PATIENT_DATA_BEFORE_SEND[this.messages.locale],
              this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
            { positionClass: 'toast-top-center' }
            );
          } else {
            this.toasterService.error(this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale], error.status.toString(),
            { positionClass: 'toast-top-center' });
          }
        } else if (error.status === 500) {
          this.toasterService.error(
            this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
            error.status.toString(),
            { positionClass: 'toast-top-center' }
          );
        }
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_FILL_FORM[this.messages.locale],
        this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
      { positionClass: 'toast-top-center' });
    }
  }

  saveCytologyReqV3(caseId: number) {
    let servicePointId: number;
    let creatorUsername: string;
    const today = new Date();

    if (this.user.roleCode === 'REFERRING_ASSISTANT') {
      if (this.doctorName.value) {
        servicePointId = this.selectedReferrerDoctor.servicePoint.id;
        creatorUsername = this.selectedReferrerDoctor.username;
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_CHOOSE_DOCTOR[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
        );
      }
    } else {
      servicePointId = this.servicePointId ? this.servicePointId : this.user.servicePointId;
      creatorUsername = this.creatorUserNameApi ? this.creatorUserNameApi : this.user.username;
    }

    const request: SaveCytologyReqV3Request = {
      citologyReqXml: this.formGroupValue,
      caseId: caseId === 0 ? null : caseId,
      creatorUsername,
      patientId: this.currentPatient?.id,
      servicePointId,
      isValidated: false,
      externalSystemId: this.historyId
    };
    if (this.isValidReq(request)) {
      if (
        today < new Date(request.citologyReqXml.utolsoMenstruacioIdeje) ||
        today < new Date(request.citologyReqXml.korabbiNogyogyaszatiMutetIdeje.ev) ||
        today < new Date(request.citologyReqXml.korabbiKemoterapiaIdeje.ev) ||
        today < new Date(request.citologyReqXml.korabbiRadioterapiaIdeje.ev)) {
        this.toasterService.error('Dátum nem lehet jövőbeli!', 'Hibás paraméterezés!', { positionClass: 'toast-top-center' });
        return;
      }
      this.apiService.saveCytologyReqV3Request(request).subscribe(response => {
        this.toasterService.success(
          this.messages.success.SUCCESS_SEND_OK[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          { positionClass: 'toast-top-center' }
        );
        this.generalService.goToPage('reports');
        this.sharedDataService.currentPatient.next(null);
      }, (error: Response) => {
        console.error(error);
        this.sharedDataService.currentPatient.next(null);
        if (error.status === 400) {
          if (this.currentPatient?.id === undefined) {
            this.toasterService.error(
              this.messages.error.ERROR_SAVE_PATIENT_DATA_BEFORE_SEND[this.messages.locale],
              this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
            { positionClass: 'toast-top-center' }
            );
          } else {
            this.toasterService.error(this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale], error.status.toString(),
            { positionClass: 'toast-top-center' });
          }
        } else if (error.status === 500) {
          this.toasterService.error(
            this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
            error.status.toString(),
            { positionClass: 'toast-top-center' }
          );
        }
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_FILL_FORM_ASTERISK[this.messages.locale],
        this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
      { positionClass: 'toast-top-center' });
    }
  }

  isValidReq(request: SaveCytologyReqV3Request): boolean {
    console.log(request);
    if (request.citologyReqXml) {
      if (
        (!request.citologyReqXml.utolsoMenstruacioIgen && !request.citologyReqXml.utolsoMenstruacioNem && request.citologyReqXml.menopausaNem) ||
        !request.citologyReqXml.utolsoMenstruacioIdeje ||
        (!request.citologyReqXml.fogamzasgatlas.igen && !request.citologyReqXml.fogamzasgatlas.nem) ||
        (!request.citologyReqXml.hormonkezeles.igen && !request.citologyReqXml.hormonkezeles.nem) ||
        (!request.citologyReqXml.iud.igen && !request.citologyReqXml.iud.nem) ||
        (!request.citologyReqXml.menopausaNem && !request.citologyReqXml.menopausaIgen) ||
        (!request.citologyReqXml.elozoCytologiaiVizsg.igen && !request.citologyReqXml.elozoCytologiaiVizsg.nem) ||
        (!request.citologyReqXml.hpv.negativ && !request.citologyReqXml.hpv.pozitiv) ||
        !request.citologyReqXml.nogyogyaszatiVizsgEredmenye.colposcoposDg ||
        !request.citologyReqXml.nogyogyaszatiVizsgEredmenye.uhDg ||
        (!request.citologyReqXml.nogyogyaszatiVizsgEredmenye.negativ && !request.citologyReqXml.nogyogyaszatiVizsgEredmenye.neopol) ||
        (!request.citologyReqXml.laktalIgen && !request.citologyReqXml.laktalNem)
        || (request.citologyReqXml.partiusNumber === null)
        || (request.citologyReqXml.graviditasNumber === null)
        ) {
          return false;
        } else {
          return true;
        }
    } else {
      return false;
    }
  }

  checkCitologySave() {
    // if (this.isOldReport) {
    //   this.saveCytologyReq();
    // } else {
    //   this.saveCytologyReqV2();
    // }
    if (this.currentServicePoint.citologyVersion === 'v3') {
      this.saveCytologyReqV3(+this.route.snapshot.paramMap.get('caseId'));
    } else {
      this.saveCytologyReqV2(+this.route.snapshot.paramMap.get('caseId'));
    }
  }

  checkHistologySave() {
    if (this.currentServicePoint.citologyVersion === 'v3') {
      this.saveHistologyReqV3();
    } else {
      this.saveHistologyReq();
    }
  }

  getUsersByServicePoint() {
    const referringDoctorsOfSp = new Array<User>();

    this.apiService.getUsersByServicePointId(this.user.servicePointId).subscribe(response => {
      this.submitReportFormGroup.reset();
      response.forEach(element => {
        if (element.role.code === 'REFERRING_DOCTOR') {
          referringDoctorsOfSp.push(element);
        }
      });
      this.usersOfServicePoint = referringDoctorsOfSp;

      this.route.queryParams.subscribe(params => {
        if (params.token) {
          this.historyId =  this.authService.getAllDataByToken(params.token)['historyId'];
          this.sealNumber = this.authService.getAllDataByToken(params.token)['doctor'];
          this.selectedReferrerDoctor = this.usersOfServicePoint.find(x => x.sealNumber === this.sealNumber);
          this.setUserInformationsFromServicePointIdAndSealNumber(this.user.servicePointId, this.sealNumber);
        }
      });
    }, (error: Response) => {
      console.error(error);
    });
  }

  setSubmitReportdata($event: User) {
    this.selectedReferrerDoctor = $event;
    this.doctorName.setValue($event.id);
    this.addressOfDoctor.setValue($event.address);
    this.phoneOfDoctor.setValue($event.phoneNumber);
    this.apiService.getServicePointById($event.servicePoint.id).subscribe(servicepointResponse => {
      this.servicePoint.setValue(servicepointResponse.name);
      this.phoneOfServicePoint.setValue(servicepointResponse.phoneNumber);
      this.addressOfServicePoint.setValue(servicepointResponse.address);
    }, (error: Response) => {
      console.error(error);
    });
  }

  saveHistologyReq() {

    const caseId = +this.route.snapshot.paramMap.get('caseId');

    let servicePointId: number;
    let creatorUsername: string;

    if (this.user.roleCode === 'REFERRING_ASSISTANT') {
      if (this.doctorName.value) {
        servicePointId = this.selectedReferrerDoctor.servicePoint.id;
        creatorUsername = this.selectedReferrerDoctor.username;
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_CHOOSE_DOCTOR[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
        );
      }
    } else {
      servicePointId = this.user.servicePointId;
      creatorUsername = this.user.username;
    }

    let request: SaveHistologyReqRequest = {
      caseId: caseId === 0 ? null : caseId,
      szovettanXml: this.formGroupValue,
      creatorUsername,
      patientId: this.currentPatient?.id,
      servicePointId,
      isValidated: false
    };

    request.szovettanXml.kenetszam = this.currentServicePoint.citologyVersion === 'v4' ? this.formGroupValue.kenetszam : null;

    request = this.alterHistologyRequest(request);

    if (request.szovettanXml && (
      request.szovettanXml.klinikaiAdatok &&
      request.szovettanXml.lokalizacio1 ||
      request.szovettanXml.lokalizacio2 ||
      request.szovettanXml.lokalizacio3 ||
      request.szovettanXml.lokalizacio4 ||
      request.szovettanXml.lokalizacio5 ||
      request.szovettanXml.lokalizacio6 ||
      request.szovettanXml.lokalizacio7 ||
      request.szovettanXml.lokalizacio8 ||
      request.szovettanXml.lokalizacio9 ||
      request.szovettanXml.lokalizacio10
      )) {

      this.apiService.saveHistologyReqRequest(request).subscribe(response => {
        this.toasterService.success(
          this.messages.success.SUCCESS_SEND_OK[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          { positionClass: 'toast-top-center' }
        );
        this.generalService.goToPage('reports');
        this.sharedDataService.currentPatient.next(null);
      }, (error: Response) => {
        this.sharedDataService.currentPatient.next(null);
        if (error.status === 400) {
          if (this.currentPatient?.id === undefined) {
            this.toasterService.error(
              this.messages.error.ERROR_SAVE_PATIENT_DATA_BEFORE_SEND[this.messages.locale],
              this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
            { positionClass: 'toast-top-center' }
            );
          } else {
            this.toasterService.error(this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale], error.status.toString(),
            { positionClass: 'toast-top-center' });
          }
        } else if (error.status === 500) {
          this.toasterService.error(
            this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
            error.status.toString(),
            { positionClass: 'toast-top-center' }
          );
        }
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_FILL_FORM[this.messages.locale],
        this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
      { positionClass: 'toast-top-center' });
    }
  }

  saveHistologyReqV3() {

    const caseId = +this.route.snapshot.paramMap.get('caseId');

    let servicePointId: number;
    let creatorUsername: string;

    if (this.user.roleCode === 'REFERRING_ASSISTANT') {
      if (this.doctorName.value) {
        servicePointId = this.selectedReferrerDoctor.servicePoint.id;
        creatorUsername = this.selectedReferrerDoctor.username;
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_CHOOSE_DOCTOR[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
        );
      }
    } else {
      servicePointId = this.servicePointId ? this.servicePointId : this.user.servicePointId;
      creatorUsername = this.creatorUserNameApi ? this.creatorUserNameApi : this.user.username;
    }

    const request: SaveHistologyReqV3Request = {
      caseId: caseId === 0 ? null : caseId,
      szovettanXml: this.formGroupValue,
      creatorUsername,
      patientId: this.currentPatient?.id,
      servicePointId,
      isValidated: false,
      externalSystemId: this.historyId
    };


    if (request.szovettanXml && (
      request.szovettanXml.klinikaiAdatok &&
      request.szovettanXml.lokalizacio1 ||
      request.szovettanXml.lokalizacio2 ||
      request.szovettanXml.lokalizacio3 ||
      request.szovettanXml.lokalizacio4 ||
      request.szovettanXml.lokalizacio5 ||
      request.szovettanXml.lokalizacio6 ||
      request.szovettanXml.lokalizacio7 ||
      request.szovettanXml.lokalizacio8 ||
      request.szovettanXml.lokalizacio9 ||
      request.szovettanXml.lokalizacio10
      )) {

      this.apiService.saveHistologyReqV3Request(request).subscribe(response => {
        this.toasterService.success(
          this.messages.success.SUCCESS_SEND_OK[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          { positionClass: 'toast-top-center' }
        );
        this.generalService.goToPage('reports');
        this.sharedDataService.currentPatient.next(null);
      }, (error: Response) => {
        this.sharedDataService.currentPatient.next(null);
        if (error.status === 400) {
          if (this.currentPatient?.id === undefined) {
            this.toasterService.error(
              this.messages.error.ERROR_SAVE_PATIENT_DATA_BEFORE_SEND[this.messages.locale],
              this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
            { positionClass: 'toast-top-center' }
            );
          } else {
            this.toasterService.error(this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale], error.status.toString(),
          { positionClass: 'toast-top-center' });
          }
        } else if (error.status === 500) {
          this.toasterService.error(
            this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
            error.status.toString(),
            { positionClass: 'toast-top-center' }
          );
        }
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_FILL_FORM[this.messages.locale],
        this.messages.error.ERROR_TITLE_WRONG_PARAMETER[this.messages.locale],
      { positionClass: 'toast-top-center' });
    }
  }

  getUserByUserName(user: GetUserByUserNameResponse) {
    const userName = user.username;
    if (this.route.snapshot.paramMap.get('caseId')) {
      this.submitReportFormGroup.disable();

      this.apiService.getCaseById(+this.route.snapshot.paramMap.get('caseId')).subscribe((response: Array<GetCaseByIdResponse>) => {
        response.forEach(element => {
          if (element.type.includes('REQ')) {
            this.apiService.getUserById(element.creatorUserId).subscribe(getUserByIdResponse => {
              this.doctorName.setValue(getUserByIdResponse.name);
              this.addressOfDoctor.setValue(getUserByIdResponse.address);
              this.phoneOfDoctor.setValue(getUserByIdResponse.phoneNumber);
              this.apiService.getServicePointById(getUserByIdResponse.servicePointId).subscribe(servicepointResponse => {
                this.servicePoint.setValue(servicepointResponse.name);
                this.phoneOfServicePoint.setValue(servicepointResponse.phoneNumber);
                this.addressOfServicePoint.setValue(servicepointResponse.address);
              }, (error: Response) => {
                console.error(error);
              });
            }, (error: Response) => {
              console.error(error);
            });
          }
        });
      }, (error: Response) => {
        console.error(error);
      });
    } else {
      this.apiService.getUserByUsername(userName).subscribe(response => {
        this.user = response;
        if (response.roleCode === 'REFERRING_ASSISTANT') {
          this.getUsersByServicePoint();
        } else {
          this.doctorName.setValue(response.name);
          this.addressOfDoctor.setValue(response.address);
          this.phoneOfDoctor.setValue(response.phoneNumber);
          this.apiService.getServicePointById(response.servicePointId).subscribe(servicepointResponse => {
            this.servicePoint.setValue(servicepointResponse.name);
            this.phoneOfServicePoint.setValue(servicepointResponse.phoneNumber);
            this.addressOfServicePoint.setValue(servicepointResponse.address);
          }, (error: Response) => {
            console.error(error);
          });
        }
      }, (error: Response) => {
        console.error(error);
      });
    }
  }

  alterHistologyRequest(request: SaveHistologyReqRequest) {
    const localizationStringArray = new Array<string>();

    for (let i = 1; i <= 10; i++) {
      if (request.szovettanXml['lokalizacio' + i] !== '') {
        localizationStringArray.push(request.szovettanXml['lokalizacio' + i]);
      }
    }

    for (let i = 1; i <= 10; i++) {
      request.szovettanXml['lokalizacio' + i] = localizationStringArray[i - 1];
      if (i > localizationStringArray.length) {
        request.szovettanXml['lokalizacio' + i] = '';
      }
    }

    return request;
  }


  setServicePointInformations(servicePointId: number) {
    this.apiService.getServicePointById(servicePointId).pipe(
      tap(servicePoint => {
        this.phoneOfServicePoint.setValue(servicePoint.phoneNumber);
        this.addressOfServicePoint.setValue(servicePoint.address);
        this.servicePoint.setValue(servicePoint.name);
      })
    ).subscribe();
  }

  setUserInformationsFromServicePointIdAndSealNumber(servicePointId: number, sealNumber: string) {
    this.apiService.getUserByServicePointIdAndSealNumber(servicePointId, sealNumber).subscribe(user => {
      this.creatorUserNameApi = user.username;
      this.servicePointId = user.servicePointId;
      if (this.user.roleCode === 'REFERRING_ASSISTANT') {
        this.doctorName.setValue(user.id);
      }
      if (this.user.roleCode === 'REFERRING_DOCTOR') {
        this.doctorName.setValue(user.name);
      }
      this.addressOfDoctor.setValue(user.address);
      this.phoneOfDoctor.setValue(user.phoneNumber);

      this.setServicePointInformations(user.servicePointId);
    });
  }
}
