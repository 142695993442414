import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ApiService } from './../../../core/http/api.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { GetPatientResponse } from 'src/app/shared/models/response/getPatientResponse';
import { EbeuSearchRequest } from 'src/app/shared/models/request/ebeuSearchRequest';
import { Ebeu } from 'src/app/shared/models/ebeu';
import { FormControl } from '@angular/forms';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-select-ebeu',
  templateUrl: './select-ebeu.component.html',
  styleUrls: ['./select-ebeu.component.css']
})
export class SelectEbeuComponent implements OnInit {

  user: GetUserByUserNameResponse;
  currentPatient: GetPatientResponse;
  ebeuResponse: Ebeu[];

  selectedEbeu = new EventEmitter();

  beutaloIdoszakKezdet = new FormControl('');
  beutaloIdoszakVege = new FormControl('');
  breakGlass = new FormControl(false);
  breakOkGlass = new FormControl('');
  ellatasIdoszakKezdete = new FormControl('');
  ellatasIdoszakVege = new FormControl('');

  dateType = this.messages.locale === 'en' ? 'en-US' : 'hu-HU';

  constructor(
    private toasterService: ToastrService,
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.sharedDataService.currentPatient.subscribe(data => {
      this.currentPatient = data;
    });
  }

  searchEbeutalo() {

    const request: EbeuSearchRequest = {
      beutaloIdoszakKezdet: this.beutaloIdoszakKezdet.value,
      beutaloIdoszakVege: this.beutaloIdoszakVege.value,
      breakGlass: this.breakGlass.value,
      breakOkGlass: this.breakOkGlass.value,
      callerTransactionId: 'callerTransactionId',
      ellatasIdoszakKezdete: this.ellatasIdoszakKezdete.value,
      ellatasIdoszakVege: this.ellatasIdoszakVege.value,
      patientId: this.currentPatient?.id,
      servicePointId: this.user.servicePointId,
      userId: this.user.id
    };

    this.apiService.searchEbeutalo(request).subscribe(searchEbeuResponse => {
      const ebeuList = [];
      if (searchEbeuResponse.message === 'OK') {
        if (searchEbeuResponse && searchEbeuResponse.data?.length === 0) {
          if (searchEbeuResponse.errorMessages) {
            this.toasterService.error(
              searchEbeuResponse.errorMessages.messages[0].message,
              searchEbeuResponse.errorMessages.messages[0].code, { positionClass: 'toast-top-center' });
          } else {
            this.toasterService.info(
              this.messages.info.INFO_EBEU_NOT_FOUND[this.messages.locale], '', { positionClass: 'toast-top-center' });
          }
        } else {
          searchEbeuResponse.data.forEach(element => {
            if (element.businessContent.beutaloTipus === '1') {
              ebeuList.push(element);
            }
          });
          if (ebeuList.length === 0) {
            this.toasterService.info(
              this.messages.info.INFO_EBEU_NOT_FOUND[this.messages.locale], '', { positionClass: 'toast-top-center' });
          } else {
            this.ebeuResponse = ebeuList;
          }
        }
      } else if (+searchEbeuResponse.code < 0 ) {
        this.toasterService.error(searchEbeuResponse.message, searchEbeuResponse.code, {positionClass: 'toast-top-center'});
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

  onSelectedEbeuAzon($event) {
    this.selectedEbeu.emit($event);
  }

}
