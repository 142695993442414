import { ServicePoint } from 'src/app/shared/models/ServicePoint';
// tslint:disable: deprecation
import { GetCaseByIdResponse } from './../../../shared/models/response/getCaseById';
import { ApiService } from 'src/app/core/http/api.service';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GeneralService } from './../../../core/services/general.service';
import { Component, OnInit, OnDestroy, Injectable, Input, OnChanges } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  userRole: string;
  isCyto = false;
  isReport = false;
  isCytoInput = new FormControl(this.isCyto);

  patientId: number;

  isCytoSub = new Subscription();
  isReportSub = new Subscription();

  isOldReport = false;
  cytologyVersion: string;

  patientData;

  constructor(
    public generalService: GeneralService,
    public sharedDataService: ShareddataService,
    public router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.changeApplication(this.isCyto);
    if (this.router.url.includes('szov')) {
      this.isCyto = false;

    } else if (this.router.url.includes('cyt')) {
      this.isCyto = true;
      this.checkReportType(JSON.parse(localStorage.getItem('servicePoint')));
    }
    this.userRole = this.authenticationService.getUserRoleByToken(this.authenticationService.getToken());
  }

  ngOnDestroy() {
  }

  changeApplication(isCyto: boolean) {
    this.isCyto = isCyto;
    this.sharedDataService.isCytoChange(isCyto);
    this.checkReportType(JSON.parse(localStorage.getItem('servicePoint')));
  }

  // checkReportType(newReport: boolean) {
  //   if (newReport) {
  //     this.isOldReport = false;
  //   } else {
  //     this.isOldReport = true;
  //   }
  // }

  checkReportType(servicePoint: ServicePoint) {
    this.cytologyVersion = servicePoint.citologyVersion;
  }

  emitPatientData(event) {
    this.patientData = event;
  }

}
