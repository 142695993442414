<div class="row">
  <div class="wrapper col" [formGroup]="" id="#cytologyForm">
    <div class="row mt-2">
      <div class="col">
        <label class="required_input" i18n>A "*"-al jelölt mezők kitöltése kötelező!</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="form-group form-check">
          <input [formControl]="gratis" type="checkbox" class="form-check-input" id="gratis">
          <label class="form-check-label" for="gratis" i18n>Gratis</label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="form-inline">
          <label class="cytologyLabel mr-2" for="konvCytologia"i18n><span class="required_input">*</span>CYTOLÓGIAI SORSZÁM: </label>
          <input [formControl]="kenetszam" class="cytologyInput form-control mr-3" disabled/>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="form-inline">
          <label class="cytologyLabel mr-2" for="konvCytologia" i18n>Páciens e-mail: </label>
          <input [formControl]="paciensEmail" class="dgInput form-control mr-3" type="text" style="min-width: 300px"/>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="form-inline">
              <label class="cytologyLabel mr-2" for="konvCytologia" i18n>Beteg kora: </label>
              <input [formControl]="betegKora" class="cytologyInput form-control mr-3" type="number" min="0" style="max-width: 70px;" disabled/>
            </div>
          </div>
        </div>
        <div class="row ml-5 my-2">
          <div class="col">
            <div class="form-inline">
              <!-- <div class="form-group form-check mr-2">
                <input [formControl]="graviditas" type="checkbox" class="form-check-input" id="graviditas">
                <label class="form-check-label" for="graviditas">Graviditas</label>
              </div> -->
              <label class="form-check-label" for="graviditas" i18n><span class="required_input">*</span>Graviditas</label>
              <input [formControl]="graviditasNumber" class="cytologyInput form-control mx-2" type="number" style="max-width: 70px" (change)="setNumberToPositive(graviditasNumber)"/>
              <!-- <div class="form-group form-check mx-2">
                <input [formControl]="partius" type="checkbox" class="form-check-input" id="partus">
                <label class="form-check-label" for="partus">Partus</label>
              </div> -->
              <label class="form-check-label" for="partus" i18n><span class="required_input">*</span>Partus</label>
              <input [formControl]="partiusNumber" class="cytologyInput form-control mx-2" type="number" style="max-width: 70px" (change)="setNumberToPositive(partiusNumber)"/>
              <label i18n><span class="required_input">*</span>Laktál</label>
              <div class="form-group form-check mx-2">
                <input [formControl]="laktalIgen" type="checkbox" class="form-check-input" id="laktalIgen">
                <label class="form-check-label ml-2" for="laktalIgen" i18n>Igen</label>
              </div>
              <div class="form-group form-check">
                <input [formControl]="laktalNem" type="checkbox" class="form-check-input" id="laktalNem">
                <label class="form-check-label ml-2" for="laktalNem" i18n>Nem</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>Szabályos menstruáció:</label>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check mr-2">
          <input [formControl]="utolsoMenstruacioIgen" type="checkbox" class="form-check-input" id="szabalyosMenstruacioIgen">
          <label class="form-check-label mr-2" for="szabalyosMenstruacioIgen" i18n>Igen</label>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check mr-2">
          <input [formControl]="utolsoMenstruacioNem" type="checkbox" class="form-check-input" id="szabalyosMenstruacioNem">
          <label class="form-check-label mr-2" for="szabalyosMenstruacioNem" i18n>Nem</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>Utolsó menstruáció ideje</label>
      </div>
      <div class="col text-right">
          <input [formControl]="utolsoMenstruacioIdeje" (change)="checkValidDate(utolsoMenstruacioIdeje.value)" type="date" [lang]="dateType" class="dateInput cytologyInput form-control ml-auto" max="2300-12-31" min="1900-01-01"/>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <label class="cytologyLabel" i18n>Jelenleg terhes</label>
      </div>
      <div class="col text-right">
        <div class="form-inline text-right">
          <div class="form-group form-check ml-auto">
            <input [formControl]="jelenlegTerhesIgen" type="checkbox" class="form-check-input" id="jelenlegTerhesIgen">
            <label class="form-check-label mr-2" for="jelenlegTerhesIgen" i18n>Igen</label>
          </div>
          <div class="form-group form-check ml-auto">
            <input [formControl]="jelenlegTerhesHet" class="cytologyInput form-control mr-3" type="number" min="0" style="width: 70px;"/>
            <label class="form-check-label mr-2" for="szabalyosMenstruacioNem" i18n>hét</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel" i18n>Szülés ideje</label>
      </div>
      <div class="col">
        <input [formControl]="szuletesiIdo" class="dateInput cytologyInput form-control ml-auto" type="date"  [lang]="dateType" max="2300-12-31" min="1900-01-01"/>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>Fogamzásgátlás:</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="fogamzasgatlasNem" type="checkbox" class="form-check-input" id="fogamzasgatlasNem">
          <label class="form-check-label" for="fogamzasgatlasNem" i18n>Nem</label>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="fogamzasgatlasIgen" type="checkbox" class="form-check-input" id="fogamzasgatlasIgen">
          <label class="form-check-label" for="fogamzasgatlasIgen" i18n>Igen</label>
        </div>
      </div>
      <div class="col">
        <div class="form-inline">
          <label class="mr-2" for="fogamzasgatlasIgen" i18n>éspedig: </label>
          <input [formControl]="fogamzasgatlasMegjegyzes" class="cytologyInput form-control"/>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>Hormonkezelés:</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="hormonkezelesNem" type="checkbox" class="form-check-input" id="hormonkezelesNem">
          <label class="form-check-label" for="hormonkezelesNem" i18n>Nem</label>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="hormonkezelesIgen" type="checkbox" class="form-check-input" id="hormonkezelesIgen">
          <label class="form-check-label" for="hormonkezelesIgen" i18n>Igen</label>
        </div>
      </div>
      <div class="col">
        <div class="form-inline">
          <label class="mr-2" for="fogamzasgatlasIgen" i18n>éspedig: </label>
          <input [formControl]="hormonkezelesMegjegyzes" class="cytologyInput form-control"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>IUD:</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-2">
        <div class="form-group form-check mr-2">
          <input [formControl]="iudNem" type="checkbox" class="form-check-input" id="iudNem">
          <label class="form-check-label" for="iudNem" i18n>Nem</label>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check mr-2">
          <input [formControl]="iudIgen" type="checkbox" class="form-check-input" id="iudIgen">
          <label class="form-check-label" for="iudIgen" i18n>Igen</label>
        </div>
      </div>
      <div class="col">
        <div class="form-inline ml-auto">
            <label class="form-check-label mr-2" for="fogamzasgatlasIgen" i18n>éspedig: </label>
            <input [formControl]="iudMegjegyzes" class="cytologyInput form-control"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>Menopausa</label>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="menopausaNem" type="checkbox" class="form-check-input" id="menopausaNem">
          <label class="form-check-label" for="menopausaNem" i18n>Nem</label>
        </div>
      </div>
      <div class="col-sm-2">
      <div class="form-group form-check">
        <input [formControl]="menopausaIgen" type="checkbox" class="form-check-input" id="menopausaIgen">
        <label class="form-check-label" for="menopausaIgen" i18n>Igen</label>
      </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="row">
      <div class="col">
        <label class="cytologyLabel" i18n><span class="required_input">*</span>Előző cytologiai vizsg.:</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="elozoCitologiaVizsgNem" type="checkbox" class="form-check-input" id="elozoCitologiaVizsgNem">
          <label class="form-check-label" for="elozoCitologiaVizsgNem" i18n>Nem</label>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="elozoCitologiaVizsgIgen" type="checkbox" class="form-check-input" id="elozoCitologiaVizsgIgen">
          <label class="form-check-label" for="elozoCitologiaVizsgIgen" i18n>Igen</label>
        </div>
      </div>
      <div class="col">
        <div class="form-inline">
          <label class="mr-1">P</label>
          <ng-select [formControl]="elozoCitologiaVizsgPappanicolau" [items]="[0,1,2,3,4,5]" style="width: 95px;"></ng-select>
        </div>
        <!-- <input [formControl]="elozoCitologiaVizsgPappanicolau" class="cytologyInput form-control"/> -->

      </div>
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="elozoCitologiaVizsgLSil" type="checkbox" class="form-check-input" id="elozoCitologiaVizsgLSil">
          <label class="form-check-label" for="elozoCitologiaVizsgLSil">L,SIL</label>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group form-check">
          <input [formControl]="elozoCitologiaVizsgHSil" type="checkbox" class="form-check-input" id="elozoCitologiaVizsgHSil">
          <label class="form-check-label" for="elozoCitologiaVizsgHSil">H,SIL</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel"><span><span class="required_input">*</span></span>HPV</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-4">
        <div class="form-group form-check">
          <input [formControl]="hpvPozitiv" type="checkbox" class="form-check-input" id="hpvPozitiv">
          <label class="form-check-label" for="hpvPozitiv" i18n>Igen</label>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-inline">
          <label class="form-check-label mr-1" for="fogamzasgatlasIgen" i18n>Típus</label>
          <input [formControl]="hpvTipus" class="cytologyInput form-control mr-1"/>
        </div>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-4">
        <div class="form-group form-check">
          <input [formControl]="hpvNegativ" type="checkbox" class="form-check-input" id="hpvNegativ">
          <label class="form-check-label" for="hpvNegativ" i18n>Nem</label>
        </div>
      </div>
    </div>
    <label class="cytologyLabel mt-2" i18n><span class="required_input">*</span>A nőgyógyászati vizsg. ered.:</label>
    <div class="row ml-3">
      <div class="col-sm-4">
        <div class="form-group form-check">
          <input [formControl]="nogyogyaszatiVizsgEredNegativ" type="checkbox" class="form-check-input" id="nogyogyaszatiVizsgEredNegativ">
          <label class="form-check-label" for="nogyogyaszatiVizsgEredNegativ" i18n>Negatív</label>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group form-check">
          <input [formControl]="nogyogyaszatiVizsgEredNeopl" type="checkbox" class="form-check-input" id="nogyogyaszatiVizsgEredNeopl">
          <label class="form-check-label" for="nogyogyaszatiVizsgEredNeopl">Neopl.</label>
        </div>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col">
        <div class="form-inline">
          <label class="mr-2"><span class="required_input">*</span>Colposcopos Dg.:</label>
          <input [formControl]="nogyogyaszatiVizsgEredColpos" class="dgInput form-control"/>
        </div>
      </div>
    </div>
    <div class="row ml-3 mt-2">
      <div class="col">
        <div class="form-inline">
          <label class="mr-2"><span class="required_input">*</span>UH Dg.:</label>
          <input [formControl]="nogyogyaszatiVizsgEredUH" class="dgInput form-control"/>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <div class="form-inline">
          <label class="mr-1" i18n>Előzmény:</label>
          <textarea [formControl]="nogyogyaszatiVizsgEredElozmeny" class="elozmenyInput form-control" style="min-width: 300px !important" rows="2"></textarea>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <label class="cytologyLabel mt-2" i18n>Korábbi nőgyógyászati műtét ideje</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col">
        <div class="form-inline mt-2">
          <!-- <input [formControl]="korabbiNogyogyMutetIdeje" type="number" min="0" max="99" size="1" maxlength="2" style="max-width: 70px;" class="cytologyInput form-control mr-1"/> -->
          <input [formControl]="korabbiNogyogyMutetIdeje" (change)="checkValidDate(korabbiNogyogyMutetIdeje.value)" type="date" [lang]="dateType" min="1900" max="2100-12-31" class="dateInput cytologyInput form-control mr-1" style="max-width: 160px;">
          <label class="cytologyLabel mr-1">Dg.:</label>
          <input [formControl]="korabbiNogyogyMutetDg" class="dgInput form-control mt-1"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel mt-2" i18n>Korábbi kemoterápia ideje</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col">
        <div class="form-inline">
          <!-- <input [formControl]="korabbiKemoIdeje" type="number" min="0" max="99" size="1" maxlength="2" style="max-width: 70px;" class="cytologyInput form-control mr-1"/> -->
          <input [formControl]="korabbiKemoIdeje" (change)="checkValidDate(korabbiKemoIdeje.value)"  type="date" [lang]="dateType" min="1900" max="2100-12-31" class="dateInput cytologyInput form-control mr-1" style="max-width: 160px;">
          <label class="cytologyLabel mr-1">Dg.:</label>
          <input [formControl]="korabbiKemoDg" class="dgInput form-control mt-1"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="cytologyLabel mt-2" i18n>Korábbi radioterápia ideje</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col-sm-12">
        <div class="form-inline">
          <!-- <input [formControl]="korabbiRadioTerapiaIdeje" type="number" min="0" max="99" size="1" maxlength="2" style="max-width: 70px;" class="cytologyInput form-control mr-1"/> -->
          <input [formControl]="korabbiRadioTerapiaIdeje" (change)="checkValidDate(korabbiRadioTerapiaIdeje.value)" type="date" [lang]="dateType" min="1900" max="2100-12-31" class="dateInput cytologyInput form-control mr-1" style="max-width: 160px;">
          <label class="cytologyLabel mr-1">Dg.:</label>
          <input [formControl]="korabbiRadioTerapiaDg" class="dgInput form-control mt-1"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-inline my-3">
          <label class="cytologyLabel mr-1" i18n>Egyéb: </label>
          <input [formControl]="egyeb" class="dgInput form-control"/>
        </div>
      </div>
    </div>
  </div>
</div>

