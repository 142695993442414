<!-- <div class="wrapper-left mt-5">
    <table>
        <thead>
            <tr class="table100-head">
                <th class="column1 l">név</th>
                <th class="column2 l">cím</th>
                <th class="column3 l">telefonszám</th>
                <th class="column4 l">email</th>
                <th class="column5"></th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let servicepoint of servicePoints | keyvalue">
                    <td class="column1 l">{{servicepoint.value.name}}</td>
                    <td class="column2 l">{{servicepoint.value.address}}</td>
                    <td class="column3 l">{{servicepoint.value.phoneNumber}}</td>
                    <td class="column4 l">{{servicepoint.value.email}}</td>
                    <td class="column5"><i aria-hidden="true" class="fa fa-wrench"  title="módosítás" (click)="modifyServicePoint(servicepoint.value)"></i></td>
                </tr>
        </tbody>
    </table>
</div> -->

<div class="wrapper-left my-5">
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    <table mat-table [dataSource]="servicePointsForPaginator" class="w-100">
        <ng-container matColumnDef="id" style="display: none;">
            <td mat-cell *matCellDef="let element">{{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>név</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="address">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>cím</th>
            <td mat-cell *matCellDef="let element">{{element.address}}</td>
        </ng-container>
        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>telefonszám</th>
            <td mat-cell *matCellDef="let element">{{element.phoneNumber}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>email</th>
            <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>
        <ng-container matColumnDef="icons">
            <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align: left; padding: 5px;">
                <i aria-hidden="true" class="fa fa-wrench"  title="módosítás" (click)="modifyServicePoint(element)"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
    </table>
</div>
<div class="wrapper-right mt-5" [formGroup]="registerServicePointFormGroup">

    <label class="submitReportLabel mt-3" i18n >név</label>
    <input [formControl]="spName" class="submitReportInput form-control required" type="text"/>
    <label class="submitReportLabel mt-3" i18n>cím</label>
    <input [formControl]="spAddress" class="submitReportInput form-control required" type="text"/>
    <label class="submitReportLabel mt-3" i18n>telefonszám</label>
    <input [formControl]="spPhoneNumber" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>email</label>
    <input [formControl]="spEmail" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n >technikai felhasználó azonosító</label>
    <input [formControl]="technicalUserId" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n >intézmény típusa</label>
    <ng-select class="submitReportInput mb-2 ng_select_required" [formControl]="ekatEventTypeControl" [items]="[{name: 'Járó', value: 'JARO'},{name: 'Patológia', value: 'PATOLOGIA'}]" bindLabel="name" bindValue="value"></ng-select>
    <button [ngClass]="isModify ? 'disabledsubmitUserOrSpButton mr-3' : 'submitUserOrSpButton mt-3 mr-3'" [disabled]="isModify" (click)="saveSp()" i18n>mentés</button>
    <button [ngClass]="!isModify ? 'disabledsubmitUserOrSpButton mr-3' : 'submitUserOrSpButton mr-3'" [disabled]="!isModify" (click)="updateServicePoint()"class="submitUserOrSpButton mt-3" i18n>módosítás</button>
    <button [ngClass]="!isModify ? 'disabledsubmitUserOrSpButton mr-3' : 'submitUserOrSpButton mr-3'" [disabled]="!isModify" (click)="deleteServicePoint(selectedServicePoint)"class="submitUserOrSpButton mt-3" i18n>törlés</button>
    <button [ngClass]="!isModify ? 'disabledsubmitUserOrSpButton mr-3 mt-3' : 'submitUserOrSpButton mr-3 mt-3'" [disabled]="!isModify" (click)="clearForm(registerServicePointFormGroup)" i18n>mégsem</button>
</div>
