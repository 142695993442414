<app-navbar></app-navbar>
<div class="row" style="margin-left: 0; margin-right: 0">
  <div class="col-sm-10">
    <app-search id='search-component' (currentPatientEmitter)="emitPatientData($event)"></app-search>
    <div *ngIf="router.url.includes('/dashboard')" style="width: 810px;" class="switcher form-inline">
      <span class="ml-auto" i18n>szövettan</span>
      <div class="switch">
        <input [formControl]="isCytoInput" type="checkbox"  (change)="changeApplication(isCytoInput.value)" class="switch-checkbox form-control" id="isCytology" tabindex="0" [checked]="isCyto">
        <label class="switch-label" for="isCytology">
            <span class="switch-inner"></span>
            <span class="switch-switch"></span>
        </label>
      </div>
      <span class="mr-auto" i18n>citológia</span>
    </div>
    <div *ngIf="isCyto || router.url.includes('report/cyt')" class="mt-3">
      <!-- <app-cytologyapplication *ngIf="cytologyVersion === 'v1'"></app-cytologyapplication> -->
      <app-cytologyapplicationv2 *ngIf="!cytologyVersion || cytologyVersion === 'v4'" [cytologyVersion]="cytologyVersion"></app-cytologyapplicationv2>
      <app-cytologyapplicationv3 *ngIf="cytologyVersion === 'v3'" [patientData]="patientData"></app-cytologyapplicationv3>
    </div>
    <div *ngIf="!isCyto || router.url.includes('report/szov')" class="mt-3">
        <app-histologyapplication></app-histologyapplication>
    </div>
  </div>
  <div class="col-sm-2">
    <app-submitreport [isOldReport]="isOldReport" [patientData]="patientData"></app-submitreport>
  </div>
</div>

