import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './../../../../core/authentication/authentication.service';
import { ApiService } from 'src/app/core/http/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { User } from 'src/app/shared/models/User';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { RegistrationRequest } from 'src/app/shared/models/request/reqistrationRequest';
import { HttpErrorResponse } from '@angular/common/http';
import { ModifyUserRequest } from 'src/app/shared/models/request/modifyUserRequest';
import { ResetPasswordComponent } from 'src/app/modules/components/reset-password/reset-password.component';
import { MatDialog } from '@angular/material/dialog';
import { Role } from 'src/app/shared/models/Role';
import { ServicePoint } from 'src/app/shared/models/ServicePoint';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: GetUserByUserNameResponse;
  selectedUser: User;
  users: Array<User>;

  usersForPaginator: MatTableDataSource<User>;
  roles: Array<Role>;
  servicePoints: Array<ServicePoint>;

  isModify = false;

  name = new FormControl('', [Validators.required]);
  sealNumber = new FormControl('', [Validators.required]);
  username = new FormControl('', [Validators.required]);
  address = new FormControl('', [Validators.required]);
  phoneNumber = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  role = new FormControl('', [Validators.required]);
  servicePointId = new FormControl('', [Validators.required]);

  registrationFormGroup = new FormGroup({
    name: this.name,
    sealNumber: this.sealNumber,
    username: this.username,
    address: this.address,
    phoneNumber: this.phoneNumber,
    email: this.email,
    role: this.role,
    servicePointId: this.servicePointId
  });

  displayedColumns: string[] = [
    'name',
    'address',
    'phoneNumber',
    'email',
    'sealNumber',
    'username',
    'role',
    'servicePoint',
    'icons'
  ];

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private toasterService: ToastrService,
    private dialog: MatDialog,
    private messages: Messages
  ) {
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getAllUsersByRefid();
    this.findAllServicePointsByRef();
    this.findAllRoles();
  }


  getAllUsersByRefid() {
    this.apiService.getUsersByRefId(this.user.servicePointId).subscribe(response => {
      this.users = response;
      this.usersForPaginator = new MatTableDataSource(this.users);
      this.usersForPaginator.paginator = this.paginator;
      this.setPaginatorTexts(this.paginator);
    }, (error: Response) => {
      console.error(error);
    });
  }

  setPaginatorTexts(paginator: MatPaginator) {
    paginator._intl.nextPageLabel = this.messages.locale === 'hu' ? 'Következő' : 'Next';
    paginator._intl.previousPageLabel = this.messages.locale === 'hu' ? 'Előző' : 'Previous';
    paginator._intl.itemsPerPageLabel = this.messages.locale === 'hu' ? 'Oldalanként' : 'Size';
    paginator._intl.firstPageLabel = this.messages.locale === 'hu' ? 'Első' : 'First';
    paginator._intl.lastPageLabel = this.messages.locale === 'hu' ? 'Utolsó' : 'Last';
  }


  findAllRoles() {
    this.apiService.getAllRoles().subscribe(data => {
      this.roles = data;
    }, (error: Response) => {
      console.error(error);
    });
  }

  findAllServicePointsByRef() {
    this.apiService.getAllServicePointsByRef(this.user.servicePointId).subscribe(data => {
      this.servicePoints = data;
    }, (error: Response) => {
      console.error(error);
    });
  }



  registerDoctor() {

    if ((this.name.value && this.username.value && this.role.value && this.servicePointId.value) ||
    (this.email.value && this.email.valid)) {
      const request: RegistrationRequest = {
        address: this.address.value,
        email: this.email.value,
        name: this.name.value,
        phoneNumber: this.phoneNumber.value,
        role: this.role.value,
        sealNumber: this.sealNumber.value,
        servicePointId: this.servicePointId.value,
        username: this.username.value
      };

      this.authenticationService.registration(request).subscribe(data => {
        this.toasterService.success(
          this.messages.success.SUCCESS_SAVE_USER[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          {positionClass: 'toast-top-center'});
        this.registrationFormGroup.reset();
        this.getAllUsersByRefid();

      }, (error: HttpErrorResponse) => {
        this.toasterService.error(error.error.message, error.status.toString(), {positionClass: 'toast-top-center'});
      });
    } else {
      if (!this.name.value) {
        this.toasterService.error(
          this.messages.error.ERROR_NAME_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
      }
      if (!this.username.value) {
        this.toasterService.error(
          this.messages.error.ERROR_USERNAME_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
      }
      if (!this.role.value) {
        this.toasterService.error(
          this.messages.error.ERROR_ROLE_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
      }
      if (!this.servicePointId.value) {
        this.toasterService.error(
          this.messages.error.ERROR_INSTITUTE_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
      }
      if (!this.email.valid) {
        this.toasterService.error(
          this.messages.error.ERROR_EMAIL_WRONG[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
      }
    }

  }

  modifyUser(user: User, element: HTMLElement) {
    this.isModify = true;
    this.name.setValue(user.name);
    this.address.setValue(user.address);
    this.sealNumber.setValue(user.sealNumber);
    this.phoneNumber.setValue(user.phoneNumber);
    this.email.setValue(user.email);
    this.username.setValue(user.username);
    this.role.setValue(user.role.code);
    this.servicePointId.setValue(user.servicePoint.id);

    this.selectedUser = user;
    element.scrollIntoView();
  }


  updateUser() {
    const request: ModifyUserRequest = {
        active: this.selectedUser.active,
        address: this.address.value,
        eesztUserId: this.selectedUser.eesztUserId,
        email: this.email.value,
        name: this.name.value,
        phoneNumber: this.phoneNumber.value,
        roleCode: this.role.value,
        sealNumber: this.sealNumber.value,
        username: this.username.value,
        servicePointId: this.servicePointId.value
    };

    this.apiService.updateUser(this.selectedUser.id, request).subscribe(response => {
      this.registrationFormGroup.reset();
      this.isModify = false;
      this.getAllUsersByRefid();
      this.toasterService.success(
        this.messages.success.SUCCESS_UPDATE_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale], {positionClass: 'toast-top-center'});
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(error.error.message, error.error.status, {positionClass: 'toast-top-center'});
    });
  }

  clearForm(formGroup: FormGroup) {
    formGroup.reset();
    this.isModify = false;
    this.selectedUser = null;
  }

  resetPassword() {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      data: this.selectedUser
    });

    dialogRef.componentInstance.resetPasswordEvent.subscribe(response => {
      if (response === 200) {
        dialogRef.close();
      }
    });
  }



}
