<div class="wrapper" [formGroup]="histologyFormGroup" id="#histologyForm">
    <div class="form-inline" *ngIf="servicePoint.citologyVersion">
      <label class="histologyLabel" i18n *ngIf="servicePoint.citologyVersion === 'v3'">Szövettani sorszám</label>
      <label class="histologyLabel" *ngIf="servicePoint.citologyVersion === 'v4'" >Naplószám</label>
      <input [formControl]="kenetszam" class="kenetszam form-control" type="text" disabled/>
    </div>
    <div class="form-group form-check ml-3">
        <input [formControl]="aspiracios" type="checkbox" class="form-check-input" id="aspircatioscitologia">
        <label class="form-check-label" for="aspircatioscitologia" i18n>aspirációs citológia</label>
    </div>
    <label class="ml-3 mt-3 mb-3" i18n>klinikai adatok</label>
    <textarea [formControl]="klinikaiAdatok" style="margin-left: 170px;" class="textareaInput form-control" rows="8"></textarea>
    <label class="ml-3 mt-3 mb-3" i18n>lokalizáció</label>
    <div class="form-inline" [ngClass]="index > 0 ? 'mt-3': ''" *ngFor="let localization of [].constructor(localizations); let index = index">
        <label class="histologyLabel">{{index + 1 | arabicToRoman}}</label>
        <input formControlName="lokalizacio{{index + 1}}" class="histologyInput form-control" type="text"/>
    </div>
    <div class="text-right mt-3" style="width: 640px; margin-left: 170px" *ngIf="showLocalizationsButton">
      <button class="iconButton mr-2" [ngClass]="localizations === 10 ? 'disabledButton' : ''" (click)="addLocalization()" [disabled]="localizations === 10">
        <a class="fa fa-plus"></a>
      </button>
      <button class="iconButton" (click)="removeLocalization(localizations - 1)" [ngClass]="localizations < 2 ? 'disabledButton' : ''" [disabled]="localizations < 2">
        <a class="fa fa-minus"></a>
      </button>
    </div>
    <!-- <div class="form-inline mt-3">
        <label class="histologyLabel">II.</label>
        <input [formControl]="lokalizacio2" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">III.</label>
        <input [formControl]="lokalizacio3" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">IV.</label>
        <input [formControl]="lokalizacio4" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">V.</label>
        <input [formControl]="lokalizacio5" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">VI.</label>
        <input [formControl]="lokalizacio6" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">VII.</label>
        <input [formControl]="lokalizacio7" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">VIII.</label>
        <input [formControl]="lokalizacio8" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">IX.</label>
        <input [formControl]="lokalizacio9" class="histologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="histologyLabel">X.</label>
        <input [formControl]="lokalizacio10" class="histologyInput form-control" type="text"/>
    </div> -->

</div>
