import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  currentTab = 1;

  constructor(
  ) { }

  ngOnInit() {
  }

  tabChange(currentTab: number) {
    this.currentTab = currentTab;
  }
}
