import { Component, OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reset-case',
  templateUrl: './reset-case.component.html',
  styleUrls: ['./reset-case.component.css']
})
export class ResetCaseComponent implements OnInit {

  option = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  deleteCaseValues(option: boolean) {
    this.option.emit(option);
  }

}
