<app-dashboard></app-dashboard>
<div class="wrapper" [formGroup]="cytologyResFormGroup">
    <div class="wrapperLeft">
     <label class="cytologyHeaderLabel" i18n>minta</label>
     <div class="form-inline ml-3">
         <div class="form-group form-check ml-5">
             <input [formControl]="pap" type="checkbox" class="form-check-input" id="pap">
             <label class="form-check-label" for="pap" i18n>pap.</label>
         </div>
         <div style="margin-left: 20px;" class="form-group form-check">
             <input [formControl]="lbc" type="checkbox" class="form-check-input" id="lbc">
             <label class="form-check-label" for="lbc" i18n>LBC</label>
         </div>
         <div style="margin-left: 20px;" class="form-group form-check">
             <input [formControl]="hpv" type="checkbox" class="form-check-input" id="hpv">
             <label class="form-check-label" for="hpv" i18n>HPV</label>
         </div>
     </div>
     <div class="form-inline mt-1 ml-3">
        <div style="margin-left: 20px;" class="form-group form-check ml-5">
            <input [formControl]="isFeldolgozott" type="checkbox" class="form-check-input" id="isFeldolgozott">
            <label class="form-check-label" for="isFeldolgozott" i18n>feldolgozott</label>
        </div>
        <div style="margin-left: 20px;" class="form-group form-check">
            <input [formControl]="nemFeldolgozott" type="checkbox" class="form-check-input" id="nemFeldolgozott">
            <label class="form-check-label" for="nemFeldolgozott" i18n>nem feldolgozott</label>
        </div>
    </div>
     <label class="cytologyHeaderLabel mt-2" i18n>kenet minősége</label>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="megfelelo" type="checkbox" class="form-check-input" id="megfelelo">
             <label class="form-check-label" for="megfelelo" i18n>megfelelő (értékelhető)</label>
         </div>
         <div style="margin-left: 81px;" class="form-group form-check">
             <input [formControl]="ertekelhetetlen" type="checkbox" class="form-check-input" id="ertekelhetetlen">
             <label class="form-check-label" for="ertekelhetetlen" i18n>értékelhetetlen, mert</label>
         </div>
     </div>
        <div style="margin-bottom: 0" class="form-group form-check mt-1 ml-5">
            <input [formControl]="lemezNemAzonosithato" type="checkbox" class="form-check-input" id="lemezNemAzonosithato">
            <label class="form-check-label" for="lemezNemAzonosithato" i18n>tárgylemez nem azonosítható</label>
        </div>
        <div style="margin-bottom: 0" class="form-group form-check mt-1 ml-5">
            <input [formControl]="klinikaiAdatokHianyoznak" type="checkbox" class="form-check-input" id="klinikaiAdatokHianyoznak">
            <label class="form-check-label" for="klinikaiAdatokHianyoznak" i18n>klinikai adatok hiányoznak</label>
        </div>
        <div style="margin-bottom: 0" class="form-group form-check mt-1 ml-5">
            <input [formControl]="endocervAtmZonaHianyzik" type="checkbox" class="form-check-input" id="endocervAtmZonaHianyzik">
            <label class="form-check-label" for="endocervAtmZonaHianyzik" i18n>endocerv átm. zóna hiányzik</label>
        </div>
        <div style="margin-bottom: 0" class="form-group form-check mt-1 ml-5">
            <input [formControl]="technikaiProblema" type="checkbox" class="form-check-input" id="technikaiProblema">
            <label class="form-check-label" for="technikaiProblema" i18n>technikai probléma</label>
        </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="sejtszegeny" type="checkbox" class="form-check-input" id="sejtszegeny">
             <label class="form-check-label" for="sejtszegeny" i18n>sejtszegény</label>
         </div>
         <div style="margin-left: 76px;"class="form-group form-check">
             <input [formControl]="zavaroGyulladas" type="checkbox" class="form-check-input" id="zavaroGyulladas">
             <label class="form-check-label" for="zavaroGyulladas" i18n>zavaró gyulladás</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="elegtelenulFixalt" type="checkbox" class="form-check-input" id="elegtelenulFixalt">
             <label class="form-check-label" for="elegtelenulFixalt" i18n>elégtelenül fixált</label>
         </div>
         <div style="margin-left: 41px;" class="form-group form-check">
            <input [formControl]="zavaroMeretuVer" type="checkbox" class="form-check-input" id="zavaroMeretuVer">
            <label class="form-check-label" for="zavaroMeretuVer" i18n>zavaró mértékű vér</label>
        </div>
     </div>
     <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="cytolysisAutolysis" type="checkbox" class="form-check-input" id="cytolysisAutolysis">
            <label class="form-check-label" for="cytolysisAutolysis" i18n>cytolysis, autolysis</label>
        </div>
        <div style="margin-left: 29px;" class="form-group form-check">
           <input [formControl]="zavaroMeretuNyak" type="checkbox" class="form-check-input" id="zavaroMeretuNyak">
           <label class="form-check-label" for="zavaroMeretuNyak" i18n>zavaró mértékű nyák</label>
       </div>
     </div>
     <div class="otherTechnicalIssues form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5" style="float: left">
            <input [formControl]="kenetErtekeleseEgyeb" type="checkbox" class="form-check-input" id="kenetErtekeleseEgyeb">
            <label class="form-check-label" for="kenetErtekeleseEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="kenetErtekeleseEgyebMegjegyzes" name="kenetErtekeleseEgyebMegjegyzes" class="cytologyReportInput kenetMinosegeEgyebMegjegyzes form-control ml-3" type="text"/>
        <div class="otherTechnicalIssuesOptions" *ngIf="kenetErtekeleseEgyebMegjegyzes.value?.length >= 1 && !hideTechnicalOptions">
            <button class="otherTechnicalIssue" *ngFor="let chooseOption of chooseOptions" (click)="kenetErtekeleseEgyebMegjegyzes.setValue(chooseOption['value']); hideTechnicalOptions=true">{{chooseOption['value']}}</button>
        </div>
     </div>
     <label class="cytologyHeaderLabel mt-3" i18n>a kenet értékelése</label>
     <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="negativKenet" type="checkbox" class="form-check-input" id="negativKenet">
            <label class="form-check-label" for="negativKenet" i18n>negatív intraepitális lézióra, malignitásra</label>
        </div>
    </div>
     <div style="margin-bottom: 0" class="form-group form-check mt-1 ml-5">
        <input [formControl]="korosHamelvaltozasok" type="checkbox" class="form-check-input" id="korosHamelvaltozasok">
        <label class="form-check-label" for="korosHamelvaltozasok" i18n>kóros hámelváltozások</label>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check  mt-1 ml-5">
            <input [formControl]="egyebElvaltozasok" type="checkbox" class="form-check-input" id="egyebElvaltozasok">
            <label class="form-check-label" for="egyebElvaltozasok" i18n>egyéb elváltozások</label>
        </div>
        <input [formControl]="egyebElvaltozasokMegjegyzes" name="egyebElvaltozasokMegjegyzes" style="width: 224px;" class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
     <label class="cytologyHeaderLabel mt-3" i18n>kórokozók</label>
     <div class="form-inline mt-1 ml-5">
        <label class="form-check-label" for="trichomonas" i18n>gomba</label>
         <div class="form-group form-check ml-5">
             <input [formControl]="candida" type="checkbox" class="form-check-input" id="candida">
             <label class="form-check-label" for="candida" i18n>candida</label>
         </div>
     </div>
     <div class="funiOtherWrapper form-inline mt-1 ml-5">
        <div style="margin-left: 98px" class="form-group form-check">
            <input [formControl]="gombaEgyeb" type="checkbox" class="form-check-input" id="gombaEgyeb">
            <label class="form-check-label" for="gombaEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="gombaEgyebMegjegyzes" name="gombaEgyebMegjegyzes" class="fungiOther cytologyReportInput form-control ml-3" type="text"/>
        <div class="fungiOtherOptions" *ngIf="gombaEgyebMegjegyzes.value?.length >= 1 && !hideFungiOptions">
            <button class="otherTechnicalIssue" *ngFor="let chooseOption of chooseOptions" (click)="gombaEgyebMegjegyzes.setValue(chooseOption['value']); hideFungiOptions=true">{{chooseOption['value']}}</button>
        </div>
     </div>
     <div class="form-inline mt-2 ml-5">
        <label class="form-check-label" for="trichomonas" i18n>baktérium</label>
         <div style="margin-left: 26px;" class="form-group form-check">
             <input [formControl]="korosFlora" type="checkbox" class="form-check-input" id="korosFlora">
             <label class="form-check-label" for="korosFlora" i18n>kóros flóra</label>
         </div>
     </div>
     <div style="margin-left: 146px; margin-bottom: 0" class="form-group form-check mt-1">
        <input [formControl]="actinomyces" type="checkbox" class="form-check-input" id="actinomyces">
        <label class="form-check-label" for="actinomyces" i18n>actinomyces</label>
    </div>
    <div class="bacteriaWrapper form-inline mt-1 ml-5">
        <div style="margin-left: 98px" class="form-group form-check">
            <input [formControl]="bakteriumEgyeb" type="checkbox" class="form-check-input" id="bakteriumEgyeb">
            <label class="form-check-label" for="bakteriumEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="bakteriumEgyebMegjegyzes" name="bakteriumEgyebMegjegyzes" style="width: 224px;"class="bacteriaOther cytologyReportInput form-control ml-3" type="text"/>
        <div class="bacteriaOptions" *ngIf="bakteriumEgyebMegjegyzes.value?.length >= 1 && !hideBacteriaOptions">
            <button class="otherTechnicalIssue" *ngFor="let chooseOption of chooseOptions" (click)="bakteriumEgyebMegjegyzes.setValue(chooseOption['value']); hideBacteriaOptions=true">{{chooseOption['value']}}</button>
        </div>
    </div>
     <div class="form-inline mt-2 ml-5">
        <label class="form-check-label" for="trichomonas" i18n>protozoon</label>
         <div style="margin-left: 24px;" class="form-group form-check">
             <input [formControl]="trichomonasvaginalis" type="checkbox" class="form-check-input" id="trichomonasvaginalis">
             <label class="form-check-label" for="trichomonasvaginalis" i18n>trichomonas vaginalis</label>
         </div>
     </div>
    <div class="form-inline mt-1 ml-5">
        <div style="margin-left: 98px" class="form-group form-check">
            <input [formControl]="protozoonEgyeb" type="checkbox" class="form-check-input" id="protozoonEgyeb">
            <label class="form-check-label" for="protozoonEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="protozoonEgyebMegjegyzes"  name="protozoonEgyebMegjegyzes" style="width: 224px;"class="cytologyReportInput form-control ml-3" type="text"/>
     </div>
     <div class="form-inline mt-2 ml-5">
        <label class="form-check-label" for="trichomonas" i18n>vírus</label>
         <div style="margin-left: 65px;" class="form-group form-check">
             <input [formControl]="herpesFertozesGyanuja" type="checkbox" class="form-check-input" id="herpesFertozesGyanuja">
             <label class="form-check-label" for="herpesFertozesGyanuja" i18n>herpes fertőzés gyanúja</label>
         </div>
     </div>
    <div class="virusOtherWrapper form-inline mt-1 ml-5">
        <div style="margin-left: 98px" class="form-group form-check">
            <input [formControl]="virusEgyeb" type="checkbox" class="form-check-input" id="virusEgyeb">
            <label class="form-check-label" for="virusEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="virusEgyebMegjegyzes" name="virusEgyebMegjegyzes" class="virusOther cytologyReportInput form-control ml-3" type="text"/>
        <div class="virusOtherOptions" *ngIf="virusEgyebMegjegyzes.value?.length >= 1 && !hideVirusOptions">
            <button class="otherTechnicalIssue" *ngFor="let chooseOption of chooseOptions" (click)="virusEgyebMegjegyzes.setValue(chooseOption['value']); hideVirusOptions=true">{{chooseOption['value']}}</button>
        </div>
     </div>
     <label class="cytologyHeaderLabel mt-3" i18n>reaktív és reparatív jelek</label>
     <div style="margin-bottom: 0" class="form-group form-check ml-5">
        <input [formControl]="gyulladasReaktivHamelvaltozassal" type="checkbox" class="form-check-input" id="gyulladasReaktivHamelvaltozassal">
        <label class="form-check-label" for="gyulladasReaktivHamelvaltozassal" i18n>gyulladás, reaktív hámelváltozással</label>
    </div>
    <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="atrophiaGyulladas" type="checkbox" class="form-check-input" id="atrophiaGyulladas">
        <label class="form-check-label" for="atrophiaGyulladas" i18n>atrophia/gyulladás</label>
    </div>
    <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="sugarhatas" type="checkbox" class="form-check-input" id="sugarhatas">
        <label class="form-check-label" for="sugarhatas" i18n>sugárhatás</label>
    </div>
    <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="terhesseghezTartozoJelek" type="checkbox" class="form-check-input" id="terhesseghezTartozoJelek">
        <label class="form-check-label" for="terhesseghezTartozoJelek" i18n>terhességhez tartozó jelek</label>
    </div>
    <!-- <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="cb" type="checkbox" class="form-check-input" id="cb">
        <label class="form-check-label" for="cb" i18n>CB</label>
    </div> -->
    <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="mirigyhamsejtekHiszterektomiaUtan" type="checkbox" class="form-check-input" id="mirigyhamsejtekHiszterektomiaUtan">
        <label class="form-check-label" for="mirigyhamsejtekHiszterektomiaUtan" i18n>mirigyhámsejtek hiszterektómia után</label>
    </div>
    <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="metaplasiaiRegeneratio" type="checkbox" class="form-check-input" id="metaplasiaiRegeneratio">
        <label class="form-check-label" for="metaplasiaiRegeneratio" i18n>metaplasia, regeneratio</label>
    </div>
    <div style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="endometriumSejtek" type="checkbox" class="form-check-input" id="endometriumSejtek">
        <label class="form-check-label" for="endometriumSejtek" i18n>endometrium sejtek > 45 éves kor</label>
    </div>
     <div class="reactiveOtherWrapper form-inline">
        <div class="form-group form-check ml-5">
            <input [formControl]="reaktivEgyeb" type="checkbox" class="form-check-input" id="reaktivEgyeb">
            <label class="form-check-label" for="reaktivEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="reaktivEgyebMegjegyzes"  name="reaktivEgyebMegjegyzes" class="reactiveOther cytologyReportInput form-control ml-3" type="text"/>
        <div class="reactiveOtherOptions" *ngIf="reaktivEgyebMegjegyzes.value?.length >= 1 && !hideReactiveOptions">
            <button class="otherTechnicalIssue" *ngFor="let chooseOption of chooseOptions" (click)="reaktivEgyebMegjegyzes.setValue(chooseOption['value']); hideReactiveOptions=true">{{chooseOption['value']}}</button>
        </div>
    </div>
    </div>
    <div class="wrapperRight">
     <label class="cytologyHeaderLabel" i18n>laphámsejtek</label>
     <div class="form-inline">
         <div class="form-group form-check ml-5">
             <input [formControl]="asc" type="checkbox" class="form-check-input" id="asc">
             <label class="form-check-label" for="asc" i18n>atypusos laphámsejtek (ASC)</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="ascus" type="checkbox" class="form-check-input" id="ascus">
             <label class="form-check-label" for="ascus" i18n>nem meghatározható okból (ASC-US)</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="asch" type="checkbox" class="form-check-input" id="asch">
             <label class="form-check-label" for="asch" i18n>nem zárható ki HSIL (ASC-H)</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="lsil" type="checkbox" class="form-check-input" id="lsil">
             <label class="form-check-label" for="lsil" i18n>enyhe fokú intraepithelialis laphám laesio (LSIL)</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="laphamhpv" type="checkbox" class="form-check-input" id="laphamhpv">
             <label class="form-check-label" for="laphamhpv" i18n>HPV</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="cinegy" type="checkbox" class="form-check-input" id="cinegy">
             <label class="form-check-label" for="cinegy" i18n>CIN 1</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="laphamhsil" type="checkbox" class="form-check-input" id="laphamhsil">
             <label class="form-check-label" for="laphamhsil" i18n>kifejezett fokú intraepithelialis laphám laesio (HSIL)</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="cinketto" type="checkbox" class="form-check-input" id="cinketto">
             <label class="form-check-label" for="cinketto" i18n>CIN 2</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="cinharom" type="checkbox" class="form-check-input" id="cinharom">
             <label class="form-check-label" for="cinharom" i18n>CIN 3</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="invasio" type="checkbox" class="form-check-input" id="invasio">
             <label class="form-check-label" for="invasio" i18n>invasio gyanúja</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="carcinoma" type="checkbox" class="form-check-input" id="carcinoma">
             <label class="form-check-label" for="carcinoma" i18n>laphámsejt carcinoma</label>
         </div>
     </div>
     <label class="cytologyHeaderLabel mt-3" i18n>mirigyhámsejtek</label>
     <div class="form-inline">
         <div class="form-group form-check ml-5">
             <input [formControl]="agcnos" type="checkbox" class="form-check-input" id="agcnos">
             <label class="form-check-label" for="agcnos" i18n>atypusos mirigyhámsejtek (AGC-NOS)</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="endocervicalis" type="checkbox" class="form-check-input" id="endocervicalis">
             <label class="form-check-label" for="endocervicalis" i18n>endocervicalis sejtek</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="endometrialis" type="checkbox" class="form-check-input" id="endometrialis">
             <label class="form-check-label" for="endometrialis" i18n>endometrialis sejtek</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="mirigynos" type="checkbox" class="form-check-input" id="mirigynos">
             <label class="form-check-label" for="mirigynos" i18n>mirigyhámsejtek - NOS</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="agc" type="checkbox" class="form-check-input" id="agc">
             <label class="form-check-label" for="agc" i18n>atypusos mirigyhámsejtek, inkább neoplasticus (AGC)</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="endocervicalis2" type="checkbox" class="form-check-input" id="endocervicalis2">
             <label class="form-check-label" for="endocervicalis2" i18n>endocervicalis sejtek</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="mirigynosketto" type="checkbox" class="form-check-input" id="mirigynosketto">
             <label class="form-check-label" for="mirigynosketto" i18n>mirigyhámsejtek - NOS</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="ais" type="checkbox" class="form-check-input" id="ais">
             <label class="form-check-label" for="ais" i18n>endocervicalis adenocarcinoma in situ (AIS)</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="adenocarcinoma" type="checkbox" class="form-check-input" id="adenocarcinoma">
             <label class="form-check-label" for="adenocarcinoma" i18n>adenocarcinoma</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="endocercivalis3" type="checkbox" class="form-check-input" id="endocercivalis3">
             <label class="form-check-label" for="endocercivalis3" i18n>endocercivalis</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="extrauterin" type="checkbox" class="form-check-input" id="extrauterin">
             <label class="form-check-label" for="extrauterin" i18n>extrauterin</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="endometrialis2" type="checkbox" class="form-check-input" id="endometrialis2">
             <label class="form-check-label" for="endometrialis2" i18n>endometrialis</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="nos" type="checkbox" class="form-check-input" id="nos">
             <label class="form-check-label" for="nos" i18n>NOS</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="malignustumor" type="checkbox" class="form-check-input" id="malignustumor">
             <label class="form-check-label" for="malignustumor" i18n>egyéb malignus tumor</label>
         </div>
         <input [formControl]="malignustumorMegjegyzes" name="malignustumorMegjegyzes" style="width: 200px;"class="cytologyReportInput form-control ml-3" type="text"/>
     </div>
     <label class="cytologyHeaderLabel mt-3" i18n>kiegészítő vizsgálatok eredményei</label>
     <div class="form-inline ml-5">
        <label class="form-check-label" for="ismetlesklinika" i18n>CINtec PLUS</label>
        <div class="form-group form-check ml-5">
            <input [formControl]="cintecPluszNegativ" type="checkbox" class="form-check-input" id="cintecPluszNegativ">
            <label class="form-check-label" for="cintecPluszNegativ" i18n>negatív</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="cintecPluszPozitiv" type="checkbox" class="form-check-input" id="cintecPluszPozitiv">
            <label class="form-check-label" for="cintecPluszPozitiv" i18n>pozitív</label>
        </div>
    </div>
     <div class="form-inline ml-5 mt-1">
        <label class="form-check-label" for="ismetlesklinika" i18n>hr HPV tipizálás</label>
        <div style="margin-left: 25px" class="form-group form-check">
            <input [formControl]="hrHpvTipizalasNegativ" type="checkbox" class="form-check-input" id="hrHpvTipizalasNegativ">
            <label class="form-check-label" for="hrHpvTipizalasNegativ" i18n>negatív</label>
        </div>
        <div style="margin-left: 48px" class="form-group form-check">
            <input [formControl]="hrHpvTipizalasTizenhat" type="checkbox" class="form-check-input" id="hrHpvTipizalasTizenhat">
            <label class="form-check-label" for="hrHpvTipizalasTizenhat" i18n>16</label>
        </div>
        <div style="margin-left: 40px" class="form-group form-check">
            <input [formControl]="hrHpvTipizalasTizennyolc" type="checkbox" class="form-check-input" id="hrHpvTipizalasTizennyolc">
            <label class="form-check-label" for="hrHpvTipizalasTizennyolc" i18n>18</label>
        </div>
    </div>
    <div style="margin-left: 184px; margin-bottom:0" class="form-group form-check">
        <input [formControl]="egyebHrHpv" type="checkbox" class="form-check-input" id="egyebHrHpv">
        <label class="form-check-label" for="egyebHrHpv" i18n>egyéb hr HPV</label>
    </div>
    <div class="form-inline ml-5 mt-1">
        <label class="form-check-label" style="margin-right: 34px" for="ismetlesklinika" i18n>megjegyzés</label>
        <input [formControl]="kiegVizsMegjegyzes"  name="kiegVizsMegjegyzes" style="width: 231px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
     <label class="cytologyHeaderLabel mt-3" i18n>javaslat</label>
     <div class="form-inline">
         <div class="form-group form-check ml-5">
             <input [formControl]="ismetlesKlinikaiMegitelesSzerint" type="checkbox" class="form-check-input" id="ismetlesKlinikaiMegitelesSzerint">
             <label class="form-check-label" for="ismetlesKlinikaiMegitelesSzerint" i18n>ismétlés klinikai megítélés szerint</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="ismetlesKezelesUtan" type="checkbox" class="form-check-input" id="ismetlesKezelesUtan">
             <label class="form-check-label" for="ismetlesKezelesUtan" i18n>ismétlés kezelés után</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="lobellenes" type="checkbox" class="form-check-input" id="lobellenes">
             <label class="form-check-label" for="lobellenes" i18n>lobellenes</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="hormonalis" type="checkbox" class="form-check-input" id="hormonalis">
             <label class="form-check-label" for="hormonalis" i18n>hormonális</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="ismetles" type="checkbox" class="form-check-input" id="ismetles">
             <label class="form-check-label" for="ismetles" i18n>ismétlés</label>
         </div>
     </div>
     <div class="form-inline ml-3 mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="haromHonapMulva" type="checkbox" class="form-check-input" id="haromhonapmulva">
             <label class="form-check-label" for="haromhonapmulva" i18n>3 hónap múlva</label>
         </div>
         <div class="form-group form-check ml-5">
             <input [formControl]="hatHonapMulva" type="checkbox" class="form-check-input" id="hathonapmulva">
             <label class="form-check-label" for="hathonapmulva" i18n>6 hónap múlva</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="hpvTipizalas" type="checkbox" class="form-check-input" id="hpvtipizalas">
             <label class="form-check-label" for="hpvtipizalas" i18n>HPV tipizálás</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="cintecPlusz" type="checkbox" class="form-check-input" id="cintecPlusz">
             <label class="form-check-label" for="cintecPlusz" i18n>CINtec PLUS</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="ismeteltCytEsKolp" type="checkbox" class="form-check-input" id="ismeteltCytEsKolp">
             <label class="form-check-label" for="ismeteltCytEsKolp" i18n>ismételt cyt. és kolp.</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="szovettaniVizsgalat" type="checkbox" class="form-check-input" id="szovettaniVizsgalat">
             <label class="form-check-label" for="szovettaniVizsgalat" i18n>szövettani vizsgálat</label>
         </div>
     </div>
     <div class="form-inline mt-1">
         <div class="form-group form-check ml-5">
             <input [formControl]="javaslategyeb" type="checkbox" class="form-check-input" id="javaslategyeb">
             <label class="form-check-label" for="javaslategyeb" i18n>egyéb</label>
         </div>
         <input [formControl]="javaslatEgyebMegjegyzes" name="javaslatEgyebMegjegyzes" style="width: 331px;"class="cytologyReportInput form-control ml-3" type="text"/>
     </div>
    </div>
    <div style="float:right; margin-bottom: 50px; margin-top: 50px;" class="form-inline">
      <div  *ngIf="!user?.roleCode.includes('REFERRING')" class="form-group form-check mr-3">
          <input [formControl]="sendEmailNotificationControl" type="checkbox" class="form-check-input" id="sendEmailNotification">
          <label class="form-check-label" for="sendEmailNotification"  title="e-mail értesítés küldése a beküldő orvos által megadott címre" i18n>email értesítés küldése</label>
      </div>
     <button (click)="generalService.printPDf(printablePdf)" class="cytoReportButton mr-3" i18n>nyomtatás</button>
     <button [disabled]="user?.roleCode.includes('REFERRING')" [ngClass]="user?.roleCode.includes('REFERRING') ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'" (click)="deleteCase()" class="cytoReportButton mr-3" i18n>törlés</button>
     <button [disabled]="!user?.roleCode.includes('CYT')" [ngClass]="!user?.roleCode.includes('CYT') ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'"  (click)="saveCytologyRes(true)" class="cytoReportButton mr-3" i18n>validálás</button>
     <button [disabled]="user?.roleCode.includes('REFERRING')" [ngClass]="user?.roleCode.includes('REFERRING') ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'"(click)="saveCytologyRes(false)" class="cytoReportButton" i18n>mentés</button>
    </div>
</div>
<div class="cytoData" [formGroup]="cytologyResDataFormGroup">
    <label class="cytoDataLabel mt-5" i18n>naplószám</label>
    <div class="form-inline mt-1">
        <label class="mr-1">{{logPrefix ? logPrefix + '/' : ''}}</label>
        <input [formControl]="logNumber" class="cytoDataInput form-control required" type="text"  name="lognumber" style="width: 158px;"/>
    </div>
    <label class="cytoDataLabel mt-3" i18n="@@bno">bno</label>
    <ng-select [formControl]="bno" class="cytoDataInput ng_select_required bnoSelector" [items]="bnoList" bindValue="code" [virtualScroll]="true" [searchFn]="generalService.bnoSearchFn">
        <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item"  title="item.name">
            {{item.code}} - {{item.name}}
        </ng-template>
    </ng-select>
    <label class="cytoDataLabel mt-3" i18n>szakmakód</label>
    <ng-select [formControl]="szakmakodControl" class="cytoDataInput ng_select_required bnoSelector" [items]="szakmakodok" bindValue="id" [virtualScroll]="true">
      <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item" title="item.name">
        <span title="{{item.name}}">{{item.name}}</span>
      </ng-template>
    </ng-select>
    <label class="cytoDataLabel mt-3" i18n>beutaló azonosító</label>
    <input [formControl]="beutaloAzon" class="cytoDataInput form-control" type="text" name="beutaloazon">
    <label class="cytoDataLabel mt-3" i18n>fejléc</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="pdfHeaders" [searchable]="true" bindLabel="instituteName" bindValue="id" [formControl]="pdfHeader" placeholder="Válasszon..."></ng-select>
    <button (click)="checkEszigTokenValid()" class="cytoReportButton mt-5" i18n>e-beutalók lekérdezése</button><br>
    <label class="cytoDataLabel mt-3" i18n>EESZT beutaló azonosító</label>
    <input [formControl]="eesztBeutaloAzon" class="mt-2 cytoDataInput form-control" name="eesztbeutaloazon">
    <label class="cytoDataLabel mt-3" i18n>Előszűrő szakasszisztens</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="preScreeningSpecialists" [searchable]="true" bindLabel="name" bindValue="id" [formControl]="preScreeningSpecialistControl" placeholder="Válasszon..."></ng-select>
    <label class="cytoDataLabel mt-3" i18n>Validáló orvos</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="validatorUsers" [searchable]="true" bindLabel="name" bindValue="id" [formControl]="validatorUserControl" placeholder="Válasszon..."></ng-select>
    <label class="cytoDataLabel mt-3" i18n>kérés online feladásának ideje</label>
    <input [formControl]="arrivalTime" class="cytoDataInput form-control"/>
    <label class="cytoDataLabel mt-3" i18n>leletezés megkezdésének ideje</label>
    <input [formControl]="responseCreationDate" class="cytoDataInput form-control"/>
    <label class="cytoDataLabel mt-3" i18n>leletezést végezte</label>
    <input [formControl]="doctorName" class="cytoDataInput form-control" type="text"/>
</div>
