import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateStatus'
})
export class TranslateStatusPipe implements PipeTransform {

  transform(value: string, locale: string): string {
    switch (value) {
      case 'kész': {
        return locale === 'en' ? 'done' : value;
      }
      case 'új': {
        return locale === 'en' ? 'new' : value;
      }

      case 'véglegesítésre vár' : {
        return locale === 'en' ? 'waiting for validation' : value;
      }

      default: {
        return value;
      }
    }
  }

}
