import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ApiService } from './../../../../core/http/api.service';
import { PathologyreportsComponent } from './../pathologyreports.component';
import { ToastrService } from 'ngx-toastr';
import { Case } from './../../../../shared/models/case';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteCaseRequest } from 'src/app/shared/models/request/deleteCaseRequest';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-delete-case',
  templateUrl: './delete-case.component.html',
  styleUrls: ['./delete-case.component.css']
})
export class DeleteCaseComponent implements OnInit {

  currentUser: GetUserByUserNameResponse;
  caseDeleted = new EventEmitter<boolean>();
  validatorUserId: number;
  technicalUserId: number;

  constructor(
    private toasterService: ToastrService,
    private router: Router,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) private data: Case,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getServicePointById(this.currentUser.servicePointId);
  }

  deleteCase() {

    if (this.router.url.includes('admin')) {
      this.apiService.deleteCaseById(this.data['$event'].id).subscribe(response => {
        this.caseDeleted.emit(true);
      }, (error: HttpErrorResponse) => {
        this.caseDeleted.emit(false);
        this.toasterService.success(
          error.error.message,
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
      });
    } else {
      const request: DeleteCaseRequest = {
        id: this.data['$event'].id,
        servicePointId: this.currentUser.servicePointId,
        technicalUserId: this.technicalUserId,
        userId: this.currentUser.id,
        validatorUserId: this.validatorUserId
      };

      this.apiService.deleteCase(request).subscribe(response => {
        this.caseDeleted.emit(true);
      }, (error: Response) => {
        console.error(error);
      });
    }

  }

  getServicePointById(spId: number) {
    this.apiService.getServicePointById(spId).subscribe(response => {
      this.validatorUserId = response.validatorUserId;
      this.technicalUserId = response.technicalUserId;
    })
  }

}
