import { Bno } from './../../../shared/models/bno';
import { ResetCaseComponent } from './../../components/reset-case/reset-case.component';
import { SelectEbeuComponent } from './../../components/select-ebeu/select-ebeu.component';
import { SelectCasesComponent } from './../../components/select-cases/select-cases.component';
import { MatDialog } from '@angular/material/dialog';
import { PdfHeader } from './../../../shared/models/pdfHeader';
import { GetPatientResponse } from './../../../shared/models/response/getPatientResponse';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { SearchEbeuResponse } from './../../../shared/models/response/searchEbeuResponse';
import { ToastrService } from 'ngx-toastr';
import { SetLogNumberRequest } from './../../../shared/models/request/setLogNumberRequest';
import { FormService } from './../../../core/services/form.service';
import { GeneralService } from './../../../core/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { ApiService } from './../../../core/http/api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { EbeuSearchRequest } from 'src/app/shared/models/request/ebeuSearchRequest';
import { Ebeu } from 'src/app/shared/models/ebeu';
import { Messages } from 'src/app/shared/locale/messages';
import { SaveCytologyResRequest } from 'src/app/shared/models/request/savecytologyResRequest';

@Component({
  selector: 'app-cytologicalreport',
  templateUrl: './cytologicalreport.component.html',
  styleUrls: ['./cytologicalreport.component.css']
})
export class CytologicalreportComponent implements OnInit {

  feldolgozott = new FormControl(false);
  nemfeldolgozott  = new FormControl(false);
  pap = new FormControl(false);
  lb = new FormControl(false);
  auto = new FormControl(false);
  ertekelhetode = new FormControl(false);
  nemertekelhetomert = new FormControl(false);
  ectfzonahiany = new FormControl(false);
  sejtszegeny = new FormControl(false);
  nemazonosithato = new FormControl(false);
  zavaromeretuver = new FormControl(false);
  elegtelenfixalas = new FormControl(false);
  zavaromeretulob = new FormControl(false);
  kenetegyeb = new FormControl(false);
  egyebMegjegyzes = new FormControl('');
  kiegeszitotesztek = new FormControl(false);
  kiegeszitotesztekMegjegyzes = new FormControl('');
  negativkorosneoplasticus = new FormControl(false);
  korosneoplasticus = new FormControl(false);
  kenetegyeb2 = new FormControl(false);
  kenetEgyebMegjegyzes = new FormControl('');
  trichomonas = new FormControl(false);
  vegyesbakterium = new FormControl(false);
  actinomyces = new FormControl(false);
  hpv = new FormControl(false);
  gomba = new FormControl(false);
  gombaEgyeb = new FormControl('');
  korokozoEgyeb = new FormControl(false);
  korokozoEgyebMegjegyzes = new FormControl('');
  relativsejtvalasztasokkaljaro = new FormControl(false);
  gyulladas = new FormControl(false);
  sugarhatas = new FormControl(false);
  mechanikushatas = new FormControl(false);
  atrophia = new FormControl(false);
  negyveneves = new FormControl(false);
  egyebnemneoplaszt = new FormControl(false);
  egyebnemneoplasztMegjegyzes = new FormControl('');
  asc = new FormControl(false);
  ascus = new FormControl(false);
  asch = new FormControl(false);
  lsil = new FormControl(false);
  laphamhpv = new FormControl(false);
  cinegy = new FormControl(false);
  laphamhsil = new FormControl(false);
  cinketto = new FormControl(false);
  cinharom = new FormControl(false);
  invasio = new FormControl(false);
  carcinoma = new FormControl(false);
  agcnos = new FormControl(false);
  endocervicalis = new FormControl(false);
  endometrialis = new FormControl(false);
  mirigynos = new FormControl(false);
  agc = new FormControl(false);
  endocervicalis2 = new FormControl(false);
  mirigynosketto = new FormControl(false);
  ais = new FormControl(false);
  adenocarcinoma = new FormControl(false);
  endocercivalis3 = new FormControl(false);
  extrauterin = new FormControl(false);
  endometrialis2 = new FormControl(false);
  nos = new FormControl(false);
  malignustumor = new FormControl(false);
  malignustumorMegjegyzes = new FormControl('');
  ismetlesklinika = new FormControl(false);
  ismetleskezeles = new FormControl(false);
  lobellenes = new FormControl(false);
  hormonalis = new FormControl(false);
  ismetles = new FormControl(false);
  haromhonapmulva = new FormControl(false);
  hathonapmulva = new FormControl(false);
  hpvtipizalas = new FormControl(false);
  szovettanivizsg = new FormControl(false);
  javaslategyeb = new FormControl(false);
  javaslatEgyebMegjegyzes = new FormControl('');
  pappanicalau = new FormControl('');
  pappanicalauList = [{name: 'p0'}, {name: 'p1'}, {name: 'p2'}, {name: 'p3'}, {name: 'p4'}, {name: 'p5'}];
  bnoList: Array<Bno>;


  cytologyResFormGroup = new FormGroup({
    feldolgozott: this.feldolgozott,
    nemfeldolgozott: this.nemfeldolgozott,
    pap: this.pap,
    lb: this.lb,
    auto: this.auto,
    ertekelhetode: this.ertekelhetode,
    nemertekelhetomert: this.nemertekelhetomert,
    ectfzonahiany: this.ectfzonahiany,
    sejtszegeny: this.sejtszegeny,
    nemazonosithato: this.nemazonosithato,
    zavaromeretuver: this.zavaromeretuver,
    elegtelenfixalas: this.elegtelenfixalas,
    zavaromeretulob: this.zavaromeretulob,
    kenetegyeb: this.kenetegyeb,
    egyebMegjegyzes: this.egyebMegjegyzes,
    kiegeszitotesztek: this.kiegeszitotesztek,
    kiegeszitotesztekMegjegyzes: this.kiegeszitotesztekMegjegyzes,
    negativkorosneoplasticus: this.negativkorosneoplasticus,
    korosneoplasticus: this.korosneoplasticus,
    kenetegyeb2: this.kenetegyeb2,
    kenetEgyebMegjegyzes: this.kenetEgyebMegjegyzes,
    trichomonas: this.trichomonas,
    vegyesbakterium: this.vegyesbakterium,
    actinomyces: this.actinomyces,
    hpv: this.hpv,
    gomba: this.gomba,
    gombaEgyeb: this.gombaEgyeb,
    korokozoEgyeb: this.korokozoEgyeb,
    korokozoEgyebMegjegyzes: this.korokozoEgyebMegjegyzes,
    relativsejtvalasztasokkaljaro: this.relativsejtvalasztasokkaljaro,
    gyulladas: this.gyulladas,
    sugarhatas: this.sugarhatas,
    mechanikushatas: this.mechanikushatas,
    atrophia: this.atrophia,
    negyveneves: this.negyveneves,
    egyebnemneoplaszt: this.egyebnemneoplaszt,
    egyebnemneoplasztMegjegyzes: this.egyebnemneoplasztMegjegyzes,
    asc: this.asc,
    ascus: this.ascus,
    asch: this.asch,
    lsil: this.lsil,
    laphamhpv: this.laphamhpv,
    cinegy: this.cinegy,
    laphamhsil: this.laphamhsil,
    cinketto: this.cinketto,
    cinharom: this.cinharom,
    invasio: this.invasio,
    carcinoma: this.carcinoma,
    agcnos: this.agcnos,
    endocervicalis: this.endocervicalis,
    endometrialis: this.endometrialis,
    mirigynos: this.mirigynos,
    agc: this.agc,
    endocervicalis2: this.endocervicalis2,
    mirigynosketto: this.mirigynosketto,
    ais: this.ais,
    adenocarcinoma: this.adenocarcinoma,
    endocercivalis3: this.endocercivalis3,
    extrauterin: this.extrauterin,
    endometrialis2: this.endometrialis2,
    nos: this.nos,
    malignustumor: this.malignustumor,
    malignustumorMegjegyzes: this.malignustumorMegjegyzes,
    ismetlesklinika: this.ismetlesklinika,
    ismetleskezeles: this.ismetleskezeles,
    lobellenes: this.lobellenes,
    hormonalis: this.hormonalis,
    ismetles: this.ismetles,
    haromhonapmulva: this.haromhonapmulva,
    hathonapmulva: this.hathonapmulva,
    hpvtipizalas: this.hpvtipizalas,
    szovettanivizsg: this.szovettanivizsg,
    javaslategyeb: this.javaslategyeb,
    javaslatEgyebMegjegyzes: this.javaslatEgyebMegjegyzes,
    pappanicalau: this.pappanicalau
  });

  logNumber = new FormControl('');
  bno = new FormControl('');
  eesztBeutaloAzon = new FormControl('');
  beutaloAzon = new FormControl('');
  arrivalTime = new FormControl('');
  responseCreationDate = new FormControl('');
  doctorName = new FormControl('');
  pdfHeader = new FormControl('');
  sendEmailNotificationControl = new FormControl(true);

  cytologyResDataFormGroup = new FormGroup({
    logNumber: this.logNumber,
    bno: this.bno,
    eesztBeutaloAzon: this.eesztBeutaloAzon,
    beutaloAzon: this.beutaloAzon,
    arrivalTime: this.arrivalTime,
    responseCreationDate: this.responseCreationDate,
    doctorName: this.doctorName,
    pdfheaders: this.pdfHeader
  });

  user: GetUserByUserNameResponse;
  currentPatient: GetPatientResponse;
  ebeuLoading = false;
  cases: Array<GetCaseByIdResponse>;

  printablePdf: string;
  pdfHeaders: Array<PdfHeader>;

  isBeutalokeres = false;
  isOldReport = true;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    public generalService: GeneralService,
    private formService: FormService,
    private toasterService: ToastrService,
    public router: Router,
    private sharedDataService: ShareddataService,
    private dialog: MatDialog,
    private messages: Messages
  ) { }

  ngOnInit() {
  // this.getUser();
    this.getCasesByCaseId(+this.route.snapshot.paramMap.get('caseId'));

    this.sharedDataService.currentPatient.subscribe(data => {
      this.currentPatient = data;
    });

    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getPdfHeadersBySpId(this.user.servicePointId);
    this.findAllBno();

    this.formService.xOrCheckboxes(this.feldolgozott, this.nemfeldolgozott);
    this.formService.xOrCheckboxes(this.ertekelhetode, this.nemertekelhetomert);
    this.formService.xOrCheckboxes(this.negativkorosneoplasticus, this.korosneoplasticus);
    this.formService.xOrCheckboxes(this.hathonapmulva, this.haromhonapmulva);
    this.formService.xOr3Checkboxes(this.pap, this.lb, this.auto);
    this.formService.checkBoxWithText(this.kenetegyeb, this.egyebMegjegyzes);
    this.formService.checkBoxWithText(this.kenetegyeb2, this.kenetEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.gomba, this.gombaEgyeb);
    this.formService.checkBoxWithText(this.korokozoEgyeb, this.korokozoEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.egyebnemneoplaszt, this.egyebnemneoplasztMegjegyzes);
    this.formService.checkBoxWithText(this.malignustumor, this.malignustumorMegjegyzes);
    this.formService.checkBoxWithText(this.javaslategyeb, this.javaslatEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.kiegeszitotesztek, this.kiegeszitotesztekMegjegyzes);
    this.formService.checkBase(this.feldolgozott,
      [
        this.pap, this.lb, this.auto
      ]
    );
    this.formService.checkBase(this.ertekelhetode,
      [
       this.ectfzonahiany, this.sejtszegeny,
       this.nemazonosithato, this.zavaromeretulob,
       this.zavaromeretuver, this.elegtelenfixalas,
       this.kenetegyeb, this.kiegeszitotesztek
      ]
    );
    this.formService.checkBase(this.nemertekelhetomert,
      [
       this.ectfzonahiany, this.sejtszegeny,
       this.nemazonosithato, this.zavaromeretulob,
       this.zavaromeretuver, this.elegtelenfixalas,
       this.kenetegyeb, this.kiegeszitotesztek
      ]
    );
    this.formService.checkBase(this.relativsejtvalasztasokkaljaro, [this.gyulladas, this.sugarhatas, this.mechanikushatas]);
    this.formService.checkBase(this.asc, [this.ascus, this.asch]);
    this.formService.checkBase(this.lsil, [this.laphamhpv, this.cinegy]);
    this.formService.checkBase(this.laphamhsil, [this.cinketto, this.cinharom, this.invasio]);
    this.formService.checkBase(this.agcnos, [this.endocervicalis, this.endometrialis, this.mirigynos]);
    this.formService.checkBase(this.agc, [this.endocervicalis2, this.mirigynosketto]);
    this.formService.checkBase(this.adenocarcinoma,
      [this.endocercivalis3, this.extrauterin, this.endometrialis2, this.nos, this.malignustumor]);
    this.formService.checkBase(this.ismetleskezeles, [this.lobellenes, this.hormonalis]);
    this.formService.checkBase(this.ismetles, [this.haromhonapmulva, this.hathonapmulva]);

    if (!JSON.parse(localStorage.getItem('newReport'))) {
      this.cytologyResFormGroup.disable();
      this.cytologyResDataFormGroup.disable();
    }
  }

  saveCytologyRes(isValidated: boolean) {
    let technicalUserId: number;

    this.apiService.getServicePointById(this.user.servicePointId).subscribe(response => {
      technicalUserId = response.technicalUserId;
    }, error => {
      console.error(error);
    });

    if (this.bno.value && this.logNumber.value && this.pdfHeader.value) {
      if (this.logNumber.value.length <= 8) {
        const request: SaveCytologyResRequest = {
          sendEmailNotification: this.sendEmailNotificationControl.value,
          caseId: +this.route.snapshot.paramMap.get('caseId'),
          // creatorUsername: this.authenticationService.getUserByToken(this.authenticationService.getToken()),
          creatorUsername: this.user.username,
          isValidated,
          patientId: +this.route.snapshot.paramMap.get('patientId'),
          servicePointId: this.user.servicePointId,
          beutaloId: this.eesztBeutaloAzon.value,
          customBeutaloId: this.beutaloAzon.value,
          diagnose: this.bno.value,
          logNumber: this.logNumber.value,
          pdfHeaderId: this.pdfHeader.value,
          technicalUserId,
          citologyResXml: {
            pappanicalau: this.pappanicalau.value,
            citologyEgyebNemNeoplasticusXml: {
              atrophia: this.atrophia.value,
              egyeb: this.egyebnemneoplaszt.value,
              egyebMegjegyzes: this.egyebnemneoplasztMegjegyzes.value,
              endometriumSejtek40evesKor: this.negyveneves.value,
              gyulladas: this.gyulladas.value,
              mechanikushatas: this.mechanikushatas.value,
              relativSejtvalasztasokkalJaro: this.relativsejtvalasztasokkaljaro.value,
              sugarhatas: this.sugarhatas.value
            },
            citologyJavaslatXml: {
              egyeb: this.javaslategyeb.value,
              egyebMegjegyzes: this.javaslatEgyebMegjegyzes.value,
              haromHonapMulva: this.haromhonapmulva.value,
              hatHonapMulva: this.hathonapmulva.value,
              hormonalis: this.hormonalis.value,
              hpvTipizalas: this.hpvtipizalas.value,
              ismetles: this.ismetles.value,
              ismetlesKezelesUtan: this.ismetleskezeles.value,
              ismetlesKlinikaiMegitelesSzerint: this.ismetlesklinika.value,
              lobellenes: this.lobellenes.value,
              szovettaniVizsgalat: this.szovettanivizsg.value
            },
            citologyKenetAltalanosMinosegeXml: {
              egyeb: this.kenetegyeb2.value,
              egyebMegjegyzes: this.kenetEgyebMegjegyzes.value,
              koros: this.korosneoplasticus.value,
              negativ: this.negativkorosneoplasticus.value
            },
            citologyKenetErtekelhetosegeXml: {
              auto: this.auto.value,
              ecTfZonaHianyzik: this.ectfzonahiany.value,
              egyeb: this.kenetegyeb.value,
              egyebMegjegyzes: this.egyebMegjegyzes.value,
              elegtelenFixalas: this.elegtelenfixalas.value,
              ertekelheto: this.ertekelhetode.value,
              isFeldolgozott: this.feldolgozott.value,
              kiegeszitoTesztek: this.kiegeszitotesztek.value,
              kiegeszitoTesztekMegjegyzes: this.kiegeszitotesztekMegjegyzes.value,
              lb: this.lb.value,
              nemAzonosithatoKenet: this.nemazonosithato.value,
              pap: this.pap.value,
              sejtszegeny: this.sejtszegeny.value,
              zavaroMeretuLob: this.zavaromeretulob.value,
              zavaroMeretuVer: this.zavaromeretuver.value
            },
            citologyKorokozokXml: {
              egyeb: this.korokozoEgyeb.value,
              egyebMegjegyzes: this.korokozoEgyebMegjegyzes.value,
              gomba: this.gomba.value,
              gombaMegjegyzes: this.gombaEgyeb.value,
              trichomonas: this.trichomonas.value,
              vegyesBakterium: this.vegyesbakterium.value,
              actinomyces: this.actinomyces.value,
              hpv: this.hpv.value
            },
            citologyLaphamsejtekXml: {
              atypusosLaphamsejtek: this.asc.value,
              cin2: this.cinketto.value,
              cin3: this.cinharom.value,
              enyheFokuIntraepithelialisLaphamLaesio: this.lsil.value,
              invasioGyanuja: this.invasio.value,
              laphamsejtCarcinoma: this.carcinoma.value,
              nemMeghatarozottOkbolAscUs: this.ascus.value,
              nemZarhatoKiHsil: this.asch.value,
              cin1: this.cinegy.value,
              hpv: this.laphamhpv.value,
              hsil: this.laphamhsil.value
            },
            citologyMirigyhamsejtekXml: {
              adenocarcinoma: this.adenocarcinoma.value,
              atypEndocervicalisSejtek: this.endocervicalis2.value,
              atypEndometrialisSejtek: this.endometrialis.value,
              atypMirigyhamsejtek: this.mirigynos.value,
              atypusosMirigyhamsejtek: this.agcnos.value,
              egyebMaligunsTumor: this.malignustumor.value,
              egyebMaligunsTumorMegjegyzes: this.malignustumorMegjegyzes.value,
              endocercivalis: this.endocervicalis.value,
              endocervicalisAdenocarcinomaInSitu: this.ais.value,
              endomentrialis: this.endometrialis2.value,
              extrauterin: this.extrauterin.value,
              inkabbNeoplasticusAtypusosMirigyhamsejtek: this.agc.value,
              inkabbNeoplasticusEndocervicalisSejtek: this.endocercivalis3.value,
              inkabbNeoplasticusMirigyhamsejtek: this.mirigynosketto.value,
              nos: this.nos.value
            }
          }
        };

        this.apiService.saveCytologyResRequest(request).subscribe(response => {
          if (this.logNumber.enabled) {
            this.setLogNumber(request.caseId, this.logNumber.value);
          }
          // this.generalService.goToPage('reports');
          const currentUrl = this.route.url;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, (error: Response) => {
          if (error.status === 500) {
            this.toasterService.error(
              this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale], error.status.toString(),
            { positionClass: 'toast-top-center' });
          }
        });
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_LENGTH[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    } else {
      if (!this.logNumber.value) {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      } else if (!this.bno.value) {
        this.toasterService.error(
          this.messages.error.ERROR_BNO_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_HEADER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    }
  }

  getCasesByCaseId(caseId: number) {
    this.cases = [];
    this.getDescription(caseId);
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(element => {
        if (element.type.includes('RES')) {
           if (((this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') && element.isValidated) ||
            (this.user.roleCode !== 'REFERRING_DOCTOR' && this.user.roleCode !== 'REFERRING_ASSISTANT')) {
            this.cases.push(element);
          }
        }
      });

      if (this.cases.length > 1) {
        const cases = this.cases;
        const dialogRef = this.dialog.open(SelectCasesComponent, {
          data: {cases}
        });
        const sub = dialogRef.componentInstance.selectedCase.subscribe((data: number) => {
          this.getCaseById(data);
          dialogRef.close();
        });
      } else {
        if (this.cases !== []) {
          this.getCaseById(this.cases[0]?.id);
        }
      }
    });
  }

  getCaseById(id: number) {
    if (this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') {
      this.cytologyResFormGroup.disable();
      this.cytologyResDataFormGroup.disable();
    }
    this.cases.forEach(element => {
      this.printablePdf = element.pdf;
      if (element.id === id) {
        this.pappanicalau.setValue(element.values['pappanicalau']);
        this.bno.setValue(element.diagnose);
        this.atrophia.setValue(element.values['citologyEgyebNemNeoplasticusXml']['atrophia']);
        this.egyebnemneoplaszt.setValue(element.values['citologyEgyebNemNeoplasticusXml']['egyeb']);
        this.egyebnemneoplasztMegjegyzes.setValue(element.values['citologyEgyebNemNeoplasticusXml']['egyebMegjegyzes']);
        this.negyveneves.setValue(element.values['citologyEgyebNemNeoplasticusXml']['endometriumSejtek40evesKor']);
        this.gyulladas.setValue(element.values['citologyEgyebNemNeoplasticusXml']['gyulladas']);
        this.mechanikushatas.setValue(element.values['citologyEgyebNemNeoplasticusXml']['mechanikushatas']);
        this.relativsejtvalasztasokkaljaro.setValue(element.values['citologyEgyebNemNeoplasticusXml']['relativSejtvalasztasokkalJaro']);
        this.sugarhatas.setValue(element.values['citologyEgyebNemNeoplasticusXml']['sugarhatas']);

        this.javaslategyeb.setValue(element.values['citologyJavaslatXml']['egyeb']);
        this.javaslatEgyebMegjegyzes.setValue(element.values['citologyJavaslatXml']['egyebMegjegyzes']);
        this.haromhonapmulva.setValue(element.values['citologyJavaslatXml']['haromHonapMulva']);
        this.hathonapmulva.setValue(element.values['citologyJavaslatXml']['hatHonapMulva']);
        this.hormonalis.setValue(element.values['citologyJavaslatXml']['hormonalis']);
        this.hpvtipizalas.setValue(element.values['citologyJavaslatXml']['hpvTipizalas']);
        this.ismetles.setValue(element.values['citologyJavaslatXml']['ismetles']);
        this.ismetleskezeles.setValue(element.values['citologyJavaslatXml']['ismetlesKezelesUtan']);
        this.ismetlesklinika.setValue(element.values['citologyJavaslatXml']['ismetlesKlinikaiMegitelesSzerint']);
        this.lobellenes.setValue(element.values['citologyJavaslatXml']['lobellenes']);
        this.szovettanivizsg.setValue(element.values['citologyJavaslatXml']['szovettaniVizsgalat']);

        this.kenetegyeb2.setValue(element.values['citologyKenetAltalanosMinosegeXml']['egyeb']);
        this.kenetEgyebMegjegyzes.setValue(element.values['citologyKenetAltalanosMinosegeXml']['egyebMegjegyzes']);
        this.korosneoplasticus.setValue(element.values['citologyKenetAltalanosMinosegeXml']['koros']);
        this.negativkorosneoplasticus.setValue(element.values['citologyKenetAltalanosMinosegeXml']['negativ']);

        this.auto.setValue(element.values['citologyKenetErtekelhetosegeXml']['auto']);
        this.ectfzonahiany.setValue(element.values['citologyKenetErtekelhetosegeXml']['ecTfZonaHianyzik']);
        this.kenetegyeb.setValue(element.values['citologyKenetErtekelhetosegeXml']['egyeb']);
        this.egyebMegjegyzes.setValue(element.values['citologyKenetErtekelhetosegeXml']['egyebMegjegyzes']);
        this.elegtelenfixalas.setValue(element.values['citologyKenetErtekelhetosegeXml']['elegtelenFixalas']);
        this.ertekelhetode.setValue(element.values['citologyKenetErtekelhetosegeXml']['ertekelheto']);
        this.feldolgozott.setValue(element.values['citologyKenetErtekelhetosegeXml']['isFeldolgozott']);
        this.kiegeszitotesztek.setValue(element.values['citologyKenetErtekelhetosegeXml']['kiegeszitoTesztek']);
        this.kiegeszitotesztekMegjegyzes.setValue(element.values['citologyKenetErtekelhetosegeXml']['kiegeszitoTesztekMegjegyzes']);
        this.lb.setValue(element.values['citologyKenetErtekelhetosegeXml']['lb']);
        this.nemazonosithato.setValue(element.values['citologyKenetErtekelhetosegeXml']['nemAzonosithatoKenet']);
        this.pap.setValue(element.values['citologyKenetErtekelhetosegeXml']['pap']);
        this.sejtszegeny.setValue(element.values['citologyKenetErtekelhetosegeXml']['sejtszegeny']);
        this.zavaromeretulob.setValue(element.values['citologyKenetErtekelhetosegeXml']['zavaroMeretuLob']);
        this.zavaromeretuver.setValue(element.values['citologyKenetErtekelhetosegeXml']['zavaroMeretuVer']);

        this.korokozoEgyeb.setValue(element.values['citologyKorokozokXml']['egyeb']);
        this.korokozoEgyebMegjegyzes.setValue(element.values['citologyKorokozokXml']['egyebMegjegyzes']);
        this.gomba.setValue(element.values['citologyKorokozokXml']['gomba']);
        this.gombaEgyeb.setValue(element.values['citologyKorokozokXml']['gombaMegjegyzes']);
        this.trichomonas.setValue(element.values['citologyKorokozokXml']['trichomonas']);
        this.vegyesbakterium.setValue(element.values['citologyKorokozokXml']['vegyesBakterium']);
        this.actinomyces.setValue(element.values['citologyKorokozokXml']['actinomyces']);
        this.hpv.setValue(element.values['citologyKorokozokXml']['hpv']);

        this.asc.setValue(element.values['citologyLaphamsejtekXml']['atypusosLaphamsejtek']);
        this.cinketto.setValue(element.values['citologyLaphamsejtekXml']['cin2']);
        this.cinharom.setValue(element.values['citologyLaphamsejtekXml']['cin3']);
        this.lsil.setValue(element.values['citologyLaphamsejtekXml']['enyheFokuIntraepithelialisLaphamLaesio']);
        this.invasio.setValue(element.values['citologyLaphamsejtekXml']['invasioGyanuja']);
        this.carcinoma.setValue(element.values['citologyLaphamsejtekXml']['laphamsejtCarcinoma']);
        this.ascus.setValue(element.values['citologyLaphamsejtekXml']['nemMeghatarozottOkbolAscUs']);
        this.asch.setValue(element.values['citologyLaphamsejtekXml']['nemZarhatoKiHsil']);
        this.cinegy.setValue(element.values['citologyLaphamsejtekXml']['cin1']);
        this.laphamhpv.setValue(element.values['citologyLaphamsejtekXml']['hpv']);
        this.laphamhsil.setValue(element.values['citologyLaphamsejtekXml']['hsil']);

        this.adenocarcinoma.setValue(element.values['citologyMirigyhamsejtekXml']['adenocarcinoma']);
        this.endocervicalis2.setValue(element.values['citologyMirigyhamsejtekXml']['atypEndocervicalisSejtek']);
        this.endometrialis.setValue(element.values['citologyMirigyhamsejtekXml']['atypEndometrialisSejtek']);
        this.mirigynos.setValue(element.values['citologyMirigyhamsejtekXml']['atypMirigyhamsejtek']);
        this.agcnos.setValue(element.values['citologyMirigyhamsejtekXml']['atypusosMirigyhamsejtek']);
        this.malignustumor.setValue(element.values['citologyMirigyhamsejtekXml']['egyebMaligunsTumor']);
        this.malignustumorMegjegyzes.setValue(element.values['citologyMirigyhamsejtekXml']['egyebMaligunsTumorMegjegyzes']);
        this.endocervicalis.setValue(element.values['citologyMirigyhamsejtekXml']['endocercivalis']);
        this.ais.setValue(element.values['citologyMirigyhamsejtekXml']['endocervicalisAdenocarcinomaInSitu']);
        this.endometrialis2.setValue(element.values['citologyMirigyhamsejtekXml']['endomentrialis']);
        this.extrauterin.setValue(element.values['citologyMirigyhamsejtekXml']['extrauterin']);
        this.agc.setValue(element.values['citologyMirigyhamsejtekXml']['inkabbNeoplasticusAtypusosMirigyhamsejtek']);
        this.endocercivalis3.setValue(element.values['citologyMirigyhamsejtekXml']['inkabbNeoplasticusEndocervicalisSejtek']);
        this.mirigynosketto.setValue(element.values['citologyMirigyhamsejtekXml']['inkabbNeoplasticusMirigyhamsejtek']);
        this.nos.setValue(element.values['citologyMirigyhamsejtekXml']['nos']);
      }
      this.getDescription(element.caseId);
    });
  }

  updateStatus() {
    this.apiService.updateStatus(+this.route.snapshot.paramMap.get('caseId'), 'kész').subscribe(response => {
      this.generalService.goToPage('reports');
    }, (error: Response) => {
      console.error(error);
    });
  }

  deleteCase() {
    const dialogRef = this.dialog.open(ResetCaseComponent);
    dialogRef.componentInstance.option.subscribe(response => {
      this.cytologyResFormGroup.reset();
      dialogRef.close();
    });
  }

  getDescription(id: number) {
    const currentDate = this.generalService.getCurrentDate();
    this.apiService.getDescription(id).subscribe(response => {
      if (response.logNumber) {
        this.logNumber.disable();
        this.logNumber.setValue(response.logNumber);
      }
      if (response.customBeutaloId) {
        this.beutaloAzon.disable();
        this.beutaloAzon.setValue(response.customBeutaloId);
      }
      if (response.arrivalTime) {
        response.arrivalTime = this.sharedDataService.
        dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(response.arrivalTime)));
        this.arrivalTime.disable();
        this.arrivalTime.setValue(response.arrivalTime.replace('T', ' '));
      }
      this.responseCreationDate.setValue(currentDate.substr(0, currentDate.indexOf('.')).replace('T', ' '));
      this.responseCreationDate.disable();
      this.doctorName.setValue(response.doctorName);
      this.doctorName.disable();
    }, (error: Response) => {
      console.error(error);
    });
  }

  setLogNumber(caseId: number, logNumber: string) {
    const request: SetLogNumberRequest = {
      caseId,
      logNumber
    };

    this.apiService.setLogNumber(request).subscribe(response => {
      // this.generalService.goToPage('reports');
    }, (error: Response) => {
      console.error(error);
    });
  }

  checkEszigTokenValid() {
    if (JSON.parse(localStorage.getItem('eszigTokenValid')))  {
      const dialogRef = this.dialog.open(SelectEbeuComponent);
      dialogRef.componentInstance.selectedEbeu.subscribe(ebeu => {
        this.setEbeuAzon(ebeu);
        dialogRef.close();
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_EESZT_AUTH_EXPIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'}
        );
    }
  }

  setEbeuAzon(ebeu: Ebeu) {
    this.eesztBeutaloAzon.setValue(ebeu.businessContent.beutaloAzonosito);
    this.eesztBeutaloAzon.disable();
  }

  getPdfHeadersBySpId(spId: number) {
    this.apiService.getPdfHeadersBySpId(spId).subscribe(response => {
     this.pdfHeaders = response;
     if (response.length === 1) {
       this.pdfHeader.setValue(response[0].id);
     }
    }, (error: Response) => {
      console.error(error);
    });
  }

  findAllBno() {
    this.apiService.findAllBno().subscribe(response => {
      this.bnoList = response;
    }, (error: Response) => {
      console.error(error);
    });
  }
}
