// tslint:disable: deprecation
import { HttpErrorResponse } from '@angular/common/http';
import { SelectPatientComponent } from './../select-patient/select-patient.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GetPatientResponse } from './../../../shared/models/response/getPatientResponse';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { SavePatientRequest } from './../../../shared/models/request/savePatientRequest';
import { GetPatientRequest } from './../../../shared/models/request/getPatientRequest';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { ApiService } from './../../../core/http/api.service';
import { GeneralService } from './../../../core/services/general.service';
import { Component, OnInit, OnDestroy, Injectable, Output, EventEmitter } from '@angular/core';
import { UpdatePatientRequest } from 'src/app/shared/models/request/updatePatientRequest';
import { Messages } from 'src/app/shared/locale/messages';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  userRole: string;
  newPatient = false;
  canModifyPatient = false;
  patientModificationStarted = false;
  validSsn: boolean;
  patientCreated: boolean;
  patientId: number;
  @Output() redirect: EventEmitter<any> = new EventEmitter();
  @Output() currentPatientEmitter: EventEmitter<any> =  new EventEmitter();

  ssn = new FormControl('', [this.TAJvalidator]);
  birthDate = new FormControl('');

  name = new FormControl({value: '', disabled: true});
  birthName = new FormControl({value: '', disabled: true});
  mothersName = new FormControl({value: '', disabled: true});
  address = new FormControl({value: '', disabled: true});

  patientFormGroup = new FormGroup({
    ssn: this.ssn,
    birthDate: this.birthDate,
    name: this.name,
    birthName: this.birthName,
    mothersName: this.mothersName,
    address: this.address
  });

  tokenPatientData: {
    address: string
    birthDate: string
    birthName: string
    mothersName: string
    name: string,
    ssn: string
  };

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public generalService: GeneralService,
    private toasterService: ToastrService,
    private authService: AuthenticationService,
    private sharedDataService: ShareddataService,
    public messages: Messages
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.ssn.valueChanges.subscribe(data => {
      if (data) {
          this.validSsn = this.ssn.valid;
      }
    });

    if (+this.route.snapshot.paramMap.get('patientId')) {
      this.getPatientById(+this.route.snapshot.paramMap.get('patientId'));
    } else {
      this.route.queryParams.subscribe(params => {
        if (params.token) {
          this.tokenPatientData = this.authService.getAllDataByToken(params.token)['patient'];
          const birthDate = new Date(this.tokenPatientData.birthDate).toISOString().substr(0, new Date(this.tokenPatientData.birthDate).toISOString().indexOf('T'));
          this.ssn.setValue(this.tokenPatientData.ssn);
          this.birthDate.setValue(birthDate);
          this.getPatientBy2T(this.tokenPatientData.ssn, birthDate);
        }
      });
    }
  }

  getPatientBy2T(ssn: string, birthDate: string) {
    const request: GetPatientRequest = {
      ssn : ssn.replace(/-/g, ''),
      birthDate
    };

    this.apiService.getPatientBy2T(request).subscribe(response => {
      response.forEach(element => {
        element.birthDate = this.sharedDataService.
          dateToDateString(new Date(this.sharedDataService.DateStringConvert(element.birthDate)));
      });

      const dialogRef = this.dialog.open(SelectPatientComponent, {
        data: response,
        width: '80%'
      });
      const sub = dialogRef.componentInstance.selectedPatient.subscribe((data: GetPatientResponse) => {
        if (data) {
          this.canModifyPatient = true;
          this.newPatient = false;
          this.patientId = data.id;
          this.name.setValue(data.name);
          this.name.disable();
          this.birthName.setValue(data.birthName);
          this.birthName.disable();
          this.mothersName.setValue(data.mothersName);
          this.mothersName.disable();
          this.address.setValue(data.address);
          this.address.disable();
        } else {
          this.newPatient = true;
          this.name.reset();
          this.birthName.reset();
          this.mothersName.reset();
          this.address.reset();
          this.name.enable();
          this.birthName.enable();
          this.mothersName.enable();
          this.address.enable();
        }
        this.sharedDataService.setCurrentPatient(data);
        this.currentPatientEmitter.emit(data);
        dialogRef.close();
      });
    }, (error: Response) => {
      if (error.status === 404) {
        this.toasterService.info(
          this.messages.info.INFO_NO_PATIENT_FOUND[this.messages.locale], '', {positionClass: 'toast-top-center'});
        this.route.queryParams.subscribe(params => {
          if (params) {
            this.newPatient = true;
            this.name.enable();
            this.birthName.enable();
            this.mothersName.enable();
            this.address.enable();
            this.name.setValue(this.tokenPatientData.name);
            this.birthName.setValue(this.tokenPatientData.birthName);
            this.mothersName.setValue(this.tokenPatientData.mothersName);
            this.address.setValue(this.tokenPatientData.address);
            this.sharedDataService.setCurrentPatient(null);
          } else {
            this.newPatient = true;
            this.name.reset();
            this.birthName.reset();
            this.mothersName.reset();
            this.address.reset();
            this.name.enable();
            this.birthName.enable();
            this.mothersName.enable();
            this.address.enable();
            this.sharedDataService.setCurrentPatient(null);
          }
        });
      }
      console.error(error);
    });
  }

getPatientById(patientId: number) {
      if (patientId) {
        this.apiService.getPatientById(patientId).subscribe(response => {
          this.name.setValue(response.name);
          this.birthName.setValue(response.birthName);
          this.mothersName.setValue(response.mothersName);
          this.address.setValue(response.address);
          this.ssn.setValue(response.ssn);
          this.birthDate.setValue(this.sharedDataService.
            dateToDateString(new Date(this.sharedDataService.DateStringConvert(response.birthDate))));
          if (this.router.url.includes('szov') || this.router.url.includes('cyt')) {
            this.patientFormGroup.disable();
          }
          this.sharedDataService.setCurrentPatient(response);
          this.currentPatientEmitter.emit(response);
        }, (error: Response) => {
          console.error(error);
        });
      }
  }

savePatient() {

    if (this.ssn.valid) {
      if (this.name.value && this.birthName.value && this.mothersName.value && this.address.value) {
        const request: SavePatientRequest = {
          address: this.address.value,
          birthDate: this.birthDate.value,
          birthName: this.birthName.value,
          mothersName: this.mothersName.value,
          name: this.name.value,
          ssn: this.ssn.value.replace(/-/g, '')
        };
        this.apiService.savePatient(request).subscribe(response => {
          this.toasterService.success(
            this.messages.success.SUCCESS_SAVE_PATIENT[this.messages.locale],
            this.messages.success.SUCCESS_TITLE[this.messages.locale],
            {positionClass: 'toast-top-center'}
            );
          this.newPatient = false;
          this.address.disable();
          this.birthName.disable();
          this.mothersName.disable();
          this.name.disable();
          // tslint:disable-next-line: max-line-length
          this.getPatientById(+response.headers.get('location').substr(response.headers.get('location').lastIndexOf('/') + 1, response.headers.get('location').length));
        }, (error: Response) => {
          // console.log(error);
          this.patientCreated = false;
        });
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_MISSING_SAVE_PATIENT_DATA[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
          );
      }
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_WRONG_SSN[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
         {positionClass: 'toast-top-center'}
         );
    }

  }

  startModifyPatient() {
    this.name.enable();
    this.birthName.enable();
    this.mothersName.enable();
    this.address.enable();
    this.patientModificationStarted = true;
    this.canModifyPatient = false;
    this.sharedDataService.setCurrentPatient(null);
  }

  updatePatient() {
    let patientData: GetPatientResponse;
    const request: UpdatePatientRequest = {
      address: this.address.value,
      birthDate: this.birthDate.value,
      birthName: this.birthName.value,
      mothersName: this.mothersName.value,
      name: this.name.value,
      ssn: this.ssn.value.replace(/-/g, '')
    };

    if (this.ssn.valid) {
      this.apiService.updatePatient(this.patientId, request).subscribe(response => {
        this.patientModificationStarted = false;
        this.canModifyPatient = true;
        this.address.disable();
        this.birthName.disable();
        this.mothersName.disable();
        this.name.disable();
        this.toasterService.success(
          this.messages.success.SUCCESS_UPDATE_PATIENT[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale],
          {positionClass: 'toast-top-center'}
          );
        patientData = {
          address: this.address.value,
          birthDate: this.birthDate.value,
          birthName: this.birthName.value,
          mothersName: this.mothersName.value,
          name: this.name.value,
          ssn:  this.ssn.value.replace(/-/g, ''),
          id: this.patientId
        };
        this.sharedDataService.setCurrentPatient(patientData);
        this.currentPatientEmitter.emit(patientData);
      }, (error: HttpErrorResponse) => {
        this.patientModificationStarted = false;
        this.canModifyPatient = false;
        this.getPatientById(this.patientId);
        this.sharedDataService.setCurrentPatient(null);
        this.toasterService.error(
          error.error.message,
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          {positionClass: 'toast-top-center'}
        );
      });
    } else {
      this.toasterService.error(
      this.messages.error.ERROR_WRONG_SSN[this.messages.locale],
      this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
       {positionClass: 'toast-top-center'}
       );
    }
  }

TAJvalidator(control: AbstractControl): {
    [key: string]: boolean
    } | null {
        if (control.value !== undefined && control.value !== null) {
            let value = control.value.replace(/ /g, '');
            value = value.replace(/-/g, '');
            if (value.length !== 9 || value === '000000000') {
                return {validTAJ: false};
            } else {
                let sum = 0;
                for (let i = 7; i > -1; i--) {
                    sum += ((i % 2 === 0) ? value[i] * 3 : value[i] * 7);
                }
                if (Number(value[8]) === sum % 10) {
                    return null;
                }
            }
        }
        return {validTAJ: false};
    }
}
