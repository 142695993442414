import { HttpErrorResponse } from '@angular/common/http';
// tslint:disable: deprecation
// tslint:disable: max-line-length
import { Bno } from './../../../shared/models/bno';
import { ValidatorUser } from './../../../shared/models/validatorUser';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { SaveCytologyResV2Request } from './../../../shared/models/request/saveCytologyResV2Request';
import { ApiService } from './../../../core/http/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from './../../../core/services/general.service';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PdfHeader } from 'src/app/shared/models/pdfHeader';
import { MatDialog } from '@angular/material/dialog';
import { SelectEbeuComponent } from '../../components/select-ebeu/select-ebeu.component';
import { Ebeu } from 'src/app/shared/models/ebeu';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ResetCaseComponent } from '../../components/reset-case/reset-case.component';
import { SetLogNumberRequest } from 'src/app/shared/models/request/setLogNumberRequest';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { SelectCasesComponent } from '../../components/select-cases/select-cases.component';
import { FormService } from 'src/app/core/services/form.service';
import { User } from 'src/app/shared/models/User';
import { Messages } from 'src/app/shared/locale/messages';
import { SzakmaKod } from 'src/app/shared/models/szakmakod';
import * as _ from 'lodash';

@Component({
  selector: 'app-cytologicalreportv2',
  templateUrl: './cytologicalreportv2.component.html',
  styleUrls: ['./cytologicalreportv2.component.css']
})
export class Cytologicalreportv2Component implements OnInit {

  currentYear = new Date().toISOString().substr(0, 4);

  hpv = new FormControl(false);
  isFeldolgozott = new FormControl(false);
  lbc = new FormControl(false);
  nemFeldolgozott = new FormControl(false);
  pap = new FormControl(false);
  asc = new FormControl(false);
  cinegy = new FormControl(false);
  cinketto = new FormControl(false);
  cinharom = new FormControl(false);
  lsil = new FormControl(false);
  laphamhpv = new FormControl(false);
  laphamhsil = new FormControl(false);
  invasio = new FormControl(false);
  carcinoma = new FormControl(false);
  ascus = new FormControl(false);
  asch = new FormControl(false);
  adenocarcinoma = new FormControl(false);
  endocercivalis3 = new FormControl(false);
  endometrialis2 = new FormControl(false);
  agc = new FormControl(false);
  agcnos = new FormControl(false);
  malignustumor = new FormControl(false);
  malignustumorMegjegyzes = new FormControl('');
  endocervicalis = new FormControl(false);
  ais = new FormControl(false);
  endometrialis = new FormControl(false);
  extrauterin = new FormControl(false);
  mirigynosketto = new FormControl(false);
  endocervicalis2 = new FormControl(false);
  mirigynos = new FormControl(false);
  nos = new FormControl(false);
  cintecPlusz = new FormControl(false);
  javaslategyeb = new FormControl(false);
  javaslatEgyebMegjegyzes = new FormControl('');
  haromHonapMulva = new FormControl(false);
  hatHonapMulva = new FormControl(false);
  hormonalis = new FormControl(false);
  hpvTipizalas = new FormControl(false);
  ismeteltCytEsKolp = new FormControl(false);
  ismetles = new FormControl(false);
  ismetlesKezelesUtan = new FormControl(false);
  ismetlesKlinikaiMegitelesSzerint = new FormControl(false);
  lobellenes = new FormControl(false);
  szovettaniVizsgalat = new FormControl(false);
  egyebElvaltozasok = new FormControl(false);
  egyebElvaltozasokMegjegyzes = new FormControl('');
  korosHamelvaltozasok = new FormControl(false);
  negativKenet = new FormControl(false);
  cytolysisAutolysis = new FormControl(false);
  elegtelenulFixalt = new FormControl(false);
  endocervAtmZonaHianyzik = new FormControl(false);
  ertekelhetetlen = new FormControl(false);
  kenetErtekeleseEgyebMegjegyzes = new FormControl('');
  kenetErtekeleseEgyeb = new FormControl(false);
  klinikaiAdatokHianyoznak = new FormControl(false);
  lemezNemAzonosithato = new FormControl(false);
  megfelelo = new FormControl(false);
  sejtszegeny = new FormControl(false);
  technikaiProblema = new FormControl(false);
  zavaroGyulladas = new FormControl(false);
  zavaroMeretuNyak = new FormControl(false);
  zavaroMeretuVer = new FormControl(false);
  cintecPluszNegativ = new FormControl(false);
  cintecPluszPozitiv = new FormControl(false);
  egyebHrHpv = new FormControl(false);
  hrHpvTipizalasNegativ = new FormControl(false);
  hrHpvTipizalasTizenhat = new FormControl(false);
  hrHpvTipizalasTizennyolc = new FormControl(false);
  kiegVizsMegjegyzes = new FormControl('');
  actinomyces = new FormControl(false);
  bakteriumEgyebMegjegyzes = new FormControl('');
  bakteriumEgyeb = new FormControl(false);
  candida = new FormControl(false);
  gombaEgyeb = new FormControl(false);
  gombaEgyebMegjegyzes = new FormControl('');
  herpesFertozesGyanuja = new FormControl(false);
  korosFlora = new FormControl(false);
  protozoonEgyebMegjegyzes = new FormControl('');
  protozoonEgyeb = new FormControl(false);
  trichomonasvaginalis = new FormControl(false);
  virusEgyeb = new FormControl(false);
  virusEgyebMegjegyzes = new FormControl('');
  atrophiaGyulladas = new FormControl(false);
  endometriumSejtek = new FormControl(false);
  gyulladasReaktivHamelvaltozassal = new FormControl(false);
  metaplasiaiRegeneratio = new FormControl(false);
  reaktivEgyebMegjegyzes = new FormControl('');
  reaktivEgyeb = new FormControl(false);
  sugarhatas = new FormControl(false);
  terhesseghezTartozoJelek = new FormControl(false);
  cb = new FormControl(false);
  mirigyhamsejtekHiszterektomiaUtan = new FormControl(false);

  cytologyResFormGroup = new FormGroup({
    hpv: this.hpv,
    isFeldolgozott: this.isFeldolgozott,
    lbc: this.lbc,
    nemFeldolgozott: this.nemFeldolgozott,
    pap: this.pap,
    asc: this.asc,
    cinegy: this.cinegy,
    cinketto: this.cinketto,
    cinharom: this.cinharom,
    lsil: this.lsil,
    laphamhpv: this.laphamhpv,
    laphamhsil: this.laphamhsil,
    invasio: this.invasio,
    carcinoma: this.carcinoma,
    ascus: this.ascus,
    asch: this.asch,
    adenocarcinoma: this.adenocarcinoma,
    endocercivalis3: this.endocercivalis3,
    endometrialis2: this.endometrialis2,
    agc: this.agc,
    agcnos: this.agcnos,
    malignustumor: this.malignustumor,
    malignustumorMegjegyzes: this.malignustumorMegjegyzes,
    endocervicalis: this.endocervicalis,
    ais: this.ais,
    endometrialis: this.endometrialis,
    extrauterin: this.extrauterin,
    mirigynosketto: this.mirigynosketto,
    endocervicalis2: this.endocervicalis2,
    mirigynos: this.mirigynos,
    nos: this.nos,
    cintecPlusz: this.cintecPlusz,
    javaslategyeb: this.javaslategyeb,
    javaslatEgyebMegjegyzes: this.javaslatEgyebMegjegyzes,
    haromHonapMulva: this.haromHonapMulva,
    hatHonapMulva: this.hatHonapMulva,
    hormonalis: this.hormonalis,
    hpvTipizalas: this.hpvTipizalas,
    ismeteltCytEsKolp: this.ismeteltCytEsKolp,
    ismetles: this.ismetles,
    ismetlesKezelesUtan: this.ismetlesKezelesUtan,
    ismetlesKlinikaiMegitelesSzerint: this.ismetlesKlinikaiMegitelesSzerint,
    lobellenes: this.lobellenes,
    szovettaniVizsgalat: this.szovettaniVizsgalat,
    egyebElvaltozasok: this.egyebElvaltozasok,
    egyebElvaltozasokMegjegyzes: this.egyebElvaltozasokMegjegyzes,
    korosHamelvaltozasok: this.korosHamelvaltozasok,
    negativKenet: this.negativKenet,
    cytolysisAutolysis: this.cytolysisAutolysis,
    elegtelenulFixalt: this.elegtelenulFixalt,
    endocervAtmZonaHianyzik: this.endocervAtmZonaHianyzik,
    ertekelhetetlen: this.ertekelhetetlen,
    kenetErtekeleseEgyebMegjegyzes: this.kenetErtekeleseEgyebMegjegyzes,
    kenetErtekeleseEgyeb: this.kenetErtekeleseEgyeb,
    klinikaiAdatokHianyoznak: this.klinikaiAdatokHianyoznak,
    lemezNemAzonosithato: this.lemezNemAzonosithato,
    megfelelo: this.megfelelo,
    sejtszegeny: this.sejtszegeny,
    technikaiProblema: this.technikaiProblema,
    zavaroGyulladas: this.zavaroGyulladas,
    zavaroMeretuNyak: this.zavaroMeretuNyak,
    zavaroMeretuVer: this.zavaroMeretuVer,
    cintecPluszNegativ: this.cintecPluszNegativ,
    cintecPluszPozitiv: this.cintecPluszPozitiv,
    egyebHrHpv: this.egyebHrHpv,
    hrHpvTipizalasNegativ: this.hrHpvTipizalasNegativ,
    hrHpvTipizalasTizenhat: this.hrHpvTipizalasTizenhat,
    hrHpvTipizalasTizennyolc: this.hrHpvTipizalasTizennyolc,
    kiegVizsMegjegyzes: this.kiegVizsMegjegyzes,
    actinomyces: this.actinomyces,
    bakteriumEgyebMegjegyzes: this.bakteriumEgyebMegjegyzes,
    bakteriumEgyeb: this.bakteriumEgyeb,
    candida: this.candida,
    gombaEgyeb: this.gombaEgyeb,
    gombaEgyebMegjegyzes: this.gombaEgyebMegjegyzes,
    herpesFertozesGyanuja: this.herpesFertozesGyanuja,
    korosFlora: this.korosFlora,
    protozoonEgyebMegjegyzes: this.protozoonEgyebMegjegyzes,
    protozoonEgyeb: this.protozoonEgyeb,
    trichomonasvaginalis: this.trichomonasvaginalis,
    virusEgyeb: this.virusEgyeb,
    virusEgyebMegjegyzes: this.virusEgyebMegjegyzes,
    atrophiaGyulladas: this.atrophiaGyulladas,
    sugarhatas: this.sugarhatas,
    endometriumSejtek: this.endometriumSejtek,
    gyulladasReaktivHamelvaltozassal: this.gyulladasReaktivHamelvaltozassal,
    metaplasiaiRegeneratio: this.metaplasiaiRegeneratio,
    reaktivEgyebMegjegyzes: this.reaktivEgyebMegjegyzes,
    reaktivEgyeb: this.reaktivEgyeb,
    terhesseghezTartozoJelek: this.terhesseghezTartozoJelek,
    cbPlusz: this.cb,
    mirigyhamsejtekHiszterektomiaUtan: this.mirigyhamsejtekHiszterektomiaUtan
  });

  logPrefix: number;
  logNumber = new FormControl('');
  bno = new FormControl('');
  eesztBeutaloAzon = new FormControl('');
  beutaloAzon = new FormControl('');
  arrivalTime = new FormControl('');
  responseCreationDate = new FormControl('');
  doctorName = new FormControl('');
  preScreeningSpecialistControl = new FormControl('');
  validatorUserControl = new FormControl('');
  sendEmailNotificationControl = new FormControl(true);
  pdfHeader = new FormControl('');
  szakmakodControl = new FormControl(null);

  cytologyResDataFormGroup = new FormGroup({
    logNumber: this.logNumber,
    bno: this.bno,
    eesztBeutaloAzon: this.eesztBeutaloAzon,
    beutaloAzon: this.beutaloAzon,
    arrivalTime: this.arrivalTime,
    responseCreationDate: this.responseCreationDate,
    doctorName: this.doctorName,
    pdfheaders: this.pdfHeader,
    preScreeningSpecialist: this.preScreeningSpecialistControl
  });

  cases: Array<GetCaseByIdResponse>;

  printablePdf: string;
  pdfHeaders: Array<PdfHeader>;

  user: GetUserByUserNameResponse;
  preScreeningSpecialists = new Array<User>();
  validatorUsers: Array<ValidatorUser>;
  bnoList: Array<Bno>;

  chooseOptions: Array<object>;
  hideFungiOptions: boolean;
  hideTechnicalOptions: boolean;
  hideBacteriaOptions: boolean;
  hideVirusOptions: boolean;
  hideReactiveOptions: boolean;

  szakmakodok: Array<SzakmaKod>;

  constructor(
    private dialog: MatDialog,
    private toasterService: ToastrService,
    public generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private sharedDataService: ShareddataService,
    private formService: FormService,
    private messages: Messages
    ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getCasesByCaseId(+this.route.snapshot.paramMap.get('caseId'));
    this.findAllValidatorUser(this.user.servicePointId);
    this.findAllBno();
    this.findAllSzakmakod();

    // this.sharedDataService.currentPatient.subscribe(data => {
    //   this.currentPatient = data;
    // });
    this.getPdfHeadersBySpId(this.user.servicePointId);
    this.getUsersOfInstitute(this.user.servicePointId);

    this.formService.xOrCheckboxes(this.isFeldolgozott, this.nemFeldolgozott);
    this.formService.xOrCheckboxes(this.megfelelo, this.ertekelhetetlen);
    this.formService.xOrCheckboxes(this.haromHonapMulva, this.hatHonapMulva);
    this.formService.checkBase(this.technikaiProblema,
      [this.sejtszegeny, this.zavaroGyulladas,
      this.elegtelenulFixalt, this.zavaroMeretuVer,
      this.cytolysisAutolysis, this.zavaroMeretuNyak, this.kenetErtekeleseEgyeb]);
    this.formService.checkBoxWithText(this.kenetErtekeleseEgyeb, this.kenetErtekeleseEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.egyebElvaltozasok, this.egyebElvaltozasokMegjegyzes);
    this.formService.checkBoxWithText(this.gombaEgyeb, this.gombaEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.bakteriumEgyeb, this.bakteriumEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.protozoonEgyeb, this.protozoonEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.virusEgyeb, this.virusEgyebMegjegyzes);
    this.formService.checkBoxWithText(this.reaktivEgyeb, this.reaktivEgyebMegjegyzes);
    this.formService.checkBase(this.asc, [this.ascus, this.asch]);
    this.formService.checkBase(this.lsil, [this.laphamhpv, this.cinegy]);
    this.formService.checkBase(this.laphamhsil, [this.cinketto, this.cinharom, this.invasio]);
    this.formService.checkBase(this.agcnos, [this.endocervicalis, this.endometrialis, this.mirigynos]);
    this.formService.checkBase(this.agc, [this.endocervicalis2, this.mirigynosketto]);
    this.formService.checkBase(this.adenocarcinoma,
      [this.endocercivalis3, this.extrauterin, this.endometrialis2, this.nos, this.malignustumor]);
    this.formService.checkBoxWithText(this.malignustumor, this.malignustumorMegjegyzes);
    this.formService.xOrCheckboxes(this.cintecPluszNegativ, this.cintecPluszPozitiv);
    this.formService.checkBase(this.ismetlesKezelesUtan, [this.lobellenes, this.hormonalis]);
    this.formService.checkBase(this.ismetles, [this.haromHonapMulva, this.hatHonapMulva]);
    this.formService.checkBoxWithText(this.javaslategyeb, this.javaslatEgyebMegjegyzes);
    this.formService.xOrCheckboxes(this.negativKenet, this.korosHamelvaltozasok);
  }

  findAllSzakmakod() {
    this.apiService.getAllSzakmaKod().subscribe(response => {
      this.szakmakodok = response;
      const szakmakod = response.find(x => x.code === '5402');
      this.szakmakodControl.setValue(szakmakod.id);
    });
  }

  findAllValidatorUser(servicePointId: number) {
    this.apiService.getAllValidatorUsersBySp(servicePointId).subscribe(response => {
      this.validatorUsers = response;
      if (response.length === 1) {
        this.validatorUserControl.setValue(response[0].id);
      }
    }, (error: Response) => {
      console.error(error);
    });

    this.kenetErtekeleseEgyebMegjegyzes.valueChanges.subscribe(value => {
      if (value?.length >= 1) {
        this.hideTechnicalOptions = false;
        this.filterOtherTechnicalIssues(value);
      } else {
        this.hideTechnicalOptions = true;
      }
    });
    this.gombaEgyebMegjegyzes.valueChanges.subscribe(value => {
      if (value?.length >= 1 ) {
        this.hideFungiOptions = false;
        this.filterFungiOptions(value);
      } else {
        this.hideFungiOptions = true;
      }
    });

    this.bakteriumEgyebMegjegyzes.valueChanges.subscribe(value => {
      if (value?.length >= 1 ) {
        this.hideBacteriaOptions = false;
        this.filterBacteria(value);
      } else {
        this.hideBacteriaOptions = true;
      }
    });
    this.virusEgyebMegjegyzes.valueChanges.subscribe(value => {
      if (value?.length >= 1 ) {
        this.hideVirusOptions = false;
        this.filterVirus(value);
      } else {
        this.hideVirusOptions = true;
      }
    });
    this.reaktivEgyebMegjegyzes.valueChanges.subscribe(value => {
      if (value?.length >= 1 ) {
        this.hideReactiveOptions = false;
        this.filterReactivAndReparative(value);
      } else {
        this.hideReactiveOptions = true;
      }
    });
  }

  filterOptions(options, value) {
    let otherTechnicalIssues = options;
    if (value) {
      otherTechnicalIssues = otherTechnicalIssues.filter(x => x.value.toLowerCase().includes(value.toLowerCase()));
    } else {
      otherTechnicalIssues = options;
    }
    this.chooseOptions = otherTechnicalIssues;
  }

  filterOtherTechnicalIssues(value) {
    const options = [
      {value: 'véres kenet'},
      {value: 'vastag kenet'}
    ];
    this.filterOptions(options, value);
  }

  filterFungiOptions(value) {
    const options = [
      {value: 'candide inf. gyanú'},
      {value: 'gombaspóra'}
    ];

    this.filterOptions(options, value);
  }

  filterBacteria(value) {
    const options = [
      {value: 'vegyes baktérium'},
      {value: 'leptothrix'}
    ];

    this.filterOptions(options, value);
  }

  filterVirus(value) {
    const options = [
      {value: 'hpv gyanú'}
    ];

    this.filterOptions(options, value);
  }

  filterReactivAndReparative(value) {
    const options = [
      {value: 'laktációs sejtkép'},
      {value: 'hypohormonális'},
      {value: 'parakeratosis'},
      {value: 'atrophia'},
      {value: 'cervicitis'}
    ];

    this.filterOptions(options, value);
  }

  findAllBno() {
    this.apiService.findAllBno().subscribe(response => {
      this.bnoList = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  getUsersOfInstitute(servicePointId: number) {
    const preScreeningSpecialists = new Array<User>();
    this.apiService.getUsersByRefId(servicePointId).subscribe(response => {
      response.forEach(user => {
        if (user.role.code !== 'ADMIN' && user.role.code !== 'REFERRING_ASSISTANT' && user.role.code !== 'REFERRING_DOCTOR') {
          preScreeningSpecialists.push(user);
        }
      });
      this.preScreeningSpecialists = preScreeningSpecialists;
    }, (error: Response) => {
      console.error(error);
    });
  }

  saveCytologyRes(isValidated: boolean) {
    let technicalUserId: number;

    this.apiService.getServicePointById(this.user.servicePointId).subscribe(response => {
      technicalUserId = response.technicalUserId;
    }, error => {
      console.error(error);
    });


    if (this.bno.value && this.logNumber.value && this.pdfHeader.value && this.preScreeningSpecialistControl.value && this.validatorUserControl.value && this.szakmakodControl.value) {
      if (this.logNumber.value.length <= 8) {
        const request: SaveCytologyResV2Request = {
          szakmakodId: this.szakmakodControl.value,
          sendEmailNotification: this.sendEmailNotificationControl.value,
          validatorUserId: this.validatorUserControl.value,
          caseId: +this.route.snapshot.paramMap.get('caseId'),
          creatorUsername: this.user.username,
          preScreeningSpecialistId: this.preScreeningSpecialistControl.value,
          isValidated,
          patientId: +this.route.snapshot.paramMap.get('patientId'),
          servicePointId: this.user.servicePointId,
          beutaloId: this.eesztBeutaloAzon.value,
          customBeutaloId: this.beutaloAzon.value,
          diagnose: this.bno.value,
          logNumber: this.logNumber.value,
          pdfHeaderId: this.pdfHeader.value,
          citologyResXmlV2: {
            citologyHamelvaltXml: {
              citologyLaphamsejtekXml: {
                atypusosLaphamsejtek: this.asc.value,
                cin2: this.cinketto.value,
                cin3: this.cinharom.value,
                enyheFokuIntraepithelialisLaphamLaesio: this.lsil.value,
                invasioGyanuja: this.invasio.value,
                laphamsejtCarcinoma: this.carcinoma.value,
                nemMeghatarozottOkbolAscUs: this.ascus.value,
                nemZarhatoKiHsil: this.asch.value,
                cin1: this.cinegy.value,
                hpv: this.laphamhpv.value,
                hsil: this.laphamhsil.value
              },
              citologyMirigyhamsejtekXml: {
                adenocarcinoma: this.adenocarcinoma.value,
                atypEndocervicalisSejtek: this.endocervicalis2.value,
                atypEndometrialisSejtek: this.endometrialis.value,
                atypMirigyhamsejtek: this.mirigynos.value,
                atypusosMirigyhamsejtek: this.agcnos.value,
                egyebMaligunsTumor: this.malignustumor.value,
                egyebMaligunsTumorMegjegyzes: this.malignustumorMegjegyzes.value,
                endocercivalis: this.endocervicalis.value,
                endocervicalisAdenocarcinomaInSitu: this.ais.value,
                endomentrialis: this.endometrialis2.value,
                extrauterin: this.extrauterin.value,
                inkabbNeoplasticusAtypusosMirigyhamsejtek: this.agc.value,
                inkabbNeoplasticusEndocervicalisSejtek: this.endocercivalis3.value,
                inkabbNeoplasticusMirigyhamsejtek: this.mirigynosketto.value,
                nos: this.nos.value
              }
            },
            citologyJavaslatXml: {
              cintecPlusz: this.cintecPlusz.value,
              javaslatEgyeb: this.javaslategyeb.value,
              javaslatEgyebMegjegyzes: this.javaslatEgyebMegjegyzes.value,
              haromHonapMulva: this.haromHonapMulva.value,
              hatHonapMulva: this.hatHonapMulva.value,
              hormonalis: this.hormonalis.value,
              hpvTipizalas: this.hpvTipizalas.value,
              ismeteltCytEsKolp: this.ismeteltCytEsKolp.value,
              ismetles: this.ismetles.value,
              ismetlesKezelesUtan: this.ismetlesKezelesUtan.value,
              ismetlesKlinikaiMegitelesSzerint: this.ismetlesKlinikaiMegitelesSzerint.value,
              lobellenes: this.lobellenes.value,
              szovettaniVizsgalat: this.szovettaniVizsgalat.value
            },
            citologyKenetErtekeleseXml: {
              egyebElvaltozasok: this.egyebElvaltozasok.value,
              egyebElvaltozasokMegjegyzes: this.egyebElvaltozasokMegjegyzes.value,
              korosHamelvaltozasok: this.korosHamelvaltozasok.value,
              negativKenet: this.negativKenet.value,
            },
            citologyKenetMinosegeXml: {
              cytolysisAutolysis: this.cytolysisAutolysis.value,
              elegtelenulFixalt: this.elegtelenulFixalt.value,
              endocervAtmZonaHianyzik: this.endocervAtmZonaHianyzik.value,
              ertekelhetetlen: this.ertekelhetetlen.value,
              kenetErtekeleseEgyeb: this.kenetErtekeleseEgyeb.value,
              kenetErtekeleseEgyebMegjegyzes: this.kenetErtekeleseEgyebMegjegyzes.value,
              klinikaiAdatokHianyoznak: this.klinikaiAdatokHianyoznak.value,
              lemezNemAzonosithato: this.lemezNemAzonosithato.value,
              megfelelo: this.megfelelo.value,
              sejtszegeny: this.sejtszegeny.value,
              technikaiProblema: this.technikaiProblema.value,
              zavaroGyulladas: this.zavaroGyulladas.value,
              zavaroMeretuNyak: this.zavaroMeretuNyak.value,
              zavaroMeretuVer: this.zavaroMeretuVer.value
            },
            citologyKiegVizsgXml: {
              cintecPluszNegativ: this.cintecPluszNegativ.value,
              cintecPluszPozitiv: this.cintecPluszPozitiv.value,
              egyebHrHpv: this.egyebHrHpv.value,
              kiegVizsMegjegyzes: this.kiegVizsMegjegyzes.value,
              hrHpvTipizalasNegativ: this.hrHpvTipizalasNegativ.value,
              hrHpvTipizalasTizenhat: this.hrHpvTipizalasTizenhat.value,
              hrHpvTipizalasTizennyolc: this.hrHpvTipizalasTizennyolc.value
            },
            citologyMintaXml: {
              hpv: this.hpv.value,
              isFeldolgozott: this.isFeldolgozott.value,
              lbc: this.lbc.value,
              nemFeldolgozott: this.nemFeldolgozott.value,
              pap: this.pap.value
            },
            citologyReszletesDiagnozisXml: {
              diagKorokozoXml: {
                actinomyces: this.actinomyces.value,
                bakteriumEgyeb: this.bakteriumEgyeb.value,
                bakteriumEgyebMegjegyzes: this.bakteriumEgyebMegjegyzes.value,
                candida: this.candida.value,
                gombaEgyeb: this.gombaEgyeb.value,
                gombaEgyebMegjegyzes: this.gombaEgyebMegjegyzes.value,
                herpesFertozesGyanuja: this.herpesFertozesGyanuja.value,
                korosFlora: this.korosFlora.value,
                protozoonEgyeb: this.protozoonEgyeb.value,
                protozoonEgyebMegjegyzes: this.protozoonEgyebMegjegyzes.value,
                trichomonasvaginalis: this.trichomonasvaginalis.value,
                virusEgyeb: this.virusEgyeb.value,
                virusEgyebMegjegyzes: this.virusEgyebMegjegyzes.value
              },
              reszDiagReaktivXml: {
                atrophiaGyulladas: this.atrophiaGyulladas.value,
                endometriumSejtek: this.endometriumSejtek.value,
                gyulladasReaktivHamelvaltozassal: this.gyulladasReaktivHamelvaltozassal.value,
                metaplasiaiRegeneratio: this.metaplasiaiRegeneratio.value,
                reaktivEgyeb: this.reaktivEgyeb.value,
                reaktivEgyebMegjegyzes: this.reaktivEgyebMegjegyzes.value,
                sugarhatas: this.sugarhatas.value,
                cb: this.cb.value,
                mirigyhamsejtekHiszterektomiaUtan: this.mirigyhamsejtekHiszterektomiaUtan.value,
                terhesseghezTartozoJelek: this.terhesseghezTartozoJelek.value
              }
            }
          }
        };

        this.apiService.saveCytologyResV2Request(request).subscribe(response => {
          if (this.logNumber.enabled) {
            this.setLogNumber(request.caseId, this.logNumber.value);
          }
          this.toasterService.success(
            this.messages.success.SUCCESS_SAVE_OK[this.messages.locale],
            this.messages.success.SUCCESS_TITLE[this.messages.locale], { positionClass: 'toast-top-center' });
          // this.generalService.goToPage('reports');
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, (error: HttpErrorResponse) => {
          // if (error.status === 500) {
            this.toasterService.error(error.error.message, error.status.toString(),
            { positionClass: 'toast-top-center' });
          // }
        });
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_LENGTH[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    } else {

      if (!this.szakmakodControl.value) {
        this.toasterService.error('Kérem adja meg a szakmakódot!', 'Hiba!', {positionClass: 'toast-top-center'});
      }

      if (!this.validatorUserControl.value) {
        this.toasterService.error(
          this.messages.error.ERROR_VALIDATOR_DOCTOR_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
      if (!this.preScreeningSpecialistControl.value) {
        this.toasterService.error(
          this.messages.error.ERROR_PRE_SCREENING_SPECIALIST_MISSING[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          { positionClass: 'toast-top-center' });
      }
      if (!this.logNumber.value) {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
      if (!this.bno.value) {
        this.toasterService.error(
          this.messages.error.ERROR_BNO_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
      if (!this.pdfHeader.value) {
        this.toasterService.error(
          this.messages.error.ERROR_HEADER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    }
  }

  getCasesByCaseId(caseId: number) {
    this.cases = [];
    //this.getDescription(caseId);
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(element => {
        if (element.type.includes('RES')) {
           if (((this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') && element.isValidated) ||
            (this.user.roleCode !== 'REFERRING_DOCTOR' && this.user.roleCode !== 'REFERRING_ASSISTANT')) {
            this.cases.push(element);
          }
        }
      });

      if (this.cases?.length > 1) {
        const cases = this.cases;
        const dialogRef = this.dialog.open(SelectCasesComponent, {
          data: {cases}
        });
        const sub = dialogRef.componentInstance.selectedCase.subscribe((data: number) => {
          this.getCaseById(data);
          dialogRef.close();
        });
      } else {
        if (this.cases !== []) {
          this.getCaseById(this.cases[0]?.id);
          this.getDescription(response[0]?.id, true);
        }
      }
    });
  }

  getCaseById(id: number) {
    if (this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') {
      this.cytologyResFormGroup.disable();
      this.cytologyResDataFormGroup.disable();
    }
    this.cases.forEach(element => {
      this.printablePdf = element.pdf;
      if (element.id === id) {

        this.bno.setValue(element.diagnose);
        this.cintecPlusz.setValue(element.values['citologyJavaslatXml']['cintecPlusz']);
        this.javaslategyeb.setValue(element.values['citologyJavaslatXml']['javaslatEgyeb']);
        this.javaslatEgyebMegjegyzes.setValue(element.values['citologyJavaslatXml']['javaslatEgyebMegjegyzes']);
        this.haromHonapMulva.setValue(element.values['citologyJavaslatXml']['haromHonapMulva']);
        this.hatHonapMulva.setValue(element.values['citologyJavaslatXml']['hatHonapMulva']);
        this.hormonalis.setValue(element.values['citologyJavaslatXml']['hormonalis']);
        this.hpvTipizalas.setValue(element.values['citologyJavaslatXml']['hpvTipizalas']);
        this.ismeteltCytEsKolp.setValue(element.values['citologyJavaslatXml']['ismeteltCytEsKolp']);
        this.ismetles.setValue(element.values['citologyJavaslatXml']['ismetles']);
        this.ismetlesKezelesUtan.setValue(element.values['citologyJavaslatXml']['ismetlesKezelesUtan']);
        this.ismetlesKlinikaiMegitelesSzerint.setValue(element.values['citologyJavaslatXml']['ismetlesKlinikaiMegitelesSzerint']);
        this.lobellenes.setValue(element.values['citologyJavaslatXml']['lobellenes']);
        this.szovettaniVizsgalat.setValue(element.values['citologyJavaslatXml']['szovettaniVizsgalat']);

        this.egyebElvaltozasok.setValue(element.values['citologyKenetErtekeleseXml']['egyebElvaltozasok']);
        this.egyebElvaltozasokMegjegyzes.setValue(element.values['citologyKenetErtekeleseXml']['egyebElvaltozasokMegjegyzes']);
        this.korosHamelvaltozasok.setValue(element.values['citologyKenetErtekeleseXml']['korosHamelvaltozasok']);
        this.negativKenet.setValue(element.values['citologyKenetErtekeleseXml']['negativKenet']);

        this.technikaiProblema.setValue(element.values['citologyKenetMinosegeXml']['technikaiProblema']);
        this.sejtszegeny.setValue(element.values['citologyKenetMinosegeXml']['sejtszegeny']);
        this.cytolysisAutolysis.setValue(element.values['citologyKenetMinosegeXml']['cytolysisAutolysis']);
        this.elegtelenulFixalt.setValue(element.values['citologyKenetMinosegeXml']['elegtelenulFixalt']);
        this.endocervAtmZonaHianyzik.setValue(element.values['citologyKenetMinosegeXml']['endocervAtmZonaHianyzik']);
        this.ertekelhetetlen.setValue(element.values['citologyKenetMinosegeXml']['ertekelhetetlen']);
        this.kenetErtekeleseEgyeb.setValue(element.values['citologyKenetMinosegeXml']['kenetErtekeleseEgyeb']);
        this.kenetErtekeleseEgyebMegjegyzes.setValue(element.values['citologyKenetMinosegeXml']['kenetErtekeleseEgyebMegjegyzes']);
        this.klinikaiAdatokHianyoznak.setValue(element.values['citologyKenetMinosegeXml']['klinikaiAdatokHianyoznak']);
        this.lemezNemAzonosithato.setValue(element.values['citologyKenetMinosegeXml']['lemezNemAzonosithato']);
        this.megfelelo.setValue(element.values['citologyKenetMinosegeXml']['megfelelo']);
        this.zavaroGyulladas.setValue(element.values['citologyKenetMinosegeXml']['zavaroGyulladas']);
        this.zavaroMeretuNyak.setValue(element.values['citologyKenetMinosegeXml']['zavaroMeretuNyak']);
        this.zavaroMeretuVer.setValue(element.values['citologyKenetMinosegeXml']['zavaroMeretuVer']);

        this.cintecPluszNegativ.setValue(element.values['citologyKiegVizsgXml']['cintecPluszNegativ']);
        this.cintecPluszPozitiv.setValue(element.values['citologyKiegVizsgXml']['cintecPluszPozitiv']);
        this.egyebHrHpv.setValue(element.values['citologyKiegVizsgXml']['egyebHrHpv']);
        this.hrHpvTipizalasNegativ.setValue(element.values['citologyKiegVizsgXml']['hrHpvTipizalasNegativ']);
        this.hrHpvTipizalasTizenhat.setValue(element.values['citologyKiegVizsgXml']['hrHpvTipizalasTizenhat']);
        this.hrHpvTipizalasTizennyolc.setValue(element.values['citologyKiegVizsgXml']['hrHpvTipizalasTizennyolc']);
        this.kiegVizsMegjegyzes.setValue(element.values['citologyKiegVizsgXml']['kiegVizsMegjegyzes']);

        this.hpv.setValue(element.values['citologyMintaXml']['hpv']);
        this.isFeldolgozott.setValue(element.values['citologyMintaXml']['isFeldolgozott']);
        this.lbc.setValue(element.values['citologyMintaXml']['lbc']);
        this.nemFeldolgozott.setValue(element.values['citologyMintaXml']['nemFeldolgozott']);
        this.pap.setValue(element.values['citologyMintaXml']['pap']);

        this.actinomyces.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['actinomyces']);
        this.bakteriumEgyeb.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['bakteriumEgyeb']);
        this.bakteriumEgyebMegjegyzes.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['bakteriumEgyebMegjegyzes']);
        this.candida.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['candida']);
        this.gombaEgyeb.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['gombaEgyeb']);
        this.gombaEgyebMegjegyzes.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['gombaEgyebMegjegyzes']);
        this.herpesFertozesGyanuja.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['herpesFertozesGyanuja']);
        this.korosFlora.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['korosFlora']);
        this.protozoonEgyeb.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['protozoonEgyeb']);
        this.protozoonEgyebMegjegyzes.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['protozoonEgyebMegjegyzes']);
        this.trichomonasvaginalis.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['trichomonasvaginalis']);
        this.virusEgyeb.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['virusEgyeb']);
        this.virusEgyebMegjegyzes.setValue(element.values['citologyReszletesDiagnozisXml']['diagKorokozoXml']['virusEgyebMegjegyzes']);

        this.atrophiaGyulladas.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['atrophiaGyulladas']);
        this.endometriumSejtek.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['endometriumSejtek']);
        this.gyulladasReaktivHamelvaltozassal.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['gyulladasReaktivHamelvaltozassal']);
        this.metaplasiaiRegeneratio.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['metaplasiaiRegeneratio']);
        this.reaktivEgyeb.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['reaktivEgyeb']);
        this.reaktivEgyebMegjegyzes.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['reaktivEgyebMegjegyzes']);
        this.sugarhatas.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['sugarhatas']);
        this.cb.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['cb']);
        this.mirigyhamsejtekHiszterektomiaUtan.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['mirigyhamsejtekHiszterektomiaUtan']);
        this.terhesseghezTartozoJelek.setValue(element.values['citologyReszletesDiagnozisXml']['reszDiagReaktivXml']['terhesseghezTartozoJelek']);

        this.asc.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['atypusosLaphamsejtek']);
        this.cinketto.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['cin2']);
        this.cinharom.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['cin3']);
        this.lsil.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['enyheFokuIntraepithelialisLaphamLaesio']);
        this.invasio.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['invasioGyanuja']);
        this.carcinoma.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['laphamsejtCarcinoma']);
        this.ascus.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['nemMeghatarozottOkbolAscUs']);
        this.asch.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['nemZarhatoKiHsil']);
        this.cinegy.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['cin1']);
        this.laphamhpv.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['hpv']);
        this.laphamhsil.setValue(element.values['citologyHamelvaltXml']['citologyLaphamsejtekXml']['hsil']);

        this.adenocarcinoma.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['adenocarcinoma']);
        this.endocervicalis2.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['atypEndocervicalisSejtek']);
        this.endometrialis.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['atypEndometrialisSejtek']);
        this.mirigynos.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['atypMirigyhamsejtek']);
        this.agcnos.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['atypusosMirigyhamsejtek']);
        this.malignustumor.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['egyebMaligunsTumor']);
        this.malignustumorMegjegyzes.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['egyebMaligunsTumorMegjegyzes']);
        this.endocervicalis.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['endocercivalis']);
        this.ais.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['endocervicalisAdenocarcinomaInSitu']);
        this.endometrialis2.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['endomentrialis']);
        this.extrauterin.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['extrauterin']);
        this.agc.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['inkabbNeoplasticusAtypusosMirigyhamsejtek']);
        this.endocercivalis3.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['inkabbNeoplasticusEndocervicalisSejtek']);
        this.mirigynosketto.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['inkabbNeoplasticusMirigyhamsejtek']);
        this.nos.setValue(element.values['citologyHamelvaltXml']['citologyMirigyhamsejtekXml']['nos']);

        this.getDescription(element.id);

        this.hideTechnicalOptions = true;
        this.hideFungiOptions = true;
        this.hideBacteriaOptions = true;
        this.hideVirusOptions = true;
        this.hideReactiveOptions = true;
      }
    });
  }

  getDescription(id: number, isNewDocument?: boolean) {
    const currentDate = this.generalService.getCurrentDate();
    this.apiService.getDescription(id).subscribe(response => {
      this.logPrefix = response.logPrefix;
      if (response.logNumber) {
        // this.logNumber.disable();
        this.logNumber.setValue(response.logNumber);
      }
      if (response.preScreeningSpecialistId) {
        this.preScreeningSpecialistControl.setValue(response.preScreeningSpecialistId);
      } else {
        this.preScreeningSpecialistControl.setValue(this.user.id);
      }

      if (response.customBeutaloId) {
        this.beutaloAzon.disable();
        this.beutaloAzon.setValue(response.customBeutaloId);
      }
      if (response.arrivalTime) {
        response.arrivalTime = this.sharedDataService.
        dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(response.arrivalTime)));
        this.arrivalTime.disable();
        this.arrivalTime.setValue(response.arrivalTime.replace('T', ' '));
      }
      this.responseCreationDate.setValue(currentDate.substr(0, currentDate.indexOf('.')).replace('T', ' '));
      this.responseCreationDate.disable();

      if (isNewDocument) {
        if (this.user.roleCode === 'REFERRING_ASSISTANT' || this.user.roleCode === 'REFERRING_DOCTR') {
          this.doctorName.setValue('');
          this.doctorName.disable();
        } else {
          this.doctorName.setValue(this.user.name);
          this.doctorName.disable();
        }

      } else {
        this.doctorName.setValue(response.doctorName);
        this.doctorName.disable();
      }

    }, (error: Response) => {
      console.error(error);
    });
  }

  setLogNumber(caseId: number, logNumber: string) {
    const request: SetLogNumberRequest = {
      caseId,
      logNumber
    };

    this.apiService.setLogNumber(request).subscribe(response => {
      // this.generalService.goToPage('reports');
    }, (error: Response) => {
      console.error(error);
    });
  }


  checkEszigTokenValid() {
    if (JSON.parse(localStorage.getItem('eszigTokenValid')))  {
      const dialogRef = this.dialog.open(SelectEbeuComponent);
      dialogRef.componentInstance.selectedEbeu.subscribe(ebeu => {
        this.setEbeuAzon(ebeu);
        dialogRef.close();
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_EESZT_AUTH_EXPIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'}
        );
    }
  }

  setEbeuAzon(ebeu: Ebeu) {
    this.eesztBeutaloAzon.setValue(ebeu.businessContent.beutaloAzonosito);
    this.eesztBeutaloAzon.disable();
  }

  deleteCase() {
    const dialogRef = this.dialog.open(ResetCaseComponent);
    dialogRef.componentInstance.option.subscribe(response => {
      this.cytologyResFormGroup.reset();
      this.negativKenet.setValue(false);
      dialogRef.close();
    });
  }

  getPdfHeadersBySpId(spId: number) {
    this.apiService.getPdfHeadersBySpId(spId).subscribe(response => {
      this.pdfHeaders = response;
      if (response.length === 1) {
        this.pdfHeader.setValue(response[0].id);
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

}
