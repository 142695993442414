import { Messages } from 'src/app/shared/locale/messages';
import { Ehr } from './../../../shared/models/ehr';
import { GetEhrDocumentRequest } from './../../../shared/models/request/getEhrDocumentRequest';
import { ToastrService } from 'ngx-toastr';
import { GetEhrListRequest } from '../../../shared/models/request/getEhrListRequest';
import { GetEhrListResponse } from 'src/app/shared/models/response/getEhrListResponse';
import { SearchEbeuResponse } from './../../../shared/models/response/searchEbeuResponse';
import { EesztSamlEszigRequest } from './../../../shared/models/request/eesztSamlEszigRequest';

import { environment } from './../../../../environments/environment';
import { EesztSamlEidRequest } from './../../../shared/models/request/eesztSamlEidRequest';
import { FormService } from './../../../core/services/form.service';
import { GetPatientRequest } from './../../../shared/models/request/getPatientRequest';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { GetPatientResponse } from './../../../shared/models/response/getPatientResponse';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { ApiService } from './../../../core/http/api.service';
import { EbeuSearchRequest } from './../../../shared/models/request/ebeuSearchRequest';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { GetServicePointByIdResponse } from 'src/app/shared/models/response/getServicePointByIdResponse';
import { BcpMessage } from 'src/app/shared/models/bcpMessage';
import { AccountedCase } from 'src/app/shared/models/accountedCase';

@Component({
  selector: 'app-eeszt',
  templateUrl: './eeszt.component.html',
  styleUrls: ['./eeszt.component.css']
})
export class EesztComponent implements OnInit {

  dateType = this.messages.locale === 'en' ? 'en-US' : 'hu-HU';

  environment = environment;

  eszigResponseCode: number;
  eszigMessage: string;
  ehrList: GetEhrListResponse;

  patient: GetPatientResponse;
  user: GetUserByUserNameResponse;

  ehrtaj = new FormControl('');
  ehrbirthDate = new FormControl('');

  currentServicePoint: GetServicePointByIdResponse;
  beutaloIdoszakVege = new FormControl('');
  beutaloIdoszakKezdet = new FormControl('');
  ellatasIdoszakKezdete = new FormControl('');
  ellatasIdoszakVege = new FormControl('');

  fromDate = new FormControl('');
  toDate = new FormControl('');
  who = new FormControl('');
  ehrBreakOkGlass = new FormControl('');
  ehrBreakGlass = new FormControl(false);

  bcpMessages: Array<BcpMessage>;
  resendableBcpMessages = new Array<number>();
  checkBcpMessage = new FormControl(false);

  constructor(
    private apiService: ApiService,
    private formService: FormService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit() {
    this.formService.checkBoxWithText(this.ehrBreakGlass, this.ehrBreakOkGlass);
    this.user = JSON.parse(localStorage.getItem('currentUser'));

  }

  // ngOnDestroy(): void {
  //   this.sharedDataService.currentPatient.unsubscribe();
  // }

  getServicePointById(servicePointId: number) {
    this.apiService.getServicePointById(servicePointId).subscribe(response => {
      this.currentServicePoint = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  setCallerTransactionId(user: GetUserByUserNameResponse) {
    this.getServicePointById(user.servicePointId);
    return this.getCodedUserName(user.name);
  }

  getCodedUserName(userName: string): string {
    let codedUserName = '';
    for (let i = 1; i < userName.length; i++) {
      if ( i % 2 === 0) {
        codedUserName += userName.substring(i - 1, i).replace(' ', '');
      }
    }
    return codedUserName;
  }

  getAllBcpMessages() {
    this.apiService.getAllBcpMessages(this.user.username).subscribe(response => {
      if (response.length === 0) {
        this.toasterService.info(
          this.messages.info.INFO_BCP_LIST_EMPTY[this.messages.locale], '', { positionClass: 'toast-top-center' });
      }
      response.forEach(element => {

        if (element.modul === 'EKAT') {
          element.modul = 'Eseménykatalógus';
        } else if (element.modul === 'EHR') {
          element.modul = 'Egészségügyi dokumentum';
        }

        if (element.messageType === 'addEventEntryV2') {
          element.messageType = 'Eseménykatalógus hozzáadása';
        } else if (element.messageType === 'addOrUpdateDocument') {
          element.messageType = 'Egészségügyi dokumentum hozzáadása/frissítése';
        } else if (element.messageType === 'updateEventEntry') {
          element.messageType = 'Eseménykatalógus frissítése';
        } else if (element.messageType === 'updateEventEntryV2') {
          element.messageType = 'Eseménykatalógus módosítása';
        }
      });

      this.bcpMessages = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  resendBcpMessages() {
    this.apiService.resendBcpMessages(this.resendableBcpMessages).subscribe(response => {
      this.getAllBcpMessages();
    }, (error: Response) => {
      this.toasterService.error(
        this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
    });
  }

  addToBcpList($event: number) {
    if (this.resendableBcpMessages.includes($event)) {
      this.resendableBcpMessages = this.resendableBcpMessages.filter(item => item !== $event);
    } else {
      this.resendableBcpMessages.push($event);
    }
  }

  resendAllBcpMessages() {
    this.apiService.resendAllBcpMessages(this.user.username).subscribe(response => {
      this.getAllBcpMessages();
    }, (error: Response) => {
      this.toasterService.error(
        this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
    });
  }

  getEhrList() {
    const getPatientRequest: GetPatientRequest = {
      ssn: this.ehrtaj.value,
      birthDate: this.ehrbirthDate.value
    };

    this.apiService.getPatientBy2T(getPatientRequest).subscribe(getPatientBy2TResponse => {

      const request: GetEhrListRequest = {
        breakGlass: this.ehrBreakGlass.value,
        breakGlassOk: this.ehrBreakOkGlass.value,
        fromDate: this.fromDate.value,
        patientId: getPatientBy2TResponse[0].id,
        servicePointId: this.user.servicePointId,
        toDate: this.toDate.value,
        userId: this.user.id,
        who: this.who.value
      };
      this.apiService.getEhrList(request).subscribe(response => {
        if (+response.code < 0 ) {
          this.toasterService.error(response.message, response.code, {positionClass: 'toast-top-center'});
        }
        if (response.data.documentList.length === 0) {
          this.toasterService.info(
            this.messages.info.INFO_EHR_NOT_FOUND[this.messages.locale], '', {positionClass: 'toast-top-center'});
        }
        this.ehrList = response;
      }, (error: Response) => {
        console.error(error);
      });
  }, (error: Response) => {
    if (error.status === 404) {
      this.toasterService.error(
        this.messages.error.ERROR_NO_PATIENT[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_SERVER_COMMUNICATION[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'});
    }
  });
  }

  downloadEhr(ehrData: Ehr) {
    const request: GetEhrDocumentRequest = {
      breakGlass: this.ehrBreakGlass.value,
      breakGlassOk: this.ehrBreakOkGlass.value,
      callerTransactionId: '',
      eesztId: ehrData.eesztId,
      servicePointId: this.user.servicePointId,
      userId: this.user.id
    };

    this.apiService.getEhrDocument(request).subscribe(response => {
      const linkSource = `data:application/pdf;base64,${response.data.document}`;
      const downloadLink = document.createElement('a');
      const fileName = 'ehrDocument.pdf';

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  }
}
