<nav class="caller-navbar navbar navbar-expand-lg navbar-light bg-light">
    <img src="../../../../assets/images/output-onlinepngtools.png" class="logo img-fluid navbar-brand"/>
    <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <span *ngIf="router.url.includes('/admin')"  class="mx-auto navbar-nav" style="color: white; font-size: 20px">{{currentServicePoint}}</span>
        <ul class="ml-auto navbar-nav">
            <li *ngIf="user.roleCode === 'REFERRING_ASSISTANT' || user.roleCode === 'REFERRING_DOCTOR'" class="nav-item">
                <button (click)="goToPageWithCheckRoute()" class="navbarbutton btn btn-light" style="margin-right:10px">
                    <i *ngIf="router.url.includes('/dashboard') || router.url.includes('report/') || router.url.includes('/eeszt') || router.url.includes('/registration')" aria-hidden="true" class="fa fa-list"  title="Munkalista"></i>
                    <i *ngIf="router.url.includes('/reports') && (user.roleCode === 'REFERRING_ASSISTANT' || user.roleCode == 'REFERRING_DOCTOR')" aria-hidden="true" class="fa fa-envelope" title="Feladás"></i>
                </button>
            </li>
            <li *ngIf="user.roleCode !== 'REFERRING_ASSISTANT' && user.roleCode !== 'REFERRING_DOCTOR' && user.roleCode !== 'ADMIN'" class="nav-item">
                <button (click)="generalService.goToPage('reports')" class="navbarbutton btn btn-light" style="margin-right:10px" title="Munkalista">
                    <i aria-hidden="true" class="fa fa-list"></i>
                </button>
            </li>
            <li *ngIf="!router.url.includes('/admin') && user.roleCode !== 'REFERRING_ASSISTANT' && user.roleCode !== 'REFERRING_DOCTOR' && user.roleCode !== 'ADMIN'" class="nav-item">
                <button class="navbarbutton btn btn-light" style="margin-right:10px" (click)="openEesztAuthModal()" title="EESZT autentikáció">
                    <i aria-hidden="true" class="fa fa-id-card"></i>
                </button>
            </li>
            <li *ngIf="!router.url.includes('/admin') && user.roleCode !== 'REFERRING_ASSISTANT' && user.roleCode !== 'REFERRING_DOCTOR' && user.roleCode !== 'ADMIN'" class="nav-item">
                <button [ngClass]="eszigTokenValid ? 'greenCloud' : 'redCloud'" (click)="goToEeszt()" class="navbarbutton btn btn-light" style="margin-right:10px" title="EESZT">
                    <i aria-hidden="true" class="fa fa-cloud"></i>
                    <span *ngIf="counter && eszigTokenValid" class="ml-2">{{(counter / 1000) | counter}}</span>
                </button>
            </li>
            <li *ngIf="!router.url.includes('/admin') && user.roleCode !== 'REFERRING_ASSISTANT' && user.roleCode !== 'REFERRING_DOCTOR' && user.roleCode !== 'ADMIN'" class="nav-item">
                <button disabled *ngIf="eszigTokenValid && bcpMessages > 0" class="hasBcpMessage navbarbutton btn btn-light" style="margin-right:10px; cursor: default;" title="EESZT kommunikációs napló">
                    {{bcpMessages}} <i aria-hidden="true" class="fa fa-exclamation"></i>
                </button>
                <button disabled *ngIf="eszigTokenValid && bcpMessages == 0" class="hasNoBcpMessage navbarbutton btn btn-light" style="margin-right:10px; cursor: default;" title="EESZT kommunikációs napló">
                    <i aria-hidden="true" class="fa fa-check"></i>
                </button>
            </li>
            <li class="nav-item" title="Jelszó módosítása">
                <button (click)="generalService.goToPage('registration')" class="navbarbutton btn btn-light" style="margin-right: 10px;">
                    <i aria-hidden="true" class="fa fa-lock"></i>
                </button>
            </li>
            <li class="nav-item" title="Kijelentkezés">
                <button (click)="logout()" class="navbarbutton btn btn-light">
                   {{user.username}}
                   <i aria-hidden="true" class="fa fa-sign-out"></i>
                </button>
            </li>
        </ul>
    </div>
</nav>
