import { GetUserByUserNameResponse } from './../../shared/models/response/getUserByUsernameResponse';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, RouterLinkWithHref, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPresmission(state, JSON.parse(localStorage.getItem('currentUser')));
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      return this.checkUser(JSON.parse(localStorage.getItem('currentUser')));
  }

  checkUser(user: GetUserByUserNameResponse) {
    if (user) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  checkPresmission(state: RouterStateSnapshot, user: GetUserByUserNameResponse) {

    if (state.url !== '/admin') {
      if (!user) {
        this.router.navigate(['/login']);
        return false;
      } else {
        if (user.roleCode.includes('ADMIN')) {
          this.router.navigate(['/login']);
          return false;
        } else {
          return true;
        }
      }
    }

    if (user) {
      if (user.roleCode.includes('ADMIN')) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }


}
