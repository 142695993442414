import { GetPatientRequest } from './../../../shared/models/request/getPatientRequest';
import { FormService } from './../../../core/services/form.service';
import { ApiService } from './../../../core/http/api.service';
import { ShareddataService } from './../../../core/services/shareddata.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cytologyapplication',
  templateUrl: './cytologyapplication.component.html',
  styleUrls: ['./cytologyapplication.component.css']
})
export class CytologyapplicationComponent implements OnInit, OnDestroy {

  utolsoMenstruacio = new FormControl('');
  kenetvetelIdeje = new FormControl('');

  fogamzasgatlasNem = new FormControl(false);
  fogamzasgatlasIgen = new FormControl(false);
  fogamzasgatlasIUD = new FormControl(false);
  fogamzasgatlasHormon = new FormControl(false);
  fogamzasgatlasEgyeb = new FormControl(false);
  fogamzasgatlasMegjegyzes = new FormControl('');

  isCitologiaNem = new FormControl(false);
  isCitologiaIgen = new FormControl(false);
  citologiaDg = new FormControl('');
  citologiaHely = new FormControl('');
  citologiaIdo = new FormControl('');

  isKezelesNem = new FormControl(false);
  isKezelesIgen = new FormControl(false);
  kezelesMegjegyzes = new FormControl('');

  isMutetNem = new FormControl(false);
  isMutetIgen = new FormControl(false);
  mutetMegjegyzes = new FormControl('');

  isSzovettanNem = new FormControl(false);
  isSzovettanIgen = new FormControl(false);
  szovettanDg = new FormControl('');
  szovettanHely = new FormControl('');
  szovettanIdo = new FormControl('');

  jelenlegTerhesNem = new FormControl(false);
  jelenlegTerhesIgen = new FormControl(false);
  jelenlegTerhesLaktal = new FormControl(false);
  jelenlegTerhesMegjegyzes = new FormControl('');

  kolposzkopiaNormal = new FormControl(false);
  kolposzkopiaKoros = new FormControl(false);
  kolposzkopiaMegjegyzes = new FormControl('');

  otherIgen = new FormControl(false);
  otherNem = new FormControl(false);
  otherMegjegyzes = new FormControl('');

  eszkozosVetelECTF = new FormControl(false);
  eszkozosVetelPortio = new FormControl(false);
  eszkozosVetelMindketto = new FormControl(false);
  eszkozosVetelHuvely = new FormControl(false);

  cytologyApplicationForm = new FormGroup( {
      utolsoMenstruacio: this.utolsoMenstruacio,
      kenetvetelIdeje: this.kenetvetelIdeje,
      fogamzasgatlasNem: this.fogamzasgatlasNem,
      fogamzasgatlasIgen: this.fogamzasgatlasIgen,
      fogamzasgatlasIUD: this.fogamzasgatlasIUD,
      fogamzasgatlasHormon: this.fogamzasgatlasHormon,
      fogamzasgatlasEgyeb: this.fogamzasgatlasEgyeb,
      fogamzasgatlasMegjegyzes: this.fogamzasgatlasMegjegyzes,
      isCitologiaNem: this.isCitologiaNem,
      isCitologiaIgen: this.isCitologiaIgen,
      citologiaDg: this.citologiaDg,
      citologiaHely: this.citologiaHely,
      citologiaIdo: this.citologiaIdo,
      isKezelesNem: this.isKezelesNem,
      isKezelesIgen: this.isKezelesIgen,
      kezelesMegjegyzes: this.kezelesMegjegyzes,
      isMutetNem: this.isMutetNem,
      isMutetIgen: this.isMutetIgen,
      mutetMegjegyzes: this.mutetMegjegyzes,
      isSzovettanNem: this.isSzovettanNem,
      isSzovettanIgen: this.isSzovettanIgen,
      szovettanDg: this.szovettanDg,
      szovettanHely: this.szovettanHely,
      szovettanIdo: this.szovettanIdo,
      jelenlegTerhesNem: this.jelenlegTerhesNem,
      jelenlegTerhesIgen: this.jelenlegTerhesIgen,
      jelenlegTerhesLaktal: this.jelenlegTerhesLaktal,
      jelenlegTerhesMegjegyzes: this.jelenlegTerhesMegjegyzes,
      kolposzkopiaNormal: this.kolposzkopiaNormal,
      kolposzkopiaKoros: this.kolposzkopiaKoros,
      kolposzkopiaMegjegyzes: this.kolposzkopiaMegjegyzes,
      otherIgen: this.otherIgen,
      otherNem: this.otherNem,
      otherMegjegyzes: this.otherMegjegyzes,
      eszkozosVetelECTF: this.eszkozosVetelECTF,
      eszkozosVetelPortio: this.eszkozosVetelPortio,
      eszkozosVetelMindketto: this.eszkozosVetelMindketto,
      eszkozosVetelHuvely: this.eszkozosVetelHuvely
  });

  public sub = new Subscription();
  constructor(
    private sharedDataService: ShareddataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private formService: FormService
  ) {
  }

  ngOnInit() {
    this.sub = this.cytologyApplicationForm.valueChanges.subscribe(data => {
        this.sharedDataService.formGroupChange(data);
    });
    if (+this.route.snapshot.paramMap.get('caseId')) {
      this.getCaseById(+this.route.snapshot.paramMap.get('caseId'));
    }

    this.formService.checkBoxWithText(this.kolposzkopiaKoros, this.kolposzkopiaMegjegyzes);
    this.formService.checkBoxWithText(this.jelenlegTerhesLaktal, this.jelenlegTerhesMegjegyzes);
    this.formService.checkBoxWithText(this.fogamzasgatlasEgyeb, this.fogamzasgatlasMegjegyzes);
    this.formService.checkBoxWithText(this.isCitologiaIgen, this.citologiaDg);
    this.formService.checkBoxWithText(this.isCitologiaIgen, this.citologiaHely);
    this.formService.checkBoxWithText(this.isCitologiaIgen, this.citologiaIdo);
    this.formService.checkBoxWithText(this.isSzovettanIgen, this.szovettanDg);
    this.formService.checkBoxWithText(this.isSzovettanIgen, this.szovettanHely);
    this.formService.checkBoxWithText(this.isSzovettanIgen, this.szovettanIdo);
    this.formService.checkBoxWithText(this.isMutetIgen, this.mutetMegjegyzes);
    this.formService.checkBoxWithText(this.isKezelesIgen, this.kezelesMegjegyzes);
    this.formService.checkBoxWithText(this.otherIgen, this.otherMegjegyzes);

    this.formService.xOrCheckboxes(this.kolposzkopiaNormal, this.kolposzkopiaKoros);
    this.formService.xOrCheckboxes(this.jelenlegTerhesIgen, this.jelenlegTerhesNem);
    this.formService.xOrCheckboxes(this.fogamzasgatlasIgen, this.fogamzasgatlasNem);
    this.formService.xOrCheckboxes(this.isCitologiaIgen, this.isCitologiaNem);
    this.formService.xOrCheckboxes(this.isSzovettanIgen, this.isSzovettanNem);
    this.formService.xOrCheckboxes(this.fogamzasgatlasIgen, this.fogamzasgatlasNem);
    this.formService.xOrCheckboxes(this.isCitologiaIgen, this.isCitologiaNem);
    this.formService.xOrCheckboxes(this.isSzovettanIgen, this.isSzovettanNem);
    this.formService.xOrCheckboxes(this.isMutetNem, this.isMutetIgen);
    this.formService.xOrCheckboxes(this.isKezelesIgen, this.isKezelesNem);
    this.formService.xOrCheckboxes(this.otherIgen, this.otherNem);

    this.formService.checkBase(this.fogamzasgatlasIgen, [this.fogamzasgatlasIUD, this.fogamzasgatlasHormon, this.fogamzasgatlasEgyeb]);
  }

  ngOnDestroy() {
    this.sharedDataService.formGroupChange(this.cytologyApplicationForm.reset());
    this.sub.unsubscribe();
  }

  getCaseById(caseId: number) {
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(element => {
        if (element.type.includes('REQ')) {
          this.cytologyApplicationForm.setValue(element.values);
          this.cytologyApplicationForm.disable();
        }
      });
      // this.cytologyApplicationForm.setValue(response[0].values);
      // this.cytologyApplicationForm.disable();
    }, (error: Response) => {
      console.error(error);
    });
  }
}
