import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter'
})
export class CounterPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    if (hours > 0) {
      const minutes: number = Math.floor((value - hours * 3600) / 60);
       return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
    } else {
      const minutes: number = Math.floor(value / 60);
      return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
    }
  }

}

