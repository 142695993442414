<app-navbar></app-navbar>
<div class="container">
    <div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label" i18n>régi jelszó</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input [type]="opFieldTextType ? 'text' : 'password'" type="password" class="form-control" [formControl]="oldPw"/>
                    <div class="input-group-append"
                    (click)="toggleopFieldTextType()" >
                      <span class="input-group-text">
                        <i [ngClass]="{
                            'fa-eye-slash': !opFieldTextType,
                            'fa-eye': opFieldTextType
                          }"
                        class="fa fa-eye-slash"></i>
                      </span>
                   </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label" i18n>új jelszó</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input [type]="npFieldTextType ? 'text' : 'password'" type="password" class="form-control" [formControl]="newPw"/>
                    <div class="input-group-append"  (click)="togglenpFieldTextType()">
                      <span class="input-group-text">
                        <i [ngClass]="{
                            'fa-eye-slash': !npFieldTextType,
                            'fa-eye': npFieldTextType
                          }"

                        class="fa fa-eye-slash"></i>
                      </span>
                   </div>
                </div>
             </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label" i18n>új jelszó mégegyszer</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input [type]="npaFieldTextType ? 'text' : 'password'" type="password" class="form-control" [formControl]="newPwAgain"/>
                    <div class="input-group-append" (click)="togglenpaFieldTextType()">
                      <span class="input-group-text">
                        <i
                        [ngClass]="{
                            'fa-eye-slash': !npaFieldTextType,
                            'fa-eye': npaFieldTextType
                          }"
                          class="fa fa-eye-slash"></i>
                      </span>
                   </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col">
                <button (click)="changePassword()" class="registerButton btn btn-primary" i18n>mentés</button>
            </div>
            <div class="col">
                <button (click)="goToPage()" class="registerButton btn btn-primary" i18n>mégsem</button>
            </div>
        </div>
    </div>
</div>
