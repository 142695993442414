<div class="wrapper">
    <div class="form-inline mt-5 mb-5">
		<form class="form-inline" [formGroup]="searchForm">
			<label class="pathologyRecordsLabel" i18n>taj szám</label>
			<input [formControl]="ssn" class="pathologyRecordsInput form-control" type="text"/>
			<label class="pathologyRecordsLabel" i18n>naplószám</label>
			<input [formControl]="logNumber" class="pathologyRecordsInput form-control" type="text"/>
			<label class="pathologyRecordsLabel" i18n>név</label>
			<input [formControl]="birthName" class="pathologyRecordsInput form-control" type="text"/>
		</form>
	</div>
	<div class="w-100">
		<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        <table mat-table [dataSource]="casesForPaginator" class="w-100">
            <ng-container matColumnDef="id" style="display: none;">
                <td mat-cell *matCellDef="let element">{{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
					<select (change)="filterByTypeOfCase($event.target.value)" class="select" name="típus" id="type">
						<option value="" disabled selected i18n>típus</option>
						<option value="szöv" i18n>szöv</option>
						<option value="cyt" i18n>cyt</option>
						<option value="acyt" i18n>acyt</option>
						<option value="" i18n>összes</option>
					</select>
				</th>
                <td mat-cell *matCellDef="let element">{{element.type}}</td>
            </ng-container>
            <ng-container matColumnDef="birthName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>név</th>
                <td mat-cell *matCellDef="let element">{{element.patientBirthName}}</td>
            </ng-container>
            <ng-container matColumnDef="ssn">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>taj</th>
                <td mat-cell *matCellDef="let element">{{element.patientSsn}}</td>
            </ng-container>
            <ng-container matColumnDef="birthDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>születési dátum</th>
                <td mat-cell *matCellDef="let element">{{element.patientBirthDate}}</td>
            </ng-container>
            <ng-container matColumnDef="doctorName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beküldő orvos</th>
                <td mat-cell *matCellDef="let element">{{element.creatorUserName}}</td>
            </ng-container>
            <ng-container matColumnDef="logNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>naplószám</th>
                <td mat-cell *matCellDef="let element">{{element.logPrefix? element.logPrefix + '/' : '' }}{{element.logNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
					<select (change)="filterByStatusOfCase($event.target.value)" class="select" name="státusz" id="statusz">
						<option value="" disabled selected i18n>státusz</option>
						<option value="új" i18n>új</option>
						<option value="véglegesítésre vár" i18n>véglegesítésre vár</option>
						<option value="kész" i18n>kész</option>
						<option value="all" i18n>összes</option>
					</select>
				</th>
                <td mat-cell *matCellDef="let element">{{element.status}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>beküldés ideje</th>
                <td mat-cell *matCellDef="let element">{{element.creationDate}}</td>
            </ng-container>
            <ng-container matColumnDef="icons">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" style="text-align: left; padding: 5px;">
					<i  class="fa fa-trash deleteIcon"  aria-hidden="true" (click)="showDeleteCase(element)"  title="törlés"></i>
				</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
