import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { GetAllCasesResponse } from './../../../shared/models/response/getAllCasesResponse';
import { GeneralService } from './../../../core/services/general.service';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { ApiService } from './../../../core/http/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { GetCaseByIdResponse } from 'src/app/shared/models/response/getCaseById';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-select-cases',
  templateUrl: './select-cases.component.html',
  styleUrls: ['./select-cases.component.css']
})
export class SelectCasesComponent implements OnInit {

  cases: Array<GetCaseByIdResponse>;
  user: GetUserByUserNameResponse;

  selectedCase: EventEmitter<number> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {cases: Array<GetCaseByIdResponse>},
    private generalService: GeneralService,
    public sharedDataService: ShareddataService,
    public messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.cases = this.convertDateInCases(this.data.cases);
  }

  convertDateInCases(cases: Array<GetCaseByIdResponse>): Array<GetCaseByIdResponse> {
    cases.forEach(element => {
      element.creationTime = this.sharedDataService.
      dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(element.creationTime)));
    });

    return cases;
  }

  closeModal() {
    this.generalService.goToPage('reports');
  }

  onSelectedCase($event) {
    this.selectedCase.emit($event);
  }
}
