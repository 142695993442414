import { ShareddataService } from './../../../core/services/shareddata.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GetPatientResponse } from './../../../shared/models/response/getPatientResponse';
import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-patient',
  templateUrl: './select-patient.component.html',
  styleUrls: ['./select-patient.component.css']
})
export class SelectPatientComponent implements OnInit {

  patients: Array<GetPatientResponse>;
  filteredPatients: Array<GetPatientResponse>;
  selectedPatient: EventEmitter<GetPatientResponse | object> = new EventEmitter();

  nameControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Array<GetPatientResponse>,
    private sharedDataService: ShareddataService
  ) { }

  ngOnInit(): void {
    this.patients = this.data;
    this.filteredPatients = this.data;

    this.nameControl.valueChanges.subscribe(value => {
      this.filterPatients(value);
    });
  }

  filterPatients(value) {
    let filteredPatients: Array<GetPatientResponse>;
    filteredPatients = this.patients;

    if (value) {
      filteredPatients = filteredPatients.filter(x =>
        x.birthName.toLowerCase().includes(value.toLowerCase()));
    } else {
      filteredPatients = filteredPatients;
    }

    this.filteredPatients = filteredPatients;
  }

  onSelectedPatient(patient?: GetPatientResponse) {
    if (patient) {
      this.selectedPatient.emit(patient);
    } else {
      this.selectedPatient.emit(null);
    }
  }

}
