// tslint:disable: deprecation
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/User';
import { ApiService } from './../http/api.service';
import { CookieService } from 'ngx-cookie-service';
import { GeneralService } from './../services/general.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ServicePoint } from 'src/app/shared/models/ServicePoint';
import { environment } from './../../../environments/environment';
import { RegistrationRequest } from 'src/app/shared/models/request/reqistrationRequest';
import { AuthenticationRequest } from './../../shared/models/request/authenticationRequest';
import { AuthenticationResponse } from 'src/app/shared/models/response/authenticationResponse';
import { Messages } from 'src/app/shared/locale/messages';

import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  environment = environment;
  servicePoint: ServicePoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private cookieService: CookieService,
    private toasterService: ToastrService,
    private generalService: GeneralService,
    private messages: Messages
  ) { }

  authentication(username: string, password: string) {
    const request: AuthenticationRequest = {
      username,
      password
    };
    this.authenticate(request).subscribe((data: AuthenticationResponse) => {
      this.setToken(data.token);

      this.apiService.getUserByUsername(this.getUserByToken(data.token)).subscribe(response => {
      localStorage.setItem('currentUser', JSON.stringify(response));
        this.apiService.getServicePointById(response.servicePointId).subscribe(servicePoint => {
          localStorage.setItem('servicePoint', JSON.stringify(servicePoint));
          if (response.roleCode === 'REFERRING_ASSISTANT' || response.roleCode === 'REFERRING_DOCTOR') {
            this.generalService.goToPage('dashboard');
          } else if (response.roleCode === 'ADMIN') {
            this.generalService.goToPage('admin');
          } else {
            this.generalService.goToPage('reports');
          }
        })
      }, (error: Response) => {
        console.error(error);
      });
    }, (error: Response)  => {
      this.toasterService.error(
        this.messages.error.ERROR_INVALID_CREDENTIALS[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
        { positionClass: 'toast-top-center' });
    });
  }

  authenticate(request: AuthenticationRequest) {
    return this.http.post<AuthenticationResponse>(this.environment.pathologyURL + 'rest/token/authenticate', request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'}
      )
    });
  }

  registration(request: RegistrationRequest) {
    return this.http.post<Response>(this.environment.pathologyURL + 'rest/token/registration', request, {
      headers: new HttpHeaders(
        {'Content-Type': 'application/json'}
      )
    });
  }

  getAllUser() {
    return this.http.get<Array<User>>(this.environment.pathologyURL + 'rest/user/find/all');
  }

  setToken(token: string) {
    this.cookieService.set('token', token);
  }

  getToken() {
    return this.cookieService.get('token');
  }

  getEszigTokenExpDate() {
    return this.cookieService.get('etexpDate');
  }

  deleteCookies() {
    this.cookieService.deleteAll();
  }

  getUserByToken(token: string) {
    if (token) {
      // const JWTDECOEtoken = jwt_decode(token);
      // console.log(JWTDECOEtoken);
      // const jwtData = token.split('.')[1];
      // const decodedJwtJsonData = window.atob(jwtData);
      // const decodedJwtData = JSON.parse(decodedJwtJsonData);

      // // console.log(decodedJwtData);
      // return  decodedJwtData.sub;
      return jwt_decode(token)['sub'] as string;
    }
  }
  getAllDataByToken(token: string) {
    if (token) {

      return jwt_decode(token) as object;
      // console.log(JWTDECOEtoken);

      // const jwtData = token.split('.')[1];
      // const decodedJwtJsonData = window.atob(jwtData);
      // const decodedJwtData = JSON.parse(decodedJwtJsonData);

      // return  decodedJwtData;
    }
  }

  getUserRoleByToken(token: string) {
    if (token) {
      // const jwtData = token.split('.')[1];
      // const decodedJwtJsonData = window.atob(jwtData);
      // const decodedJwtData = JSON.parse(decodedJwtJsonData);
      // // console.log(decodedJwtData.role[0].authority);
      // return  decodedJwtData.role[0].authority;

      return jwt_decode(token)['role'][0].authority as string;
    }
  }

}
