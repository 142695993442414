import { AuthenticationRequest } from './shared/models/request/authenticationRequest';
import { AuthenticationService } from './core/authentication/authentication.service';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { SPINNER } from 'ngx-ui-loader';
import { DOCUMENT } from '@angular/common';
import { Messages } from './shared/locale/messages';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'esprint';
  SPINNER = SPINNER;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    public messages: Messages

  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('login')) {
          this.route.queryParams.subscribe(params => {
            if (params.token) {
              const tokenData = this.authService.getAllDataByToken(params.token);
              this.authService.authentication(tokenData['user'].username, tokenData['user'].password);
            }
          });
        }
      }
    });
  }
}
