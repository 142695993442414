import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ChangePwRequest } from './../../../shared/models/request/changePwRequest';
import { ApiService } from './../../../core/http/api.service';
import { GeneralService } from './../../../core/services/general.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GetUserByUserNameResponse } from 'src/app/shared/models/response/getUserByUsernameResponse';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  user: GetUserByUserNameResponse;

  oldPw = new FormControl('', [Validators.required]);
  newPw = new FormControl('', [Validators.required]);
  newPwAgain = new FormControl('', [Validators.required]);

  opFieldTextType: boolean;
  npFieldTextType: boolean;
  npaFieldTextType: boolean;

  registrationFormGroup = new FormGroup({
    oldPw: this.oldPw,
    newPw: this.newPw,
    newPwAgain: this.newPwAgain
  });

  constructor(
    public generalService: GeneralService,
    private apiService: ApiService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }

  changePassword() {
    if (this.newPw.value === this.newPwAgain.value) {
      const request: ChangePwRequest  = {
        actualPasswod: this.oldPw.value,
        newPassword: this.newPw.value,
        newPasswordRe: this.newPwAgain.value
      };

      this.apiService.changePassword(this.user.id, request).subscribe(response => {
        this.toasterService.success(
          this.messages.success.SUCCESS_PASSWORD_RESET[this.messages.locale],
          this.messages.success.SUCCESS_TITLE[this.messages.locale], { positionClass: 'toast-top-center' });
      }, (error: HttpErrorResponse) => {
        this.toasterService.error(
          error.error.message,
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
          { positionClass: 'toast-top-center' });
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_PASSWORDS_DONT_MATCH[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],{ positionClass: 'toast-top-center' });
    }
  }

  toggleopFieldTextType() {
    this.opFieldTextType = !this.opFieldTextType;
  }
  togglenpFieldTextType() {
    this.npFieldTextType = !this.npFieldTextType;
  }
  togglenpaFieldTextType() {
    this.npaFieldTextType = !this.npaFieldTextType;
  }

  goToPage() {
    if (this.user.roleCode === 'ADMIN') {
      this.generalService.goToPage('admin');
    } else {
      this.generalService.goToPage('reports');
    }
  }

}
