import { ApiService } from './../../../core/http/api.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountedCase } from 'src/app/shared/models/accountedCase';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-select-financetype',
  templateUrl: './select-financetype.component.html',
  styleUrls: ['./select-financetype.component.css']
})
export class SelectFinancetypeComponent implements OnInit {

  onModalClosed = new EventEmitter();
  accountedCase: AccountedCase;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AccountedCase,
    private apiService: ApiService,
    public messages: Messages
  ) { }

  ngOnInit(): void {
    this.accountedCase = this.data;
  }

  accountCase(status: boolean) {
    this.apiService.setAccounted(this.data.id, status).subscribe(response => {
      this.onModalClosed.emit(response);
    }, (error: Response) => {
      this.onModalClosed.emit(error);
    });
  }

}
