// tslint:disable: deprecation
import { Snomed } from './../../../shared/models/snomed';
import { Bno } from './../../../shared/models/bno';
import { User } from './../../../shared/models/User';
import { MatDialog } from '@angular/material/dialog';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { GeneralService } from './../../../core/services/general.service';
import { GetUserByUserNameResponse } from './../../../shared/models/response/getUserByUsernameResponse';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { SaveHistologyResRequest } from './../../../shared/models/request/saveHistologyResRequest';
import { FormControl, FormGroup } from '@angular/forms';
import { GetCaseByIdResponse } from './../../../shared/models/response/getCaseById';
import { ApiService } from './../../../core/http/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetLogNumberRequest } from 'src/app/shared/models/request/setLogNumberRequest';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'src/app/core/services/form.service';
import { Ebeu } from 'src/app/shared/models/ebeu';
import { EbeuSearchRequest } from 'src/app/shared/models/request/ebeuSearchRequest';
import { GetPatientResponse } from 'src/app/shared/models/response/getPatientResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { PdfHeader } from 'src/app/shared/models/pdfHeader';
import { SelectCasesComponent } from '../../components/select-cases/select-cases.component';
import { SelectEbeuComponent } from '../../components/select-ebeu/select-ebeu.component';
import { ResetCaseComponent } from '../../components/reset-case/reset-case.component';
import { Messages } from 'src/app/shared/locale/messages';
import { SzakmaKod } from 'src/app/shared/models/szakmakod';

@Component({
  selector: 'app-histologicalreport',
  templateUrl: './histologicalreport.component.html',
  styleUrls: ['./histologicalreport.component.css']
})
export class HistologicalreportComponent implements OnInit {

  currentYear = new Date().toISOString().substr(0, 4);

  cases: Array<GetCaseByIdResponse>;
  histologyReqLocalizacio = new Array<string>();
  currentIdOfCase: number;

  printablePdf: string;
  pdfHeaders: Array<PdfHeader>;
  pdfHeader = new FormControl('');

  makroszkop = [new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''),
                new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl('')];

  mikroszkop = [new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''),
                new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl('')];

  korszovdiag = [new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''),
                 new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl('')];

  bnoRes = [new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''),
            new FormControl(''), new FormControl(''), new FormControl(''), new FormControl(''), new FormControl('')];

  snomed = [new FormControl(null), new FormControl(null), new FormControl(null), new FormControl(null), new FormControl(null),
            new FormControl(null), new FormControl(null), new FormControl(null), new FormControl(null), new FormControl(null)];


  who25040 = new FormControl();
  who29000 = new FormControl();
  who29050 = new FormControl();
  who29070 = new FormControl();
  who29400 = new FormControl();
  who29450 = new FormControl();
  who27000 = new FormControl();
  who25030 = new FormControl();


  histologyReportFormGroup = new FormGroup({
    who25040: this.who25040,
    who29000: this.who29000,
    who29050: this.who29050,
    who29070: this.who29070,
    who29400: this.who29400,
    who29450: this.who29450,
    who25030: this.who25030,
    who27000: this.who27000
  });
  logPrefix: number;
  logNumber = new FormControl('');
  bno = new FormControl(null);
  eesztBeutaloAzon = new FormControl('');
  beutaloAzon = new FormControl('');
  arrivalTime = new FormControl('');
  responseCreationDate = new FormControl('');
  doctorName = new FormControl('');

  beutaloIdoszakKezdet = new FormControl('');
  beutaloIdoszakVege = new FormControl('');
  breakGlass = new FormControl(false);
  breakOkGlass = new FormControl('');
  ellatasIdoszakKezdete = new FormControl('');
  ellatasIdoszakVege = new FormControl('');
  sendEmailNotificationControl = new FormControl(true);

  szakmakodControl = new FormControl(null);

  histologyResDataFormGroup = new FormGroup({
    logNumber: this.logNumber,
    bno: this.bno,
    eesztBeutaloAzon: this.eesztBeutaloAzon,
    beutaloAzon: this.beutaloAzon,
    arrivalTime: this.arrivalTime,
    responseCreationDate: this.responseCreationDate,
    doctorName: this.doctorName,
    pdfheaders: this.pdfHeader
  });

  user: GetUserByUserNameResponse;
  currentPatient: GetPatientResponse;
  ebeuResponse: Ebeu[];
  isBeutalokeres = false;
  bnoList: Array<Bno>;
  bnoStringList: Array<string>;
  snomedList: Array<string>;
  szakmakodok: Array<SzakmaKod>;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public generalService: GeneralService,
    public toasterService: ToastrService,
    public formService: FormService,
    private sharedDataService: ShareddataService,
    private dialog: MatDialog,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.findAllSzakmakod();
    this.findAllSnomed();
    this.findAllBno();
    this.getPdfHeadersBySpId(this.user.servicePointId);
    this.getCasesByCaseId(+this.route.snapshot.paramMap.get('caseId'));

    this.sharedDataService.currentPatient.subscribe(data => {
      this.currentPatient = data;
    });

  }

  findAllSzakmakod() {
    this.apiService.getAllSzakmaKod().subscribe(response => {
      this.szakmakodok = response;
    });
  }


  findAllBno() {
    this.apiService.findAllBno().subscribe(response => {
      this.bnoStringList = response.map(x => x.code + ' - ' + x.name);
      this.bnoList = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  findAllSnomed() {
    this.apiService.findAllSnomed().subscribe(response => {
      this.snomedList = response.map(x =>  x.detail + ' - ' + x.snomed);
    }, (error: Response) => {
      console.error(error);
    });
  }



  saveHistologyRes(isValidated: boolean) {

    let technicalUserId: number;

    this.apiService.getServicePointById(this.user.servicePointId).subscribe(response => {
      technicalUserId = response.technicalUserId;
    }, error => {
      console.error(error);
    });

    const bnoList: Array<string> = [];
    const snomed: Array<string> = [];
    const korszovettaniDiagnozis: Array<string> = [];
    const makroszkoposLeiras: Array<string> = [];
    const mikroszkoposLeiras: Array<string> = [];

    for (let i = 0 ; i < 10 ; i++) {
      bnoList.push(this.bnoRes[i].value);
      snomed.push(this.snomed[i].value);
      korszovettaniDiagnozis.push(this.korszovdiag[i].value);
      makroszkoposLeiras.push(this.makroszkop[i].value);
      mikroszkoposLeiras.push(this.mikroszkop[i].value);
    }

    if (this.bno.value && this.logNumber.value && this.pdfHeader.value) {
      if (this.logNumber.value.length <= 8 ) {
        const request: SaveHistologyResRequest = {
          szakmakodId: this.szakmakodControl.value,
          sendEmailNotification: this.sendEmailNotificationControl.value,
          preScreeningSpecialistId: null,
          validatorUserId: null,
          caseId: +this.route.snapshot.paramMap.get('caseId'),
          creatorUsername: this.user.username,
          isValidated,
          patientId: +this.route.snapshot.paramMap.get('patientId'),
          servicePointId: this.user.servicePointId,
          beutaloId: this.eesztBeutaloAzon.value,
          customBeutaloId: this.beutaloAzon.value,
          diagnose: this.bno.value.substr(0, 5),
          logNumber: this.logNumber.value,
          pdfHeaderId: this.pdfHeader.value,
          technicalUserId,
          szovettanResXml: {
            bnoList: {
              detailList: bnoList
            },
            korszovettaniDiagnozis: {
              detailList: korszovettaniDiagnozis
            },
            makroszkoposLeiras: {
              detailList: makroszkoposLeiras
            },
            mikroszkoposLeiras: {
              detailList: mikroszkoposLeiras
            },
            snomed: {
              detailList: snomed
            },
            szovettanWhoXml: {
              kiegeszitoPontszamVolumiozus: this.who29070.value,
              mikroszkoposVizsgalatZiehl: this.who25040.value,
              szovettaniVizsgalatImmunhisz: this.who29050.value,
              szovettaniVizsgalatASzokaso: this.who29000.value,
              cytologiaiVizsgalatImmuncyto: this.who29450.value,
              diagnosztikusAspiratiosExfol: this.who29400.value,
              mikroszkiposVizsgalatTenyesztetlenAnyagbol: this.who25030.value,
              gombaVizsgalatDirektKenetben: this.who27000.value
            }
          }
        };

        this.apiService.saveHistologyResRequest(request).subscribe(response => {
          if (this.logNumber.enabled) {
            this.setLogNumber(request.caseId, this.logNumber.value);
          }
          this.toasterService.success(
            this.messages.success.SUCCESS_SAVE_OK[this.messages.locale],
            this.messages.success.SUCCESS_TITLE[this.messages.locale], { positionClass: 'toast-top-center' });
          const currentUrl = this.router.url;
          // console.log(currentUrl);
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.generalService.goToPage(currentUrl);
          });
        }, (error: HttpErrorResponse) => {
          this.toasterService.error(
            error.error.message,
            this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
        });
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_LENGTH[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    } else {
      if (!this.logNumber.value) {
        this.toasterService.error(
          this.messages.error.ERROR_LOGNUMBER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      } else if (!this.bno.value) {
        this.toasterService.error(
          this.messages.error.ERROR_BNO_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      } else {
        this.toasterService.error(
          this.messages.error.ERROR_HEADER_REQUIRED[this.messages.locale],
          this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], { positionClass: 'toast-top-center' });
      }
    }
  }


  getCasesByCaseId(caseId: number) {
    this.cases = [];
    this.histologyReqLocalizacio = [];
    const reqCases = new Array<GetCaseByIdResponse>();
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(element => {
        if (element.type.includes('REQ')) {
          this.szakmakodControl.setValue(element.values['aspiracios'] ?
          this.szakmakodok.find(x => x.code === '5403').id :
          this.szakmakodok.find(x => x.code === '5401').id
          );
        }

        if (element.type.includes('RES')) {
           if (((this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') && element.isValidated) ||
            (this.user.roleCode !== 'REFERRING_DOCTOR' && this.user.roleCode !== 'REFERRING_ASSISTANT')) {
            this.cases.push(element);
          }
        } else {
          reqCases.push(element);
        }
      });

      Object.entries(reqCases[reqCases.length - 1].values).map(entry => {
        if (entry[0].includes('lokalizacio')) {
          if (entry[1]?.length >= 1) {
            this.histologyReqLocalizacio.push(entry[1]);
          }
        }
      });

      if (this.cases?.length > 1) {
        const cases = this.cases;
        const dialogRef = this.dialog.open(SelectCasesComponent, {
          data: {cases}
        });
        const sub = dialogRef.componentInstance.selectedCase.subscribe((data: number) => {
          this.getCaseById(data);
          dialogRef.close();
        });
      } else {
        if (this.cases !== []) {
          this.getCaseById(this.cases[0]?.id);
          this.getDescription(response[0]?.id, true);
        }
      }
    });
  }

  getCaseById(id: number) {
    if (this.user.roleCode === 'REFERRING_DOCTOR' || this.user.roleCode === 'REFERRING_ASSISTANT') {
      this.makroszkop.forEach(element => {
        element.disable();
      });
      this.mikroszkop.forEach(element => {
        element.disable();
      });
      this.korszovdiag.forEach(element => {
        element.disable();
      });
      this.bnoRes.forEach(element => {
        element.disable();
      });
      this.snomed.forEach(element => {
        element.disable();
      });
      this.histologyReportFormGroup.disable();
      this.histologyResDataFormGroup.disable();
    }
    this.cases.forEach(element => {
      this.printablePdf = element.pdf;
      if (element.id === id) {
        this.bno.setValue(element.diagnose);
        for (let i = 0; i < 10; i++ ) {
          this.makroszkop[i].setValue(element.values['makroszkoposLeiras']['detailList']?.[i]);
          this.mikroszkop[i].setValue(element.values['mikroszkoposLeiras']['detailList']?.[i]);
          this.korszovdiag[i].setValue(element.values['korszovettaniDiagnozis']['detailList']?.[i]);
          this.bnoRes[i].setValue(element.values['bnoList']['detailList']?.[i]);
          this.snomed[i].setValue(element.values['snomed']['detailList']?.[i]);
        }
        this.who25040.setValue(element.values['szovettanWhoXml']['mikroszkoposVizsgalatZiehl']);
        this.who29000.setValue(element.values['szovettanWhoXml']['szovettaniVizsgalatASzokaso']);
        this.who29050.setValue(element.values['szovettanWhoXml']['szovettaniVizsgalatImmunhisz']);
        this.who29070.setValue(element.values['szovettanWhoXml']['kiegeszitoPontszamVolumiozus']);
        this.who29400.setValue(element.values['szovettanWhoXml']['diagnosztikusAspiratiosExfol']);
        this.who29450.setValue(element.values['szovettanWhoXml']['cytologiaiVizsgalatImmuncyto']);
        this.who25030.setValue(element.values['szovettanWhoXml']['mikroszkiposVizsgalatTenyesztetlenAnyagbol']);
        this.who27000.setValue(element.values['szovettanWhoXml']['gombaVizsgalatDirektKenetben']);

        this.getDescription(element.id);
      }
    });
  }

  updateStatus() {
    this.apiService.updateStatus(+this.route.snapshot.paramMap.get('caseId'), 'kész').subscribe(response => {
      this.generalService.goToPage('reports');
    }, (error: Response) => {
      console.error(error);
    });
  }


  deleteCase() {
    const dialogRef = this.dialog.open(ResetCaseComponent);
    dialogRef.componentInstance.option.subscribe(response => {
      this.makroszkop.forEach(element => {
        element.reset();
      });
      this.mikroszkop.forEach(element => {
        element.reset();
      });
      this.korszovdiag.forEach(element => {
        element.reset();
      });
      this.bnoRes.forEach(element => {
        element.reset();
      });
      this.snomed.forEach(element => {
        element.reset();
      });
      this.histologyReportFormGroup.reset();
      dialogRef.close();
    });
  }

  getDescription(id: number, isNewDocument?: boolean) {
    const currentDate = this.generalService.getCurrentDate();
    this.apiService.getDescription(id).subscribe(response => {
      this.logPrefix = response.logPrefix;
      if (response.logNumber) {
        // this.logNumber.disable();
        this.logNumber.setValue(response.logNumber);
      }
      if (response.customBeutaloId) {
        this.beutaloAzon.disable();
        this.beutaloAzon.setValue(response.customBeutaloId);
      }
      if (response.arrivalTime) {
        response.arrivalTime = this.sharedDataService.
        dateToDateTimeStringHuman(new Date(this.sharedDataService.DateStringConvert(response.arrivalTime)));
        this.arrivalTime.disable();
        this.arrivalTime.setValue(response.arrivalTime.replace('T', ' '));
      }
      this.responseCreationDate.setValue(currentDate.substr(0, currentDate.indexOf('.')).replace('T', ' '));
      this.responseCreationDate.disable();
      if (isNewDocument) {
        if (this.user.roleCode === 'REFERRING_ASSISTANT' || this.user.roleCode === 'REFERRING_DOCTR') {
          this.doctorName.setValue('');
          this.doctorName.disable();
        } else {
          this.doctorName.setValue(this.user.name);
          this.doctorName.disable();
        }
      } else {
        this.doctorName.setValue(response.doctorName);
        this.doctorName.disable();
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

  setLogNumber(caseId: number, logNumber: string) {
    const request: SetLogNumberRequest = {
      caseId,
      logNumber
    };

    this.apiService.setLogNumber(request).subscribe(response => {
      // this.generalService.goToPage('reports');
    }, (error: Response) => {
      console.error(error);
    });

  }

  checkEszigTokenValid() {
    if (JSON.parse(localStorage.getItem('eszigTokenValid')))  {
      const dialogRef = this.dialog.open(SelectEbeuComponent);
      dialogRef.componentInstance.selectedEbeu.subscribe(ebeu => {
        this.setEbeuAzon(ebeu);
        dialogRef.close();
      });
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_EESZT_AUTH_EXPIRED[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'}
        );
    }
  }

  setEbeuAzon(ebeu: Ebeu) {
    this.isBeutalokeres = false;
    this.eesztBeutaloAzon.setValue(ebeu.businessContent.beutaloAzonosito);
    this.eesztBeutaloAzon.disable();
  }

  getPdfHeadersBySpId(spId: number) {
    this.apiService.getPdfHeadersBySpId(spId).subscribe(response => {
      this.pdfHeaders = response;
      if (response.length === 1) {
        this.pdfHeader.setValue(response[0].id);
      }
    }, (error: Response) => {
      console.error(error);
    });
  }

}
