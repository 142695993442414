<div class="wrapper">
    <form [formGroup]="patientFormGroup" id="#searchForm">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-inline">
            <label class="searchLabel" i18n>taj szám</label>
            <input [ngClass]="!validSsn && ssn.value && ssn.enabled ? 'nonValidSearchInput' : 'searchInput'" [formControl]="ssn" class="searchInput form-control required" type="text"/>
            <i *ngIf="!validSsn && ssn.value && ssn.enabled" class="fa fa-exclamation icon" aria-hidden="true" matTooltip="Ismeretlen tajszám esetén használja a 90000007-es tajszámot!"></i>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-inline">
            <label class="searchLabel" i18n>születési idő</label>
            <input [formControl]="birthDate" class="dateInput searchInput form-control required" type="date" max="2300-12-31" min="1900-01-01"/>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-inline">
            <button [disabled]="!validSsn" [ngClass]="!validSsn ? 'disabledSearchButton mx-2' : 'searchButton mx-2'" *ngIf="router.url.includes('/dashboard')" (click)="getPatientBy2T(ssn.value, birthDate.value)" i18n>keresés</button>
            <!-- <button [disabled]="!validSsn" [ngClass]="!validSsn ? 'disabledSearchButton mx-2' : 'searchButton mx-2'" *ngIf="!newPatient && router.url === '/dashboard'" (click)="getPatientBy2T(ssn.value, birthDate.value)">keresés</button> -->
            <button [disabled]="!validSsn" [ngClass]="!validSsn ? 'disabledSearchButton ml-2' : 'searchButton ml-2'" *ngIf="newPatient && router.url.includes('/dashboard')" (click)="savePatient()" i18n>mentés</button>
            <button *ngIf="canModifyPatient && !newPatient" (click)="startModifyPatient()" class="searchButton" style="min-width: 150px;" i18n>adatok módosítása</button>
            <button *ngIf="patientModificationStarted && !newPatient" (click)="updatePatient()" class="searchButton" style="min-width: 180px;" i18n>módosítások mentése</button>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-4">
          <div class="form-inline">
              <label class="searchLabel" i18n>név</label>
              <input [formControl]="name" class="searchInput form-control required" type="text"/>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-inline">
            <label class="searchLabel" i18n>születési név</label>
            <input [formControl]="birthName" class="searchInput form-control required" type="text"/>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-inline">
            <label class="searchLabel" i18n>anyja neve</label>
            <input [formControl]="mothersName" class="searchInput form-control required" type="text"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div class="form-inline mt-3">
            <label class="searchLabel" i18n>cím</label>
            <input [formControl]="address" class="addressInput form-control required" type="text"/>
          </div>
        </div>
      </div>
  </form>
</div>
