<app-dashboard></app-dashboard>
<div class="wrapper row" style="margin-left: 0; margin-right:0">
  <div class="col-sm-10">
    <div class="row">
      <div class="col">
        <div class="diagnoseWrapper">
          <label class="cytologyHeaderLabel" i18n>*DIAGNÓZIS (TBS)</label>
          <div class="form-group form-check ml-5">
            <input [formControl]="diagnozisNegativ" type="checkbox" class="form-check-input" id="diagnozisNegativ">
            <label class="form-check-label" for="diagnozisNegativ" i18n>NEGATÍV</label>
          </div>
          <div class="form-group form-check ml-5">
              <input [formControl]="diagnozisEgyeb" type="checkbox" class="form-check-input" id="diagnozisEgyeb">
              <label class="form-check-label" for="diagnozisEgyeb" i18n>EGYÉB</label>
          </div>
          <div class="form-group form-check ml-5">
              <input [formControl]="diagnozisKoros" type="checkbox" class="form-check-input" id="diagnozisKoros">
              <label class="form-check-label" for="diagnozisKoros" i18n>KÓROS, NEOPLASTICUS ELVÁLTOZÁS</label>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="d-flex align-items-center">
              <label class="mr-5" i18n>Physiologiás</label>
              <div class="d-flex align-items-center">
                <label class="mr-4" i18n> Döderlein flóra</label>
                <div class="form-group form-check">
                  <input [formControl]="dodorleinFlora" type="checkbox" class="form-check-input" id="dodorleinFlora">
                  <label class="form-check-label mr-2" for="dodorleinFlora" i18n>Igen</label>
                </div>
                <div class="form-group form-check">
                  <input [formControl]="dodorleinFloraNem" type="checkbox" class="form-check-input" id="dodorleinFloraNem">
                  <label class="form-check-label" for="dodorleinFloraNem" i18n>Nem</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="cytologyHeaderLabel" i18n>Kórokozók</label>
          </div>
        </div>
        <div class="row ml-3">
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokTrichomonas" type="checkbox" class="form-check-input" id="korokozokTrichomonas">
              <label class="form-check-label" for="korokozokTrichomonas" i18n>Trichomonas</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokGomba" type="checkbox" class="form-check-input" id="korokozokGomba">
              <label class="form-check-label" for="korokozokGomba" i18n>Gomba (Candida)</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokBakterialis" type="checkbox" class="form-check-input" id="korokozokBakterialis">
              <label class="form-check-label" for="korokozokBakterialis" i18n>Bakteriális vaginosis</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokVegyes" type="checkbox" class="form-check-input" id="korokozokVegyes">
              <label class="form-check-label" for="korokozokVegyes" i18n>Vegyes baktérium</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokActynomices" type="checkbox" class="form-check-input" id="korokozokActynomices">
              <label class="form-check-label" for="korokozokActynomices" i18n>Actynomices</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokHerpes" type="checkbox" class="form-check-input" id="korokozokHerpes">
              <label class="form-check-label" for="korokozokHerpes" i18n>Herpes Simplex Vírus</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="korokozokCitomegalogVirus" type="checkbox" class="form-check-input" id="korokozokCitomegalogVirus">
              <label class="form-check-label" for="korokozokCitomegalogVirus" i18n>Citomegalovírus</label>
            </div>
            <input [formControl]="korokozokEgyeb" class="form-control cytoDataInput ml-3">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label class="cytologyHeaderLabel" i18n>Egyéb</label>
          </div>
        </div>
        <div class="row ml-3">
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="egyebEndometriumSejtek" type="checkbox" class="form-check-input" id="egyebEndometriumSejtek">
              <label class="form-check-label" for="egyebEndometriumSejtek" i18n>Endometrium sejtek ≥ 45 éves kor</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="form-inline">
              <label class="mr-2" i18n>Kenet beérkezésének időpontja</label>
              <input type="date" [lang]="dateType" class="cytoDataInput form-control" [formControl]="kenetBeerkezesIdoPontja" (change)="checkValidDate(kenetBeerkezesIdoPontja.value)" min="1900-01-01" max="9999-12-31"/>
            </div>
          </div>
        </div>
        <div class="form-inline mt-3">
          <label class="cytologyHeaderLabel" i18n>*KENETTÍPUS</label>
          <div class="form-group form-check ml-3">
            <input [formControl]="kenettipusPap" type="checkbox" class="form-check-input" id="kenettipusPap">
            <label class="form-check-label" for="kenettipusPap" i18n>Pap.</label>
          </div>
          <div class="form-group form-check ml-5">
              <input [formControl]="kenettipusLB" type="checkbox" class="form-check-input" id="kenettipusLB">
              <label class="form-check-label" for="kenettipusLB" i18n>L.B.</label>
          </div>
        </div>
        <div class="form-inline mt-2">
          <label class="cytologyHeaderLabel" i18n>*ÉRTÉKELHETŐ-E A KENET</label>
        </div>
        <div class="row mt-2 ml-3">
          <div class="col">
            <div class="form-group form-check">
              <input [formControl]="ertekelhetoKenetFeldolgozott" type="checkbox" class="form-check-input" id="ertekelhetoKenetFeldolgozott">
              <label class="form-check-label" for="ertekelhetoKenetFeldolgozott" i18n>Feldolgozott</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check">
              <input [formControl]="ertekelhetoKenetNemFeldolgozott" type="checkbox" class="form-check-input" id="ertekelhetoKenetNemFeldolgozott">
              <label class="form-check-label" for="ertekelhetoKenetNemFeldolgozott" i18n>Nem feldolgozott</label>
            </div>
            <div class="form-inline">
              <label class="form-check-label" for="ertekelhetoKenetNemFeldolgozott" i18n>mert</label>
              <input [formControl]="ertekelhetoKenetNemFeldolgozottMert" type="text" class="cytoDataInput form-control" id="ertekelhetoKenetNemFeldolgozott">
            </div>
          </div>
        </div>
        <div class="row ml-3 mt-2">
          <div class="col">
            <div class="form-group form-check">
              <input [formControl]="ertekelhetoKenetErtekelhetoDe" type="checkbox" class="form-check-input" id="ertekelhetoKenetErtekelhetoDe">
              <label class="form-check-label" for="ertekelhetoKenetErtekelhetoDe" i18n>Értékelhető, de</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check">
              <input [formControl]="ertekelhetoKenetNemErtekelheto" type="checkbox" class="form-check-input" id="ertekelhetoKenetNemErtekelheto">
              <label class="form-check-label" for="ertekelhetoKenetNemErtekelheto" i18n>Nem értékelhető, mert</label>
            </div>
          </div>
        </div>
        <div class="row ml-4">
          <div class="col">
            <ng-select class="ml-3 mb-1" [formControl]="ertekelhetoKenetCellularitas" [items]="['Sejtszegény', 'Laphám komp kevés']" placeholder="Cellularitás"></ng-select>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="ertekelhetoKenetEndorCervicalis" type="checkbox" class="form-check-input" id="ertekelhetoKenetEndorCervicalis">
              <label class="form-check-label" for="ertekelhetoKenetEndorCervicalis" i18n>Endocervicalis/ transformatios zóna hiányzik</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="ertekelhetoKenetZavaroTenyezok" type="checkbox" class="form-check-input" id="ertekelhetoKenetZavaroTenyezok">
              <label class="form-check-label" for="ertekelhetoKenetZavaroTenyezok" i18n>Zavaró tényezők</label>
            </div>
            <div class="form-group form-check ml-5 mb-1">
              <input [formControl]="ertekelhetoKenetLaphamsejtek" type="checkbox" class="form-check-input" id="ertekelhetoKenetLaphamsejtek">
              <label class="form-check-label" for="ertekelhetoKenetLaphamsejtek" i18n>Laphámsejtek károsodása</label>
            </div>
            <div class="row" style="margin-left: 33px">
              <div class="col">
                <div class="form-inline" style="min-width: 200px">
                  <div class="form-group form-check mb-1 mr-2">
                    <input [formControl]="ertekelhetoKenetKiszaradas" type="checkbox" class="form-check-input" id="ertekelhetoKenetKiszaradas">
                    <label class="form-check-label" for="ertekelhetoKenetKiszaradas" i18n>Kiszáradás</label>
                  </div>
                  <div class="form-group form-check mb-1">
                    <input [formControl]="ertekelhetoKenetNyakos" type="checkbox" class="form-check-input" id="ertekelhetoKenetNyakos">
                    <label class="form-check-label" for="ertekelhetoKenetNyakos" i18n>Nyákos</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group form-check ml-5 mb-1">
              <input [formControl]="ertekelhetoKenetSulyosAcutGyulladas" type="checkbox" class="form-check-input" id="ertekelhetoKenetSulyosAcutGyulladas">
              <label class="form-check-label" for="ertekelhetoKenetSulyosAcutGyulladas" style="width: 164px" i18n>Súlyos acut gyulladás</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="ertekelhetoKenetGatloAnyagok" type="checkbox" class="form-check-input" id="ertekelhetoKenetGatloAnyagok">
              <label class="form-check-label" for="ertekelhetoKenetGatloAnyagok" i18n>Gátló anyagok</label>
            </div>
            <div class="form-group form-check ml-5 mb-1">
              <input [formControl]="ertekelhetoKenetHuvelyKup" type="checkbox" class="form-check-input" id="ertekelhetoKenetHuvelyKup">
              <label class="form-check-label" for="ertekelhetoKenetHuvelyKup" style="width: 164px" i18n>Hüvelykúp vivőanyag</label>
            </div>
            <div class="form-group form-check ml-5 mb-1" >
              <input [formControl]="ertekelhetoKenetTulzottanVeresKenet" type="checkbox" class="form-check-input" id="ertekelhetoKenetTulzottanVeresKenet">
              <label class="form-check-label" for="ertekelhetoKenetTulzottanVeresKenet" style="width: 164px" i18n>Túlzottan véres kenet</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="ertekelhetoKenetNemAzonosithato" type="checkbox" class="form-check-input" id="ertekelhetoKenetNemAzonosithato">
              <label class="form-check-label" for="ertekelhetoKenetNemAzonosithato" i18n>Nem azonosítható</label>
            </div>
            <div class="form-group form-check ml-3 mb-1" >
              <input [formControl]="ertekelhetoKenetSejtszegeny" type="checkbox" class="form-check-input" id="ertekelhetoKenetSejtszegeny">
              <label class="form-check-label" for="ertekelhetoKenetSejtszegeny" i18n>Sejtszegény</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="ertekelhetoKenetEgyeb" type="checkbox" class="form-check-input" id="ertekelhetoKenetEgyeb">
              <label class="form-check-label" for="ertekelhetoKenetEgyeb" i18n>Egyéb: </label>
              <input [formControl]="ertekelhetoKenetEgyebMegjegyzes" class="cytoDataInput form-control"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col text-left">
        <label class="cytologyHeaderLabel" i18n>Nem neoplasztikus sejtelváltozás</label>
      </div>
    </div>
    <div class="row ml-3">
      <div class="col">
        <div class="form-group form-check mb-1">
          <input [formControl]="nemNeoplasztikusNemNeopl" type="checkbox" class="form-check-input" id="nemNeoplasztikusNemNeopl">
          <label class="form-check-label" for="nemNeoplasztikusNemNeopl" i18n>Nem neopl. sejtelváltozások</label>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="nemNeoplasztikusLapham" type="checkbox" class="form-check-input" id="nemNeoplasztikusLapham">
              <label class="form-check-label" for="nemNeoplasztikusLapham" i18n>Laphám metaplasia</label>
            </div>
            <div class="form-group form-check ml-5 mb-1">
              <input [formControl]="nemNeoplasztikusEretlen" type="checkbox" class="form-check-input" id="nemNeoplasztikusEretlen">
              <label class="form-check-label" for="nemNeoplasztikusEretlen" i18n>Éretlen</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="nemNeoplasztikusElszarusodas" type="checkbox" class="form-check-input" id="nemNeoplasztikusElszarusodas">
              <label class="form-check-label" for="nemNeoplasztikusElszarusodas" i18n>Elszarusodás</label>
            </div>
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="nemNeoplasztikusTubalis" type="checkbox" class="form-check-input" id="nemNeoplasztikusTubalis">
              <label class="form-check-label" for="nemNeoplasztikusTubalis" i18n>Tubalis metaplasia</label>
            </div>
            <div class="form-inline ml-3">
              <div class="form-group form-check mr-2">
                <input [formControl]="nemNeoplasztikusEgyeb" type="checkbox" class="form-check-input" id="nemNeoplasztikusEgyeb">
                <label class="form-check-label" for="nemNeoplasztikusEgyeb" i18n>Egyéb: </label>
              </div>
              <div>
                <ng-select [formControl]="nemNeoplasztikusEgyebDrop" [items]="['Rezerv sejtes hyperplasia', 'Reaktív mirigysejtek', 'Histiocyták', 'Neutrophyl ganulocyták', 'Oestrogen hatás', 'Gestagen hatás', 'HPK', 'LUS', 'Anti Oe hatás', 'Oestrogen hiány', 'HPV minor jelek', 'Pseudoparakeratosis']" style="width: 200px;"></ng-select>
                <ng-select [formControl]="nemNeoplasztikusEgyebDrop2" [items]="['Rezerv sejtes hyperplasia', 'Reaktív mirigysejtek', 'Histiocyták', 'Neutrophyl ganulocyták', 'Oestrogen hatás', 'Gestagen hatás', 'HPK', 'LUS', 'Anti Oe hatás', 'Oestrogen hiány', 'HPV minor jelek', 'Pseudoparakeratosis']" style="width: 200px;" class="mt-1"></ng-select>
              </div>
            </div>
          </div>
          <div class="col ml-3">
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusAtrophia" type="checkbox" class="form-check-input" id="nemNeoplasztikusAtrophia">
              <label class="form-check-label" for="nemNeoplasztikusAtrophia" i18n>Atrophia</label>
            </div>
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusAtrophiasColpitis" type="checkbox" class="form-check-input" id="nemNeoplasztikusAtrophiasColpitis">
              <label class="form-check-label" for="nemNeoplasztikusAtrophiasColpitis" i18n>Atrophiás colpitis</label>
            </div>
            <div class="form-group form-check mb-1 mr-1">
              <input [formControl]="nemNeoplasztikusGraviditashozTarsulo" type="checkbox" class="form-check-input" id="nemNeoplasztikusGraviditashozTarsulo">
              <label class="form-check-label" for="nemNeoplasztikusGraviditashozTarsulo" i18n>Graviditáshoz társuló</label>
            </div>
            <ng-select class="my-1 ml-3" [formControl]="nemNeoplasztikusGraviditas" [items]="['Decidua', 'Arias Stella']" style="width: 140px"></ng-select>
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusPostPartum" type="checkbox" class="form-check-input" id="nemNeoplasztikusPostPartum">
              <label class="form-check-label" for="nemNeoplasztikusPostPartum" i18n>Post partum</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group form-check ml-3 mb-1">
          <input [formControl]="nemNeoplasztikusReaktivSejtelvaltozasokkalJaro" type="checkbox" class="form-check-input" id="nemNeoplasztikusReaktivSejtelvaltozasokkalJaro">
          <label class="form-check-label" for="nemNeoplasztikusReaktivSejtelvaltozasokkalJaro" i18n>Reaktív sejtelváltozásokkal járó</label>
        </div>
        <ng-select class="ml-5 w-50" [formControl]="nemNeoplasztikusReaktivGyulladasMerteke" [items]="['Enyhe gyulladás','Közepes gyulladás','Súlyos gyulladás']"placeholder="Gyulladás mértéke"></ng-select>
        <div class="form-group form-check ml-5 mb-1">
          <input [formControl]="nemNeoplasztikusLymphocytas" type="checkbox" class="form-check-input" id="nemNeoplasztikusLymphocytas">
          <label class="form-check-label" for="nemNeoplasztikusLymphocytas" i18n>Lymphocytas cervicitis</label>
        </div>
        <div class="row" style="margin-left: 33px;">
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusSugarhatas" type="checkbox" class="form-check-input" id="nemNeoplasztikusSugarhatas">
              <label class="form-check-label" for="nemNeoplasztikusSugarhatas" i18n>Sugárhatás</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusKesoi" type="checkbox" class="form-check-input" id="nemNeoplasztikusKesoi">
              <label class="form-check-label" for="nemNeoplasztikusKesoi" i18n>Késői</label>
            </div>
          </div>
        </div>
        <div class="row" style="margin-left: 33px;">
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusKemoterapiasHatas" type="checkbox" class="form-check-input" id="nemNeoplasztikusKemoterapiasHatas">
              <label class="form-check-label" for="nemNeoplasztikusKemoterapiasHatas" i18n>Kemoterápiás hatás</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="nemNeoplasztikusKemoKesoi" type="checkbox" class="form-check-input" id="nemNeoplasztikusKemoKesoi">
              <label class="form-check-label" for="nemNeoplasztikusKemoKesoi" i18n>Késői</label>
            </div>
          </div>
        </div>
        <div class="form-group form-check ml-5 mb-1">
          <input [formControl]="nemNeoplasztikusIUDMechanikusHatas" type="checkbox" class="form-check-input" id="nemNeoplasztikusIUDMechanikusHatas">
          <label class="form-check-label" for="nemNeoplasztikusIUDMechanikusHatas" i18n>(IUD) Mechanikus hatás</label>
        </div>
        <div class="form-group form-check ml-3 mb-1">
          <input [formControl]="nemNeoplasztikusMirigysejtekHysterect" type="checkbox" class="form-check-input" id="nemNeoplasztikusMirigysejtekHysterect">
          <label class="form-check-label" for="nemNeoplasztikusMirigysejtekHysterect" i18n>Mirigysejtek hysterect. után</label>
        </div>
        <div class="form-inline ml-3 mb-1">
          <div class="form-group form-check mb-1 mr-2">
            <input [formControl]="nemNeoplasztikusRepair" type="checkbox" class="form-check-input" id="nemNeoplasztikusRepair">
            <label class="form-check-label" for="nemNeoplasztikusRepair">Repair</label>
          </div>
          <input [formControl]="nemNeoplasztikusRepairMegjegyzes" class="cytoDataInput form-control">
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label class="cytologyHeaderLabel" i18n>Kóros neoplasztikus hámelváltozás</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label class="cytologyHeaderLabel" i18n>Laphámsejtek</label>
      </div>
    </div>
    <div class="row mt-2 ml-3">
      <div class="col">
        <div class="form-group form-check mb-1">
          <input [formControl]="laphamsejtekAtipusos" type="checkbox" class="form-check-input" id="laphamsejtekAtipusos">
          <label class="form-check-label" for="laphamsejtekAtipusos" i18n>Atipusos laphámsejtek (ASC)</label>
        </div>
        <div class="form-group form-check mb-1 ml-3">
          <input [formControl]="laphamsejtekNemMeghatarozhato" type="checkbox" class="form-check-input" id="laphamsejtekNemMeghatarozhato">
          <label class="form-check-label" for="laphamsejtekNemMeghatarozhato" i18n>Nem meghatározható okból (ASC-US)</label>
        </div>
        <div class="form-group form-check mb-1 ml-3">
          <input [formControl]="laphamsejtekNemZarhatoki" type="checkbox" class="form-check-input" id="laphamsejtekNemZarhatoki">
          <label class="form-check-label" for="laphamsejtekNemZarhatoki" i18n>Nem zárható ki HSIL (ASC-H)</label>
        </div>
      </div>
      <div class="col">
        <div class="form-group form-check mb-1">
          <input [formControl]="laphamsejtekEnyhefoku" type="checkbox" class="form-check-input" id="laphamsejtekEnyhefoku">
          <label class="form-check-label" for="laphamsejtekEnyhefoku" i18n>Enyhe fokú intreapithelialis laphám laesio (LSIL)</label>
        </div>
        <div class="form-group form-check ml-3 mb-1">
          <input [formControl]="laphamsejtekHPV" type="checkbox" class="form-check-input" id="laphamsejtekHPV">
          <label class="form-check-label" for="laphamsejtekHPV" i18n>HPV</label>
        </div>
        <div class="form-group form-check ml-3 mb-1">
          <input [formControl]="laphamsejtekCIN1" type="checkbox" class="form-check-input" id="laphamsejtekCIN1">
          <label class="form-check-label" for="laphamsejtekCIN1" i18n>CIN 1</label>
        </div>
      </div>
      <div class="col">
        <div class="form-group form-check ml-3 mb-1">
          <input [formControl]="laphamsejtekKifejezettFoku" type="checkbox" class="form-check-input" id="laphamsejtekKifejezettFoku">
          <label class="form-check-label" for="laphamsejtekKifejezettFoku" i18n>Kifejezett fokú intreapithelialis laphám laesio (HSIL)</label>
        </div>
        <div class="form-inline ml-3 mb-1 mt-1">
          <div class="form-group form-check ml-3 mb-1">
            <input [formControl]="laphamsejtekCIN2" type="checkbox" class="form-check-input" id="laphamsejtekCIN2">
            <label class="form-check-label" for="laphamsejtekCIN2">CIN 2</label>
          </div>
          <div class="form-group form-check ml-3 mb-1">
            <input [formControl]="laphamsejtekCIN3" type="checkbox" class="form-check-input" id="laphamsejtekCIN3">
            <label class="form-check-label" for="laphamsejtekCIN3">CIN 3</label>
          </div>
          <div class="form-group form-check ml-3 mb-1">
            <input [formControl]="laphamsejtekCIS" type="checkbox" class="form-check-input" id="laphamsejtekCIS">
            <label class="form-check-label" for="laphamsejtekCIS">CIS</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="laphamsejtekMirigybeterjesedes" type="checkbox" class="form-check-input" id="laphamsejtekMirigybeterjesedes">
              <label class="form-check-label" for="laphamsejtekMirigybeterjesedes" i18n>Mirigybeterjedés</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check ml-3 mb-1">
              <input [formControl]="laphamsejtekInvasioGyanuja" type="checkbox" class="form-check-input" id="laphamsejtekInvasioGyanuja">
              <label class="form-check-label" for="laphamsejtekInvasioGyanuja" i18n>Invasio gyanúja</label>
            </div>
          </div>
        </div>
        <div class="form-group form-check ml-3 mb-1">
          <input [formControl]="laphamsejtekLaphamsejtekCarcinoma" type="checkbox" class="form-check-input" id="laphamsejtekLaphamsejtekCarcinoma">
          <label class="form-check-label" for="laphamsejtekLaphamsejtekCarcinoma" i18n>Laphámsejtes carcinoma</label>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label class="cytologyHeaderLabel" i18n>Mirigyhámsejtek</label>
        <div class="row ml-3">
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="mirigyhamsejtekAtypusos" type="checkbox" class="form-check-input" id="mirigyhamsejtekAtypusos">
              <label class="form-check-label" for="mirigyhamsejtekAtypusos"  i18n>Atypusos mirigyhámsejtek nem megh.okból (AGC-NOS)</label>
            </div>
            <div class="form-group form-check mb-1 ml-3">
              <input [formControl]="mirigyhamsejtekEndoCervicalisNOS" type="checkbox" class="form-check-input" id="mirigyhamsejtekEndoCervicalisNOS">
              <label class="form-check-label" for="mirigyhamsejtekEndoCervicalisNOS"i18n>Endocervicális sejtek - NOS</label>
            </div>
            <div class="form-group form-check mb-1 ml-3">
              <input [formControl]="mirigyhamsejtekEndometrialisNOS" type="checkbox" class="form-check-input" id="mirigyhamsejtekEndometrialisNOS">
              <label class="form-check-label" for="mirigyhamsejtekEndometrialisNOS" i18n>Endometriális sejtek - NOS</label>
            </div>
            <div class="form-group form-check mb-1 ml-3">
              <input [formControl]="mirigyhamsejtekNOS" type="checkbox" class="form-check-input" id="mirigyhamsejtekNOS">
              <label class="form-check-label" for="mirigyhamsejtekNOS" i18n>Mirigyhámsejtek - NOS</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="mirigyhamsejtekInkabbNeoplasticusAGC" type="checkbox" class="form-check-input" id="mirigyhamsejtekInkabbNeoplasticusAGC">
              <label class="form-check-label" for="mirigyhamsejtekInkabbNeoplasticusAGC" i18n>Atypusos mirigyhámsejtek, inkább neoplasticus (AGC)</label>
            </div>
            <div class="row ml-3">
              <div class="col">
                <div class="form-group form-check mb-1">
                  <input [formControl]="mirigyhamsejtekEndocervicalisInkabbNeoplast" type="checkbox" class="form-check-input" id="mirigyhamsejtekEndocervicalisInkabbNeoplast">
                  <label class="form-check-label" for="mirigyhamsejtekEndocervicalisInkabbNeoplast" i18n>Endocervicalis sejtek - inkább neoplasticus</label>
                </div>
              </div>
              <div class="col">
                <div class="form-group form-check mb-1">
                  <input [formControl]="mirigyhamsejtekMirigyhamsejtekInkabbNeoplast" type="checkbox" class="form-check-input" id="mirigyhamsejtekMirigyhamsejtekInkabbNeoplast">
                  <label class="form-check-label" for="mirigyhamsejtekMirigyhamsejtekInkabbNeoplast" i18n>Mirigyhámsejtek - inkább neoplasticus</label>
                </div>
              </div>
            </div>
            <div class="form-group form-check mb-1">
              <input [formControl]="mirigyhamsejtekEndocervicalisAIS" type="checkbox" class="form-check-input" id="mirigyhamsejtekEndocervicalisAIS">
              <label class="form-check-label" for="mirigyhamsejtekEndocervicalisAIS" i18n>Endocervicalis adenocarcinoma in situ (AIS)</label>
            </div>
            <div class="form-group form-check mb-1">
              <input [formControl]="mirigyhamsejtekAdenocarcinoma" type="checkbox" class="form-check-input" id="mirigyhamsejtekAdenocarcinoma">
              <label class="form-check-label" for="mirigyhamsejtekAdenocarcinoma" i18n>Adenocarcinoma</label>
            </div>
            <div class="row ml-3">
              <div class="col">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group form-check mb-1">
                      <input [formControl]="mirigyhamsejtekEndocervicalis" type="checkbox" class="form-check-input" id="mirigyhamsejtekEndocervicalis">
                      <label class="form-check-label" for="mirigyhamsejtekEndocervicalis" i18n>Endocervicalis</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group form-check mb-1">
                      <input [formControl]="mirigyhamsejtekEndoMetrialis" type="checkbox" class="form-check-input" id="mirigyhamsejtekEndoMetrialis">
                      <label class="form-check-label" for="mirigyhamsejtekEndoMetrialis" i18n>Endometrialis</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group form-check mb-1">
                      <input [formControl]="mirigyhamsejtekExtrauterin" type="checkbox" class="form-check-input" id="mirigyhamsejtekExtrauterin">
                      <label class="form-check-label" for="mirigyhamsejtekExtrauterin" i18n>Extrauterin</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group form-check mb-1">
                      <input [formControl]="mirigyhamsejtekAdenoNOS" type="checkbox" class="form-check-input" id="mirigyhamsejtekAdenoNOS">
                      <label class="form-check-label" for="mirigyhamsejtekAdenoNOS" i18n>NOS</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group form-check mb-1">
              <input [formControl]="egyebMalingnusElvaltozas" type="checkbox" class="form-check-input" id="egyebMalingnusElvaltozas">
              <label class="form-check-label" for="egyebMalingnusElvaltozas" i18n>Egyéb malignus elváltozás</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label class="cytologyHeaderLabel" i18n>Javaslat</label>
      </div>
    </div>
    <div class="row mt-2 ml-3">
      <div class="col-sm-2">
        <div class="form-group form-check mb-1">
          <input [formControl]="javaslatKiegeszitoTesztek" type="checkbox" class="form-check-input" id="javaslatKiegeszitoTesztek">
          <label class="form-check-label" for="javaslatKiegeszitoTesztek" i18n>Kiegészítő tesztek</label>
        </div>
        <div class="form-group form-check mb-1 ml-2">
          <input [formControl]="javaslatHPVDNS" type="checkbox" class="form-check-input" id="javaslatHPVDNS">
          <label class="form-check-label" for="javaslatHPVDNS" i18n>HPV DNS</label>
        </div>
      </div>
      <div class="col-sm-2">
        <label>IMMUNCITOKÉMIA</label>
        <div class="form-group form-check mb-1">
          <input [formControl]="javaslatP16" type="checkbox" class="form-check-input" id="javaslatP16">
          <label class="form-check-label" for="javaslatP16">P16</label>
        </div>
        <div class="form-group form-check mb-1">
          <input [formControl]="javaslatKi67" type="checkbox" class="form-check-input" id="javaslatKi67">
          <label class="form-check-label" for="javaslatKi67">Ki67</label>
        </div>
        <div class="form-group form-check mb-1">
          <input [formControl]="javaslatCinTecPlusz" type="checkbox" class="form-check-input" id="javaslatCinTecPlusz">
          <label class="form-check-label" for="javaslatCinTecPlusz" i18n>CIN tec plusz</label>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="javaslatIsmetlesKlinikaiMegitelesSzerint" type="checkbox" class="form-check-input" id="javaslatIsmetlesKlinikaiMegitelesSzerint">
              <label class="form-check-label" for="javaslatIsmetlesKlinikaiMegitelesSzerint" i18n>Ismétlés klinikai megítélés szerint</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="javaslatIsmetlesKezelesUtan" type="checkbox" class="form-check-input" id="javaslatIsmetlesKezelesUtan">
              <label class="form-check-label" for="javaslatIsmetlesKezelesUtan" i18n>Ismétlés kezelés után</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check mb-1">
              <input [formControl]="javaslatIsmetles36Honap" type="checkbox" class="form-check-input" id="javaslatIsmetles36Honap">
              <label class="form-check-label" for="javaslatIsmetles36Honap" i18n>Ismétlés 3-6 hónap múlva</label>
            </div>
          </div>
        </div>
        <div class="row ml-1">
          <div class="col-sm-4">
            <div class="row">
              <div class="form-group form-check mb-1">
                <input [formControl]="javaslatSzovettaniVizsgalat" type="checkbox" class="form-check-input" id="javaslatSzovettaniVizsgalat">
                <label class="form-check-label" for="javaslatSzovettaniVizsgalat" i18n>Szövettani vizsgálat</label>
              </div>
            </div>
            <div class="row">
              <div class="form-group form-check mb-1">
                <input [formControl]="javaslatKezeles" type="checkbox" class="form-check-input" id="javaslatKezeles">
                <label class="form-check-label" for="javaslatKezeles" i18n>Kezelés</label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-inline">
              <div class="form-group form-check mb-1 mr-2">
                <input [formControl]="javaslatEgyeb" type="checkbox" class="form-check-input" id="javaslatEgyeb">
                <label class="form-check-label" for="javaslatEgyeb" i18n>Egyéb:</label>
              </div>
              <input [formControl]="javaslatEgyebMegjegyzes" class="form-control cytoDataInput">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <div class="form-inline" *ngIf="!ertekelhetoKenetNemFeldolgozott.value && !ertekelhetoKenetNemErtekelheto.value">
          <label class="ml-auto mr-2">Papanicolaou:</label>
          <ng-select [formControl]="papanicolaou" [items]="[0,1,2,3,4,5]" style="width: 75px"></ng-select>
        </div>
      </div>
    </div>
    <div style="float:right; margin-bottom: 50px; margin-top: 50px;" class="form-inline">
      <div  *ngIf="!user?.roleCode.includes('REFERRING')" class="form-group form-check mr-3">
          <input [formControl]="sendEmailNotificationControl" type="checkbox" class="form-check-input" id="sendEmailNotification">
          <label class="form-check-label" for="sendEmailNotification"  title="e-mail értesítés küldése a beküldő orvos által megadott címre" i18n>email értesítés küldése</label>
      </div>
     <button (click)="generalService.printPDf(printablePdf)" class="cytoReportButton mr-3" i18n>nyomtatás</button>
     <button [disabled]="user?.roleCode.includes('REFERRING')" [ngClass]="user?.roleCode.includes('REFERRING') ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'" (click)="deleteCase()" class="cytoReportButton mr-3" i18n>törlés</button>
     <button [disabled]="!user?.roleCode.includes('CYT')" [ngClass]="!user?.roleCode.includes('CYT') ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'"  (click)="saveCytologyRes(true)" class="cytoReportButton mr-3" i18n>validálás</button>
     <button [disabled]="user?.roleCode.includes('REFERRING')" [ngClass]="user?.roleCode.includes('REFERRING') ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'"(click)="saveCytologyRes(false)" class="cytoReportButton" i18n>mentés</button>
    </div>
  </div>
  <div class="col-sm-2">
    <label class="cytoDataLabel mt-5" i18n>CYTOLÓGIAI SORSZÁM</label>
    <div class="form-inline mt-1">
        <label class="mr-1">{{logPrefix ? logPrefix + '/' : ''}}</label>
        <input [formControl]="logNumber" class="cytoDataInput form-control required" type="text"  name="lognumber" style="max-width: 158px;"/>
    </div>
    <label class="cytoDataLabel mt-3">bno</label>
    <ng-select [formControl]="bno" class="cytoDataInput ng_select_required bnoSelector" [items]="bnoList" bindValue="code" [virtualScroll]="true" [searchFn]="generalService.bnoSearchFn">
        <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item"  title="item.name">
            {{item.code}} - {{item.name}}
        </ng-template>
    </ng-select>
    <label class="cytoDataLabel mt-3" i18n>szakmakód</label>
    <ng-select [formControl]="szakmakodControl" class="cytoDataInput ng_select_required bnoSelector" [items]="szakmakodok" bindValue="id" [virtualScroll]="true">
      <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item" title="item.name">
        <span title="{{item.name}}">{{item.name}}</span>
      </ng-template>
    </ng-select>
    <label class="cytoDataLabel mt-3" i18n>beutaló azonosító</label>
    <input [formControl]="beutaloAzon" class="cytoDataInput form-control" type="text" name="beutaloazon">
    <!-- <label class="cytoDataLabel mt-3">fejléc</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="pdfHeaders" [searchable]="true" bindLabel="instituteName" bindValue="id" [formControl]="pdfHeader" placeholder="Válasszon..."></ng-select> -->
    <button (click)="checkEszigTokenValid()" class="cytoReportButton mt-5" i18n>e-beutalók lekérdezése</button><br>
    <label class="cytoDataLabel mt-3" i18n>EESZT beutaló azonosító</label>
    <input [formControl]="eesztBeutaloAzon" class="mt-2 cytoDataInput form-control" name="eesztbeutaloazon">
    <label class="cytoDataLabel mt-3" i18n>Előszűrő szakasszisztens</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="preScreeningSpecialists" [searchable]="true" bindLabel="name" bindValue="id" [formControl]="preScreeningSpecialistControl" placeholder="Válasszon..."></ng-select>
    <label class="cytoDataLabel mt-3" i18n>Validáló orvos</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="validatorUsers" [searchable]="true" bindLabel="name" bindValue="id" [formControl]="validatorUserControl" placeholder="Válasszon..."></ng-select>
    <label class="cytoDataLabel mt-3" i18n>kérés online feladásának ideje</label>
    <input [formControl]="arrivalTime" class="cytoDataInput form-control"/>
    <label class="cytoDataLabel mt-3" i18n>leletezés megkezdésének ideje</label>
    <input [formControl]="responseCreationDate" class="cytoDataInput form-control"/>
    <label class="cytoDataLabel mt-3" i18n>leletezést végezte</label>
    <input [formControl]="doctorName" class="cytoDataInput form-control" type="text"/>
  </div>
</div>
