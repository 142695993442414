<div class="wrapper-left mt-5">
    <!-- <table id="financeTable">
        <thead>
            <tr class="table100-head">
                <th class="finColumn1 l">intézmény</th>
                <th class="finColumn2">citológia</th>
                <th class="finColumn2">pap</th>
                <th class="finColumn2">lbc</th>
                <th class="finColumn3">29450</th>
                <th class="finColumn4">29400</th>
                <th class="finColumn5">29070</th>
                <th class="finColumn6">25040</th>
                <th class="finColumn7">29050</th>
                <th class="finColumn8">29000</th>
                <th class="finColumn9"></th>
            </tr>
        </thead>
        <tbody>
                <tr *ngFor="let finance of allFinance">
                    <td class="finColumn1 l">{{finance.servicePoint.name}}</td>
                    <td class="finColumn2">{{finance.citologia}}</td>
                    <td class="finColumn2">{{finance.citologiaPap}}</td>
                    <td class="finColumn2">{{finance.citologiaLbc}}</td>
                    <td class="finColumn3">{{finance.cytologiaiVizsgalatImmuncyto}}</td>
                    <td class="finColumn4">{{finance.diagnosztikusAspiratiosExfol}}</td>
                    <td class="finColumn5">{{finance.kiegeszitoPontszamVolumiozus}}</td>
                    <td class="finColumn6">{{finance.mikroszkoposVizsgalatZiehl}}</td>
                    <td class="finColumn7">{{finance.szovettaniVizsgalatImmunhisz}}</td>
                    <td class="finColumn8">{{finance.szovettaniVizsgalatASzokaso}}</td>
                    <td class="finColumn9"><i aria-hidden="true" class="fa fa-wrench"  title="módosítás" (click)="modifyFinance(finance)"></i></td>
                </tr>
        </tbody>
    </table> -->
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" style="float:left"></mat-paginator>
    <table mat-table [dataSource]="allFinance" class="w-100" id="financeTable">
        <ng-container matColumnDef="id" style="display: none;">
            <td mat-cell *matCellDef="let element">{{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="servicePoint">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n> intézmény</th>
            <td mat-cell *matCellDef="let element">{{element.servicePoint.name}}</td>
        </ng-container>
        <ng-container matColumnDef="citology">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>citológia</th>
            <td mat-cell *matCellDef="let element">{{element.citologia}}</td>
        </ng-container>
        <ng-container matColumnDef="pap">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>pap</th>
            <td mat-cell *matCellDef="let element">{{element.citologiaPap}}</td>
        </ng-container>
        <ng-container matColumnDef="lbc">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>lbc</th>
            <td mat-cell *matCellDef="let element">{{element.citologiaLbc}}</td>
        </ng-container>
        <ng-container matColumnDef="29450">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29450</th>
            <td mat-cell *matCellDef="let element">{{element.cytologiaiVizsgalatImmuncyto}}</td>
        </ng-container>
        <ng-container matColumnDef="29400">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29400</th>
            <td mat-cell *matCellDef="let element">{{element.diagnosztikusAspiratiosExfol}}</td>
        </ng-container>
        <ng-container matColumnDef="29070">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29070</th>
            <td mat-cell *matCellDef="let element">{{element.kiegeszitoPontszamVolumiozus}}</td>
        </ng-container>
        <ng-container matColumnDef="25040">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>25040</th>
            <td mat-cell *matCellDef="let element">{{element.mikroszkoposVizsgalatZiehl}}</td>
        </ng-container>
        <ng-container matColumnDef="29050">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29050</th>
            <td mat-cell *matCellDef="let element">{{element.szovettaniVizsgalatImmunhisz}}</td>
        </ng-container>
        <ng-container matColumnDef="29000">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>29000</th>
            <td mat-cell *matCellDef="let element">{{element.szovettaniVizsgalatASzokaso}}</td>
        </ng-container>
        <ng-container matColumnDef="25030">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>25030</th>
            <td mat-cell *matCellDef="let element">{{element.mikroszkiposVizsgalatTenyesztetlenAnyagbol}}</td>
        </ng-container>
        <ng-container matColumnDef="27000">
            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n>27000</th>
            <td mat-cell *matCellDef="let element">{{element.gombaVizsgalatDirektKenetben}}</td>
        </ng-container>
        <ng-container matColumnDef="icons">
            <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><i aria-hidden="true" class="fa fa-wrench"  title="módosítás" (click)="modifyFinance(element)"></i></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
    </table>
</div>
<div class="wrapper-right my-5 mr-5" [formGroup]="financeFormGroup">
    <label class="submitReportLabel mt-3" i18n>intézmény</label>
    <ng-select [clearable]="true" [closeOnSelect]="true" [items]="servicePoints" [searchable]="true" class="ng_select_required" bindLabel="name" bindValue="id" [formControl]="financeServicePoint" placeholder="Válasszon..."></ng-select>
    <label class="submitReportLabel mt-3" i18n>citológia</label>
    <input [formControl]="citology" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>pap</label>
    <input [formControl]="pap" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>lbc</label>
    <input [formControl]="lbc" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>29450</label>
    <input [formControl]="cytologiaiVizsgalatImmuncyto" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>29400</label>
    <input [formControl]="diagnosztikusAspiratiosExfol" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>29070</label>
    <input [formControl]="kiegeszitoPontszamVolumiozus" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>25040</label>
    <input [formControl]="mikroszkoposVizsgalatZiehl" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>29050</label>
    <input [formControl]="szovettaniVizsgalatImmunhisz" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>29000</label>
    <input [formControl]="szovettaniVizsgalatASzokaso" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>25030</label>
    <input [formControl]="mikroszkiposVizsgalatTenyesztetlenAnyagbol" class="submitReportInput form-control" type="text"/>
    <label class="submitReportLabel mt-3" i18n>27000</label>
    <input [formControl]="gombaVizsgalatDirektKenetben" class="submitReportInput form-control" type="text"/>
    <button [ngClass]="isModifyFinance || !financeServicePoint.value ? 'disabledsubmitUserOrSpButton mr-3 mb-3' : 'submitUserOrSpButton mr-3 mb-3'" [disabled]="isModifyFinance" (click)="saveFinance()"class="submitUserOrSpButton mt-3" i18n>mentés</button>
    <button [ngClass]="!isModifyFinance ? 'disabledsubmitUserOrSpButton mr-3 mb-3' : 'submitUserOrSpButton mr-3 mb-3'" [disabled]="!isModifyFinance" (click)="updateFinance()"class="submitUserOrSpButton mt-3" i18n>módosítás</button>
    <button [ngClass]="!isModifyFinance ? 'disabledsubmitUserOrSpButton mr-3 mb-3' : 'submitUserOrSpButton mr-3 mb-3'" [disabled]="!isModifyFinance" (click)="clearForm(financeFormGroup)" i18n>mégsem</button>
</div>
