import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from './../authentication/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Messages } from 'src/app/shared/locale/messages';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  environment = environment;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private messages: Messages
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getToken();

    if (!request.url.includes(this.environment.eszigReader)) {
      const authRequest =  request.clone({
        headers: request.headers
        .set('Authorization', 'Bearer ' + authToken)
        .set('Accept-Language', this.messages.locale)
      });
      return next.handle(authRequest).pipe(
        tap( () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.router.navigate[('/login')];
            }
          }
        })
      );
    }
    return next.handle(request).pipe(
      tap( () => {},
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigate[('/login')];
          }
        }
      })
    );
  }
}
