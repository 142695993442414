<app-dashboard></app-dashboard>
<div class="wrapper" [formGroup]="cytologyResFormGroup">
   <div class="wrapperLeft">
    <label class="cytologyHeaderLabel">kenet értékelhetősége</label>
    <div class="form-inline">
        <div class="form-group form-check ml-5">
            <input [formControl]="feldolgozott" type="checkbox" class="form-check-input" id="feldolgozott">
            <label class="form-check-label" for="feldolgozott">feldolgozott</label>
        </div>
        <div style="margin-left: 100px;" class="form-group form-check">
            <input [formControl]="nemfeldolgozott" type="checkbox" class="form-check-input" id="nemfeldolgozott">
            <label class="form-check-label" for="nemfeldolgozott">nem feldolgozott</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="pap" type="checkbox" class="form-check-input" id="pap">
            <label class="form-check-label" for="pap">pap.</label>
        </div>
        <div style="margin-left: 20px;" class="form-group form-check">
            <input [formControl]="lb" type="checkbox" class="form-check-input" id="lb">
            <label class="form-check-label" for="lb">L.B.</label>
        </div>
        <div style="margin-left: 20px;" class="form-group form-check">
            <input [formControl]="auto" type="checkbox" class="form-check-input" id="auto">
            <label class="form-check-label" for="auto">auto</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="ertekelhetode" type="checkbox" class="form-check-input" id="ertekelhetode">
            <label class="form-check-label" for="ertekelhetode">értékelhető, de</label>
        </div>
        <div style="margin-left: 81px;" class="form-group form-check">
            <input [formControl]="nemertekelhetomert" type="checkbox" class="form-check-input" id="nemertekelhetomert">
            <label class="form-check-label" for="nemertekelhetomert">nem értékelhető, mert</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="ectfzonahiany" type="checkbox" class="form-check-input" id="ectfzonahiany">
            <label class="form-check-label" for="ectfzonahiany">EC/TF zóna hiányzik</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="sejtszegeny" type="checkbox" class="form-check-input" id="sejtszegeny">
            <label class="form-check-label" for="sejtszegeny">sejtszegény</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="nemazonosithato" type="checkbox" class="form-check-input" id="nemazonosithato">
            <label class="form-check-label" for="nemazonosithato">nem azonosítható kenet</label>
        </div>
        <div style="margin-left: 18px;"class="form-group form-check">
            <input [formControl]="zavaromeretuver" type="checkbox" class="form-check-input" id="zavaromeretuver">
            <label class="form-check-label" for="zavaromeretuver">zavaró méretű vér</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="elegtelenfixalas" type="checkbox" class="form-check-input" id="elegtelenfixalas">
            <label class="form-check-label" for="elegtelenfixalas">elégtelen fixálás</label>
        </div>
        <div style="margin-left: 76px;"class="form-group form-check">
            <input [formControl]="zavaromeretulob" type="checkbox" class="form-check-input" id="zavaromeretulob">
            <label class="form-check-label" for="zavaromeretulob">zavaró méretű lob</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="kenetegyeb" type="checkbox" class="form-check-input" id="kenetegyeb">
            <label class="form-check-label" for="kenetegyeb">egyéb</label>
        </div>
        <input [formControl]="egyebMegjegyzes" style="width: 275px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="kiegeszitotesztek" type="checkbox" class="form-check-input" id="kiegeszitotesztek">
            <label class="form-check-label" for="kiegeszitotesztek">kiegészítő tesztek</label>
        </div>
        <input [formControl]="kiegeszitotesztekMegjegyzes" style="width: 195px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <label class="cytologyHeaderLabel mt-3">a kenet általános minősítése</label>
    <div style="margin-bottom: 0" class="form-group form-check ml-5">
        <input [formControl]="negativkorosneoplasticus" type="checkbox" class="form-check-input" id="negativkorosneoplasticus">
        <label class="form-check-label" for="negativkorosneoplasticus">negatív, kóros, neoplasticus hámelválasztásokra</label>
    </div>
    <div  style="margin-bottom: 0" class="form-group form-check ml-5 mt-1">
        <input [formControl]="korosneoplasticus" type="checkbox" class="form-check-input" id="korosneoplasticus">
        <label class="form-check-label" for="korosneoplasticus">kóros, neoplasticus hámelválasztás</label>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="kenetegyeb2" type="checkbox" class="form-check-input" id="kenetegyeb2">
            <label class="form-check-label" for="kenetegyeb2">egyéb</label>
        </div>
        <input [formControl]="kenetEgyebMegjegyzes" style="width: 292px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <label class="cytologyHeaderLabel mt-3">kórokozók</label>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="trichomonas" type="checkbox" class="form-check-input" id="trichomonas">
            <label class="form-check-label" for="trichomonas">trichomonas</label>
        </div>
        <div style="margin-left: 76px;"class="form-group form-check">
            <input [formControl]="vegyesbakterium" type="checkbox" class="form-check-input" id="vegyesbakterium">
            <label class="form-check-label" for="vegyesbakterium">vegyes baktérium</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="actinomyces" type="checkbox" class="form-check-input" id="actinomyces">
            <label class="form-check-label" for="actinomyces">actinomyces</label>
        </div>
        <div style="margin-left: 76px;"class="form-group form-check">
            <input [formControl]="hpv" type="checkbox" class="form-check-input" id="hpv">
            <label class="form-check-label" for="hpv">hpv</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="gomba" type="checkbox" class="form-check-input" id="gomba">
            <label class="form-check-label" for="gomba">gomba, éspedig</label>
        </div>
        <input [formControl]="gombaEgyeb" style="width: 224px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="korokozoEgyeb" type="checkbox" class="form-check-input" id="korokozoegyeb">
            <label class="form-check-label" for="korokozoegyeb">egyéb, éspedig</label>
        </div>
        <input [formControl]="korokozoEgyebMegjegyzes" style="width: 231px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <label class="cytologyHeaderLabel mt-3">egyéb, nem neoplasticus elváltozások</label>
    <div class="form-inline">
        <div class="form-group form-check ml-5">
            <input [formControl]="relativsejtvalasztasokkaljaro" type="checkbox" class="form-check-input" id="relativsejtvalasztasokkaljaro">
            <label class="form-check-label" for="relativsejtvalasztasokkaljaro">relatív sejtelválasztásokkal járó</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="gyulladas" type="checkbox" class="form-check-input" id="gyulladas">
            <label class="form-check-label" for="gyulladas">gyulladás</label>
        </div>
        <div style="margin-left: 20px;" class="form-group form-check">
            <input [formControl]="sugarhatas" type="checkbox" class="form-check-input" id="sugarhatas">
            <label class="form-check-label" for="sugarhatas">sugárhatás</label>
        </div>
        <div style="margin-left: 20px;" class="form-group form-check">
            <input [formControl]="mechanikushatas" type="checkbox" class="form-check-input" id="mechanikushatas">
            <label class="form-check-label" for="mechanikushatas">mechanikus hatás</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="atrophia" type="checkbox" class="form-check-input" id="atrophia">
            <label class="form-check-label" for="atrophia">atrophia</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="negyveneves" type="checkbox" class="form-check-input" id="negyveneves">
            <label class="form-check-label" for="negyveneves">endometrium sejtek +40 éves kor</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="egyebnemneoplaszt" type="checkbox" class="form-check-input" id="egyebnemneoplaszt">
            <label class="form-check-label" for="egyebnemneoplaszt">egyéb, éspedig</label>
        </div>
        <input [formControl]="egyebnemneoplasztMegjegyzes" style="width: 231px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
   </div>
   <div class="wrapperRight">
    <label class="cytologyHeaderLabel">laphámsejtek</label>
    <div class="form-inline">
        <div class="form-group form-check ml-5">
            <input [formControl]="asc" type="checkbox" class="form-check-input" id="asc">
            <label class="form-check-label" for="asc">atypusos laphámsejtek (ASC)</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="ascus" type="checkbox" class="form-check-input" id="ascus">
            <label class="form-check-label" for="ascus">nem meghatározható okból (ASC-US)</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="asch" type="checkbox" class="form-check-input" id="asch">
            <label class="form-check-label" for="asch">nem zárható ki HSIL (ASC-H)</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="lsil" type="checkbox" class="form-check-input" id="lsil">
            <label class="form-check-label" for="lsil">enyhe fokú intraepithelialis laphám laesio (LSIL)</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="laphamhpv" type="checkbox" class="form-check-input" id="laphamhpv">
            <label class="form-check-label" for="laphamhpv">HPV</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="cinegy" type="checkbox" class="form-check-input" id="cinegy">
            <label class="form-check-label" for="cinegy">CIN 1</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="laphamhsil" type="checkbox" class="form-check-input" id="laphamhsil">
            <label class="form-check-label" for="laphamhsil">kifejezett fokú intraepithelialis laphám laesio (HSIL)</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="cinketto" type="checkbox" class="form-check-input" id="cinketto">
            <label class="form-check-label" for="cinketto">CIN 2</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="cinharom" type="checkbox" class="form-check-input" id="cinharom">
            <label class="form-check-label" for="cinharom">CIN 3</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="invasio" type="checkbox" class="form-check-input" id="invasio">
            <label class="form-check-label" for="invasio">invasio gyanúja</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="carcinoma" type="checkbox" class="form-check-input" id="carcinoma">
            <label class="form-check-label" for="carcinoma">laphámsejt carcinoma</label>
        </div>
    </div>
    <label class="cytologyHeaderLabel mt-3">mirigyhámsejtek</label>
    <div class="form-inline">
        <div class="form-group form-check ml-5">
            <input [formControl]="agcnos" type="checkbox" class="form-check-input" id="agcnos">
            <label class="form-check-label" for="agcnos">atypusos mirigyhámsejtek (AGC-NOS)</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="endocervicalis" type="checkbox" class="form-check-input" id="endocervicalis">
            <label class="form-check-label" for="endocervicalis">endocervicalis sejtek</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="endometrialis" type="checkbox" class="form-check-input" id="endometrialis">
            <label class="form-check-label" for="endometrialis">endometrialis sejtek</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="mirigynos" type="checkbox" class="form-check-input" id="mirigynos">
            <label class="form-check-label" for="mirigynos">mirigyhámsejtek - NOS</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="agc" type="checkbox" class="form-check-input" id="agc">
            <label class="form-check-label" for="agc">atypusos mirigyhámsejtek, inkább neoplasticus (AGC)</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="endocervicalis2" type="checkbox" class="form-check-input" id="endocervicalis2">
            <label class="form-check-label" for="endocervicalis2">endocervicalis sejtek</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="mirigynosketto" type="checkbox" class="form-check-input" id="mirigynosketto">
            <label class="form-check-label" for="mirigynosketto">mirigyhámsejtek - NOS</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="ais" type="checkbox" class="form-check-input" id="ais">
            <label class="form-check-label" for="ais">endocervicalis adenocarcinoma in situ (AIS)</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="adenocarcinoma" type="checkbox" class="form-check-input" id="adenocarcinoma">
            <label class="form-check-label" for="adenocarcinoma">adenocarcinoma</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="endocercivalis3" type="checkbox" class="form-check-input" id="endocercivalis3">
            <label class="form-check-label" for="endocercivalis3">endocercivalis</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="extrauterin" type="checkbox" class="form-check-input" id="extrauterin">
            <label class="form-check-label" for="extrauterin">extrauterin</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="endometrialis2" type="checkbox" class="form-check-input" id="endometrialis2">
            <label class="form-check-label" for="endometrialis2">endometrialis</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="nos" type="checkbox" class="form-check-input" id="nos">
            <label class="form-check-label" for="nos">NOS</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="malignustumor" type="checkbox" class="form-check-input" id="malignustumor">
            <label class="form-check-label" for="malignustumor">egyéb malignus tumor</label>
        </div>
        <input [formControl]="malignustumorMegjegyzes" style="width: 200px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <label class="cytologyHeaderLabel mt-3">javaslat</label>
    <div class="form-inline">
        <div class="form-group form-check ml-5">
            <input [formControl]="ismetlesklinika" type="checkbox" class="form-check-input" id="ismetlesklinika">
            <label class="form-check-label" for="ismetlesklinika">ismétlés klinikai megítélés szerint</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="ismetleskezeles" type="checkbox" class="form-check-input" id="ismetleskezeles">
            <label class="form-check-label" for="ismetleskezeles">ismétlés kezelés után</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="lobellenes" type="checkbox" class="form-check-input" id="lobellenes">
            <label class="form-check-label" for="lobellenes">lobellenes</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="hormonalis" type="checkbox" class="form-check-input" id="hormonalis">
            <label class="form-check-label" for="hormonalis">hormonális</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="ismetles" type="checkbox" class="form-check-input" id="ismetles">
            <label class="form-check-label" for="ismetles">ismétlés</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="haromhonapmulva" type="checkbox" class="form-check-input" id="haromhonapmulva">
            <label class="form-check-label" for="haromhonapmulva">3 hónap múlva</label>
        </div>
        <div class="form-group form-check ml-5">
            <input [formControl]="hathonapmulva" type="checkbox" class="form-check-input" id="hathonapmulva">
            <label class="form-check-label" for="hathonapmulva">6 hónap múlva</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="hpvtipizalas" type="checkbox" class="form-check-input" id="hpvtipizalas">
            <label class="form-check-label" for="hpvtipizalas">HPV tipizálás</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="szovettanivizsg" type="checkbox" class="form-check-input" id="szovettanivizsg">
            <label class="form-check-label" for="szovettanivizsg">szövettani vizsgálat</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div class="form-group form-check ml-5">
            <input [formControl]="javaslategyeb" type="checkbox" class="form-check-input" id="javaslategyeb">
            <label class="form-check-label" for="javaslategyeb">egyéb</label>
        </div>
        <input [formControl]="javaslatEgyebMegjegyzes" style="width: 331px;"class="cytologyReportInput form-control ml-3" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="form-check-label ml-5" for="javaslategyeb">papanicolaou</label>
        <ng-select class='pappanicalau ml-3' style="min-width: 200px;" [clearable]="true" [closeOnSelect]="true" [items]="pappanicalauList" [searchable]="true" bindLabel="name" bindValue="name" [formControl]="pappanicalau" placeholder="Válasszon..."></ng-select>
    </div>
   </div>
   <div style="float:right; margin-bottom: 50px; margin-top: 50px;" class="form-inline">
    <div *ngIf="!user?.roleCode.includes('REFERRING')" class="form-group form-check mr-3">
        <input [formControl]="sendEmailNotificationControl" type="checkbox" class="form-check-input" id="sendEmailNotification">
        <label class="form-check-label" for="sendEmailNotification"  title="e-mail értesítés küldése a beküldő orvos által megadott címre">email értesítés küldése</label>
    </div>
    <button (click)="generalService.printPDf(printablePdf)" class="cytoReportButton mr-3">nyomtatás</button>
    <button [disabled]="user?.roleCode.includes('REFERRING') || isOldReport" [ngClass]="user?.roleCode.includes('REFERRING') || isOldReport ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'" (click)="deleteCase()" class="cytoReportButton mr-3">törlés</button>
    <button [disabled]="!user?.roleCode.includes('CYT') || isOldReport" [ngClass]="!user?.roleCode.includes('CYT') || isOldReport ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'"  (click)="saveCytologyRes(true)" class="cytoReportButton mr-3">validálás</button>
    <button [disabled]="user?.roleCode.includes('REFERRING') || isOldReport" [ngClass]="user?.roleCode.includes('REFERRING') || isOldReport ? 'disabledcytoReportButton mr-3' : 'cytoReportButton mr-3'"(click)="saveCytologyRes(false)" class="cytoReportButton">mentés</button>
   </div>
</div>
<div class="cytoData" [formGroup]="cytologyResDataFormGroup">
    <label class="cytoDataLabel mt-5">naplószám</label>
    <input [formControl]="logNumber" class="cytoDataInput form-control required" type="text"/>
    <label class="cytoDataLabel mt-3">bno</label>
    <ng-select [formControl]="bno" class="cytoDataInput ng_select_required bnoSelector" [items]="bnoList" bindValue="code" [virtualScroll]="true" [searchFn]="generalService.bnoSearchFn">
        <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="item"  title="item.name">
            {{item.code}} - {{item.name}}
        </ng-template>
    </ng-select>
    <label class="cytoDataLabel mt-3">beutaló azonosító</label>
    <input [formControl]="beutaloAzon" class="cytoDataInput form-control" type="text">
    <label class="cytoDataLabel mt-3">fejléc</label>
    <ng-select class='cytoDataInput ng_select_required' [clearable]="true" [closeOnSelect]="true" [items]="pdfHeaders" [searchable]="true" bindLabel="instituteName" bindValue="id" [formControl]="pdfHeader" placeholder="Válasszon..."></ng-select>
    <button *ngIf="!isOldReport" (click)="checkEszigTokenValid()" class="cytoReportButton mt-5">e-beutalók lekérdezése</button><br>
    <label class="cytoDataLabel mt-3">EESZT beutaló azonosító</label>
    <input [formControl]="eesztBeutaloAzon" class="mt-2 cytoDataInput form-control">
    <label class="cytoDataLabel mt-3">kérés online feladásának ideje</label>
    <input [formControl]="arrivalTime" class="cytoDataInput form-control"/>
    <label class="cytoDataLabel mt-3">leletezés megkezdésének ideje</label>
    <input [formControl]="responseCreationDate" class="cytoDataInput form-control"/>
    <label class="cytoDataLabel mt-3">leletezést végezte</label>
    <input [formControl]="doctorName" class="cytoDataInput form-control" type="text"/>
</div>
