// tslint:disable: deprecation
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../../core/http/api.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './../../../core/authentication/authentication.service';
import { GeneralService } from './../../../core/services/general.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { RssDataResponse } from 'src/app/shared/models/response/rssDataResponse';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  rssData: RssDataResponse;
  showRss: boolean;

  userName = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);

  loginFormGroup = new FormGroup({
    userName: this.userName,
    password: this.password
  });

  rssInterval: NodeJS.Timeout;

  constructor(
    public generalService: GeneralService,
    private authService: AuthenticationService,
    private cookieService: CookieService,
    private apiService: ApiService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit() {
    localStorage.clear();
    this.cookieService.deleteAll();
    this.getRssData();
    this.rssInterval = setInterval(() => {
      this.getRssData();
    }, 60000);
  }

  ngOnDestroy() {
    clearInterval(this.rssInterval);
  }

  doLogin() {
    if (!localStorage.getItem('currentUser')) {
      this.authService.authentication(this.userName.value, this.password.value);
      // document.forms['loginForm'].submit();
    } else {
      this.toasterService.error(
        this.messages.error.ERROR_USER_ALREADY_LOGGED_IN[this.messages.locale],
        this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale], {positionClass: 'toast-top-center'}
        );
    }
  }

  getRssData() {
    this.apiService.getRssData().subscribe(response => {
      this.rssData = response;
    });
  }

  showNews() {
    this.showRss = !this.showRss;
  }

}
