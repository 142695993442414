// tslint:disable: max-line-length
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicePoint } from './../../shared/models/ServicePoint';
import { GetUserByUserNameResponse } from './../../shared/models/response/getUserByUsernameResponse';
import { AreYouSureComponent } from './../../modules/components/are-you-sure/are-you-sure.component';
import { Messages } from 'src/app/shared/locale/messages';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  user: GetUserByUserNameResponse;
  servicePoint: ServicePoint;

  constructor(
    private router: Router,
    private toasterService: ToastrService,
    private dialog: MatDialog,
    private messages: Messages,
  ) { }

  goToPage(path: string) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.servicePoint = JSON.parse(localStorage.getItem('servicePoint'));
    if (this.servicePoint?.citologyVersion === 'v3' && this.router.url.includes('report/') && this.user.roleCode !== 'REFERRING_ASSISTANT' && this.user.roleCode !== 'REFERRING_DOCTOR' && this.user.roleCode !== 'ADMIN') {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        data: 'Biztosan elhagyja az oldalt? A nem mentett adatai elveszhetnek!'
      });

      dialogRef.componentInstance.emitAnswer.subscribe(answer => {
        if (answer) {
          this.router.navigate(['/' + path], {queryParamsHandling: 'merge'});
        }
        dialogRef.close();
      });

    } else {
      this.router.navigate(['/' + path], {queryParamsHandling: 'merge'});
    }
  }

  checkRouteToHideElement(url: string) {
    if (url !== '/dashboard') {
      return true;
    }
  }

  getCurrentDate() {
    const date = new Date();
    const currentDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    return currentDate;
  }

  printPDf(printablePDF: string) {
    if (!printablePDF) {
      this.toasterService.error(this.messages.error.ERROR_PRINTABLE_CASE_DOESNT_EXIST[this.messages.locale],
      this.messages.error.ERROR_TITLE_DEFAULT[this.messages.locale],
      { positionClass: 'toast-top-center' });
    } else {
      const byteArray = new Uint8Array(atob(printablePDF).split('').map(char => char.charCodeAt(0)));
      const blob =  new Blob([byteArray], {type: 'application/pdf'});
      window.open(URL.createObjectURL(blob));
    }
  }

  bnoSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.code.toLocaleLowerCase().indexOf(term) > -1 ||
           item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  snomedSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.detail.toLocaleLowerCase().indexOf(term) > -1 ||
           item.snomed.toLocaleLowerCase().indexOf(term) > -1;
  }
}
