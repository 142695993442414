import { GetUserByUserNameResponse } from './../../../shared/models/response/getUserByUsernameResponse';
import { ServicePoint } from 'src/app/shared/models/ServicePoint';
import { GetCaseByIdResponse } from './../../../shared/models/response/getCaseById';
import { ApiService } from './../../../core/http/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareddataService } from 'src/app/core/services/shareddata.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { timeStamp } from 'console';

@Component({
  selector: 'app-histologyapplication',
  templateUrl: './histologyapplication.component.html',
  styleUrls: ['./histologyapplication.component.css']
})
export class HistologyapplicationComponent implements OnInit, OnDestroy {

  kenetszam = new FormControl('');
  aspiracios = new FormControl(false);
  klinikaiAdatok = new FormControl('');
  lokalizacio1 = new FormControl('');
  lokalizacio2 = new FormControl('');
  lokalizacio3 = new FormControl('');
  lokalizacio4 = new FormControl('');
  lokalizacio5 = new FormControl('');
  lokalizacio6 = new FormControl('');
  lokalizacio7 = new FormControl('');
  lokalizacio8 = new FormControl('');
  lokalizacio9 = new FormControl('');
  lokalizacio10 = new FormControl('');

  histologyFormGroup = new FormGroup({
    kenetszam: this.kenetszam,
    aspiracios: this.aspiracios,
    klinikaiAdatok: this.klinikaiAdatok,
    lokalizacio1: this.lokalizacio1,
    lokalizacio2: this.lokalizacio2,
    lokalizacio3: this.lokalizacio3,
    lokalizacio4: this.lokalizacio4,
    lokalizacio5: this.lokalizacio5,
    lokalizacio6: this.lokalizacio6,
    lokalizacio7: this.lokalizacio7,
    lokalizacio8: this.lokalizacio8,
    lokalizacio9: this.lokalizacio9,
    lokalizacio10: this.lokalizacio10
  });

  public sub = new Subscription();

  localizations = 1;
  localizationArray = [
    this.lokalizacio1,
    this.lokalizacio2,
    this.lokalizacio3,
    this.lokalizacio4,
    this.lokalizacio5,
    this.lokalizacio6,
    this.lokalizacio7,
    this.lokalizacio8,
    this.lokalizacio9,
    this.lokalizacio10,
  ];
  showLocalizationsButton = true;

  servicePoint: ServicePoint;
  user: GetUserByUserNameResponse;
  constructor(
    private sharedDataService: ShareddataService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
      this.sub = this.histologyFormGroup.valueChanges.subscribe(data => {
          this.sharedDataService.formGroupChange(data);
      });

      this.servicePoint = JSON.parse(localStorage.getItem('servicePoint'));
      this.user = JSON.parse(localStorage.getItem('currentUser'));

      if (+this.route.snapshot.paramMap.get('caseId')) {
        this.getCaseById(+this.route.snapshot.paramMap.get('caseId'));
      } else {
        this.setKenetSzam(this.servicePoint);
      }
  }

  ngOnDestroy() {
    this.sharedDataService.formGroupChange(this.histologyFormGroup.reset());
    this.sub.unsubscribe();
  }

  setKenetSzam(servicePoint: any) {
    if (!this.kenetszam.value) {
      this.apiService.getNextLogNumber(servicePoint['refServicePointId']).subscribe(response => {
        this.kenetszam.setValue(response);
      });
    }
  }

  getCaseById(caseId: number) {
    this.apiService.getCaseById(caseId).subscribe((response: Array<GetCaseByIdResponse>) => {
      response.forEach(document => {
        if (document.type.includes('REQ')) {
          this.aspiracios.setValue(document.values['aspiracios']);
          this.kenetszam.setValue(document.values['kenetszam']);
          this.klinikaiAdatok.setValue(document.values['klinikaiAdatok']);
          this.lokalizacio1.setValue(document.values['lokalizacio1']);
          this.lokalizacio2.setValue(document.values['lokalizacio2']);
          this.lokalizacio3.setValue(document.values['lokalizacio3']);
          this.lokalizacio4.setValue(document.values['lokalizacio4']);
          this.lokalizacio5.setValue(document.values['lokalizacio5']);
          this.lokalizacio6.setValue(document.values['lokalizacio6']);
          this.lokalizacio7.setValue(document.values['lokalizacio7']);
          this.lokalizacio8.setValue(document.values['lokalizacio8']);
          this.lokalizacio9.setValue(document.values['lokalizacio9']);
          this.lokalizacio10.setValue(document.values['lokalizacio10']);
          this.localizations = 10;
          // this.histologyFormGroup.setValue(document.values);
          if (response.find(x => x.type.includes('RES')) || (this.user.roleCode !== 'REFERRING_ASSISTANT' && this.user.roleCode !== 'REFERRING_DOCTOR')) {
            this.histologyFormGroup.disable();
            this.showLocalizationsButton = false;
          }
        }
      });
    }, (error: Response) => {
      console.error(error);
    });
  }

  addLocalization() {
    this.localizations++;
  }

  removeLocalization(index: number) {
    this.localizations--;
    this.localizationArray[index].reset();
  }

}
