import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateType'
})
export class TranslateTypePipe implements PipeTransform {

  transform(value: string, locale: string): string {
    switch (value) {
      case 'szöv': {
        return locale === 'en' ? 'hyst' : value;
      }
      case 'cyt': {
        return locale === 'en' ? 'cyt' : value;
      }

      case 'acyt' : {
        return locale === 'en' ? 'acyt' : value;
      }

      default: {
        return value;
      }
    }
  }
}
