import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SaveValidatorUserRequest } from './../../../../shared/models/request/saveValidatorUser';
import { ValidatorUser } from './../../../../shared/models/validatorUser';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/core/http/api.service';
import { GetUserByUserNameResponse } from './../../../../shared/models/response/getUserByUsernameResponse';
import { User } from './../../../../shared/models/User';
import { Component, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/locale/messages';

@Component({
  selector: 'app-validator-users',
  templateUrl: './validator-users.component.html',
  styleUrls: ['./validator-users.component.css']
})
export class ValidatorUsersComponent implements OnInit {

  users: Array<User>;
  validatorUsers: Array<ValidatorUser>;

  servicePointId: number;

  usersControl = new FormControl('');

  constructor(
    private apiService: ApiService,
    private toasterService: ToastrService,
    private messages: Messages
  ) { }

  ngOnInit(): void {
    this.servicePointId = JSON.parse(localStorage.getItem('currentUser')).servicePointId;
    this.findAllUser(this.servicePointId);
    this.findAllValidatorUsers(this.servicePointId);
  }

  findAllUser(servicePointId: number) {
    this.apiService.getUsersByServicePointId(servicePointId).subscribe(response => {
      this.users = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  findAllValidatorUsers(servicePointId: number) {
    this.apiService.getAllValidatorUsersBySp(servicePointId).subscribe(response => {
      this.validatorUsers = response;
    }, (error: Response) => {
      console.error(error);
    });
  }

  addValidatorUser() {
    const request: SaveValidatorUserRequest = {
      servicePointId: this.servicePointId,
      validatorUserId: this.usersControl.value
    };

    this.apiService.saveValidatorUser(request).subscribe(response => {
      this.findAllValidatorUsers(this.servicePointId);
      this.usersControl.reset();
      this.toasterService.success(
        this.messages.success.SUCCESS_SAVE_OK[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale], {positionClass: 'toast-top-center'});
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(error.error.message, error.error.status, {positionClass: 'toast-top-center'});
    });
  }

  // deleteValidatorUser(validatorUser: ValidatorUser) {
  //   this.apiService.deleteValidatorUserById(validatorUser.id).subscribe(response => {
  //     this.toasterService.success('Validáló orvos törölve', 'Ok', {positionClass: 'toast-top-center'});
  //     this.findAllValidatorUsers(this.servicePointId);
  //   }, (error: Response) => {
  //     console.log(error);
  //   });
  // }

  deleteValidatorUser(validatorUser: ValidatorUser) {
    this.apiService.deleteValidatorUserBySp(this.servicePointId, validatorUser.id).subscribe(response => {
      this.toasterService.success(
        this.messages.success.SUCCESS_VALIDATOR_USER_DELETED[this.messages.locale],
        this.messages.success.SUCCESS_TITLE[this.messages.locale], {positionClass: 'toast-top-center'});
      this.findAllValidatorUsers(this.servicePointId);
    }, (error: HttpErrorResponse) => {
      this.toasterService.error(error.error.message, error.error.status, {positionClass: 'toast-top-center'});
    });
  }



}
