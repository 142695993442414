<div class="wrapper" [formGroup]="cytologyApplicationFormV2" id="#cytologyForm">
    <div class="row mb-2" *ngIf="cytologyVersion === 'v4'">
      <div class="col">
        <div class="form-inline">
          <label class="cytologyLabel mr-2">Naplószám: </label>
          <input [formControl]="logNumber" class="cytologyInput form-control mr-3" disabled/>
        </div>
      </div>
    </div>
    <div class="form-inline ml-3">
        <div class="form-group form-check mr-3">
            <input [formControl]="konvCytologia" type="checkbox" class="form-check-input" id="konvCytologia">
            <label style="margin-right: 116px;" class="form-check-label" for="konvCytologia" i18n>Konv. cytologia</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="konvCytologiaLbc" type="checkbox" class="form-check-input" id="konvCytologiaLbc">
            <label  style="margin-right: 116px;" class="form-check-label" for="konvCytologiaLbc" i18n>LBC.</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="konvCytologiaHrHpvTip" type="checkbox" class="form-check-input" id="konvCytologiaHrHpvTip">
            <label  style="margin-right: 116px;" class="form-check-label" for="konvCytologiaHrHpvTip" i18n>hr HPV tip.</label>
        </div>
        <div class="form-group form-check">
            <input [formControl]="konvCytologiaHrHpvTipPluszCyt" type="checkbox" class="form-check-input" id="konvCytologiaHrHpvTipPluszCyt">
            <label class="form-check-label" for="konvCytologiaHrHpvTipPluszCyt" i18n>hr HPV tip. + cyt.</label>
        </div>
    </div>
    <div class="form-inline ml-3 mt-3">
        <div class="form-group form-check mr-3">
            <input [formControl]="konvCytologiaHrHpvTipPluszLbc" type="checkbox" class="form-check-input" id="konvCytologiaHrHpvTipPluszLbc">
            <label  style="margin-right: 97px;" class="form-check-label" for="konvCytologiaHrHpvTipPluszLbc" i18n>hr HPV tip. + LBC.</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="konvCytologiaCintec" type="checkbox" class="form-check-input" id="konvCytologiaCintec">
            <label  style="margin-right: 100px;" class="form-check-label" for="konvCytologiaCintec" i18n>CINtec</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="konvCytologiaCintecAltalany" type="checkbox" class="form-check-input" id="konvCytologiaCintecAltalany">
            <label  style="margin-right: 86px;" class="form-check-label" for="konvCytologiaCintecAltalany" i18n>CINtec általány</label>
        </div>
        <div class="form-group form-check">
            <input [formControl]="konvCytologiaSurgos" type="checkbox" class="form-check-input" id="konvCytologiaSurgos">
            <label class="form-check-label" for="konvCytologiaSurgos" i18n>sürgős</label>
        </div>

    </div>
    <div class="form-inline mt-3" >
        <label style="margin-right: 5px; width: 130px;" class="cytologyLabel" i18n>utolsó menstruáció</label>
        <input [formControl]="utolsoMenstruacio" class="dateInput cytologyInput form-control" type="date" max="2300-12-31" min="1900-01-01" [lang]="dateType"/>
        <label style="margin-right: 5px; width: 120px;" class="cytologyLabel" i18n>kenetvétel ideje</label>
        <input [formControl]="kenetvetelIdeje" class="dateInput cytologyInput form-control" type="date" max="2300-12-31" min="1900-01-01" [lang]="dateType"/>
        <label style="margin-right: 5px; width: 85px;" class="cytologyLabel" i18n>kenetszám</label>
        <input [formControl]="kenetSzam" class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel" i18n>kolposzkópia</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="kolposzkopiaNormal" type="checkbox" class="form-check-input" id="kolposzkopiaNormal">
            <label class="form-check-label" for="kolposzkopiaNormal" i18n>normál</label>
        </div>
        <div class="form-group form-check">
            <input [formControl]="kolposzkopiaKoros" type="checkbox" class="form-check-input" id="kolposzkopiaKoros">
            <label class="form-check-label" for="kolposzkopiaKoros" i18n>kóros</label>
        </div>
        <label style="margin-right: 10px;" class="cytologyLabel" i18n>éspedig</label>
        <input [formControl]="kolposzkopiaMegjegyzes" style="width: 300px"class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel" i18n>jelenleg terhes</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="jelenlegTerhesNem" type="checkbox" class="form-check-input" id="jelenlegTerhesNem">
            <label style="margin-right: 19px;" class="form-check-label" for="jelenlegTerhesNem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-5">
            <input [formControl]="jelenlegTerhesIgen" type="checkbox" class="form-check-input" id="jelenlegTerhes">
            <label class="form-check-label" for="jelenlegTerhes" i18n>igen</label>
        </div>
        <div class="form-group form-check mr-5">
            <input [formControl]="jelenlegTerhesLaktal" type="checkbox" class="form-check-input" id="laktal">
            <label style="margin-right: 28px;"class="form-check-label" for="laktal" i18n>laktál</label>
        </div>
        <input [formControl]="jelenlegTerhesMegjegyzes" style="width: 300px"class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel" i18n>fogamzásgátlás</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="fogamzasgatlasNem" type="checkbox" class="form-check-input" id="fogamzasgatlasNem">
            <label style="margin-right: 19px;"class="form-check-label" for="fogamzasgatlasNem" i18n>nem</label>
        </div>
        <div class="form-group form-check">
            <input [formControl]="fogamzasgatlasIgen" type="checkbox" class="form-check-input" id="fogamzasgatlas">
            <label class="form-check-label" for="fogamzasgatlas" i18n>igen</label>
        </div>
        <label sytle="width: 40px;" class="mr-3 ml-3" i18n>éspedig</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="fogamzasgatlasIUD" type="checkbox" class="form-check-input" id="IUD">
            <label class="form-check-label" for="IUD" i18n>IUD</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="fogamzasgatlasHormon" type="checkbox" class="form-check-input" id="hormon">
            <label class="form-check-label" for="hormon" i18n>hormon</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="fogamzasgatlasEgyeb" type="checkbox" class="form-check-input" id="egyeb">
            <label class="form-check-label" for="egyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="fogamzasgatlasMegjegyzes" style="width: 166px"class="cytologyInput form-control" type="text"/>
    </div>
    <label class="ml-3 mt-3 mb-3" i18n>előzmény</label>
    <div class="form-inline">
        <label style="width: 84px"class="cytologyLabel" i18n>citológia</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isCitologiaNem" type="checkbox" class="form-check-input" id="citologiaNem">
            <label style="margin-right: 10px;" class="form-check-label" for="citologiaNem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isCitologiaIgen" type="checkbox" class="form-check-input" id="citologiaIgen">
            <label class="form-check-label" for="citologiaIgen" i18n>igen</label>
        </div>
        <label style="margin-right: 2px" i18n>hol</label>
        <input [formControl]="citologiaHely" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px" i18n>mikor</label>
        <input [formControl]="citologiaIdo" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px" i18n>dg</label>
        <input [formControl]="citologiaDg" class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 99px;
        margin-right: 15px;"class="cytologyLabel" i18n>HPV tipizálás</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="hpvTipizalasNem" type="checkbox" class="form-check-input" id="hpvTipizalasNem">
            <label style="margin-right: 10px;" class="form-check-label" for="hpvTipizalasNem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="hpvTipizalasIgen" type="checkbox" class="form-check-input" id="hpvTipizalasIgen">
            <label class="form-check-label" for="hpvTipizalasIgen" i18n>igen</label>
        </div>
        <label class="cytologyLabel" style="margin-right: 10px" i18n>eredménye</label>
        <input [formControl]="hpvTipizalasEredmenye"  style="width: 400px" class="cytologyInput form-control mr-3" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel" i18n>szövettan</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isSzovettanNem" type="checkbox" class="form-check-input" id="szovettanNem">
            <label style="margin-right: 10px;" class="form-check-label" for="szovettanNem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isSzovettanIgen" type="checkbox" class="form-check-input" id="szovettanIgen">
            <label class="form-check-label" for="szovettanIgen" i18n>igen</label>
        </div>
        <label style="margin-right: 2px" i18n>hol</label>
        <input [formControl]="szovettanHely" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px" i18n>mikor</label>
        <input [formControl]="szovettanIdo" class="cytologyInput form-control mr-3" type="text"/>
        <label style="margin-right: 2px" i18n>dg</label>
        <input [formControl]="szovettanDg" class="cytologyInput form-control" type="text"/>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel" i18n>műtét</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isMutetNem" type="checkbox" class="form-check-input" id="mutetnem">
            <label style="margin-right: 10px;" class="form-check-label" for="mutetnem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isMutetIgen" type="checkbox" class="form-check-input" id="mutetigen">
            <label class="form-check-label" for="mutetigen" i18n>igen</label>
        </div>
        <label style="margin-right: 10px;" class="cytologyLabel" i18n>éspedig</label>
        <!-- <input [formControl]="mutetMegjegyzes" style="width: 400px" class="cytologyInput form-control mr-3" type="text"/> -->
        <textarea [formControl]="mutetMegjegyzes" style="width: 400px" class="cytologyInputArea form-control" rows="5"></textarea>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel" i18n>kezelés</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="isKezelesNem" type="checkbox" class="form-check-input" id="kezelesNem">
            <label style="margin-right: 10px;" class="form-check-label" for="kezelesNem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="isKezelesIgen" type="checkbox" class="form-check-input" id="kezelesIgen">
            <label class="form-check-label" for="kezelesIgen" i18n>igen</label>
        </div>
        <label style="margin-right: 10px;" class="cytologyLabel" i18n>éspedig</label>
        <!-- <input [formControl]="kezelesMegjegyzes" style="width: 400px" class="cytologyInput form-control mr-3" type="text"/> -->
        <textarea [formControl]="kezelesMegjegyzes" style="width: 400px" class="cytologyInputArea form-control" rows="5"></textarea>
    </div>
    <div class="form-inline mt-3">
        <label style="width: 84px"class="cytologyLabel" i18n>egyéb</label>
        <div class="form-group form-check mr-3">
            <input [formControl]="otherNem" type="checkbox" class="form-check-input" id="egyebNem">
            <label style="margin-right: 10px;" class="form-check-label" for="egyebNem" i18n>nem</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="otherIgen" type="checkbox" class="form-check-input" id="egyebIgen">
            <label class="form-check-label" for="egyebIgen" i18n>igen</label>
        </div>
        <label  style="margin-right: 10px;" class="cytologyLabel" i18n>éspedig</label>
        <!-- <input [formControl]="otherMegjegyzes" style="width: 400px" class="cytologyInput form-control mr-3" type="text"/> -->
        <textarea [formControl]="otherMegjegyzes" style="width: 400px" class="cytologyInputArea form-control" rows="5"></textarea>
    </div>
    <div class="form-inline mt-3">
        <label class="cytologyLabel" i18n>eszközös vétel</label>
        <div class="form-group form-check mr-5">
            <input [formControl]="eszkozosVetelECTF" type="checkbox" class="form-check-input" id="ectfzonabol">
            <label style="margin-right: 10px;" class="form-check-label" for="ectfzonabol" i18n>EC/TF zónából</label>
        </div>
        <div class="form-group form-check mr-5">
            <input [formControl]="eszkozosVetelPortio" type="checkbox" class="form-check-input" id="portiofelszinerol">
            <label class="form-check-label" for="portiofelszinerol" i18n>portio felszínéről</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="eszkozosVetelMindketto" type="checkbox" class="form-check-input" id="mindkettorol">
            <label style="margin-right: 17px;"class="form-check-label" for="mindkettorol" i18n>mindkettőről</label>
        </div>
        <div class="form-group form-check mr-3">
            <input [formControl]="eszkozosVetelHuvely" type="checkbox" class="form-check-input" id="huvelyboltozatrol">
            <label class="form-check-label" for="huvelyboltozatrol" i18n>hüvelyboltozatról</label>
        </div>
    </div>
    <div class="form-inline mt-1">
        <div style="margin-left: 190px;" class="form-group form-check mr-3">
            <input [formControl]="eszkozosVetelEgyeb" type="checkbox" class="form-check-input" id="eszkozosVetelEgyeb">
            <label class="form-check-label" for="eszkozosVetelEgyeb" i18n>egyéb</label>
        </div>
        <input [formControl]="eszkozosVetelEgyebMegjegyzes"  class="cytologyInput form-control mr-3" type="text"/>
    </div>
</div>
